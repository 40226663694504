<!-- validation hero -->
<validation-hero [display]="match.validationErrors.length > 0"
  [header]="'This match has some setup problems. Please open match configuration for more information.'">
</validation-hero>

<!-- match -->
<ion-item class="ion-no-padding" style="--inner-padding-end: 0px" lines="none">

  <!-- *********** STROKE PLAY *********** -->
  <ion-grid class="ion-no-padding game" *ngIf="match.game === GameType.StrokePlay">

    <!-- match name -->
    <ion-row class="ion-no-padding matchName">
      <ion-col class="ion-no-padding">
        {{match.getGame(match.game)?.name}}
      </ion-col>
    </ion-row>

    <!-- match config -->
    <ion-row class="ion-no-padding matchConfig">
      <ion-col class="ion-no-padding">
        {{match.config()}}
      </ion-col>
    </ion-row>

    <!-- header row -->
    <ion-row class="ion-align-items-center" style="font-size: 14px">
      <ion-col class="ion-no-padding" size="1.2">
        <ion-text class="ion-float-left"> Pos </ion-text>
      </ion-col>

      <ion-col class="ion-no-padding" size="4.8">
        <ion-text class="ion-float-left"> Team </ion-text>
      </ion-col>

      <ion-col class="ion-no-padding" size="4">
        <ion-text class="ion-float-right"> To Par </ion-text>
      </ion-col>

      <ion-col class="ion-no-padding" size="2">
        <ion-text class="ion-float-right"> Thru </ion-text>
      </ion-col>
    </ion-row>

    <!-- display scores and position when the teams have scores for the sub match -->
    <ng-container *ngIf="this.match.subMatches[0].totalScoreLeaderboardEventTeamListMerged.length > 0; else noteams">

      <ion-row class="ion-no-padding ion-align-items-center"
        *ngFor="let teamScoreCard of this.match.subMatches[0].totalScoreLeaderboardEventTeamListMerged; trackBy: teamId;">

        <!-- position -->
        <ion-col class="ion-no-padding" size="1.2">
          {{teamScoreCard.position === 1000 ? '-' : teamScoreCard.tiedIndicator + teamScoreCard.position}}
        </ion-col>

        <!-- name -->
        <ion-col class="ion-no-padding ion-text-nowrap fadeText" size="6.5">{{teamScoreCard.team.name}}</ion-col>

        <!-- score -->
        <ion-col class="ion-no-padding" size="2.25">
          <ion-chip *ngIf="teamScoreCard.won" class="won">
            <ion-label>Won</ion-label>
          </ion-chip>
          <ion-text class="ion-float-right">
            <ion-text>{{teamScoreCard.position === 1000 || teamScoreCard.teamToPar <= 0 ? '' : '+' }}</ion-text>
                <ion-text>{{teamScoreCard.position === 1000 ? '-' : (teamScoreCard.teamToPar === 0 ? 'E' :
                  teamScoreCard.teamToPar)}}</ion-text>
            </ion-text>
        </ion-col>

        <!-- thru hole -->
        <ion-col class="ion-no-padding" size="2">
          <ion-text class="ion-float-right">
            <!-- if event is starting on the back nine then show * -->
            {{teamScoreCard.position === 1000 ? '-' : ((teamScoreCard.holeThruMatchIndex + 1).toString() +
            (event.startingHoleIndex === 9 ? '*' : ''))}}
          </ion-text>
        </ion-col>

      </ion-row>

    </ng-container>

  </ion-grid>

  <!-- *********** STABLEFORD *********** -->
  <ion-grid class="ion-no-padding game" *ngIf="match.game === GameType.Stableford">

    <!-- match name -->
    <ion-row class="ion-no-padding matchName">
      <ion-col class="ion-no-padding">
        {{match.getGame(match.game)?.name}}
      </ion-col>
    </ion-row>

    <!-- match config -->
    <ion-row class="ion-no-padding matchConfig">
      <ion-col class="ion-no-padding">
        {{match.config()}}
      </ion-col>
    </ion-row>

    <!-- header row -->
    <ion-row class="ion-align-items-center" style="font-size: 14px">
      <ion-col class="ion-no-padding" size="1.2">
        <ion-text class="ion-float-left"> Pos </ion-text>
      </ion-col>

      <ion-col class="ion-no-padding" size="4.8">
        <ion-text class="ion-float-left"> Team </ion-text>
      </ion-col>

      <ion-col class="ion-no-padding" size="4">
        <ion-text class="ion-float-right"> Points </ion-text>
      </ion-col>

      <ion-col class="ion-no-padding" size="2">
        <ion-text class="ion-float-right"> Thru </ion-text>
      </ion-col>
    </ion-row>

    <!-- display scores and position when the teams have scores for the sub match -->
    <ng-container *ngIf="this.match.subMatches[0].totalScoreLeaderboardEventTeamListMerged.length > 0; else noteams">

      <ion-row class="ion-no-padding ion-align-items-center"
        *ngFor="let teamScoreCard of this.match.subMatches[0].totalScoreLeaderboardEventTeamListMerged; trackBy: teamId;">

        <!-- position -->
        <ion-col class="ion-no-padding" size="1.2">
          {{teamScoreCard.position === 1000 ? '-' : teamScoreCard.tiedIndicator + teamScoreCard.position}}
        </ion-col>

        <!-- name -->
        <ion-col class="ion-no-padding ion-text-nowrap fadeText" size="6.5">{{teamScoreCard.team.name}}</ion-col>

        <!-- score -->
        <ion-col class="ion-no-padding" size="2.25">
          <ion-chip *ngIf="teamScoreCard.won" class="won">
            <ion-label>Won</ion-label>
          </ion-chip>
          <ion-text class="ion-float-right">
            <ion-text>{{teamScoreCard.position === 1000 ? '-' : teamScoreCard.teamScore}}</ion-text>
          </ion-text>
        </ion-col>

        <!-- thru hole -->
        <ion-col class="ion-no-padding" size="2">
          <ion-text class="ion-float-right">
            <!-- if event is starting on the back nine then show * -->
            {{teamScoreCard.position === 1000 ? '-' : ((teamScoreCard.holeThruMatchIndex + 1).toString() +
            (event.startingHoleIndex === 9 ? '*' : ''))}}
          </ion-text>
        </ion-col>

      </ion-row>

    </ng-container>

  </ion-grid>

  <!-- *********** MATCH PLAY or SKINS *********** -->
  <ion-grid class="ion-no-padding game" *ngIf="[GameType.MatchPlay, GameType.Skins].includes(this.match.game)">

    <!-- match name -->
    <ion-row class="ion-no-padding matchName">
      <ion-col class="ion-no-padding">
        {{match.getGame(match.game)?.name}}
      </ion-col>
    </ion-row>

    <!-- match config -->
    <ion-row class="ion-no-padding matchConfig">
      <ion-col class="ion-no-padding">
        {{match.config()}}
      </ion-col>
    </ion-row>

    <!-- get sub match varible -->
    <ng-container *ngVar="this.match.subMatches[0]; let subMatch;">

      <!-- display scores and position when the teams have scores for the sub match -->
      <ng-container *ngIf="subMatch.totalHoleByHoleLeaderboardEventTeamListMerged.length > 0; else noteams">

        <!-- build the list of horizontal hole numbers for Match play-->
        <ion-row class="ion-no-padding" *ngIf="match.game === GameType.MatchPlay">
          <ion-col class="ion-no-padding" style="display: flex">
            <div class="hole" *ngFor="let holeIndex of match.subMatches[0].getAdjustMatchHoleIndexes(event)"
              [ngStyle]="{ 'background-color': subMatch.holeByHoleLeaderboard?.eventsList[0]?.holes[holeIndex]?.holeWonByTeam ? subMatch.holeByHoleLeaderboard?.eventsList[0]?.holes[holeIndex]?.holeWonByTeam.teamColor.backgroundcolor : ([null, undefined].includes(subMatch.holeByHoleLeaderboard?.eventsList[0]?.holes[holeIndex]) ? 'initial' : 'var(--ion-color-white-d)'), 
                            'border-color': subMatch.holeByHoleLeaderboard?.eventsList[0]?.holes[holeIndex]?.holeWonByTeam ? subMatch.holeByHoleLeaderboard?.eventsList[0]?.holes[holeIndex]?.holeWonByTeam.teamColor.backgroundcolor : 'var(--ion-color-black-b)',
                            'color': subMatch.holeByHoleLeaderboard?.eventsList[0]?.holes[holeIndex]?.holeWonByTeam ? subMatch.holeByHoleLeaderboard?.eventsList[0]?.holes[holeIndex]?.holeWonByTeam.teamColor.fontcolor: 'initial'}">
              <div>{{holeIndex + 1}}</div>
            </div>
          </ion-col>
        </ion-row>

        <!-- build the list of horizontal hole numbers for Skins-->
        <ion-row class="ion-no-padding" *ngIf="match.game === GameType.Skins">
          <ion-col class="ion-no-padding" style="display: flex">
            <div class="hole" *ngFor="let holeIndex of match.subMatches[0].getAdjustMatchHoleIndexes(event)"
              [ngStyle]="{ 
                              'background-color': (subMatch.holeByHoleLeaderboard?.eventsList[0]?.holes[holeIndex]?.holeWonByTeam && !subMatch.holeByHoleLeaderboard?.eventsList[0]?.holes[holeIndex]?.holeCarryOverByTeam) ? subMatch.holeByHoleLeaderboard?.eventsList[0]?.holes[holeIndex]?.holeWonByTeam?.teamColor.backgroundcolor : ([null, undefined].includes(subMatch.holeByHoleLeaderboard?.eventsList[0]?.holes[holeIndex]) ? 'initial' : 'var(--ion-color-white-d)'), 
                              'border-color': (subMatch.holeByHoleLeaderboard?.eventsList[0]?.holes[holeIndex]?.holeWonByTeam) ? subMatch.holeByHoleLeaderboard?.eventsList[0]?.holes[holeIndex]?.holeWonByTeam.teamColor.backgroundcolor : 
                                    (subMatch.holeByHoleLeaderboard?.eventsList[0]?.holes[holeIndex]?.holeWonByTeam===null && subMatch.holeByHoleLeaderboard?.eventsList[0]?.holes[holeIndex]?.holeCarryOverByTeam===null ? 'var(--ion-color-black-b)' : 
                                    (subMatch.holeByHoleLeaderboard?.eventsList[0]?.holes[holeIndex]?.holeCarryOverByTeam) ? subMatch.holeByHoleLeaderboard?.eventsList[0]?.holes[holeIndex]?.holeCarryOverByTeam?.teamColor.backgroundcolor : 'initial'), 
                              'color': (subMatch.holeByHoleLeaderboard?.eventsList[0]?.holes[holeIndex]?.holeWonByTeam) ? subMatch.holeByHoleLeaderboard?.eventsList[0]?.holes[holeIndex]?.holeWonByTeam?.teamColor.fontcolor: 'initial',
                              'border-width': (!subMatch.holeByHoleLeaderboard?.eventsList[0]?.holes[holeIndex]?.holeWonByTeam && subMatch.holeByHoleLeaderboard?.eventsList[0]?.holes[holeIndex]?.holeCarryOverByTeam) ? '1.5px': '0.5px'}">
              <div>{{holeIndex + 1}}</div>
            </div>
          </ion-col>
        </ion-row>

        <!-- show this message until the first hole has been scored -->
        <!-- <ion-row class="ion-no-padding"
          *ngIf="subMatch.holeByHoleLeaderboard.eventsList[subMatch.holeByHoleLeaderboard.eventsList.length - 1].holesList.length === 0"
          style="padding-top: 10px !important; font-size: smaller !important; color: var(--ion-color-white-d) !important;
        padding-bottom: 5px !important;">
          <ion-col class="ion-no-padding">
            Each hole will be scored after all teams have finished playing the hole.
          </ion-col>
        </ion-row> -->

        <!-- header row -->
        <ion-row class="ion-align-items-center" style="margin-top: 10px; font-size: 14px">

          <ion-col class="ion-no-padding" size="1.2">
            <ion-text class="ion-float-left"> Pos </ion-text>
          </ion-col>

          <ion-col class="ion-no-padding" size="4.8">
            <ion-text class="ion-float-left"> Team </ion-text>
          </ion-col>

          <ion-col class="ion-no-padding" size="4">
            <ion-text class="ion-float-right"> Holes won </ion-text>
          </ion-col>

          <ion-col class="ion-no-padding" size="2">
            <ion-text class="ion-float-right"> Thru </ion-text>
          </ion-col>

        </ion-row>

        <!-- build the list of teams winning team first -->
        <ion-row class="ion-no-padding ion-align-items-center"
          *ngFor="let teamScoreCard of this.match.subMatches[0].totalHoleByHoleLeaderboardEventTeamListMerged; trackBy: teamId;">

          <!-- position -->
          <ion-col class="ion-no-padding" size="1.2">
            {{teamScoreCard.position === 1000 ? '-' : teamScoreCard.tiedIndicator + teamScoreCard.position}}
          </ion-col>

          <!-- name -->
          <ion-col class="ion-no-padding ion-text-nowrap fadeText" style="display: flex" size="6.75">
            <div class="teamMarker" [ngStyle]="{ 'background-color': teamScoreCard.team.teamColor.backgroundcolor }">
            </div>
            <ion-text>{{teamScoreCard.team.name}}</ion-text>
          </ion-col>

          <!-- score -->
          <ion-col class="ion-no-padding" size="2">
            <ion-chip *ngIf="teamScoreCard.won" class="won">
              <ion-label>Won</ion-label>
            </ion-chip>
            <ion-text class="ion-float-right">
              <ion-text>{{teamScoreCard.position === 1000 ? '-' : teamScoreCard.holesWon}}</ion-text>
            </ion-text>
          </ion-col>

          <!-- thru hole -->
          <ion-col class="ion-no-padding" size="2">
            <ion-text class="ion-float-right">
              <!-- if event is starting on the back nine then show * -->
              {{teamScoreCard.position === 1000 ? '-' : ((teamScoreCard.holeThruMatchIndex + 1).toString() +
              (event.startingHoleIndex === 9 ? '*' : ''))}}
            </ion-text>
          </ion-col>

        </ion-row>

      </ng-container>

    </ng-container>

  </ion-grid>

  <!-- *********** SPINS or NASSAU *********** -->
  <ng-container *ngIf="[GameType.Spins9, GameType.Spins18, GameType.Nassau].includes(this.match.game)">

    <ng-container *ngIf="!showInScorecard">
      <ion-slides #matchSlides pager="true" effect="slide" [options]="slideOpts" class="matchSlides">
        <ion-slide *ngFor="let subMatch of match.subMatches;">
          <ng-container
            *ngTemplateOutlet="spins; context: { subMatch: subMatch, showInScorecard: showInScorecard }"></ng-container>
        </ion-slide>
      </ion-slides>
    </ng-container>

    <div *ngIf="showInScorecard" style="width: 100%;">
      <div *ngFor="let subMatch of match.subMatches;" class="subMatchContainer"
        style="display: flex; overflow-y: scroll;">
        <ng-container
          *ngTemplateOutlet="spins; context: { subMatch: subMatch, showInScorecard: showInScorecard }"></ng-container>
      </div>
    </div>

  </ng-container>

  <!-- *********** NINES *********** -->
  <ion-grid class="ion-no-padding game" *ngIf="match.game === GameType.Nines">

    <!-- match name -->
    <ion-row class="ion-no-padding matchName">
      <ion-col class="ion-no-padding">
        {{match.getGame(match.game)?.name}}
      </ion-col>
    </ion-row>

    <!-- match config -->
    <ion-row class="ion-no-padding matchConfig">
      <ion-col class="ion-no-padding">
        {{match.config()}}
      </ion-col>
    </ion-row>

    <!-- header row -->
    <ion-row class="ion-align-items-center" style="font-size: 14px">
      <ion-col class="ion-no-padding" size="1.2">
        <ion-text class="ion-float-left"> Pos </ion-text>
      </ion-col>

      <ion-col class="ion-no-padding" size="4.8">
        <ion-text class="ion-float-left"> Team </ion-text>
      </ion-col>

      <ion-col class="ion-no-padding" size="4">
        <ion-text class="ion-float-right"> Points </ion-text>
      </ion-col>

      <ion-col class="ion-no-padding" size="2">
        <ion-text class="ion-float-right"> Thru </ion-text>
      </ion-col>
    </ion-row>

    <!-- display scores and position when the teams have scores for the sub match -->
    <ng-container *ngIf="this.match.subMatches[0].totalScoreLeaderboardEventTeamListMerged.length > 0; else noteams">

      <ion-row class="ion-no-padding ion-align-items-center"
        *ngFor="let teamScoreCard of this.match.subMatches[0].totalScoreLeaderboardEventTeamListMerged; trackBy: teamId;">

        <!-- position -->
        <ion-col class="ion-no-padding" size="1.2">
          {{teamScoreCard.position === 1000 ? '-' : teamScoreCard.tiedIndicator + teamScoreCard.position}}
        </ion-col>

        <!-- name -->
        <ion-col class="ion-no-padding ion-text-nowrap fadeText" size="6.5">{{teamScoreCard.team.name}}</ion-col>

        <!-- score -->
        <ion-col class="ion-no-padding" size="2.25">
          <ion-chip *ngIf="teamScoreCard.won" class="won">
            <ion-label>Won</ion-label>
          </ion-chip>
          <ion-text class="ion-float-right">
            <ion-text>{{teamScoreCard.position === 1000 ? '-' : teamScoreCard.teamScore}}</ion-text>
          </ion-text>
        </ion-col>

        <!-- thru hole -->
        <ion-col class="ion-no-padding" size="2">
          <ion-text class="ion-float-right">
            <!-- if event is starting on the back nine then show * -->
            {{teamScoreCard.position === 1000 ? '-' : ((teamScoreCard.holeThruMatchIndex + 1).toString() +
            (event.startingHoleIndex === 9 ? '*' : ''))}}
          </ion-text>
        </ion-col>

      </ion-row>

    </ng-container>

  </ion-grid>

</ion-item>

<!-- icon row -->
<ion-item *ngIf="!showInScorecard" class="iconRow" lines="full"
  style="--min-height: unset; font-size: larger; color: grey;">

  <ion-grid class="ion-no-padding">

    <ion-row class="ion-no-padding">

      <ion-col class="ion-no-padding" size="2" (click)="openScorecardMethod(match.players, match)">
        <ion-icon src="../../assets/icons/scoreboard3.svg"></ion-icon>
      </ion-col>

      <ion-col class="ion-no-padding" size="2" (click)="viewMatchFormat()">
        <ion-icon name="game-controller"></ion-icon>
      </ion-col>

      <!-- only show if logged in member created the match -->
      <ion-col class="ion-no-padding ion-text-right" *ngIf="match.organizerMemberId === accountService.member.id"
        (click)="matchActionConfirmation()">
        <ion-icon name="options"></ion-icon>
      </ion-col>

      <!-- <ion-col class="ion-no-padding ion-text-right" *ngIf="match.organizerMemberId === accountService.member.id">
        <ion-button (click)="matchActionConfirmation();" *ngIf="event.isMemberAdmin(accountService.member)"
          shape="circleSmall">
          <ion-icon name="ellipsis-vertical" slot="icon-only"></ion-icon>
        </ion-button>
      </ion-col> -->

    </ion-row>

  </ion-grid>

</ion-item>

<!-- no team scores yet -->
<ng-template #noscores>
  <ion-row style="margin-left: 10px;">
    <ion-col size="12" class="ion-text-center" style="margin-top: 10px;">
      <ion-note style="font-size: smaller;">No scores have been posted for this match</ion-note>
    </ion-col>
  </ion-row>
</ng-template>

<!-- no teams yet -->
<ng-template #noteams>
  <ion-row style="margin-left: 10px;">
    <ion-col size="12" class="ion-text-center" style="margin-top: 10px;">
      <ion-note style="font-size: smaller;">The teams for this match haven't yet been created.</ion-note>
    </ion-col>
  </ion-row>
</ng-template>

<!-- spins or nassau -->
<ng-template #spins let-subMatch="subMatch" let-showInScorecard="showInScorecard">

  <ion-grid class="ion-no-padding game" [ngStyle]="{'min-width': showInScorecard ? '350px' : 'unset'}">

    <!-- sub match name -->
    <ion-row class="ion-no-padding matchName">
      <ion-col class="ion-no-padding">
        {{subMatch.subMatchName()}}
      </ion-col>
    </ion-row>

    <!-- match config -->
    <ion-row class="ion-no-padding matchConfig">
      <ion-col class="ion-no-padding">
        {{match.config()}}
      </ion-col>
    </ion-row>

    <!-- display scores and position when the teams have scores for the sub match -->
    <ng-container *ngIf="subMatch.totalHoleByHoleLeaderboardEventTeamListMerged.length > 0; else subMatchNotStarted;">

      <!-- build the list of horizontal hole numbers -->
      <ion-row class="ion-no-padding">
        <ion-col class="ion-no-padding" style="display: flex">
          <div class="hole" *ngFor="let holeIndex of subMatch.getAdjustMatchHoleIndexes(event);"
            [ngStyle]="{ 'background-color': subMatch.holeByHoleLeaderboard.eventsList[0]?.holes[holeIndex]?.holeWonByTeam ? subMatch.holeByHoleLeaderboard.eventsList[0]?.holes[holeIndex]?.holeWonByTeam.teamColor.backgroundcolor : ([null, undefined].includes(subMatch.holeByHoleLeaderboard?.eventsList[0]?.holes[holeIndex]) ? 'initial' : 'var(--ion-color-white-d)'), 
                          'border-color': subMatch.holeByHoleLeaderboard.eventsList[0]?.holes[holeIndex]?.holeWonByTeam ? subMatch.holeByHoleLeaderboard.eventsList[0]?.holes[holeIndex]?.holeWonByTeam.teamColor.backgroundcolor : 'var(--ion-color-black-b)',
                          'color': subMatch.holeByHoleLeaderboard.eventsList[0]?.holes[holeIndex]?.holeWonByTeam ? subMatch.holeByHoleLeaderboard.eventsList[0]?.holes[holeIndex]?.holeWonByTeam.teamColor.fontcolor: 'initial'}">
            <div>{{holeIndex + 1}}</div>
          </div>
        </ion-col>
      </ion-row>

      <!-- header row -->
      <ion-row class="ion-align-items-center" style="margin-top: 10px; font-size: 14px">

        <ion-col class="ion-no-padding" size="1.2">
          <ion-text class="ion-float-left"> Pos </ion-text>
        </ion-col>

        <ion-col class="ion-no-padding" size="4.8">
          <ion-text class="ion-float-left"> Team </ion-text>
        </ion-col>

        <ion-col class="ion-no-padding" size="4">
          <ion-text class="ion-float-right"> Holes won </ion-text>
        </ion-col>

        <ion-col class="ion-no-padding" size="2">
          <ion-text class="ion-float-right"> Thru </ion-text>
        </ion-col>

      </ion-row>

      <!-- build the list of teams winning team first -->
      <ion-row class="ion-no-padding ion-align-items-center"
        *ngFor="let teamScoreCard of subMatch.totalHoleByHoleLeaderboardEventTeamListMerged; trackBy: teamId;">

        <!-- position -->
        <ion-col class="ion-no-padding" size="1.2">
          {{teamScoreCard.position === 1000 ? '-' : teamScoreCard.tiedIndicator + teamScoreCard.position}}
        </ion-col>

        <!-- name -->
        <ion-col class="ion-no-padding ion-text-nowrap fadeText" style="display: flex" size="6.75">
          <div class="teamMarker" [ngStyle]="{ 'background-color': teamScoreCard.team.teamColor.backgroundcolor }">
          </div>
          <ion-text>{{teamScoreCard.team.name}}</ion-text>
        </ion-col>

        <!-- score -->
        <ion-col class="ion-no-padding" size="2">
          <ion-chip *ngIf="teamScoreCard.won" class="won">
            <ion-label>Won</ion-label>
          </ion-chip>
          <ion-text class="ion-float-right">
            <ion-text>{{teamScoreCard.position === 1000 ? '-' : teamScoreCard.holesWon}}</ion-text>
          </ion-text>
        </ion-col>

        <!-- thru hole -->
        <ion-col class="ion-no-padding" size="2">
          <ion-text class="ion-float-right">
            <!-- if event is starting on the back nine then show * -->
            {{teamScoreCard.position === 1000 ? '-' : teamScoreCard.holeThruMatchIndex + 1}}
          </ion-text>
        </ion-col>

      </ion-row>

    </ng-container>

  </ion-grid>

  <!-- show the press -->
  <ng-container *ngIf="subMatch.press">
    <ng-container
      *ngTemplateOutlet="spins; context: { subMatch: subMatch.press, showInScorecard: showInScorecard }"></ng-container>
  </ng-container>

</ng-template>

<!-- create team -->
<ng-template #showCreateTeam let-subMatch="subMatch">
  <ion-row class="ion-no-padding buttonBar">
    <ion-col size="12" style="margin-top: 10px; text-align: center;">
      <ion-button shape="round" color="primary" size="default" (click)="createTeam(subMatch)">
        Create Teams
      </ion-button>
    </ion-col>
  </ion-row>
</ng-template>

<!-- subMatch hasn't started -->
<ng-template #subMatchNotStarted>
  <ion-row class="ion-no-padding">
    <ion-col size="12" style="margin-top: 10px; text-align: center;">
      Has not yet started.
    </ion-col>
  </ion-row>
</ng-template>