import {
  NgModule,
  ErrorHandler,
  CUSTOM_ELEMENTS_SCHEMA,
  Injectable,
} from "@angular/core";
import { IonicModule, IonicRouteStrategy, Platform } from "@ionic/angular";
import { IonicStorageModule } from "@ionic/storage";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { RouteReuseStrategy } from "@angular/router";
import { AngularFireModule } from "@angular/fire/compat";
import { AngularFireAuthModule } from "@angular/fire/compat/auth";
import { AngularFireStorageModule } from "@angular/fire/compat/storage";
import { AngularFirestoreModule } from "@angular/fire/compat/firestore";
import { initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { HttpClientModule } from "@angular/common/http";
import { AngularFireAuthGuard } from "@angular/fire/compat/auth-guard";
import * as SentryAngular from "@sentry/angular-ivy";

//native plugins
import { TouchID } from "@ionic-native/touch-id/ngx";
import { Contacts } from "@ionic-native/contacts/ngx"; //@capacitor-community/contacts
import { Geolocation } from "@ionic-native/geolocation/ngx";
import { NativeGeocoder } from "@ionic-native/native-geocoder/ngx";
import { MediaCapture } from "@ionic-native/media-capture/ngx"; //@capacitor-community/media???
import { Diagnostic } from "@ionic-native/diagnostic/ngx";
import { Market } from "@ionic-native/market/ngx"; //@capacitor-community/native-market
import { AppRate } from "@awesome-cordova-plugins/app-rate/ngx";
import { Chooser } from "@ionic-native/chooser/ngx";

//my services
import { AppRoutingModule } from "./app.routing";
import { AppComponent } from "./app.component";

//components
import { DragulaModule } from "ng2-dragula";
import { register } from 'swiper/element/bundle';
register();

//modals
import { ProfilePageModule } from "../app/pages/profile/profile.module";
import { SupportPageModule } from "../app/pages/support/support.module";
import { ProfileSummaryPageModule } from "../app/pages/profile-summary/profile-summary.module";
import { TermsOfServicePageModule } from "../app/pages/terms-of-service/terms-of-service.module";
import { PrivacyPolicyPageModule } from "../app/pages/privacy-policy/privacy-policy.module";
import { SystemUtilitiesPageModule } from "./pages/system-utilities/system-utilities.module";
import { PreferencesPageModule } from "../app/pages/preferences/preferences.module";
import { ClubDetailPageModule } from "./pages/club-detail/club-detail.module";
import { ClubSearchPageModule } from "./pages/club-search/club-search.module";
import { CourseDetailPageModule } from "./pages/course-detail/course-detail.module";
import { CourseTeePageModule } from "./pages/course-tee/course-tee.module";
import { CourseHolePageModule } from "./pages/course-hole/course-hole.module";
import { CourseScorecardPageModule } from "./pages/course-scorecard/course-scorecard.module";
import { ColorPickerPopoverPageModule } from "./pages/color-popover/color-picker-popover.module";
import { GroupEventDetailPageModule } from "./pages/group-event-detail/group-event-detail.module";
import { GroupTripDetailPageModule } from "./pages/group-trip-detail/group-trip-detail.module";
import { GroupTripScorecardPageModule } from "./pages/group-trip-scorecard/group-trip-scorecard.module";
import { MemberDetailPageModule } from "../app/pages/member-detail/member-detail.module";
import { GroupEventViewPageModule } from "./pages/group-event-view/group-event-view.module";
import { GroupTripViewPageModule } from "./pages/group-trip-view/group-trip-view.module";
import { EventDetailPageModule } from "../app/pages/event-detail/event-detail.module";
import { EventViewPageModule } from "../app/pages/event-view/event-view.module";
import { EventScoringPageModule } from "../app/pages/event-scoring/event-scoring.module";
import { EventScorecardPageModule } from "./pages/event-scorecard/event-scorecard.module";
import { ContactPageModule } from "../app/pages/contact/contact.module";
import { MemberListPageModule } from "../app/pages/member-list/member-list.module";
import { MemberSearchPageModule } from "../app/pages/member-search/member-search.module";
import { GroupSearchPageModule } from "../app/pages/group-search/group-search.module";
import { NotePageModule } from "../app/pages/note/note.module";
import { NetworkPageModule } from "../app/pages/network/network.module";
import { ContactSearchPageModule } from "../app/pages/contact-search/contact-search.module";
import { PostNewPageModule } from "./pages/post-new/post-new.module";
import { PostCommentPageModule } from "./pages/post-comment/post-comment.module";
import { MaintenancePageModule } from "./pages/maintenance/maintenance.module";
import { AppUpdatePageModule } from "./pages/app-update/app-update.module";
import { ServiceWorkerModule } from "@angular/service-worker";
import { environment } from "../environments/environment";
import { HelpViewPageModule } from "./pages/help-view/help-view.module";
import { HelpListPageModule } from "./pages/help-list/help-list.module";
import { HelpDetailPageModule } from "./pages/help-detail/help-detail.module";
import { EmailPageModule } from "./pages/email/email.module";
import { PlayerStatsPageModule } from "./pages/player-stats/player-stats.module";
import { MatchViewPageModule } from "./pages/match-format-view/match-format-view.module";
import { MatchEventDetailPageModule } from "./pages/match-event-detail/match-event-detail.module";
import { MatchPlayerSearchPageModule } from "./pages/match-player-search/match-player-search.module";
import { WhatsNewPageModule } from "./pages/whats-new/whats-new.module";
import { MatchMemberDetailPageModule } from "./pages/match-member-detail/match-member-detail.module";
import { TeamPopoverPageModule } from "./pages/team-popover/team-popover.module";
import { DeeplinkWelcomeMessagePageModule } from "./pages/deeplink-welcome-message/deeplink-welcome-message.module";
import { RuleHelperPageModule } from "./pages/rule-helper/rule-helper.module";
import { ChatBotPageModule } from "./pages/chat-bot/chat-bot.module";

@Injectable()
export class MyErrorHandler implements ErrorHandler {
  errorHandler: ErrorHandler;

  handleError(err: any): void {
    //Remove this if you want to disable Ionic's auto exception handling in development mode.
    this.errorHandler && this.errorHandler.handleError(err);
  }
}

@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [
    BrowserModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    IonicStorageModule.forRoot({
      name: "__doubleacegolf",
    }),
    BrowserAnimationsModule,
    DragulaModule,
    AngularFireModule.initializeApp(environment.FIREBASE_CONFIG),
    AngularFireAuthModule,
    AngularFireStorageModule,
    AngularFirestoreModule,
    HttpClientModule,
    ProfilePageModule,
    SupportPageModule,
    ProfileSummaryPageModule,
    TermsOfServicePageModule,
    PrivacyPolicyPageModule,
    SystemUtilitiesPageModule,
    PreferencesPageModule,
    ClubDetailPageModule,
    ClubSearchPageModule,
    CourseDetailPageModule,
    GroupEventDetailPageModule,
    GroupTripDetailPageModule,
    GroupEventViewPageModule,
    GroupTripViewPageModule,
    GroupTripScorecardPageModule,
    EventDetailPageModule,
    EventViewPageModule,
    EventScoringPageModule,
    EventScorecardPageModule,
    ContactPageModule,
    MemberListPageModule,
    MemberSearchPageModule,
    NotePageModule,
    NetworkPageModule,
    ContactSearchPageModule,
    PostNewPageModule,
    PostCommentPageModule,
    MemberDetailPageModule,
    CourseTeePageModule,
    CourseHolePageModule,
    CourseScorecardPageModule,
    ColorPickerPopoverPageModule,
    MaintenancePageModule,
    AppUpdatePageModule,
    HelpViewPageModule,
    HelpListPageModule,
    HelpDetailPageModule,
    EmailPageModule,
    PlayerStatsPageModule,
    MatchViewPageModule,
    MatchEventDetailPageModule,
    TeamPopoverPageModule,
    MatchMemberDetailPageModule,
    MatchPlayerSearchPageModule,
    WhatsNewPageModule,
    DeeplinkWelcomeMessagePageModule,
    RuleHelperPageModule,
    ChatBotPageModule,
    GroupSearchPageModule,
    ServiceWorkerModule.register("ngsw-worker.js", {
      enabled: environment.production,
    }),
    provideFirebaseApp(() => initializeApp(environment.FIREBASE_CONFIG)),
  ],
  providers: [
    Contacts,
    Geolocation,
    NativeGeocoder,
    MediaCapture,
    Diagnostic,
    TouchID,
    Market,
    AngularFireAuthGuard,
    AppRate,
    Chooser,
    { provide: ErrorHandler, useClass: MyErrorHandler },
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    {
      provide: ErrorHandler,
      useValue: SentryAngular.createErrorHandler(),
    },
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [AppComponent],
})
export class AppModule {

  constructor(private platform: Platform) {

    console.log('app.module.ts AppModule constructor');

    // Get live update info
    /* if (platform.is('capacitor')) {

      this.pro
        .deploy()
        .info()
        .then((res: DeployInfo) => {
          console.log('app.module.ts AppModule deploy',res)
        })
        .catch((err)=>{
          console.log('app.module.ts AppModule deploy error', JSON.stringify(err))
        });

    } */

  }

}
