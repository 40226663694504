<ion-header class="event-view-header ion-no-border">

  <ion-toolbar>

    <ion-title>Event</ion-title>

    <ion-buttons slot="primary">
      <ion-button fill="solid" shape="round" (click)="done()" color="light">Close</ion-button>
    </ion-buttons>

    <ion-buttons slot="secondary">
      <ion-button slot="icon-only" [screenKey]="screenKey">
        <ion-icon name="help-circle-outline"></ion-icon>
      </ion-button>
    </ion-buttons>

  </ion-toolbar>

</ion-header>

<ion-content class="event-view-content">

  <!-- segment -->
  <div class="ion-padding">
    <ion-segment [(ngModel)]="eventViewSegment" mode="ios">
      <ion-segment-button [value]="EventViewSegment.Information">
        <ion-label>Details</ion-label>
      </ion-segment-button>
      <ion-segment-button [value]="EventViewSegment.TeeTimes">
        <ion-label>Tee Times</ion-label>
      </ion-segment-button>
      <ion-segment-button [value]="EventViewSegment.TimeLine" *ngIf="accountService.member.admin">
        <ion-label>Timeline</ion-label>
      </ion-segment-button>
    </ion-segment>
  </div>

  <div *ngIf="eventViewSegment === EventViewSegment.Information">

    <!-- cover and avatar -->
    <div class="coverAvatar" style="margin-top: -10px">

      <!-- cover -->
      <div class="cover" style="padding: 16px;">
        <img [src]="event.cover" style="border-radius: 16px;" draggable="false">
      </div>

      <!-- avatar -->
      <div class="avatar" style="margin-top: -30px;">
        <ion-avatar class="avatar-large avatarImageOverlay">
          <img #imgAvatar [src]="event.avatar" (click)="appFunction.showAvatarView($event, imgAvatar)"
            draggable="false">
        </ion-avatar>
      </div>

    </div>

    <!-- 'Ask to Join' action card -->
    <ion-card class="actionCard" *ngIf="this.actionCd === ActionCd.AskToJoin">

      <ion-card-content>
        <ion-card-title>
          Join Now!
        </ion-card-title>

        <p class="message">Tap <b>Join Event</b> to play in this Event.</p>

      </ion-card-content>

      <ion-grid class="ion-no-padding ion-nowrap" class="buttonBar">
        <ion-row>
          <ion-col class="ion-text-center ion-no-padding" [size]="12">
            <ion-button expand="block" shape="round" class="primary-action" (click)="joinEvent()">Join Event
            </ion-button>
          </ion-col>
        </ion-row>
      </ion-grid>

    </ion-card>

    <!-- 'Joined' action card -->
    <ion-card class="actionCard" *ngIf="this.actionCd === ActionCd.Joined">

      <ion-card-content>
        <ion-card-title>
          Success!
        </ion-card-title>

        <p>
          You have been added to the <b>{{event.name}}</b> golf event scheduled for
          <b>{{event.eventDt.toDate() | amUtc | amLocal | amDateFormat:'dddd, MMMM Do'}}</b>.
        </p>

        <p style="margin-top: 10px;">
          Pairings and tee times will be assigned by the group's organizer.
        </p>

        <p style="margin-top: 10px;">
          Add guests and a note to the organizer below.
        </p>

      </ion-card-content>

    </ion-card>

    <!-- 'Leave' action card -->
    <ion-card class="actionCard" *ngIf="this.actionCd === ActionCd.Leave">

      <ion-card-content>
        <ion-card-title>
          Sorry to see you go
        </ion-card-title>

        <p class="message">You can alway rejoin this Event.</p>

      </ion-card-content>

      <ion-grid class="ion-no-padding ion-nowrap" class="buttonBar">
        <ion-row>
          <ion-col class="ion-text-center ion-no-padding" [size]="12">
            <ion-button expand="block" shape="round" class="primary-action" (click)="joinEvent()">Re-join Event
            </ion-button>
          </ion-col>
        </ion-row>
      </ion-grid>

    </ion-card>

    <!-- event details -->
    <ion-list class="itemLines">

      <ion-list-header>
        <ion-label>Event Details</ion-label>
      </ion-list-header>

      <!-- group name -->
      <ion-item *ngIf="isGroupEvent">
        <ion-label>
          <ion-label position="stacked">Group Name</ion-label>
          <div>{{event.group.name}}</div>
        </ion-label>
      </ion-item>

      <!-- description -->
      <ion-item>
        <ion-label>
          <ion-label position="stacked">Message</ion-label>
          <div class="preserveFormat">{{event.description}}</div>
        </ion-label>
      </ion-item>

      <!-- club name -->
      <ion-item>
        <ion-label>
          <ion-label position="stacked">Club Name</ion-label>
          <div>{{event.club.name}}</div>
        </ion-label>
      </ion-item>

      <!-- course name -->
      <ion-item>
        <ion-label>
          <ion-label position="stacked">Course Name</ion-label>
          <div>{{event.course.name}}</div>
        </ion-label>
      </ion-item>

      <!-- tees -->
      <ion-item>
        <ion-label>
          <ion-label position="stacked">Tees</ion-label>
          <tee [tee]="event.tee" [nineHolesOnlyIndex]="event.nineHolesOnlyIndex"></tee>
        </ion-label>
      </ion-item>

      <!-- date -->
      <ion-item>
        <ion-label>
          <ion-label position="stacked">Date</ion-label>
          <div class="iconTextSingleLine">
            <ion-icon name="calendar"></ion-icon>
            <ion-text>{{event.eventDt.toDate() | amUtc | amLocal | amDateFormat:'dddd, MMM Do'}}
            </ion-text>
          </div>
        </ion-label>
      </ion-item>

      <!-- first tee time or... -->
      <ion-item *ngIf="!event.players.memberPlayer;">
        <ion-label>
          <ion-label position="stacked">First Tee Time</ion-label>
          <div class="iconTextSingleLine">
            <ion-icon name="time"></ion-icon>
            <ion-text>{{event.eventDt.toDate() | amDateFormat:'h:mm A'}}</ion-text>
          </div>
        </ion-label>
      </ion-item>

      <!-- ...player tee time -->
      <ion-item *ngIf="event.players.memberPlayer">
        <ion-label>
          <ion-label position="stacked">Time</ion-label>

          <div *ngIf="event.players.memberPlayer && event.players.memberPlayer.teeTime.toDate() < waitListTeeTime"
            class="iconTextSingleLine">
            <ion-icon name="time" color="safe"></ion-icon>
            <ion-text>Your tee time is {{event.players.memberPlayer.teeTime.toDate() | amUtc | amLocal |
              amDateFormat: 'h:mm A'}}</ion-text>
          </div>

          <div *ngIf="event.players.memberPlayer && event.players.memberPlayer.teeTime.toDate() >= waitListTeeTime"
            class="iconTextSingleLine">
            <ion-icon name="time" color="warning"></ion-icon>
            <ion-text>Your tee time has not been posted.</ion-text>
          </div>

          <div *ngIf="!event.players.memberPlayer && (event.numberTeeTimes * 4) - event.players.active.all.length > 1"
            class="iconTextSingleLine">
            <ion-icon name="time"></ion-icon>
            <ion-text>There are {{(event.numberTeeTimes * 4) - event.players.active.all.length}} spots
              available.</ion-text>
          </div>

          <div *ngIf="!event.players.memberPlayer && (event.numberTeeTimes * 4) - event.players.active.all.length === 1"
            class="iconTextSingleLine">
            <ion-icon name="time"></ion-icon>
            <ion-text>There is 1 spot available.</ion-text>
          </div>

          <div *ngIf="!event.players.memberPlayer && (event.numberTeeTimes * 4) - event.players.active.all.length === 0"
            class="iconTextSingleLine">
            <ion-icon name="time" color="danger"></ion-icon>
            <ion-text>There are no open tee times.</ion-text>
          </div>

          <div *ngIf="!event.players.memberPlayer && (event.numberTeeTimes * 4) - event.players.active.all.length < 0"
            class="iconTextSingleLine">
            <ion-icon name="time" color="danger"></ion-icon>
            <ion-text>There are {{(((event.numberTeeTimes * 4) - event.players.active.all.length) * -1).toString()}}
              players
              on the wait list.</ion-text>
          </div>

        </ion-label>
      </ion-item>

    </ion-list>

    <!-- match detail -->
    <ion-list class="itemLines" *ngIf="event.matches.parent.length > 0" lines="inset">

      <ion-list-header>
        <ion-label>Match Detail</ion-label>
      </ion-list-header>

      <!-- match -->
      <ion-item *ngFor="let match of event.matches.parent">
        <match-view [match]="match" [formatLabel]="'Match Format'">
        </match-view>
      </ion-item>

    </ion-list>

    <!-- note, only show if in the event -->
    <ion-list *ngIf="event.players.memberPlayer" class="itemLines">

      <ion-list-header>
        <ion-label>Tee Time Note</ion-label>
      </ion-list-header>

      <ion-item lines="inset">
        <ion-textarea [(ngModel)]="note" auto-grow="true" placeholder="Add note for organizer" autocomplete="on"
          autocorrect="on" autocapitalize="on" (click)="appFunction.focusInput($event)">
        </ion-textarea>
      </ion-item>

    </ion-list>

    <!-- guests -->
    <ion-list *ngIf="event.players.memberPlayer">

      <ion-list-header>
        <ion-label>Guests</ion-label>
      </ion-list-header>

      <!-- add new guest -->
      <ion-item (click)="addGuestConfirm(event.players.memberPlayer);" style="padding-top: 5px;">

        <ion-avatar style="background-color: lightgrey;" slot="start">
          <ion-icon color="primary" name="person-add" style="height: 35px; width: 35px; padding: 10px;"></ion-icon>
        </ion-avatar>

        <ion-label>
          <h2>Add a guest</h2>
        </ion-label>

      </ion-item>

      <!-- event guests -->
      <ion-item *ngFor="let guestPlayer of event.players.memberPlayer.guests.all; let i = index">

        <ion-avatar slot="start">
          <img [imgLoader]="guestPlayer.URI" draggable="false">
        </ion-avatar>

        <ion-label>
          <h2>{{guestPlayer.firstName}} {{guestPlayer.lastName}}</h2>
          <ion-note>{{guestPlayer.email}}</ion-note>
        </ion-label>

        <ion-icon name="ellipsis-vertical" (click)="guestAction(guestPlayer)" slot="end"
          style="align-self: end; margin-right: 0px;" color="primary">
        </ion-icon>

      </ion-item>

    </ion-list>

  </div>

  <div *ngIf="eventViewSegment === EventViewSegment.TeeTimes">

    <ng-container *ngIf="nestedTeeTimes.length > 0, else noTeeTimes">

      <ion-item-group *ngFor="let nestedTeeTime of nestedTeeTimes" class="itemGroupLines">

        <ion-item-divider sticky>
          <ion-label>
            {{nestedTeeTime.time}}
          </ion-label>
        </ion-item-divider>

        <ng-container *ngFor="let player of nestedTeeTime.players">

          <!-- member -->
          <ion-item>

            <avatar #avatar [member]="player.member" slot="start"></avatar>

            <ion-label (click)="avatar.displayProfileSummary($event)">
              <h2>{{player.firstName}} {{player.lastName}}</h2>
              <p>{{player.email}}</p>
              <ion-note [hidden]="!player.note" (click)="displayNote($event, player)">
                <ion-icon name="chatbubbles" style="margin-left: 0px; margin-right: 5px;"></ion-icon>
                {{player.note}}
              </ion-note>
            </ion-label>

            <ion-icon *ngIf="this.event.players.memberPlayer?.id===player.id" name="ellipsis-vertical"
              (click)="confirmPlayerAction()" slot="end" style="align-self: end; margin-right: 0px;" color="primary">
            </ion-icon>

          </ion-item>

          <!-- guests -->
          <ion-item *ngFor="let guest of player.guests.all">

            <avatar [member]="guest.member" slot="start"></avatar>

            <ion-label>

              <h3>{{guest.firstName}} {{guest.lastName}}</h3>
              <p>guest</p>

            </ion-label>

            <ion-icon *ngIf="this.event.players.memberPlayer?.id===guest.parentPlayerId" name="ellipsis-vertical"
              (click)="guestAction(guest)" slot="end" style="align-self: end; margin-right: 0px;" color="primary">
            </ion-icon>

          </ion-item>

        </ng-container>

      </ion-item-group>

    </ng-container>

  </div>

  <div *ngIf="eventViewSegment === EventViewSegment.TimeLine">

    <ion-list class="itemLines" *ngIf="event.players.groupMembersOnly.length > 0, else noTeeTimes">

      <ng-container *ngFor="let player of event.players.groupMembersOnly">

        <ion-item>

          <avatar #avatar [member]="player.member" slot="start"></avatar>

          <ion-label (click)="avatar.displayProfileSummary($event)">

            <h2>{{player.firstName}} {{player.lastName}}</h2>
            <p *ngIf="player.status">joined on
              {{player.createdDt?.toDate() | amUtc | amLocal | amDateFormat: 'ddd, MMMM Do h:mm A'}}</p>
            <p *ngIf="!player.status">dropped on
              {{player.updatedDt?.toDate() | amUtc | amLocal | amDateFormat: 'ddd, MMMM Do h:mm A'}}</p>

          </ion-label>

        </ion-item>

        <ng-container *ngFor="let guest of player.guests.all">

          <ion-item>

            <avatar [member]="guest.member" slot="start"></avatar>

            <ion-label>

              <h3>{{guest.firstName}} {{guest.lastName}}</h3>
              <p>guest</p>

            </ion-label>

          </ion-item>

        </ng-container>

      </ng-container>

    </ion-list>

  </div>

  <ion-fab #fab vertical="bottom" horizontal="end" slot="fixed" haptic [hidden]="!event.players.memberPlayer"
    [fabClose]="fab">
    <ion-fab-button>
      <ion-icon name="add"></ion-icon>
    </ion-fab-button>
    <ion-fab-list side="top">
      <ion-button shape="round" (click)="newPost(); fab.close();">
        <ion-icon name="chatbubbles" slot="start"></ion-icon>
        <ion-label>Post Message</ion-label>
      </ion-button>
      <ion-button shape="round" (click)="leaveEventConfirm(); fab.close();">
        <ion-icon name="exit" slot="start"></ion-icon>
        <ion-label>Leave Event</ion-label>
      </ion-button>
      <ion-button shape="round" (click)="addGuestConfirm(event.players.memberPlayer); fab.close();"
        *ngIf="event.players.memberPlayer">
        <ion-icon name="person-add" slot="start"></ion-icon>
        <ion-label>Add a guest</ion-label>
      </ion-button>
    </ion-fab-list>
  </ion-fab>

</ion-content>

<ng-template #noTeeTimes>
  <ion-note class="instructional">
    No members have joined this event
  </ion-note>
</ng-template>