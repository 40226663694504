import { Component, OnInit } from '@angular/core';
import { IonSlides, NavParams } from '@ionic/angular';
import { AppEventPlayer } from 'src/app/app.event';
import { AppMatch, AppMatchParentI } from 'src/app/app.match';
import { AppFunction, HelpService } from 'src/app/app.function';
import { AccountService } from 'src/app/app.account';
import { MatchPlayerSearchPage } from '../match-player-search/match-player-search.page';
import { FormGroup } from '@angular/forms';
import { AppConfig } from '../../app.config';

@Component({
  selector: 'match-member-detail',
  templateUrl: './match-member-detail.page.html',
  styleUrls: ['./match-member-detail.page.scss'],
})
export class MatchMemberDetailPage implements OnInit {

  parent: AppMatchParentI;
  match: AppMatch;
  matchSlider: IonSlides;
  matchForm: FormGroup;
  isSliderBeginning: boolean = true;
  editMode: any;
  formEditMode: typeof AppConfig.EDIT_MODE = AppConfig.EDIT_MODE;
  isFormSubmitted: boolean = false;
  screenKey: string = 'match';
  matchOptions: any;

  constructor(
    private navParams: NavParams,
    public accountService: AccountService,
    public appFunction: AppFunction,
    public helpService: HelpService) {

    helpService.screenWhatsNew(this.screenKey);

  }

  ngOnInit() {

    //get match options
    this.matchOptions = this.navParams.get('matchOptions');

    //is this new or update
    this.editMode = this.navParams.get('editMode');
    if (this.editMode === AppConfig.EDIT_MODE.update) {

      //get match
      this.match = <AppMatch>this.navParams.get('match');

    } else { //new

      //what parent does this match belong to
      this.parent = <AppMatchParentI>this.navParams.get('parent');

      //create a new match...
      const match: AppMatch = new AppMatch();

      //...then initialize
      match
        .initialize(this.parent)
        .then(() => {
          //set organizer
          match.organizerMemberId = this.accountService.member.id;
          this.match = match;
        });

    }

  }

  async addEventPlayer() {

    //create member search page
    this.appFunction
      .modalCtrlCreate({
        component: MatchPlayerSearchPage,
        presentingElement: await this.appFunction.routerOutlet(),
        componentProps: {
          match: this.match
        }
      })
      .then((modal) => {

        //process newly selected players
        modal
          .onDidDismiss()
          .then((result) => {

            //if returned array has contacts then...
            if (result.data.selectedPlayers?.length > 0) {

              //loop through returned players
              result
                .data
                .selectedPlayers
                .forEach((selectedPlayer: AppEventPlayer) => {

                  //determine if selected player is already part of match...
                  const isAlreadyMatchPlayer: boolean = this.match.players.some((matchPlayer) => {
                    //this is the hackest ever but had a situation trying to add two guests (which don't have member?.id). 
                    //both sides below were returning 'undefined' making it seem like the player was already added to the match 
                    return (selectedPlayer?.member?.id || 0) === (matchPlayer.member?.id || 1);
                  });

                  //...if not then player is new to match...add to match players
                  if (!isAlreadyMatchPlayer) {
                    //this.match.addMatchPlayer(selectedPlayer);
                    this.match.addMemberMatchPlayer(selectedPlayer);
                    this.matchForm.markAsDirty();
                  }

                });

            }

          });

        modal
          .present()
          .catch((err) => {
            console.log('match.page.ts modal present error', err);
          });

      })
      .catch((err) => {
        console.log('match.page.ts modal create error', err);
      });

  }

  removeMatchPlayerConfirm(playerIndex: number) {

    //confirmation
    this.appFunction
      .actionCtrl
      .create({
        header: 'Please confirm',
        buttons: [
          {
            text: 'Remove player',
            role: 'destructive',
            handler: () => {
              this.match.removeMemberMatchPlayer(playerIndex);
            }
          },
          {
            text: 'No',
            role: 'cancel'
          }
        ]
      })
      .then((action) => {
        action.present();
      });

  }

  matchChange(matchFromComponent) {
    this.match = matchFromComponent;
  }

  matchFormChange(matchFormFromComponent) {
    this.matchForm = matchFormFromComponent;
  }

  deleteMatchConfirmation() {

    //confirmation
    this.appFunction
      .actionCtrl
      .create({
        header: 'Please confirm',
        buttons: [
          {
            text: 'Delete Match',
            role: 'destructive',
            handler: () => {

              this.match
                .delete()
                .then(() => {
                  this.appFunction.modalCtrl.dismiss();
                });

            }
          },
          {
            text: 'Cancel',
            role: 'cancel'
          }
        ]
      })
      .then((action) => {
        action.present();
      });

  }

  done() {

    //let validation know that user tried to save
    this.isFormSubmitted = true;

    //save the match
    if (this.matchForm.valid) {

      //set form data
      this.match.handicapType = this.matchForm.controls.handicapType.value;
      this.match.handicapAllowance = this.matchForm.controls.handicapAllowance.value;
      this.match.notes = this.matchForm.controls.notes.value;
      this.match.teamSize = this.matchForm.controls.teamSize.value;
      this.match.game = this.matchForm.controls.game.value;
      this.match.grossNetType = this.matchForm.controls.grossNetType.value;
      this.match.scoringType = this.matchForm.controls.scoringType.value;
      this.match.pressOption = this.matchForm.controls.pressOption.value;
      this.match.pressStokesDown = this.matchForm.controls.pressStokesDown.value;
      this.match.stablefordPoints = this.matchForm.controls.stablefordPoints.value;

      //look for validation erros
      if (this.match.validationErrors.length === 0) {

        //get batch and save match...
        const batch = this.appFunction.firestore.batch();
        this.match
          .save(batch)
          .then(() => {

            //commit batch
            batch
              .commit()
              .then(() => {
                //close modal
                this.appFunction.modalCtrl.dismiss({ match: this.match }, 'update');
              });

          });

      }

    } else {
      //show any untouched errors
      this.appFunction.setDirtyControlAsTouched(this.matchForm);
    }

  }

  cancel() {

    //confirm that user wants to discard changes
    if (this.matchForm.dirty) {

      this.appFunction
        .alertCtrl
        .create({
          header: 'Discard changes?',
          message: 'You have made changes to your Match. Do you want to discard these changes?',
          buttons: [
            {
              text: 'No',
              handler: () => {
              }
            },
            {
              text: 'Yes, discard',
              handler: () => {
                this.appFunction.modalCtrl.dismiss({}, 'cancel');
              }
            }
          ]
        })
        .then((alert) => {
          alert.present();
        });

    } else {
      this.appFunction.modalCtrl.dismiss({}, 'cancel');
    }

  }

}
