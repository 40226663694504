import { Subject } from 'rxjs';
import { AppConfig, TeamSize, NetGrossType, HandicapType, GameType, ScoringType, PressOptions, HandicapAllowance, FirestoreUpdateType } from './app.config';
import { AppEvent, AppEventPlayerHole, AppPlayerScoreNineI, PubSubMatchPlayerHoleI, ScoringMode } from './app.event';
import { AppFunction, PublishSubscribeTopics, SortByOrder } from './app.function';
import firebase from 'firebase/compat/app';
import { AccountService, AppMember } from './app.account';
import { TeamPopoverPage } from './pages/team-popover/team-popover.page';
import { AppGroupI, AppGroupTrip, TripAttendanceStatus } from './app.group';
import PublishSubscribe from 'publish-subscribe-js';
import { AppTee } from './app.club';
import * as SentryAngular from "@sentry/angular-ivy";
import { format } from 'node:path/win32';

interface EventTeamScorecardI {
    events: EventTeamScorecardEventI;
    eventList: EventTeamScorecardEventI[];
}

interface EventTeamScorecardEventI {
    event: AppEvent;
    team: AppTeam;
    holes: EventTeamHolesScorecardHoleI;
}

interface EventTeamHolesScorecardHoleI {
    event: AppEvent;
    team: AppTeam;
    courseHoleIndex: number;
    score: number;
    ninesScore: number; //we needed special handling for nines scoring
    par: number;
}

interface EventPlayerScorecardI {
    events: EventPlayerScorecardEventI;
    eventList: EventPlayerScorecardEventI[];
}

interface EventPlayerScorecardEventI {
    players: EventPlayerScorecardPlayerI;
    event: AppEvent;
}

interface EventPlayerScorecardPlayerI {
    holes: EventPlayerScorecardHoleI;
}

interface EventPlayerScorecardHoleI {
    score: number;
}

interface AppTeamScoreUpdate {
    eventTeamScoreCardEvent: EventTeamScorecardEventI;
    eventTeamHole: EventTeamHolesScorecardHoleI
}

export interface MatchPlayerI {
    id: string;
    scoringPlayerId: string;
    firstName: string;
    lastName: string;
    email: string;
    member: AppMember;
    nines: AppPlayerScoreNineI[];
    infoConfirmed?: boolean;
    scoreConfirmed?: boolean;
    teeHandicap?: number;
    teeHandicapAdjusted?(match: AppMatch): number;
    teeHandicapAdjusted?(match: AppMatch, matchStrokeAdjustment: number): number;
    teeHandicapDisplay();
    teeHandicapDisplay(match: AppMatch);
    teeTime?: firebase.firestore.Timestamp;
    updatedDt?: firebase.firestore.Timestamp;
    createdDt?: firebase.firestore.Timestamp;
    status?: boolean | TripAttendanceStatus;
    guests?: any;
    scoringMode?: ScoringMode
    statistics?: any;
    dynamicData?: any;
    handicapIndex?: number
    score?: any;
    event?: AppEvent;
    courseHoleThruIndex?: number;
    tee?: AppTee;
    holeThruDisplay?: string
}

export interface StablefordPointsI {
    albatross: number;
    eagle: number;
    birdie: number;
    par: number;
    bogey: number;
    doubleBogey: number;
}

export enum StablefordPointsDefault {
    albatross = 5,
    eagle = 4,
    birdie = 3,
    par = 2,
    bogey = 1,
    doubleBogey = 0
}

export interface AppColorI {
    backgroundcolor: string;
    fontcolor: string;
}

export interface AppTeamI {
    matchId: string;
    subMatchId: string;
    playerIds: string[];
    name: string;
    teamColor: AppColorI
}

export class AppTeam implements AppTeamI {

    id: string;
    matchId: string;
    subMatchId: string;
    playerIds: string[] = [];
    exists: boolean = true; //was false
    eventPlayerScorecard = <EventPlayerScorecardI>{ events: {}, eventList: [] };
    eventTeamScoreCard = <EventTeamScorecardI>{ events: {}, eventList: [] };
    eventTeamHoleScoreUpdate: Subject<AppTeamScoreUpdate> = new Subject<AppTeamScoreUpdate>();
    holeThruMatchIndex: number;
    teamColor: AppColorI;
    private _subMatch: AppSubMatch;
    private _match: AppMatch;
    private _appFunction: AppFunction;
    private _teamDoc: firebase.firestore.DocumentSnapshot;
    private _name: string;
    private _dirty: boolean = false;

    constructor() {

        //get services
        this._appFunction = AppFunction.serviceLocator.get(AppFunction);

        //clean up on app logout
        this._appFunction
            .shutDown
            .subscribe(() => {
                //console.log('app.match.ts AppTeam shutdown');
            });

    }

    async initialize(subMatch: AppSubMatch, teamDoc: firebase.firestore.DocumentSnapshot = undefined, teamColor: AppColorI = undefined): Promise<AppTeam> {

        //save references
        this._subMatch = subMatch;
        this.subMatchId = this._subMatch.id;
        this._match = subMatch.match;
        this.matchId = this._match.id;

        //if team already exists...
        if (teamDoc) {
            //set some team properties
            this._teamDoc = teamDoc;
            //this.id = teamDoc.id;
        } else { //else match is new...

            //get match doc
            this._teamDoc = await this._appFunction.firestore.collection(AppConfig.COLLECTION.MatchTeams).doc().get();

            //set other properties
            //this.id = this._teamDoc.id;

            //set to dirty so that this team saves
            this._dirty = true;

            //use passed in team color (needed for presses) or get team color, used by the scoreboards 
            //(this gets a color but also removes from array so that it isn't used twice)
            this.teamColor = teamColor || this._subMatch.teamColors.splice(0, 1)[0];

        }

        //set team id
        this.id = this._teamDoc.id;

        return this;

        /* return new Promise<AppTeam>((resolve) => {

            //save references
            this._subMatch = subMatch;
            this._match = subMatch.match;

            //if team already exists...
            if (teamDoc) {

                //set some team properties
                this._teamDoc = teamDoc;
                this.id = teamDoc.id;

                //set the team data
                this.update(teamDoc, FirestoreUpdateType.Added);

                //return
                resolve(this);

            } else { //else team is new...

                //create new team doc ref
                const teamDoc: firebase.firestore.DocumentReference = this._appFunction.firestore.collection(AppConfig.COLLECTION.MatchTeams).doc();

                //now get it, it should be empty as this is a new team object
                teamDoc
                    .get()
                    .then((newTeamDoc) => {

                        //save doc, this will be used when saving
                        this._teamDoc = newTeamDoc;

                        //set other properties
                        this.id = teamDoc.id;
                        this.subMatchId = this._subMatch.id;
                        this.matchId = this._match.id;

                        //when adding a new team we need to force this to true so is displays as expected 
                        this.exists = true;

                        //set to dirty so that this team saves
                        this._dirty = true;

                        //use passed in team color (needed for presses) or get team color, used by the scoreboards 
                        //(this gets a color but also removes from array so that it isn't used twice)
                        this.teamColor = teamColor || this._subMatch.teamColors.splice(0, 1)[0];

                        resolve(this);

                    });

            }

        }); */

    }

    update(updatedTeam: firebase.firestore.DocumentSnapshot, type: FirestoreUpdateType) {

        try {

            this._appFunction
                .ngZone
                .run(() => {

                    if ([FirestoreUpdateType.Added, FirestoreUpdateType.Modified].includes(type)) {
                        this.matchId = updatedTeam.data().matchId;
                        this.subMatchId = updatedTeam.data().subMatchId
                        this.exists = true;
                        this._name = updatedTeam.data().name;
                        this.players.get(updatedTeam.data().playerIds);
                        this.teamColor = updatedTeam.data().teamColor
                        this._dirty = false;
                    } else {
                        this.exists = false;
                    }

                });

        } catch (err) {
            console.log('app.match.ts AppTeam update error', err, JSON.stringify(err));
            throw err;
        }

    }

    get name(): string {
        //build and return team name 
        return this._name || this.players.all.reduce((teamName, player) => teamName + player.lastName + ', ', '').replace(/(\s*,?\s*)*$/, "");;
    }

    set name(name: string) {
        this._name = name;
        this._dirty = true;
    }

    private calculatePlayerScore(playerHole: AppEventPlayerHole): number {

        //only calc score if the gross score has been recorded
        if (playerHole.grossScore) {

            //depending on gross/net 
            let score: number;
            switch (this._match.grossNetType) {

                //if net then calculate net score
                case NetGrossType.Net:

                    //determine player strokes for the hole based on full or low ball scoring
                    const adjustedStrokes: number = this._match.calcStrokesForHole(playerHole, playerHole.number)
                    score = playerHole.grossScore - adjustedStrokes;
                    break;

                //if gross then return gross score
                case NetGrossType.Gross:
                    score = playerHole.grossScore;
                    break;

            }

            return score;

        } else {
            return undefined;
        }

    }

    calculatePlayerHole(eventPlayerHole: AppEventPlayerHole) {

        try {

            //if hole is part of submatch AND is there's an actual recorded score
            const isMatchHole: boolean = this._subMatch.isMatchHole(eventPlayerHole.event, eventPlayerHole.courseHoleIndex);
            if (isMatchHole && eventPlayerHole.scoreEntered) {

                //calculate player score (net vs gross)
                const playerScore: number = this.calculatePlayerScore(eventPlayerHole);

                /* example of eventPlayerScorecard object
                events: {
                    `eventId`: {
                        players: {
                            `memberId`: { //we're using memberId so we can agregate scores across events
                                holes: {
                                    0: 4,
                                    1: 4,
                                }
                            },
                            `memberId`: {
                                holes: {
                                    0: 4,
                                    1: 4,
                                }
                            },
                        },
                    `eventId`: {
                        players: {
                            `memberId`: {
                                holes: {
                                    0: 4,
                                    1: 4,
                                }
                            },
                            `memberId`: {
                                holes: {
                                    0: 4,
                                    1: 4,
                                }
                            },
                        },
                    ...
                } 
                */

                //get and set event object...
                const playerScorecardEvent: EventPlayerScorecardEventI = <EventPlayerScorecardEventI>this._appFunction.getJSONProperty(this.eventPlayerScorecard.events, eventPlayerHole.event.id, { players: {} });
                playerScorecardEvent.event = eventPlayerHole.event;

                //get player object (rather than playerId we're using playerScoringId so we can agregate scores across events)...
                const playerScorecardPlayer: EventPlayerScorecardPlayerI = <EventPlayerScorecardPlayerI>this._appFunction.getJSONProperty(playerScorecardEvent.players, eventPlayerHole.player.scoringPlayerId, { holes: {} });

                //get hole object...
                const playerScorecardHole: EventPlayerScorecardHoleI = <EventPlayerScorecardHoleI>this._appFunction.getJSONProperty(playerScorecardPlayer.holes, eventPlayerHole.courseHoleIndex, {});

                //if game stableford then set player score to stableford score...
                if (this._match.game === GameType.Stableford) {
                    playerScorecardHole.score = this._match.stableFordScore(playerScore, eventPlayerHole.par);
                } else { //...or set player gross/net score
                    playerScorecardHole.score = playerScore;
                }

                //calc playerHoleThruMatchIndex based on the min of the player hole htru index and the last hole of the submatch (this is needed for games such as spins or nassau where the sub match isn't all holes of the course (whether 9 or 18))
                const playerHoleThruMatchIndex: number = this._subMatch.getMatchHoleIndex(eventPlayerHole.event, eventPlayerHole.player.courseHoleThruIndex);
                const subMatchLastHoleIndex: number = this._subMatch.getMatchHoleIndex(eventPlayerHole.event, this._subMatch.getAdjustMatchHoleIndexes(eventPlayerHole.event).at(-1)); //-1 gets the last item of the array
                this.holeThruMatchIndex = Math.min(playerHoleThruMatchIndex, subMatchLastHoleIndex);

                //clear up
                this.eventPlayerScorecard.eventList = this._appFunction.convertJSONToArray(this.eventPlayerScorecard.events);

                //...then calculate team score for the hole
                this.calculateTeamScore(eventPlayerHole);

            }

        } catch (err) {
            console.log('app.match.ts AppTeam calculatePlayerHole error', err);
            SentryAngular.captureException(err, {
                tags: {
                    scoringPlayerId: eventPlayerHole.player.scoringPlayerId,
                    eventId: eventPlayerHole.event.id
                }
            });
        }

    }

    private calculateTeamScore(playerHole: AppEventPlayerHole) {

        try {

            const allPlayerScoresForHole: number[] = [];

            //get all players for event/team
            const playerScorecardEventPlayers: EventPlayerScorecardPlayerI = (<EventPlayerScorecardEventI>this.eventPlayerScorecard.events[playerHole.event.id]).players;

            //get players scores for the given event/hole
            for (const memberId in playerScorecardEventPlayers) {
                const playerScorecardEventHoles: EventPlayerScorecardHoleI = (<EventPlayerScorecardPlayerI>playerScorecardEventPlayers[memberId]).holes;
                if (playerScorecardEventHoles[playerHole.courseHoleIndex]) {
                    allPlayerScoresForHole.push(playerScorecardEventHoles[playerHole.courseHoleIndex].score);
                }
            }

            //confirm that all team players have a score for the hole
            //we shouldn't calculate the team score until all players have a score for the hole  
            if (allPlayerScoresForHole.length === this.players.all.length) {

                let teamHoleScore: number = 0;
                let teamHolePar: number = 0;

                //sort array acsending so we can sum the lowest best x scores as defined by the match configuration
                allPlayerScoresForHole.sort();

                //stableford needs the highest best scores first
                if ([GameType.Stableford].includes(this._match.game)) {
                    allPlayerScoresForHole.reverse();
                }

                //sum the better net scores (and par) to create the team score (scoringType represents the "better x" of the team)
                for (let n = 0; n <= this._match.scoringType; n++) {
                    teamHoleScore = teamHoleScore + allPlayerScoresForHole[n];
                    teamHolePar = teamHolePar + playerHole.par;
                }

                /* example of teamScorecardEvent object
                events: {
                    `eventId`: {
                        holes: {
                            0: {
                                event: AppEvent,
                                team: AppTeam,
                                courseHoleIndex: number,
                                score: number,
                                par: number
                            },
                            1: {
                                event: AppEvent,
                                team: AppTeam,
                                courseHoleIndex: number,
                                score: number,
                                par: number
                            },
                            ...
                        }
                    },
                    `eventId`: {
                        holes: {
                            0: {
                                event: AppEvent,
                                team: AppTeam,
                                courseHoleIndex: number,
                                score: number,
                                par: number
                            },
                            1: {
                                event: AppEvent,
                                team: AppTeam,
                                courseHoleIndex: number,
                                score: number,
                                par: number
                            },
                            ...
                        }
                    }
                    ...
                } 
                */

                //get event object...
                const eventTeamScoreCardEvent: EventTeamScorecardEventI = <EventTeamScorecardEventI>this._appFunction.getJSONProperty(this.eventTeamScoreCard.events, playerHole.event.id, { holes: {} });
                eventTeamScoreCardEvent.event = playerHole.event;
                eventTeamScoreCardEvent.team = this;

                //get hole object...
                const eventTeamHole: EventTeamHolesScorecardHoleI = <EventTeamHolesScorecardHoleI>this._appFunction.getJSONProperty(eventTeamScoreCardEvent.holes, playerHole.courseHoleIndex, {});

                //set hole
                eventTeamHole.event = playerHole.event;
                eventTeamHole.team = this;
                eventTeamHole.courseHoleIndex = playerHole.courseHoleIndex;
                eventTeamHole.score = teamHoleScore;
                eventTeamHole.par = teamHolePar;

                //clean up
                this.eventTeamScoreCard.eventList = this._appFunction.convertJSONToArray(this.eventTeamScoreCard.events);

                //signal change
                this.eventTeamHoleScoreUpdate.next({
                    eventTeamScoreCardEvent: eventTeamScoreCardEvent,
                    eventTeamHole: eventTeamHole
                });

            }



        } catch (err) {
            console.log('app.match.ts AppTeam calculateTeamScore error', err);
            SentryAngular.captureException(err, {
                tags: {
                    scoringPlayerId: playerHole.player.scoringPlayerId,
                    eventId: playerHole.event.id
                }
            });
            throw err;
        }

    }

    teamScorecardTotal(event: AppEvent): string {

        //if player not found then...
        if (!event) return '';

        //get event object...
        const eventTeamScorecardEvent: EventTeamScorecardEventI = <EventTeamScorecardEventI>this._appFunction.getJSONProperty(this.eventTeamScoreCard.events, event.id);

        //get event holes and convert to array
        const eventTeamScorecardHoles: EventTeamHolesScorecardHoleI[] = this._appFunction.convertJSONToArray(eventTeamScorecardEvent.holes);

        //return total score for the given event and team
        return eventTeamScorecardHoles.reduce((totalScore, eventTeamScorecardHole) => {
            return totalScore + (eventTeamScorecardHole.score || 0);
        }, 0).toString();

    }

    playerScorecardTotal(player: EventPlayerScorecardPlayerI): string {

        //if player not found then...
        if (!player) return '';

        //get event holes and convert to array
        const playerScorecardHoles: EventPlayerScorecardHoleI[] = this._appFunction.convertJSONToArray(player.holes);

        //return total score for the given event and team
        return playerScorecardHoles.reduce((totalScore, playerScorecardHole) => {
            return totalScore + (playerScorecardHole.score || 0);
        }, 0).toString();

    }

    markAsDirty() {
        this._dirty = true;
    }

    reset() {
        this.eventPlayerScorecard = <EventPlayerScorecardI>{ events: {}, eventList: [] };
        this.eventTeamScoreCard = <EventTeamScorecardI>{ events: {}, eventList: [] };
    }

    players = new class Players {

        _players: MatchPlayerI[] = [];
        private _updateEventPlayerScoreSubscription: any = {};

        constructor(private parentTeam: AppTeam) {
        }

        get all(): MatchPlayerI[] {
            return this._players?.filter((player) => {
                //return if...
                //member is still valid AND 
                //player is active
                return player;
            }) || [];
        }

        teamPlayers(): MatchPlayerI[] {
            return this.all;
        }

        add(player: MatchPlayerI) {
            this._players.push(player);
        }

        get(playerIds: string[]) {

            //interate through each player id...
            playerIds
                .forEach((playerId) => {

                    //see if player is already in the team players array...
                    let foundTeamPlayer: MatchPlayerI = this._players.find((player) => {
                        return player.id === playerId;
                    });

                    console.log('app.match.ts AppTeam.Players get', this.parentTeam.matchId, this.parentTeam.subMatchId, playerId, foundTeamPlayer?.lastName, foundTeamPlayer?.status);

                    //...if player not found then add
                    if (!foundTeamPlayer) {

                        //find player from the list of event players...
                        //we do this so to confirm that the player is still part of the event/trip
                        foundTeamPlayer = this.parentTeam._subMatch
                            .match.parent.players.active.all
                            .find((player) => {
                                return player.id === playerId;
                            });

                        //...then push reference on team players array and listen for score updates
                        //this could happen when the player was added to the team but then removed from the event/trip
                        if (foundTeamPlayer) {
                            this.add(foundTeamPlayer);
                            this.listenPlayerScoreUpdate(foundTeamPlayer);
                        }

                    } else {
                        //listen for score updates
                        this.listenPlayerScoreUpdate(foundTeamPlayer);
                    }

                });

            //if there are no players then mark as dirty so that the team won't show in app and is deleted
            if (this.all.length === 0) {
                this.parentTeam.exists = false;
                this.parentTeam._dirty = true;
            }

        }

        remove(player: MatchPlayerI) {

            //reset team scorecards
            //note: if team only has one player then the team will be removed by the subMatch. 
            //note: if the team has more than one player then the team scorecards will be repopulated by the match.reset() method
            this.parentTeam.reset();

            //remove player from team players array...
            this._players = this._players.filter((teamPlayer) => {
                return teamPlayer.id !== player.id;
            });

        }

        private listenPlayerScoreUpdate(player: MatchPlayerI) {

            //if we're not already listening for player score updates then start listening
            if (!this._updateEventPlayerScoreSubscription[player.scoringPlayerId]) {

                this._updateEventPlayerScoreSubscription[player.scoringPlayerId] = PublishSubscribe.subscribe(PublishSubscribeTopics.UpdateEventPlayerScore + player.scoringPlayerId, (pubSubMatchPlayerHole: PubSubMatchPlayerHoleI) => {

                    try {

                        //if the event is part of the match then process the score update
                        if (this.parentTeam._match.parent.events.in(pubSubMatchPlayerHole.event)) {

                            //if specific playerHole then calculate the playerHole...
                            if (pubSubMatchPlayerHole.playerHole) {
                                this.parentTeam.calculatePlayerHole(pubSubMatchPlayerHole.playerHole);
                            } else {

                                //...else republish all of the player hole scores
                                pubSubMatchPlayerHole.event.players.active.all
                                    .filter((player) => {
                                        return player.scoringPlayerId === pubSubMatchPlayerHole.player.scoringPlayerId;
                                    })
                                    .forEach((player) => {

                                        player
                                            .nines?.forEach((nine) => {
                                                nine
                                                    .holes
                                                    .forEach((playerHole) => {
                                                        this.parentTeam.calculatePlayerHole(playerHole);
                                                    });
                                            });

                                    });

                            }

                        }

                    } catch (err) {
                        console.log('app.match.ts AppTeam listenPlayerScoreUpdate error', err);
                        SentryAngular.captureException(err, {
                            tags: {
                                scoringPlayerId: player.scoringPlayerId,
                                eventId: pubSubMatchPlayerHole.event.id
                            }
                        });
                    }

                });

                //now that we're listening for the player score updates we need to republish the player scores for each event of the match parent
                this.parentTeam._match.parent.events.all.forEach((event) => {
                    PublishSubscribe.publish(PublishSubscribeTopics.UpdateEventPlayerScore + player.scoringPlayerId, <PubSubMatchPlayerHoleI>{ event: event, player: player, playerHole: undefined });
                });

            }

        }

    }(this);

    get scoringMode(): ScoringMode {

        //if all players have confirmed their info but not their scoring is still active
        const scoringActive: boolean = this.players.all
            .every((player) => {
                return player.infoConfirmed && !player.scoreConfirmed;
            });

        if (scoringActive) {
            return ScoringMode.ScoringActive;
        }

        //if all players have confirmed their scoring then scoring is complete
        const scoringCompleted: boolean = this.players.all
            .every((player) => {
                return player.scoreConfirmed;
            });

        if (scoringCompleted) {
            return ScoringMode.ScoringComplete;
        } else {
            return ScoringMode.ScoringNotStarted;
        }

    }

    delete(batch: firebase.firestore.WriteBatch) {

        /* await this._teamDoc
            .ref
            .delete()
            .catch((err) => {
                console.log('app.match.ts AppTeam delete error', JSON.stringify(err));
            }); */

        batch.delete(this._teamDoc.ref);

        /* return new Promise<void>((resolve, reject) => {

            this._teamDoc
                .ref
                .delete()
                .then(() => {
                    resolve();
                })
                .catch((err) => {
                    console.log('app.match.ts AppTeam delete error', JSON.stringify(err));
                    reject(err);
                });

        }); */

    }

    save(batch: firebase.firestore.WriteBatch) {

        try {

            //only save team if there has been a change
            if (this._dirty) {

                //if this team doesn't have any players then delete the team...
                if (this.players.all.length === 0) {
                    batch.delete(this._teamDoc.ref);
                } else { //...otherwise save the team
                    batch.set(this._teamDoc.ref, this.data(), { merge: true });
                }

                //set dirty back
                this._dirty = false;

            }

        } catch (err) {
            console.log('app.match.ts AppTeam save error', err);
        }

    }

    data(): AppTeamI {

        try {

            const playerIds: string[] = this.players.all.map((player) => {
                return player.id;
            });

            return {
                matchId: this.matchId,
                subMatchId: this.subMatchId,
                playerIds: playerIds,
                name: this.name,
                teamColor: this.teamColor || null
            };

        }
        catch (err) {
            console.log('app.match.ts AppTeam data error', err, JSON.stringify(err));
            throw err;
        }

    }

}

export interface HoleByHoleLeaderboardI {
    events: HoleByHoleLeaderboardEventI;
    eventsList: HoleByHoleLeaderboardEventI[];
}

export interface HoleByHoleLeaderboardEventI {
    holes: HoleByHoleLeaderboardHoleI;
    holesList: HoleByHoleLeaderboardHoleI[];
    teams: HoleByHoleLeaderboardTeamI;
    teamsList: HoleByHoleLeaderboardTeamI[];
}

export interface HoleByHoleLeaderboardTeamI {
    team: AppTeam;
    holesWon: number;
    holeThruMatchIndex: number;
    position: number;
    tiedIndicator: string;
    won: boolean;
}

export interface HoleByHoleLeaderboardHoleI {
    holeWonByTeam: AppTeam,
    holeCarryOverByTeam: AppTeam,
    courseHoleIndex: number;
}

export interface TotalScoreLeaderboardI {
    events: TotalScoreLeaderboardEventI;
    eventsList: TotalScoreLeaderboardEventI[];
}

export interface TotalScoreLeaderboardEventI {
    teams: TotalScoreLeaderboardEventTeamI;
    teamsList: TotalScoreLeaderboardEventTeamI[];
    id: string;
    eventDt: firebase.firestore.Timestamp;
}

export interface TotalScoreLeaderboardEventTeamI {
    event: AppEvent;
    team: AppTeam;
    teamScore: number;
    teamPar: number;
    teamToPar: number;
    holeThruMatchIndex: number;
    position: number;
    tiedIndicator: string;
    won: boolean;
}

export interface AppSubMatchI {
    id: string;
    name: string;
    holes: number[];
    allowPress: boolean;
    press: AppSubMatchI;
    promptedForPress: boolean;
}

export class AppSubMatch implements AppSubMatchI {

    id: string;
    name: string;
    match: AppMatch;
    press: AppSubMatch;
    allowPress: boolean;
    parentSubMatch: AppSubMatch;
    promptedForPress: boolean = false;
    totalScoreLeaderboard: TotalScoreLeaderboardI = <TotalScoreLeaderboardI>{ events: {}, eventsList: [] };
    holeByHoleLeaderboard: HoleByHoleLeaderboardI = <HoleByHoleLeaderboardI>{ events: {}, eventsList: [] };
    teamColors: AppColorI[] = [
        {
            'backgroundcolor': 'red',
            'fontcolor': 'white'
        },
        {
            'backgroundcolor': 'blue',
            'fontcolor': 'white'
        },
        {
            'backgroundcolor': 'orange',
            'fontcolor': 'black'
        },
        {
            'backgroundcolor': 'green',
            'fontcolor': 'white'
        },
        {
            'backgroundcolor': 'violet',
            'fontcolor': 'black'
        },
        {
            'backgroundcolor': '#800000', //maroon
            'fontcolor': 'white'
        },
        {
            'backgroundcolor': '#9A6324', //brown
            'fontcolor': 'white'
        },
        {
            'backgroundcolor': '#808000', //olive
            'fontcolor': 'white'
        },
        {
            'backgroundcolor': '#469990', //teal
            'fontcolor': 'white'
        },
        {
            'backgroundcolor': '#bfef45', //teal
            'fontcolor': 'black'
        },
        {
            'backgroundcolor': '#42d4f4', //cyan
            'fontcolor': 'black'
        },
        {
            'backgroundcolor': '#911eb4', //purple
            'fontcolor': 'black'
        },
        {
            'backgroundcolor': '#dcbeff', //lavender
            'fontcolor': 'black'
        },
        {
            'backgroundcolor': '#aaffc3', //mint
            'fontcolor': 'black'
        }
    ];
    private _matchHoleIndexes: number[] = [];
    private _teams: AppTeam[];
    private _appFunction: AppFunction;
    teamCreatePrompted: boolean = false;

    constructor() {

        //get services
        this._appFunction = AppFunction.serviceLocator.get(AppFunction);

        //clean up on app logout
        this._appFunction
            .shutDown
            .subscribe(() => {
                //console.log('app.match.ts AppSubMatch shutdown', this.id);
            });

    }

    async initialize(match: AppMatch, subMatch: AppSubMatchI, parentSubMatch: AppSubMatch = undefined): Promise<AppSubMatch> {

        //set match
        this.match = match;

        //set parent submatch
        this.parentSubMatch = parentSubMatch;

        //update...
        await this.update(subMatch);

        return this;

    }

    private async update(updatedSubMatch: AppSubMatchI): Promise<void> {

        try {

            await this._appFunction
                .ngZone
                .run(async () => {

                    this.id = updatedSubMatch.id || this._appFunction.newGuid(); //we need a unique identifier for submatches
                    this.name = updatedSubMatch.name;
                    this.allowPress = updatedSubMatch.allowPress || false;
                    this.promptedForPress = updatedSubMatch.promptedForPress || false;

                    //this represents the relavent position of the holes of the course
                    //for example matchHoleIndex of 0 would be the first hole of the course played.
                    //So if the event is starting on hole 10 then matchHoleIndex of 0 would represent hole 10,
                    //If the event is starting on hole 1 then matchHoleIndex of 0 would represent hole 1
                    this._matchHoleIndexes = updatedSubMatch.holes;

                    //get teams
                    await this.getTeams(this.match, this);

                    //if there is a press then get it
                    if (updatedSubMatch.press) {
                        this.press = new AppSubMatch();
                        await this.press.initialize(this.match, updatedSubMatch.press, this);
                    }

                });

        } catch (err) {
            console.log('app.match.ts AppSubMatch update error', err, JSON.stringify(err));
            throw err;
        }

    }

    get teams(): AppTeam[] {

        return this._teams?.filter((team) => {
            //only return teams that have players
            return team.exists && team.players.all.length > 0;
        }) || [];

    }

    get holes(): number[] {

        //if match parent is an event
        if (this.match.parent instanceof AppEvent) {

            //get the holes that are common between the submatch and the event
            return this._appFunction.arrayIntersection(this._matchHoleIndexes, this.match.parent.holes);

        } else {
            //for not event matches return the submatch holes
            return this._matchHoleIndexes;
        }

    }

    calcNumberOfTeams(): number {

        //we need to calculate the number of teams based on the number of players in the submatch. this is used in the match live scoring component

        //get the number of players in the submatch
        const numberOfPlayers: number = this.match.players.length

        //get the number of players per team
        const numberOfPlayersPerTeam: number = this.match.getTeamSize(this.match.game, this.match.teamSize).descriptionHeader;

        //this should always be an integer
        return numberOfPlayers / numberOfPlayersPerTeam;

    }

    getMatchHoleIndex(event: AppEvent, courseHoleIndex: number): number {
        //get the relative match hole index based on the course hole index
        return this._appFunction.mod(courseHoleIndex + event.startingHoleIndex, event.numberOfHoles);
    }

    getCourseHoleIndex(event: AppEvent, matchHoleIndex: number): number {
        //get the relative course hole index based on the match hole index
        return this._appFunction.mod(matchHoleIndex + event.startingHoleIndex, 18);
    }

    isMatchHole(event: AppEvent, currentCourseHoleIndex: number): boolean {
        //because some submatches only include certain holes (i.e. spins, nassau, etc) we have to determine if the current hole is part of the submatch
        return this._matchHoleIndexes.includes(this.getMatchHoleIndex(event, currentCourseHoleIndex));
    }

    getMatchHoleIndexes(event: AppEvent): number[] {
        //get the first x holes of the submatch based on the number of holes being played by the event
        return this._matchHoleIndexes.slice(0, event.numberOfHoles);
    }

    getAdjustMatchHoleIndexes(event: AppEvent): number[] {

        //get the match hole indexes for the submatch based on the event starting hole
        const adjustedMatchHoleIndexes: number[] = this._matchHoleIndexes
            .map((matchHoleIndex) => {
                return this._appFunction.mod((event.startingHoleIndex + matchHoleIndex), 18);
            });

        //get the first x holes of the submatch based on the number of holes being played by the event
        return adjustedMatchHoleIndexes.slice(0, event.numberOfHoles);
    }

    addTeam(team: AppTeam) {

        //initial array
        if (!Array.isArray(this._teams)) {
            this._teams = [];
        }

        //add team...
        this._teams.push(team);

        //mark dirty...
        team.markAsDirty();

        //...then start listening to published team scores
        team
            .eventTeamHoleScoreUpdate
            .subscribe((eventTeamScoreUpdate) => {
                //calc match leaderboard
                this.calcSubMatchLeaderboard(eventTeamScoreUpdate);
            });

    }

    deleteTeams(batch: firebase.firestore.WriteBatch) {

        //use for statement so we can await the delete
        for (const team of this.teams) {
            team.delete(batch);
        }

        //reset teams
        this._teams = [];

        //reset leaderboards
        this.reset();

        /* return new Promise<void>((resolve) => {

            const promiseArray: any[] = [];

            this.teams
                .forEach((team) => {
                    const p = team.delete();
                    promiseArray.push(p);
                });

            Promise
                .all(promiseArray)
                .then(() => {

                    //reset teams
                    this._teams = [];

                    //reset leaderboards
                    this.reset();

                    //return
                    resolve();

                });

        }); */

    }

    async deleteTeam(team: AppTeam): Promise<void> {

        //remove team from submatch
        this._teams = this._teams.filter((foundTeam) => {
            return foundTeam.id !== team.id;
        });

        //delete team
        const batch: firebase.firestore.WriteBatch = this._appFunction.firestore.batch();
        team.delete(batch);
        await batch.commit();

    }

    subMatchName(): string {
        //if this is a press (i.e. has a parentSubMatch) then return just the submatch name of the press
        //if match name and submatch name are the same then just return the match name (i.e. stroke play)
        //else return the match name and submatch name (i.e. nassua - front nine)
        return this.parentSubMatch ? this.name : (this.match.getGame(this.match.game)?.name === this.name ? this.match.getGame(this.match.game)?.name : this.match.getGame(this.match.game)?.name + ' - ' + this.name);
    }

    private getTeam(teamId: string): AppTeam {
        //turn team
        return this.teams.find((team) => {
            return team.id === teamId;
        });
    }

    private getTeams(match: AppMatch, subMatch: AppSubMatchI) {

        return new Promise<void>((resolve) => {

            try {

                //only run once
                if (!Array.isArray(this._teams)) {

                    //setup
                    this._teams = [];

                    const promiseArray: any[] = [];

                    //get teams for given subMatch
                    const getTeamsUnsubscribe = this._appFunction
                        .firestore
                        .collection(AppConfig.COLLECTION.MatchTeams)
                        .where('matchId', '==', match.id)
                        .where('subMatchId', '==', subMatch.id)
                        .onSnapshot((foundTeams) => {

                            //for each found team...
                            foundTeams
                                .docChanges()
                                .forEach(async (foundTeam) => {

                                    //try to find team...
                                    const localTeamFound: AppTeam = this.getTeam(foundTeam.doc.id);

                                    //for new teams
                                    if (foundTeam.type === FirestoreUpdateType.Added) {

                                        //...if not found then create team
                                        if (!localTeamFound) {

                                            //***DO NOT CHANGE THE ORDER OF THESE STATEMENTS***

                                            //create new team...
                                            const team: AppTeam = new AppTeam();

                                            //then add team to array...
                                            this.addTeam(team);

                                            //then initialize team
                                            const p = team.initialize(this, foundTeam.doc);
                                            team.update(foundTeam.doc, foundTeam.type as FirestoreUpdateType);
                                            promiseArray.push(p);

                                            //***DO NOT CHANGE THE ORDER OF THESE STATEMENTS***

                                        } else { //else found, update
                                            localTeamFound.update(foundTeam.doc, FirestoreUpdateType.Modified);
                                        }

                                    } else {
                                        //for updated or removed teams
                                        localTeamFound?.update(foundTeam.doc, foundTeam.type as FirestoreUpdateType);
                                    }

                                });

                            Promise
                                .all(promiseArray)
                                .then(() => {
                                    resolve();
                                });

                        }, (err) => {
                            console.log('app.match.ts AppSubMatch getTeams onSnapshot error', err);
                            throw err;
                        });

                    //store snapshot for shutdown
                    this._appFunction.registerUnsubscribe(getTeamsUnsubscribe);

                } else {
                    //noop
                    resolve();
                }

            } catch (err) {
                console.log('app.match.ts AppSubMatch getTeams err', err);
                throw err;
            }

        });

    }

    private calcSubMatchLeaderboard(teamScoreUpdate: AppTeamScoreUpdate) {

        const event: AppEvent = teamScoreUpdate.eventTeamScoreCardEvent.event;
        const currentCourseHoleIndex: number = teamScoreUpdate.eventTeamHole.courseHoleIndex;

        switch (this.match.game) {

            case GameType.StrokePlay:
            case GameType.Stableford: {

                //create and array of each team's total score (whether stoke or stableford)

                try {

                    /* example of totalScoreLeaderboard object
                    events: {
                        `eventId`: {
                            teams: {
                                `teamId`: {
                                    event: AppEvent,
                                    team: AppTeam,
                                    teamScore: number;
                                    teamPar: number;
                                    teamToPar: number;
                                    playerHoleThruMatchIndex: number;
                                    position: number;
                                    tiedIndicator: string;
                                    won: boolean;
                                },
                                `teamId`: {
                                    event: AppEvent,
                                    team: AppTeam,
                                    teamScore: number;
                                    teamPar: number;
                                    teamToPar: number;
                                    playerHoleThruMatchIndex: number;
                                    position: number;
                                    tiedIndicator: string;
                                    won: boolean;
                                },
                                ...
                            }
                        },
                        `eventId`: {
                            teams: {
                                `teamId`: {
                                    event: AppEvent,
                                    team: AppTeam,
                                    teamScore: number;
                                    teamPar: number;
                                    teamToPar: number;
                                    playerHoleThruMatchIndex: number;
                                    position: number;
                                    tiedIndicator: string;
                                    won: boolean;
                                },
                                `teamId`: {
                                    event: AppEvent,
                                    team: AppTeam,
                                    teamScore: number;
                                    teamPar: number;
                                    teamToPar: number;
                                    playerHoleThruMatchIndex: number;
                                    position: number;
                                    tiedIndicator: string;
                                    won: boolean;
                                },
                                ...
                            }
                        }
                        ...
                    } 
                    */

                    //iterate through team holes and get score and par sums
                    var totalTeamScore: number = 0;
                    var totalTeamPar: number = 0;
                    const holesList: EventTeamHolesScorecardHoleI[] = <EventTeamHolesScorecardHoleI[]>this._appFunction.convertJSONToArray(teamScoreUpdate.eventTeamScoreCardEvent.holes);
                    holesList
                        .forEach((hole) => {
                            totalTeamScore = totalTeamScore + hole.score || 0;
                            totalTeamPar = totalTeamPar + hole.par || 0;
                        });

                    //get event from total score leaderboard
                    const totalScoreLeaderboardEvent: TotalScoreLeaderboardEventI = <TotalScoreLeaderboardEventI>this._appFunction.getJSONProperty(this.totalScoreLeaderboard.events, event.id, { teams: {} });
                    totalScoreLeaderboardEvent.id = event.id;
                    totalScoreLeaderboardEvent.eventDt = event.eventDt;

                    //get team from event total score leaderboard
                    const totalScoreLeaderboardEventTeam: TotalScoreLeaderboardEventTeamI = <TotalScoreLeaderboardEventTeamI>this._appFunction.getJSONProperty(totalScoreLeaderboardEvent.teams, teamScoreUpdate.eventTeamScoreCardEvent.team.id, {});

                    //set team values
                    totalScoreLeaderboardEventTeam.event = event;
                    totalScoreLeaderboardEventTeam.team = teamScoreUpdate.eventTeamScoreCardEvent.team;
                    totalScoreLeaderboardEventTeam.teamScore = totalTeamScore;
                    totalScoreLeaderboardEventTeam.teamPar = totalTeamPar;
                    totalScoreLeaderboardEventTeam.holeThruMatchIndex = teamScoreUpdate.eventTeamScoreCardEvent.team.holeThruMatchIndex;
                    totalScoreLeaderboardEventTeam.teamToPar = totalTeamScore - totalTeamPar;

                    //****************** calculate leaderboard position  ******************/

                    //sort stroke play leaderboard by least to greatest number of strokes 
                    //sort stableford leaderboard by greatest to least number of points
                    const eventTeamList: TotalScoreLeaderboardEventTeamI[] = this._appFunction.convertJSONToArray(totalScoreLeaderboardEvent.teams);

                    //if stroke play sort team to par by least to greatest
                    if (this.match.game === GameType.StrokePlay) {
                        eventTeamList.sortBy('teamToPar', SortByOrder.ASC);
                    }
                    else {
                        //if stableford sort team score by greatest to least
                        eventTeamList.sortBy('teamScore', SortByOrder.DESC);
                    }

                    //determine leaderboard position
                    eventTeamList
                        .forEach((team, teamIndex, leaderboard) => {

                            if (teamIndex === 0) {
                                team.position = 1;
                                team.tiedIndicator = '';
                            } else {

                                //if current team score the same as previous team score
                                if (team.teamScore === leaderboard[teamIndex - 1].teamScore) {

                                    //update previous team to be tied with this team
                                    leaderboard[teamIndex - 1].tiedIndicator = 'T';

                                    //update current team's position to be the same as the previous team
                                    team.position = leaderboard[teamIndex - 1].position;
                                    team.tiedIndicator = 'T';

                                } else {
                                    //update current team position
                                    team.position = teamIndex + 1;
                                    team.tiedIndicator = '';
                                }

                            }

                            //reset won property for each team
                            team.won = false;

                        });

                    //if match is complete AND 
                    //this team is in first place set won property to true (or flase)
                    totalScoreLeaderboardEventTeam.won = this.match.status === ScoringMode.ScoringComplete && totalScoreLeaderboardEventTeam.position === 1;

                    //convert teams array back to json
                    totalScoreLeaderboardEvent.teams = <TotalScoreLeaderboardEventTeamI>this._appFunction.convertArrayToJSON(eventTeamList, 'team');

                    //save array versions, these are used for dispaying the leaderboards such as on the event scoring the scorecard pages
                    totalScoreLeaderboardEvent.teamsList = eventTeamList;
                    this.totalScoreLeaderboard.eventsList = <TotalScoreLeaderboardEventI[]>this._appFunction.convertJSONToArray(this.totalScoreLeaderboard.events);

                    //****************** calculate trip "event" leaderboard ******************/

                    //if match parent is a trip then calculate trip "event" leaderboard
                    if (this.match.parent instanceof AppGroupTrip) {

                        //get the trip "event" id, which is the subMatch id
                        const tripEventId: string = this.id;

                        //filter out the trip "event"
                        const actualEvents: TotalScoreLeaderboardEventI[] = this.totalScoreLeaderboard.eventsList
                            .filter((totalScoreLeaderboardEvent) => {
                                return totalScoreLeaderboardEvent.id !== tripEventId;
                            });

                        //sum the team's total score for each event
                        const allEventTotalTeamScore: number = actualEvents.reduce((sum, totalScoreLeaderboardEventFiltered) =>
                            sum + (totalScoreLeaderboardEventFiltered.teams[teamScoreUpdate.eventTeamScoreCardEvent.team.id]?.teamScore || 0), 0
                        );

                        //sum the total par for each event
                        const allEventTotalPar: number = actualEvents.reduce((sum, totalScoreLeaderboardEventFiltered) =>
                            sum + (totalScoreLeaderboardEventFiltered.teams[teamScoreUpdate.eventTeamScoreCardEvent.team.id]?.teamPar || 0), 0
                        );

                        //get trip "event" from total score leaderboard using the submatch id (vs event id)
                        const aggregateLeaderboardEvent: TotalScoreLeaderboardEventI = <TotalScoreLeaderboardEventI>this._appFunction.getJSONProperty(this.totalScoreLeaderboard.events, tripEventId, { teams: {} });
                        aggregateLeaderboardEvent.id = tripEventId;

                        //set eventDt timestamp to 12/31/9999 so that it is always at the bottom of the leaderboard
                        aggregateLeaderboardEvent.eventDt = new firebase.firestore.Timestamp(253402300799, 999999999);

                        //get team from trip "event" score leaderboard
                        const aggregateLeaderboardEventTeam: TotalScoreLeaderboardEventTeamI = <TotalScoreLeaderboardEventTeamI>this._appFunction.getJSONProperty(aggregateLeaderboardEvent.teams, teamScoreUpdate.eventTeamScoreCardEvent.team.id, {});

                        //set team values
                        aggregateLeaderboardEventTeam.team = teamScoreUpdate.eventTeamScoreCardEvent.team;
                        aggregateLeaderboardEventTeam.teamScore = allEventTotalTeamScore;
                        aggregateLeaderboardEventTeam.teamPar = allEventTotalPar;
                        aggregateLeaderboardEventTeam.teamToPar = allEventTotalTeamScore - allEventTotalPar;

                        //****************** calculate leaderboard position  ******************/

                        //sort stroke play leaderboard by least to greatest number of strokes 
                        //sort stableford leaderboard by greatest to least number of points
                        const aggregateEventTeamList: TotalScoreLeaderboardEventTeamI[] = this._appFunction.convertJSONToArray(aggregateLeaderboardEvent.teams);
                        aggregateEventTeamList.sortBy('teamScore', this.match.game === GameType.StrokePlay ? SortByOrder.ASC : SortByOrder.DESC);

                        //determine leaderboard position
                        aggregateEventTeamList
                            .forEach((team, teamIndex, leaderboard) => {

                                if (teamIndex === 0) {
                                    team.position = 1;
                                    team.tiedIndicator = '';
                                } else {

                                    //if current team score the same as previous team score
                                    if (team.teamScore === leaderboard[teamIndex - 1].teamScore) {

                                        //update previous team to be tied with this team
                                        leaderboard[teamIndex - 1].tiedIndicator = 'T';

                                        //update current team's position to be the same as the previous team
                                        team.position = leaderboard[teamIndex - 1].position;
                                        team.tiedIndicator = 'T';

                                    } else {

                                        //update current team position
                                        team.position = teamIndex + 1;
                                        team.tiedIndicator = '';

                                    }

                                }

                                //reset won property for each team
                                team.won = false;

                            });

                        //****************** save arrays ******************/

                        //save array versions, these are used for dispaying the leaderboards such as on the event scoring the scorecard pages
                        aggregateLeaderboardEvent.teamsList = <TotalScoreLeaderboardEventTeamI[]>this._appFunction.convertJSONToArray(aggregateEventTeamList);
                        this.totalScoreLeaderboard.eventsList = <TotalScoreLeaderboardEventI[]>this._appFunction.convertJSONToArray(this.totalScoreLeaderboard.events);
                        this.totalScoreLeaderboard.eventsList.sortBy('eventDt', SortByOrder.ASC);

                    }

                } catch (err) {
                    console.log('app.match.ts AppSubMatch calcSubMatchLeaderboard ' + this.match.getGame(this.match.game) + ' error', err);
                    throw err;
                }

                break;

            }
            case GameType.Nassau: //not allowed for trip matches???
            case GameType.Spins9: //not allowed for trip matches???
            case GameType.Spins18: //not allowed for trip matches???
            case GameType.Skins: //not allowed for trip matches???
            case GameType.MatchPlay: { //not allowed for trip matches???

                try {

                    //get teams that have scores for the current hole
                    const teamsWithHoles: AppTeam[] = this.teams.filter((team) => {
                        //TODO: shold we add ?.score to the end of this? Maybe add isNumeric to the end of this
                        //return this._appFunction.isNumeric(team.eventTeamScoreCard.events[event.id]?.holes[currentCourseHoleIndex]?.score); //We haven't tried this yet
                        return team.eventTeamScoreCard.events[event.id]?.holes[currentCourseHoleIndex];
                    });

                    //don't calculate leaderboard if all teams don't have scores for the current hole
                    if (teamsWithHoles.length === this.teams.length) {

                        //get all the team's scores for the given event/hole...
                        const allTeamScoresForHole: number[] = teamsWithHoles.map((team) => {
                            return team.eventTeamScoreCard.events[event.id].holes[currentCourseHoleIndex].score;
                        });

                        //...then determine the lowest score
                        const minScore: number = Math.min(...allTeamScoresForHole);

                        //...then determine how many teams have that lowest score
                        const teamsWithLowestScore: AppTeam[] = teamsWithHoles.filter((team) => {
                            //...then return the team(s) that have this lowest score
                            return (team.eventTeamScoreCard.events[event.id].holes[currentCourseHoleIndex].score === minScore);
                        });

                        //get event from hole by hole leaderboard
                        const holeByHoleEventLeaderboardEvent: HoleByHoleLeaderboardEventI = <HoleByHoleLeaderboardEventI>this._appFunction.getJSONProperty(this.holeByHoleLeaderboard.events, event.id, { holes: {}, teams: {} });

                        //get hole from event leaderboard
                        const holeByHoleEventLeaderboardHole: HoleByHoleLeaderboardHoleI = <HoleByHoleLeaderboardHoleI>this._appFunction.getJSONProperty(holeByHoleEventLeaderboardEvent.holes, currentCourseHoleIndex, {});

                        //set hole data
                        holeByHoleEventLeaderboardHole.courseHoleIndex = currentCourseHoleIndex;
                        holeByHoleEventLeaderboardHole.holeCarryOverByTeam = null;

                        //if only one team has the lowest score then they win the hole
                        let winningTeam: AppTeam;
                        if (teamsWithLowestScore.length === 1) {
                            //get winning team
                            winningTeam = teamsWithLowestScore[0];
                            holeByHoleEventLeaderboardHole.holeWonByTeam = winningTeam;
                        } else { //otherwise no one wins the hole
                            holeByHoleEventLeaderboardHole.holeWonByTeam = null;
                        }

                        //****************** calculate skins carry over  ******************/

                        //if game is skins then iterate backwards and look for any carry over holes
                        if (this.match.game === GameType.Skins) {

                            //get the match hole indexes for the submatch
                            const adjustedMatchHoleIndexes: number[] = this.getMatchHoleIndexes(event);

                            //starting from the last hole of the event iterate through previous holes
                            let currentHoleWinner: AppTeam;
                            for (let adjustedMatchHoleArrayIndex = (adjustedMatchHoleIndexes.length - 1); adjustedMatchHoleArrayIndex > -1; adjustedMatchHoleArrayIndex--) {

                                //get current course hole index
                                const courseHoleIndex: number = this.getCourseHoleIndex(event, adjustedMatchHoleIndexes[adjustedMatchHoleArrayIndex]);

                                //get hole from event leaderboard
                                const courseHoleByHoleEventLeaderboardHole: HoleByHoleLeaderboardHoleI = <HoleByHoleLeaderboardHoleI>holeByHoleEventLeaderboardEvent.holes[courseHoleIndex];

                                //if hole found and has a winner then for previsou holes and reset the carry over hole
                                if (courseHoleByHoleEventLeaderboardHole?.holeWonByTeam) {
                                    currentHoleWinner = courseHoleByHoleEventLeaderboardHole.holeWonByTeam;
                                    courseHoleByHoleEventLeaderboardHole.holeCarryOverByTeam = null;
                                } else if (courseHoleByHoleEventLeaderboardHole) {
                                    //if hole found and has no winner then set the carry over team to the current hole winner
                                    courseHoleByHoleEventLeaderboardHole.holeCarryOverByTeam = currentHoleWinner || null;
                                }

                            }

                        }

                        //****************** calculate leaderboard position  ******************/

                        //default all teams to the team leaderboard
                        this.teams
                            .forEach((team) => {

                                //get team from event leaderboard
                                const holeByHoleEventLeaderboardTeam: HoleByHoleLeaderboardTeamI = <HoleByHoleLeaderboardTeamI>this._appFunction.getJSONProperty(holeByHoleEventLeaderboardEvent.teams, team.id, {});

                                //set team values
                                holeByHoleEventLeaderboardTeam.team = team;
                                holeByHoleEventLeaderboardTeam.holesWon = 0;
                                holeByHoleEventLeaderboardTeam.holeThruMatchIndex = teamScoreUpdate.eventTeamScoreCardEvent.team.holeThruMatchIndex;
                                holeByHoleEventLeaderboardTeam.position = undefined;
                                holeByHoleEventLeaderboardTeam.tiedIndicator = undefined;
                                holeByHoleEventLeaderboardTeam.won = false;

                            });

                        //iterate through holes and tally holes won by team
                        const holeByHoleLeaderboardHolesList: HoleByHoleLeaderboardHoleI[] = this._appFunction.convertJSONToArray(holeByHoleEventLeaderboardEvent.holes);
                        holeByHoleLeaderboardHolesList
                            .forEach((holeByHoleLeaderboardHole) => {

                                //if the hole has a carry over winner
                                if (holeByHoleLeaderboardHole.holeCarryOverByTeam) {

                                    //get team from event leaderboard
                                    const holeByHoleEventLeaderboardTeam: HoleByHoleLeaderboardTeamI = <HoleByHoleLeaderboardTeamI>this._appFunction.getJSONProperty(holeByHoleEventLeaderboardEvent.teams, holeByHoleLeaderboardHole.holeCarryOverByTeam.id, {});

                                    //increment holesWon
                                    holeByHoleEventLeaderboardTeam.holesWon = holeByHoleEventLeaderboardTeam.holesWon + 1;

                                }
                                //if the hole has a winner
                                else if (holeByHoleLeaderboardHole.holeWonByTeam) {

                                    //get team from event leaderboard
                                    const holeByHoleEventLeaderboardTeam: HoleByHoleLeaderboardTeamI = <HoleByHoleLeaderboardTeamI>this._appFunction.getJSONProperty(holeByHoleEventLeaderboardEvent.teams, holeByHoleLeaderboardHole.holeWonByTeam.id, {});

                                    //increment holesWon
                                    holeByHoleEventLeaderboardTeam.holesWon = holeByHoleEventLeaderboardTeam.holesWon + 1;

                                }

                            });

                        //convert to array and sort teams leaderboard by number of holesWon - most to least
                        const holeByHoleEventLeaderboardTeamList: HoleByHoleLeaderboardTeamI[] = this._appFunction.convertJSONToArray(holeByHoleEventLeaderboardEvent.teams);
                        holeByHoleEventLeaderboardTeamList.sortBy('holesWon', SortByOrder.DESC);

                        //calc position
                        holeByHoleEventLeaderboardTeamList
                            .forEach((team, teamIndex) => {

                                if (teamIndex === 0) {
                                    team.position = 1;
                                    team.tiedIndicator = '';
                                } else {

                                    //if current team holesWon is the same as previous team holesWon
                                    if (team.holesWon === holeByHoleEventLeaderboardTeamList[teamIndex - 1].holesWon) {

                                        //update previous team to be tied with this team
                                        holeByHoleEventLeaderboardTeamList[teamIndex - 1].tiedIndicator = 'T';

                                        //update current team's position to be the same as the previous team
                                        team.position = holeByHoleEventLeaderboardTeamList[teamIndex - 1].position;
                                        team.tiedIndicator = 'T';

                                    } else {

                                        //update current team position
                                        team.position = teamIndex + 1;
                                        team.tiedIndicator = '';

                                    }

                                }

                                //reset won property for each team
                                team.won = false;

                            });

                        //****************** determine if there's a match winner  ******************/              

                        //determine if there's a winner for this sub match
                        const numberOfHolesWinningBy: number = holeByHoleEventLeaderboardTeamList[0].holesWon - (holeByHoleEventLeaderboardTeamList[1]?.holesWon || 0);
                        holeByHoleEventLeaderboardTeamList[0].won = numberOfHolesWinningBy > this.getRemainingHoles(event).length;

                        //****************** clean things up  ******************/              

                        //convert teams back to JSON and save
                        holeByHoleEventLeaderboardEvent.teams = <HoleByHoleLeaderboardTeamI>this._appFunction.convertArrayToJSON(holeByHoleEventLeaderboardTeamList, 'team');
                        holeByHoleEventLeaderboardEvent.teamsList = holeByHoleEventLeaderboardTeamList;
                        holeByHoleEventLeaderboardEvent.holesList = holeByHoleLeaderboardHolesList;
                        this.holeByHoleLeaderboard.eventsList = <HoleByHoleLeaderboardEventI[]>this._appFunction.convertJSONToArray(this.holeByHoleLeaderboard.events);

                        //if press option is on then publish event signaling that the leaderboard has been updated for specific submatch
                        //this updateEvent message is handled by event-scoring.page.ts, we did this because we only want event-scoring.page.ts to trigger the checkForPress method
                        if (this.match.pressOption !== PressOptions.Off) event.updateEvent.next('checkForPress');

                    }

                } catch (err) {
                    console.log('app.match.ts AppSubMatch calcSubMatchLeaderboard ' + this.match.getGame(this.match.game) + ' error', err);
                    throw err;
                }

                break;

            }
            case GameType.Nines: { //not allowed for trip matches???

                try {

                    //the scoring for Nines is different than most games because the scoring is based on comparing team scores. 
                    //So rather determining the team scores in AppTeam we have to determine the team scores here in AppSubMatch.
                    //We will still store the team scores in AppTeam. 

                    //iterate through the teams array and get the first player's score...in the Nines game there will only be one player per team
                    const teamScores: { team: AppTeam, score: number }[] = [];
                    this.teams
                        .forEach((team) => {

                            //get event object...
                            const playerScorecardEvent: EventPlayerScorecardEventI = <EventPlayerScorecardEventI>this._appFunction.getJSONProperty(team.eventPlayerScorecard.events, event.id, { players: {} });

                            //get the first player's id, because this is Nines there should only be one player per team
                            const playerId: string = Object.keys(playerScorecardEvent.players)[0];

                            //if a player is found then...
                            if (playerId) {

                                //get player object...
                                const playerScorecardPlayer: EventPlayerScorecardPlayerI = <EventPlayerScorecardPlayerI>this._appFunction.getJSONProperty(playerScorecardEvent.players, playerId, { holes: {} });

                                //get hole object...
                                const playerScorecardHole: EventPlayerScorecardHoleI = <EventPlayerScorecardHoleI>this._appFunction.getJSONProperty(playerScorecardPlayer.holes, currentCourseHoleIndex, {});

                                //get the first player's score for the given hole
                                const firstPlayerScore: number = playerScorecardHole.score;

                                //only return the team if the first player has a score for the given hole
                                if (firstPlayerScore !== undefined) {
                                    teamScores.push({
                                        team: team,
                                        score: firstPlayerScore
                                    });
                                }

                            }

                        });

                    //wait until all three nines teams have scores
                    if (teamScores.length === 3) {

                        //sort the team scores from lowest to highest
                        teamScores.sortBy('score', SortByOrder.ASC);

                        //determine how many unique scores there are
                        const uniqueTeamScores: { team: AppTeam, score: number }[] = teamScores.filter((teamScore, index, self) => {
                            return index === self.findIndex((selfTeam) => {
                                return selfTeam.score === teamScore.score;
                            });
                        });

                        //score array
                        let nineScores: number[];

                        //base on the number of unique scores...
                        switch (uniqueTeamScores.length) {

                            case 1: //if there is only one unique score which means all teams tie
                                nineScores = [3, 3, 3];
                                break;

                            case 2: //if there are two unique scores

                                //determine if the first two scores are the same
                                if (teamScores[0].score === teamScores[1].score) {
                                    nineScores = [4, 4, 1];
                                } else { //otherwise the last two scores are the same
                                    nineScores = [5, 2, 2];
                                }

                                break;

                            case 3: //if there are three unique scores
                                nineScores = [5, 3, 1];
                                break;

                        }

                        //get event from submatch total score leaderboard
                        const totalScoreLeaderboardEvent: TotalScoreLeaderboardEventI = <TotalScoreLeaderboardEventI>this._appFunction.getJSONProperty(this.totalScoreLeaderboard.events, teamScoreUpdate.eventTeamScoreCardEvent.event.id, { teams: {} });

                        //iterate through the teams and update the team scorecard for the given hole
                        teamScores
                            .forEach((teamScore, index) => {

                                //******** update the AppTeam eventTeamScoreCard for given hole ********

                                //get the event team scorecard
                                const eventTeamScorecardEvent: EventTeamScorecardEventI = teamScore.team.eventTeamScoreCard.events[event.id];

                                //get hole
                                const hole: EventTeamHolesScorecardHoleI = <EventTeamHolesScorecardHoleI>eventTeamScorecardEvent.holes[currentCourseHoleIndex];

                                //update the team scorecard for hole
                                //hole.score = nineScores[index];
                                hole.ninesScore = nineScores[index];
                                hole.courseHoleIndex = currentCourseHoleIndex;
                                hole.event = event;
                                hole.team = teamScore.team;
                                hole.par = undefined;

                                //******** update the AppSubMatch totalScoreLeaderboard across all holes ********

                                //get team from event total score leaderboard
                                const totalScoreLeaderboardEventTeam: TotalScoreLeaderboardEventTeamI = <TotalScoreLeaderboardEventTeamI>this._appFunction.getJSONProperty(totalScoreLeaderboardEvent.teams, teamScore.team.id, {});

                                //sum team scores
                                var totalTeamNinesScore: number = 0;
                                const teamHoleList: EventTeamHolesScorecardHoleI[] = <EventTeamHolesScorecardHoleI[]>this._appFunction.convertJSONToArray(eventTeamScorecardEvent.holes);
                                teamHoleList.forEach((hole) => {
                                    totalTeamNinesScore = totalTeamNinesScore + (hole.ninesScore || 0);
                                });

                                //set team leaderboard
                                totalScoreLeaderboardEventTeam.event = teamScoreUpdate.eventTeamScoreCardEvent.event;
                                totalScoreLeaderboardEventTeam.team = teamScore.team;
                                totalScoreLeaderboardEventTeam.teamScore = totalTeamNinesScore;
                                totalScoreLeaderboardEventTeam.holeThruMatchIndex = teamScore.team.holeThruMatchIndex;
                                totalScoreLeaderboardEventTeam.teamPar = undefined; //not needed for nines
                                totalScoreLeaderboardEventTeam.teamToPar = undefined; //not needed for nines
                                totalScoreLeaderboardEventTeam.won = false; //not needed for nines

                            });

                        /* example of totalScoreLeaderboard object
                         events: {
                             `eventId`: {
                                 teams: {
                                     `teamId`: {
                                         event: AppEvent,
                                         team: AppTeam,
                                         teamScore: number;
                                         teamPar: number;
                                         teamToPar: number;
                                         playerHoleThruMatchIndex: number;
                                         position: number;
                                         tiedIndicator: string;
                                         won: boolean;
                                     },
                                     `teamId`: {
                                         event: AppEvent,
                                         team: AppTeam,
                                         teamScore: number;
                                         teamPar: number;
                                         teamToPar: number;
                                         playerHoleThruMatchIndex: number;
                                         position: number;
                                         tiedIndicator: string;
                                         won: boolean;
                                     },
                                     ...
                                 }
                             },
                             `eventId`: {
                                 teams: {
                                     `teamId`: {
                                         event: AppEvent,
                                         team: AppTeam,
                                         teamScore: number;
                                         teamPar: number;
                                         teamToPar: number;
                                         playerHoleThruMatchIndex: number;
                                         position: number;
                                         tiedIndicator: string;
                                         won: boolean;
                                     },
                                     `teamId`: {
                                         event: AppEvent,
                                         team: AppTeam,
                                         teamScore: number;
                                         teamPar: number;
                                         teamToPar: number;
                                         playerHoleThruMatchIndex: number;
                                         position: number;
                                         tiedIndicator: string;
                                         won: boolean;
                                     },
                                     ...
                                 }
                             }
                             ...
                         } 
                         */

                        //****************** calculate leaderboard position  ******************/

                        //sort leaderboard by points - most to least
                        const totalScoreLeaderboardEventTeamList: TotalScoreLeaderboardEventTeamI[] = this._appFunction.convertJSONToArray(totalScoreLeaderboardEvent.teams);
                        totalScoreLeaderboardEventTeamList.sortBy('teamScore', SortByOrder.DESC);

                        //calc position
                        totalScoreLeaderboardEventTeamList
                            .forEach((totalScoreLeaderboardEventTeam, teamIndex) => {

                                //console.log('app.match.ts AppSubMatch calcNinesLeaderboardPosition', team, teamIndex, leaderboard);

                                if (teamIndex === 0) {
                                    totalScoreLeaderboardEventTeam.position = 1;
                                    totalScoreLeaderboardEventTeam.tiedIndicator = '';
                                } else {

                                    //if current team score the same as previous team score
                                    if (totalScoreLeaderboardEventTeam.teamScore === totalScoreLeaderboardEventTeamList[teamIndex - 1].teamScore) {

                                        //update previous team to be tied with this team
                                        totalScoreLeaderboardEventTeamList[teamIndex - 1].tiedIndicator = 'T';

                                        //update current team's position to be the same as the previous team
                                        totalScoreLeaderboardEventTeam.position = totalScoreLeaderboardEventTeamList[teamIndex - 1].position;
                                        totalScoreLeaderboardEventTeam.tiedIndicator = 'T';

                                    } else {

                                        //update current team position
                                        totalScoreLeaderboardEventTeam.position = teamIndex + 1;
                                        totalScoreLeaderboardEventTeam.tiedIndicator = '';

                                    }

                                }

                                //if match is complete AND 
                                //this team is in first place set won property to true (or flase)
                                totalScoreLeaderboardEventTeam.won = this.match.status === ScoringMode.ScoringComplete && totalScoreLeaderboardEventTeam.position === 1;

                            });

                        //convert teams back to JSON and save
                        totalScoreLeaderboardEvent.teams = <TotalScoreLeaderboardEventTeamI>this._appFunction.convertArrayToJSON(totalScoreLeaderboardEventTeamList, 'team');

                        //save array versions, these are used for dispaying the leaderboards such as on the event scoring the scorecard pages
                        totalScoreLeaderboardEvent.teamsList = totalScoreLeaderboardEventTeamList;
                        this.totalScoreLeaderboard.eventsList = <TotalScoreLeaderboardEventI[]>this._appFunction.convertJSONToArray(this.totalScoreLeaderboard.events);

                    }

                } catch (err) {
                    console.log('app.match.ts AppSubMatch calcSubMatchLeaderboard GameType.Nines error', err);
                    throw err;
                }

                break;

            }

        }

    }

    get scoringMode(): ScoringMode {

        //there's no teams so obvioulsy can't be in scoring mode
        if (this.teams.length === 0) {
            return ScoringMode.ScoringNotStarted;
        }

        //is some teams in scoring mode??
        const scoringActive: boolean = this.teams
            .some((team) => {
                return team.scoringMode === ScoringMode.ScoringActive;
            });

        if (scoringActive) {
            return ScoringMode.ScoringActive;
        }

        //is every team done scoring??
        const scoringCompleted: boolean = this.teams
            .every((team) => {
                return team.scoringMode === ScoringMode.ScoringComplete;
            });

        if (scoringCompleted) {
            return ScoringMode.ScoringComplete;
        } else {
            return ScoringMode.ScoringNotStarted;
        }

    }

    private getRemainingHoles(event: AppEvent): number[] {

        //we can only property determine the remaining holes if all teams have the same number of holesThru

        //get event from hole by hole leaderboard
        const holeByHoleEventLeaderboardEvent: HoleByHoleLeaderboardEventI = <HoleByHoleLeaderboardEventI>this._appFunction.getJSONProperty(this.holeByHoleLeaderboard.events, event.id);

        //get teams array
        const holeByHoleEventLeaderboardTeams: HoleByHoleLeaderboardTeamI[] = this._appFunction.convertJSONToArray(holeByHoleEventLeaderboardEvent.teams);

        //get list of all teams holeThruMatchIndexes
        const holeThruMatchIndexes: number[] = holeByHoleEventLeaderboardTeams.map((team) => {
            return team.holeThruMatchIndex;
        });

        //determine how many unique holeThruMatchIndexes there are
        const uniqueHoleThruIndexes: number[] = holeThruMatchIndexes.filter((holeThruMatchIndex, index) => {
            return holeThruMatchIndexes.indexOf(holeThruMatchIndex) === index;
        });

        //if there's only one holeThruMatchIndexes then we know that all teams are thru the same hole and we can determine the remaining holes
        if (uniqueHoleThruIndexes.length === 1) {

            //get the index of uniqueHoleThruIndexes[0] + 1 in _matchHoleIndexes
            //const uniqueHoleThruIndexesPosition: number = this._matchHoleIndexes.indexOf(uniqueHoleThruIndexes[0] + 1);
            const matchHoleIndexes: number[] = this.getMatchHoleIndexes(event);
            const uniqueHoleThruIndexesPosition: number = matchHoleIndexes.indexOf(uniqueHoleThruIndexes[0] + 1);

            //if the startingHoleIndex is -1 then we know there are no more holes to play
            if (uniqueHoleThruIndexesPosition === -1) {
                return [];
            } else {
                //return this._matchHoleIndexes.slice(uniqueHoleThruIndexesPosition);
                return matchHoleIndexes.slice(uniqueHoleThruIndexesPosition);
            }

        } else {
            return [];
        }

    }

    private createPress(event: AppEvent) {

        //create and init the new press
        const p = new Promise<void>((resolve) => {

            //create press name using the top level submatch name AND how many presses there are
            let pressName: string;
            let pressCount: number = 0;
            let subMatch: AppSubMatch = this;
            do {
                pressName = subMatch.name;
                pressCount = pressCount + 1;
                subMatch = subMatch.parentSubMatch;
            }
            while (subMatch);

            //create press match
            this.press = new AppSubMatch();
            this.press
                .initialize(this.match, {
                    id: this._appFunction.newGuid(),
                    //name: pressName + ' press ' + pressCount.toString(),
                    name: 'Press ' + pressCount.toString(),
                    holes: this.getRemainingHoles(event),
                    press: null,
                    promptedForPress: false,
                    allowPress: this.allowPress,
                    //useTeamFromSubmatch: this.useTeamFromSubmatch
                }, this)
                .then(() => {

                    //create the array for promises
                    const promiseArray: any[] = [];

                    //add the parent submatch teams to the press teams
                    this
                        .teams
                        .forEach((subMatchTeam) => {

                            //create team
                            const team: AppTeam = new AppTeam();

                            //lastly add team to press subMatch
                            this.press.addTeam(team);

                            //and initialize
                            const q = team
                                .initialize(this.press, undefined, subMatchTeam.teamColor)
                                .then(() => {

                                    //add parent sub match team players the press sub match team, 
                                    subMatchTeam
                                        .players
                                        .all
                                        .forEach((player) => {
                                            //add player to team
                                            team.players.add(player);
                                        });

                                });

                            promiseArray.push(q);

                        });

                    //return once teams are all done initializing 
                    Promise
                        .all(promiseArray)
                        .then(() => {
                            resolve();
                        });

                });

        });

        Promise
            .all([p])
            .then(() => {

                //now mark match as dirty and save it so that it saves press
                this.match.markAsDirty();
                const batch: firebase.firestore.WriteBatch = this._appFunction.firestore.batch();
                this.match
                    .save(batch)
                    .then(() => {
                        batch.commit();
                    });

            });

    }

    checkForPress(event: AppEvent) {

        //TODO: we need to check when presses are allowed, doesn't seem like presses should be allowed for players across different tee times 

        //get event from hole by hole leaderboard
        const holeByHoleEventLeaderboardEvent: HoleByHoleLeaderboardEventI = <HoleByHoleLeaderboardEventI>this._appFunction.getJSONProperty(this.holeByHoleLeaderboard.events, event.id);

        //if scoring has started for this submatch AND
        //submatch allows presses AND
        //a press hasn't already been started AND
        if (holeByHoleEventLeaderboardEvent && this.allowPress && !this.press) {

            //get teams
            const holeByHoleEventLeaderboardTeams: HoleByHoleLeaderboardTeamI[] = holeByHoleEventLeaderboardEvent.teamsList;

            //there are holes left to play in the submatch AND
            //there's more than one (1) team in the submatch (not sure why this would happen, maybe during the hydration from firestore?)
            if (this.getRemainingHoles(event).length > 0 && holeByHoleEventLeaderboardTeams.length > 1) {

                //...get the number of strokes first place is winning by
                const winningBy: number = holeByHoleEventLeaderboardTeams[0].holesWon - holeByHoleEventLeaderboardTeams[1].holesWon;

                //if this team is winning by the number of configured strokes then...
                if (winningBy === this.match.pressStokesDown) {

                    //...see if the auto press option is on for this match
                    if (this.match.pressOption === PressOptions.Auto) {

                        //create the press
                        this.createPress(event);

                        //create toast
                        this._appFunction
                            .queueToast({
                                message: 'An new auto press is in effect.',
                                position: 'top',
                                duration: 4000,
                                color: 'secondary',
                                closeButtonText: 'Ok'
                            });

                    }

                    //...see if the prompt press is on for this match AND
                    //make sure we didn't already prompt for a press (i.e. only ask once)
                    if (this.match.pressOption === PressOptions.Prompt && !this.promptedForPress) {

                        //signal that we already asked for a press so that we dont' ask again
                        this.promptedForPress = true;

                        //now ask if they want to press
                        this._appFunction
                            .alertCtrl
                            .create({
                                header: 'Is the press on?!',
                                message: "Team '" + holeByHoleEventLeaderboardTeams[0].team.name + "' is winning the '" + this.name + "' match by " + this.match.pressStokesDown + " strokes. Do you want to press?",
                                buttons: [
                                    {
                                        text: 'No',
                                        role: 'cancel',
                                        handler: () => {

                                            //save, using this as a short cut...could just save match
                                            //this.match.event.save();
                                            this.match.save(this._appFunction.firestore.batch());
                                        }
                                    }, {
                                        text: 'Yes',
                                        handler: () => {
                                            this.createPress(event);
                                        }
                                    }
                                ]
                            })
                            .then((alert) => {
                                alert.present();
                            });

                    }

                }

            }

        }

        //if there's already a press, check to see if that press needs another press (this is a cascading press)
        if (this.press) {
            this.press.checkForPress(event);
        }

    }

    //merge the subMatch total score leaderboard with the subMatch team list
    get totalScoreLeaderboardEventTeamListMerged(): TotalScoreLeaderboardEventTeamI[] {

        //create a merged team list. the subMatch team list is the master list. the event team list is the slave list. the columns should be position, team name, score, and holes played
        const mergedTeamList: TotalScoreLeaderboardEventTeamI[] = [];

        //get the event and team list
        const eventList = this.totalScoreLeaderboard.eventsList;
        const teamList = eventList[eventList.length - 1]?.teamsList;

        //add the subMatch team list to the merged team list
        this.teams.forEach((team) => {

            //find the team totalScoreLeaderboardEventList
            const eventTeam = teamList?.find((teamItem) => teamItem.team.id === team.id);

            //create a new team object
            mergedTeamList.push({
                team: team,
                event: eventTeam?.event,
                teamScore: eventTeam?.teamScore,
                teamPar: eventTeam?.teamPar,
                teamToPar: eventTeam?.teamToPar,
                holeThruMatchIndex: eventTeam?.team.holeThruMatchIndex,
                position: eventTeam?.position || 1000, //be sure this team is at the bottom of the list
                tiedIndicator: eventTeam?.tiedIndicator,
                won: eventTeam?.won
            });

        });

        //sort the merged team list
        mergedTeamList.sortBy('position', SortByOrder.ASC, 'team.name', SortByOrder.ASC);

        //return the merged team list
        return mergedTeamList;

    }

    get totalHoleByHoleLeaderboardEventTeamListMerged(): HoleByHoleLeaderboardTeamI[] {

        //create a merged team list. the subMatch team list is the master list. the event team list is the slave list. the columns should be position, team name, score, and holes played
        const mergedTeamList: HoleByHoleLeaderboardTeamI[] = [];

        //get the event and team list
        const eventList = this.holeByHoleLeaderboard.eventsList;
        const teamList = eventList[eventList.length - 1]?.teamsList;

        //add the subMatch team list to the merged team list
        this.teams.forEach((team) => {

            //find the team totalScoreLeaderboardEventList
            const eventTeam = teamList?.find((teamItem) => teamItem.team.id === team.id);

            //create a new team object
            mergedTeamList.push({
                team: team,
                holesWon: eventTeam?.holesWon,
                holeThruMatchIndex: eventTeam?.team.holeThruMatchIndex,
                position: eventTeam?.position || 1000, //be sure this team is at the bottom of the list
                tiedIndicator: eventTeam?.tiedIndicator,
                won: eventTeam?.won
            });

        });

        //sort the merged team list
        mergedTeamList.sortBy('position', SortByOrder.ASC, 'team.name', SortByOrder.ASC);

        //return the merged team list
        return mergedTeamList;

    }

    reset() {
        //reinitialize the scorebaords
        this.totalScoreLeaderboard = <TotalScoreLeaderboardI>{ events: {}, eventsList: [] };
        this.holeByHoleLeaderboard = <HoleByHoleLeaderboardI>{ events: {}, eventsList: [] };
    }

    save(batch: firebase.firestore.WriteBatch) {

        //first save teams of this submatch...
        this.teams.forEach((team) => {
            team.save(batch);
        });

        //...then if there's a press then save that
        this.press?.save(batch);

    }

    data(): AppSubMatchI {

        try {

            return {
                id: this.id,
                name: this.name,
                holes: this._matchHoleIndexes,
                allowPress: this.allowPress,
                promptedForPress: this.promptedForPress,
                press: this.press?.data() || null,
            };

        }
        catch (err) {
            console.log('app.match.ts AppSubMatch data error', err, JSON.stringify(err));
            throw err;
        }

    }

}

export interface MatchesI {
    get active(): AppMatch[];
    get parent(): AppMatch[];
    getMatch(matchId: string): AppMatch;
    get(): Promise<void>;
    getMyMemberMatches(player: MatchPlayerI): AppMatch[];
    getOtherMemberMatches(player: MatchPlayerI): AppMatch[];
    getPlayerMatches(player: MatchPlayerI): AppMatch[];
    addMatch(match: AppMatch, isMatch: boolean): boolean;
    createTeams(currentCourseHoleIndex: number);
    refresh();
    removePlayer(id: string);
    save(batch: firebase.firestore.WriteBatch): Promise<void>;
}

export interface ActivePlayersI { //some of these getters I would make optional but angular doesn't allow that.
    get all(): MatchPlayerI[];
    get withTeeTime(): MatchPlayerI[];
    get groupMembersOnly(): MatchPlayerI[];
    get teeTime(): MatchPlayerI[];
    get teeTimeScoringMode(): ScoringMode;
    get scoringMode(): ScoringMode;
    get leaderboard(): MatchPlayerI[];
    get leaderboardNet(): MatchPlayerI[];
    calcLeaderboardPosition?();
    calcLeaderboardNetPosition?();
    get members(): AppMember[];
    republishHoleScores?();
}

export interface PlayersI { //some of these getters I would make optional but angular doesn't allow that.
    add?(player: MatchPlayerI);
    addPlayer?(firstName: string, lastName: string, email: string): Promise<void>;
    addFromContacts?(): Promise<void>;
    addJustName?(): Promise<void>;
    active: ActivePlayersI;
    get groupMembersOnly(): MatchPlayerI[];
    is?(member: AppMember): boolean;
    getPlayer?(playerId: string): MatchPlayerI;
    getPlayerByMemberId?(memberId: string): MatchPlayerI;
    get dropped(): MatchPlayerI[];
    get memberPlayer(): MatchPlayerI;
    get?(): Promise<void>;
    remove?(player: MatchPlayerI): Promise<boolean>;
    playerInfoConfirmed(players: MatchPlayerI[]): ScoringMode;
    join?(memberId: string): Promise<void>;
}

export interface MatchEventsI {
    all: AppEvent[];
    in(event: AppEvent): boolean;
    get?(group: AppGroupI): Promise<void>;
    reset?();
}

export interface AppMatchParentI {
    id: string;
    matches: MatchesI;
    players: PlayersI;
    numberOfHoles?: number;
    matchIds: string[];
    events: MatchEventsI;
}

export interface AppMatchI {
    parentId: string; //event or a trip id
    teamSize: TeamSize;
    game: GameType;
    grossNetType: NetGrossType;
    scoringType: ScoringType;
    notes: string;
    handicapType: HandicapType;
    subMatches: AppSubMatchI[];
    playerIds: string[];
    organizerMemberId: string;
    handicapAllowance: number;
    pressOption: PressOptions;
    pressStokesDown: number;
    stablefordPoints: StablefordPointsI;
    createdDt: firebase.firestore.Timestamp;
    updatedDt: firebase.firestore.Timestamp;
}

export class AppMatch implements AppMatchI {

    id: string;
    parentId: string;
    parent: AppMatchParentI;
    subMatches: AppSubMatch[];
    exists: boolean = true;
    organizerMemberId: string;
    createdDt: firebase.firestore.Timestamp = firebase.firestore.Timestamp.fromDate(new Date());
    updatedDt: firebase.firestore.Timestamp;
    private _memberMatchplayers: MatchPlayerI[] = [];
    private _game: GameType = undefined;
    private _stablefordPoints: StablefordPointsI = undefined;
    private _teamSize: TeamSize = undefined;
    private _scoringType: ScoringType = undefined;
    private _scoreType: NetGrossType = NetGrossType.Net;
    private _handicapType: HandicapType = HandicapType.Full;
    private _notes: string;
    private _matchDoc: firebase.firestore.DocumentSnapshot;
    private _appFunction: AppFunction;
    private _dirty: boolean = false;
    private _memberMatchplayerIds: string[];
    private _handicapAllowance: number = HandicapAllowance.Percent100;
    private _matchOptions = AppConfig.EVENT_MATCH_CONFIGURATION;
    private _handicapAllowanceOptions = AppConfig.HANDICAP_ALLOWANCE.values;
    private _handicapTypeOptions = AppConfig.HANDICAP_TYPE.values;
    private _netGrossTypeOptions = AppConfig.NETGROSS_TYPE.values;
    private _accountService: AccountService;
    private _pressOption: PressOptions = PressOptions.Off;
    private _pressStokesDown: number = 2;
    private _deleteTeams: boolean = false; //if set to true existing match teams will be deleted and new teams will be recreated 

    constructor() {

        //get services
        this._appFunction = AppFunction.serviceLocator.get(AppFunction);
        this._accountService = AppFunction.serviceLocator.get(AccountService);

        //clean up on app logout
        this._appFunction
            .shutDown
            .subscribe(() => {
                //console.log('app.match.ts AppMatch shutdown', this.id);
            });

    }

    async initialize(parent: AppMatchParentI, matchDoc: firebase.firestore.DocumentSnapshot = undefined): Promise<AppMatch> {

        //set parent
        this.parent = parent;
        this.parentId = this.parent.id;

        //if match already exists...
        if (matchDoc) {
            //set match doc
            this._matchDoc = matchDoc;
        } else { //else match is new...

            //get match doc
            this._matchDoc = await this._appFunction.firestore.collection(AppConfig.COLLECTION.Matches).doc().get();

            //set to dirty so that this player saves
            this._dirty = true;
        }

        //get match id
        this.id = this._matchDoc.id;

        return this;

    }

    async update(updatedMatch: firebase.firestore.DocumentSnapshot, type: FirestoreUpdateType): Promise<void> {

        try {

            await this._appFunction
                .ngZone
                .run(async () => {

                    if ([FirestoreUpdateType.Added, FirestoreUpdateType.Modified].includes(type)) {

                        this._game = updatedMatch.data().game;
                        this._scoreType = updatedMatch.data().grossNetType || NetGrossType.Net;
                        this._scoringType = updatedMatch.data().scoringType;
                        this._teamSize = updatedMatch.data().teamSize;
                        this._notes = updatedMatch.data().notes;
                        this._handicapType = updatedMatch.data().handicapType || HandicapType.Full;
                        this.createdDt = updatedMatch.data().createdDt;
                        this.updatedDt = updatedMatch.data().updatedDt;
                        this._handicapAllowance = updatedMatch.data().handicapAllowance || HandicapAllowance.Percent100;
                        this._pressOption = updatedMatch.data().pressOption || PressOptions.Off;
                        this._pressStokesDown = updatedMatch.data().pressStokesDown || 2;
                        this.organizerMemberId = updatedMatch.data().organizerMemberId;
                        this._stablefordPoints = updatedMatch.data().stablefordPoints || this.stablefordPoints;
                        this.exists = true;
                        this._dirty = false;

                        this.getMemberMatchPlayers(updatedMatch.data().playerIds);
                        await this.getSubMatches(updatedMatch.data().subMatches);

                        //if the match config is being updated then recalc the submatches
                        //iterate through match events, match parent could be event or trip
                        if (type === FirestoreUpdateType.Modified) {
                            //iterate through match events, match parent could be event or trip
                            this.parent.events.all
                                .forEach((event) => {
                                    //republish scores
                                    event.players.active.republishHoleScores();
                                });
                        }

                    } else {
                        this.exists = false;
                    }

                });

        } catch (err) {
            console.log('app.match.ts AppMatch update error', err, JSON.stringify(err));
            throw err;
        }

    }

    //#region properties

    get game(): GameType {
        return this._game;
    }

    set game(game: GameType) {

        //only update if changed 
        if (this._game !== game) {
            this._game = game;
            this._deleteTeams = true;
            this._dirty = true;
        }

    }

    get teamSize(): TeamSize {
        return this._teamSize;
    }

    set teamSize(teamSize: TeamSize) {

        if (this._teamSize !== teamSize) {
            this._teamSize = teamSize;
            this._deleteTeams = true;
            this._dirty = true;
        }

    }

    get scoringType(): ScoringType {
        return this._scoringType;
    }

    set scoringType(scoringType: ScoringType) {

        if (this._scoringType !== scoringType) {
            this._scoringType = scoringType;
            this._dirty = true;
        }

    }

    get grossNetType(): NetGrossType {
        return this._scoreType;
    }

    set grossNetType(grossNetType: NetGrossType) {

        if (this._scoreType !== grossNetType) {
            this._scoreType = grossNetType;
            this._dirty = true;
        }

    }

    get handicapType(): HandicapType {
        return this._handicapType;
    }

    set handicapType(handicapType: HandicapType) {

        if (this._handicapType !== handicapType) {
            this._handicapType = handicapType;
            this._dirty = true;
        }

    }

    get handicapAllowance(): HandicapAllowance {
        return this._handicapAllowance;
    }

    set handicapAllowance(handicapAllowance: HandicapAllowance) {

        if (this._handicapAllowance !== handicapAllowance) {
            this._handicapAllowance = handicapAllowance;
            this._dirty = true;
        }

    }

    get notes(): string {
        return this._notes;
    }

    set notes(notes: string) {

        if (this._notes !== notes) {
            this._notes = notes;
            this._dirty = true;
        }

    }

    get playerIds(): string[] {
        return this._memberMatchplayerIds;
    }

    get pressOption(): PressOptions {
        return this._pressOption;
    }

    set pressOption(pressOption: PressOptions) {

        if (this._pressOption !== pressOption) {
            this._pressOption = pressOption;
            this._dirty = true;
        }

    }

    get pressStokesDown(): number {
        return this._pressStokesDown;
    }

    set pressStokesDown(pressStokesDown: number) {

        if (this._pressStokesDown !== pressStokesDown) {
            this._pressStokesDown = pressStokesDown;
            this._dirty = true;
        }

    }

    get stablefordPoints(): StablefordPointsI {
        return this._stablefordPoints || StablefordPointsDefault;
    }

    set stablefordPoints(stablefordPoints: StablefordPointsI) {

        if (this._stablefordPoints !== stablefordPoints) {
            this._stablefordPoints = stablefordPoints;
            this._dirty = true;
        }

    }

    //#endregion properties

    private getSubMatches(subMatches: AppSubMatchI[]): Promise<void> {

        /* if (!Array.isArray(this.subMatches)) {

            //initialize nines array
            this.subMatches = [];

            //create subMatch for each subMatch passed in (need to use a for statement so that we can await the promise)
            for (const foundSubMatch of subMatches) {
                //create subMatch
                const subMatch: AppSubMatch = new AppSubMatch();
                await subMatch.initialize(this, foundSubMatch);
                this.subMatches.push(subMatch);
            }

        }

        return; */

        return new Promise<void>((resolve) => {

            if (!Array.isArray(this.subMatches)) {

                //create the array for promises
                const promiseArray: any[] = [];

                //initialize nines array
                this.subMatches = [];

                //create subMatch for each subMatch passed in
                subMatches.forEach((foundSubMatch) => {

                    //create subMatch...
                    const subMatch: AppSubMatch = new AppSubMatch();

                    //...then init
                    const p = subMatch.initialize(this, foundSubMatch);
                    promiseArray.push(p);

                    //...then push on subMatches 
                    this.subMatches.push(subMatch);

                });

                Promise
                    .all(promiseArray)
                    .then(() => {
                        resolve();
                    });

            }

        });

    }

    createTeam(players: MatchPlayerI[], parent: AppMatchParentI, currentCourseHoleIndex: number = 0): Promise<void> {

        return new Promise<void>(async (resolve) => {

            const promiseArray: any[] = [];

            //iterate through sub-matches and create teams, using a for loop so that we can await the promise
            for (const subMatch of this.subMatches) {

                //if parent is trip type OR
                //parent is event type AND current match hole index is greater or equal to the first hole of the current submatch matches holes
                //getting the match hole index and the matches indexes makes everything relative regardless of the starting hole
                const currentMatchHoleIndex = subMatch.getMatchHoleIndex(<AppEvent>parent, currentCourseHoleIndex);
                const matchHoleIndexes = subMatch.getMatchHoleIndexes(<AppEvent>parent);
                const shouldCreateTeam: boolean = parent instanceof AppGroupTrip || currentMatchHoleIndex >= matchHoleIndexes[0];
                if (shouldCreateTeam) {

                    //first get a list of teeTimePlayers that aren't part of a team yet...
                    const playersWithoutTeam: MatchPlayerI[] = players.filter((player) => {

                        //...look for teeTimePlayer in each team of the current sub match
                        const foundTeam: AppTeam = subMatch
                            .teams
                            .find((team) => {

                                //...look through each player in the team
                                return team
                                    .players
                                    .all
                                    .some((teamPlayer) => {
                                        return teamPlayer.scoringPlayerId === player.scoringPlayerId;
                                    });

                            });

                        //...if team not found then that means player has been added to a team...return teeTimePlayer
                        if (!foundTeam) {
                            return player;
                        }

                    });

                    //if teamless players are found then add to the appropriate teamn
                    if (playersWithoutTeam.length > 0) {

                        //if the match is a Nassau game then we want to use the same teams for each subMatch
                        let reuseTeams: AppTeam[];
                        if (subMatch.match.game === GameType.Nassau) {

                            //iterate through the subMatches looking existing teams
                            this.subMatches
                                .forEach((subMatch) => {
                                    //if an existing teams is found...
                                    if (Array.isArray(subMatch.teams) && subMatch.teams.length > 0) {
                                        reuseTeams = subMatch.teams;
                                    }
                                });

                        }

                        //if this submatch should use a another submatch's teams AND
                        //the submatch doesn't have any teams yet (if the subMatch already has teams we can assume we're jusy adding players to the existing subMatch/teams)
                        if (reuseTeams && subMatch.teams.length === 0) {

                            reuseTeams
                                .forEach((team) => {

                                    //create and initialize new team using old team...
                                    const newTeam: AppTeam = new AppTeam();
                                    const p = newTeam
                                        .initialize(subMatch, undefined, team.teamColor)
                                        .then(() => {

                                            //add players from other team into this team
                                            team
                                                .players
                                                .all
                                                .forEach((player) => {
                                                    newTeam.players.add(player);
                                                });

                                        });

                                    //...then add team to subMatch
                                    subMatch.addTeam(newTeam);

                                    promiseArray.push(p);

                                });

                        } else { //create new teams

                            switch (this.teamSize) {

                                case TeamSize.OnePersonTeam: {

                                    //For one person teams: create a team for each player
                                    playersWithoutTeam
                                        .forEach((playerWithoutTeam) => {

                                            //create and initialize...
                                            const newTeam: AppTeam = new AppTeam();
                                            const p = newTeam
                                                .initialize(subMatch)
                                                .then(() => {

                                                    //...then add player to team
                                                    newTeam.players.add(playerWithoutTeam);

                                                    //...then add team to subMatch
                                                    subMatch.addTeam(newTeam);

                                                });

                                            promiseArray.push(p);

                                        });

                                    break;

                                }

                                case TeamSize.TwoPersonTeam: {

                                    //if already prompted to create team then skip
                                    /* if (!subMatch.teamCreatePrompted) { */

                                    const twoPersonTeam = await new Promise<void>(async (resolve) => {

                                        //for two person teams: prompt user to specify the two person team
                                        await this._appFunction
                                            .modalCtrlCreate({
                                                component: TeamPopoverPage,
                                                backdropDismiss: false,
                                                presentingElement: await this._appFunction.routerOutlet(),
                                                cssClass: 'custom-modal', //for md
                                                componentProps: {
                                                    players: players,
                                                    playersWithoutTeam: playersWithoutTeam,
                                                    subMatch: subMatch
                                                }
                                            })
                                            .then((popover) => {

                                                console.log('app.match.ts createTeam two person team modal create success');

                                                //display modal to create two man teams
                                                popover
                                                    .present()
                                                    .catch((err) => {
                                                        console.log('app.match.ts createTeam two person team modal present error', err);
                                                    });

                                                //return once modal dismisses
                                                popover
                                                    .onDidDismiss()
                                                    .then(() => {
                                                        resolve();
                                                    });

                                            })
                                            .catch((err) => {
                                                console.log('app.match.ts createTeam two person team modal create error', err);
                                            });

                                    });

                                    promiseArray.push(twoPersonTeam);

                                    /* } */

                                    break;

                                }

                                case TeamSize.ThreePersonTeam: {

                                    const threePersonTeam = await new Promise<void>(async (resolve) => {

                                        //for two person teams: prompt user to specify the two person team
                                        await this._appFunction
                                            .modalCtrlCreate({
                                                component: TeamPopoverPage,
                                                backdropDismiss: false,
                                                presentingElement: await this._appFunction.routerOutlet(),
                                                cssClass: 'custom-modal', //for md
                                                componentProps: {
                                                    players: players,
                                                    playersWithoutTeam: playersWithoutTeam,
                                                    subMatch: subMatch
                                                }
                                            })
                                            .then((popover) => {

                                                //display modal to create two man teams
                                                popover
                                                    .present()
                                                    .catch((err) => {
                                                        console.log('app.match.ts createTeam two person team modal present error', err);
                                                    });

                                                //return once modal dismisses
                                                popover
                                                    .onDidDismiss()
                                                    .then(() => {
                                                        resolve();
                                                    });

                                            })
                                            .catch((err) => {
                                                console.log('app.match.ts createTeam two person team modal create error', err);
                                            });

                                    });

                                    promiseArray.push(threePersonTeam);

                                    break;

                                }

                                case TeamSize.FourPersonTeam: {

                                    //For four person teams: add player to appropriate team
                                    const fourPersonTeam = new Promise<AppTeam>((resolve) => {

                                        try {
                                            //see if there's a team already created for this tee time (just use player[0] to find existing team)...
                                            const existingTeam: AppTeam = subMatch
                                                .teams
                                                .find((team) => {

                                                    //find player in team...
                                                    const teamExists: boolean = team.players.all.some((player) => { return player.id === players[0].id; })

                                                    //...and if found return with the team ref
                                                    if (teamExists) {
                                                        return true;
                                                    }
                                                });

                                            //return found team
                                            if (existingTeam) {
                                                resolve(existingTeam);
                                            } else {

                                                //else create and initialize new team
                                                const newTeam: AppTeam = new AppTeam();
                                                newTeam
                                                    .initialize(subMatch)
                                                    .then(() => {
                                                        //...then add team to subMatch
                                                        subMatch.addTeam(newTeam);
                                                        resolve(newTeam);
                                                    });

                                            }

                                        } catch (err) {
                                            console.log('app.match.ts AppMatch createTeam FourPersonTeam error', err);
                                            throw err;
                                        }

                                    });

                                    promiseArray.push(fourPersonTeam);

                                    //once we have a team...
                                    fourPersonTeam
                                        .then((team) => {

                                            //...add all players to returned team
                                            playersWithoutTeam
                                                .forEach((playerWithoutTeam) => {
                                                    team.players.add(playerWithoutTeam);
                                                });

                                        });

                                    break;

                                }

                            }

                        }

                    }

                }

            }

            //if promise array is empty then resolve
            if (promiseArray.length === 0) {
                resolve();
            } else {

                //then save match (which will save teams)
                Promise
                    .all(promiseArray)
                    .then(() => {

                        //mark dirty so that it saves
                        this.markAsDirty();

                        //save
                        const batch: firebase.firestore.WriteBatch = this._appFunction.firestore.batch();
                        this.save(batch)
                            .then(() => {
                                //commit saves
                                batch.commit()
                                    .then(() => {
                                        resolve();
                                    });
                            });

                    });

            }

        });

    }

    deleteTeams(batch: firebase.firestore.WriteBatch) {

        //if recreate teams flag set then delete all teams
        if (this._deleteTeams) {

            this.subMatches.forEach(async (subMatch) => {
                subMatch.deleteTeams(batch);
            });

            //reset flag
            this._deleteTeams = false;

        }

    }

    removePlayerFromMemberMatch(memberId) {
        this._memberMatchplayers = this._memberMatchplayers.filter((memberMatchPlayer) => {
            return memberMatchPlayer.id !== memberId;
        });
    };

    get status(): ScoringMode {

        //as long as one sub match is active then scoring is active
        const scoringActive: boolean = this.subMatches?.some((subMatch) => {
            return subMatch.scoringMode === ScoringMode.ScoringActive;
        });

        if (scoringActive) {
            return ScoringMode.ScoringActive;
        }

        //if every sub match is complete then scoring is complete
        const scoringCompleted: boolean = this.subMatches?.every((subMatch) => {
            return subMatch.scoringMode === ScoringMode.ScoringComplete;
        });

        if (scoringCompleted) {
            return ScoringMode.ScoringComplete;
        } else {
            return ScoringMode.ScoringNotStarted;
        }

    }

    get players(): MatchPlayerI[] {
        return this.isMemberMatch ? this._memberMatchplayers : this.parent.players.active.all;
    }

    strokeAdjustment(): number {

        //return a 0 adjustment if using handicap full type...
        if (this.handicapType === HandicapType.Full) {
            return 0;
        } else { //low ball

            //...else get all handicaps
            const teeHandicaps: number[] = [];

            //stroke adjustment just be based on individual event. We might need to pass in the event to this function.  
            this.players
                .forEach((player) => {
                    if (player.teeHandicap !== undefined) {
                        //we need the handicap allowance adjusted handicap
                        const teeHandicap: number = player.teeHandicapAdjusted(this);
                        teeHandicaps.push(teeHandicap);
                    }
                });

            //and return the lowest handicap
            return Math.min(...teeHandicaps);

        }

    }

    calcStrokesForHole(playerHole: AppEventPlayerHole, playerHoleNumber: number): number {

        //TODO: isn't playerHoleNumber already in the playerHole object?  If so, then we don't need to pass it in as a parameter

        //adjust player handicap based on full handicap or low ball AND handicap allowance
        const adjustedPlayerTeeHandicap: number = playerHole.player.teeHandicapAdjusted(this, this.strokeAdjustment());

        //for plus handicaps
        if (playerHole.player.teeHandicap < 0) {

            const quotient: number = Math.ceil(adjustedPlayerTeeHandicap / playerHole.event.numberOfHoles);
            //const remainder: number = Math.abs(playerHole.player.teeHandicap % playerHole.event.numberOfHoles);
            const remainder: number = Math.abs(adjustedPlayerTeeHandicap % playerHole.event.numberOfHoles);

            //sort holes by hdcp from easiest to hardest
            const holes: AppEventPlayerHole[] = playerHole.player.holes();
            holes.sortBy('hdcp', SortByOrder.DESC);

            //if given hole is in the first x (remainder) of the flattened array then award stroke
            const getsStroke: boolean = holes.some((hole, holeIndex) => {
                return holeIndex < remainder && hole.number === playerHoleNumber;
            });

            //return strokes based on hole, this should be a negative number (should this be using the player tees and not the event tees????)
            return -(quotient + (getsStroke ? 1 : 0));

        } else { //for non-plus handicaps

            const quotient: number = Math.floor(adjustedPlayerTeeHandicap / playerHole.event.numberOfHoles);
            const remainder: number = adjustedPlayerTeeHandicap % playerHole.event.numberOfHoles;

            //sort holes by hdcp from hardest to easiest 
            const holes: AppEventPlayerHole[] = playerHole.player.holes();
            holes.sortBy('hdcp', SortByOrder.ASC);

            //if given hole is in the first x (remainder) of the flattened array then award stroke
            const getsStroke: boolean = holes.some((hole, holeIndex) => {
                return holeIndex < remainder && hole.number === playerHoleNumber;
            });

            //return strokes based on hole, this should be a positive number (should this be using the player tees and not the event tees????)
            return quotient + (getsStroke ? 1 : 0);

        }

    }

    //#region match properties

    getGrossNetValueType(grossNetValue: number): any {
        return this._netGrossTypeOptions.find((grossNet) => {
            return grossNet.value === grossNetValue;
        });
    }

    getHandicapAllowance(value: number): any {
        return this._handicapAllowanceOptions.find((handicapAllowance) => {
            return handicapAllowance.value === value;
        });
    }

    getHandicapType(handicapValue: number): any {
        return this._handicapTypeOptions.find((handicap) => {
            return handicap.value === handicapValue;
        });
    }

    getGame(gameValue: number): any {
        return this._matchOptions
            .game
            .find((game) => {
                return game.value === gameValue;
            });
    }

    getAllowPress(gameValue: number): boolean {
        return this.getGame(gameValue)?.allowPress;
    }

    getTeamSize(gameValue: number, teamSizeValue: number): any {
        return this.getGame(gameValue)?.teamSize
            .find((teamSize) => {
                return teamSize.value === teamSizeValue;
            });
    }

    getTeamScoring(gameValue: number, teamSizeValue: number, teamScoringValue: number): any {
        return this.getTeamSize(gameValue, teamSizeValue)?.scoringType
            .find((scoringType) => {
                return scoringType.value === teamScoringValue;
            });
    }

    getPressOption(): string {
        if (this.pressOption === PressOptions.Off) {
            return 'Presses are off';
        } else if (this.pressOption === PressOptions.Prompt) {
            return 'Prompt for press after ' + this.pressStokesDown.toString() + ' down';
        } else if (this.pressOption === PressOptions.Auto) {
            return 'Auto press after ' + this.pressStokesDown.toString() + ' down';
        }
    }

    config(): string {
        return this.getHandicapAllowance(this.handicapAllowance).description + ', ' +
            this.getGrossNetValueType(this.grossNetType).description +
            (this.grossNetType === NetGrossType.Net ? ', ' + this.getHandicapType(this.handicapType).name : '');
    }

    //#endregion match properties

    isPlayerInMemberMatch(playerId: string): boolean {
        return this._memberMatchplayers.some((memberMatchPlayer) => {
            return memberMatchPlayer.id === playerId;
        });
    }

    isPlayerInMatch(playerId: string): boolean {
        return this.players.some((player) => {
            return player.id === playerId;
        });
    }

    addMemberMatchPlayer(player: MatchPlayerI) {
        this._memberMatchplayers.push(player);
        this._dirty = true;
    }

    markAsDirty() {
        this._dirty = true;
    }

    markAsNotDirty() {
        this._dirty = false;
    }

    removeMemberMatchPlayer(index: number) {

        //remove from match players array
        const removedPlayer: MatchPlayerI = this._memberMatchplayers.splice(index, 1)[0];

        //mark dirty so the removed play saves
        this.markAsDirty()

        //if there are submatches then remove player from submatch teams
        if (this.subMatches) {

            //now remove from any subMatch teams
            this.subMatches
                .forEach((subMatch) => {

                    //look for player in each submatch team
                    subMatch.teams
                        .forEach((team) => {

                            //look for player in team
                            team.players.all
                                .forEach((player, index, players) => {

                                    //if found then...
                                    if (player.id === removedPlayer.id) {

                                        //remove player
                                        players.splice(index, 1);

                                        //mark the team as dirty so that it saves
                                        team.markAsDirty();

                                    }

                                });

                        });

                });

            //save match
            const batch: firebase.firestore.WriteBatch = this._appFunction.firestore.batch();
            this.save(batch)
                .then(() => {
                    batch.commit();
                });

        }

    }

    private getMemberMatchPlayers(playerIds: string[]) {

        //if there are players then...
        if (Array.isArray(playerIds)) {

            //get players ref
            playerIds
                .forEach((playerId) => {

                    //see if player is already in match
                    const playerAlreadyInMatch: boolean = this._memberMatchplayers.some((player) => {
                        return player.id === playerId;
                    });

                    //if not...
                    if (!playerAlreadyInMatch) {

                        //...then find player from the list of event players
                        const foundPlayer: MatchPlayerI = this.parent
                            .players
                            .active
                            .all
                            .find((player) => {
                                return player.id === playerId;
                            });

                        //...then push player on match players
                        if (foundPlayer) {
                            this._memberMatchplayers.push(foundPlayer);
                        }
                    }

                });

        }

    }

    get isMemberMatch(): boolean {
        //return true if this is a member match (vs an event match)
        return !this.parent.matchIds.includes(this.id);
    }

    get validationErrors(): string[] {

        const errors: string[] = [];

        //check to see if the selected game has validations
        const validations = AppConfig.EVENT_MATCH_CONFIGURATION.game[this.game]?.validations;
        if (validations) {

            //validations
            validations
                .forEach((validation) => {

                    if (validation.sameTeeTime) {

                        //get unique tee times for given players 
                        const uniquePlayerTeeTimes: MatchPlayerI[] = this.players.groupBy('teeTime');

                        //determine if they are in the same tee time
                        if (uniquePlayerTeeTimes.length !== 1) {
                            errors.push(validation.sameTeeTime.validationMessage);
                        }

                    }

                    if (validation.teams) {

                        /* //get correct list of players 
                        const players: MatchPlayerI[] = this.isMemberMatch ? this.players : this.event.players.active.all;
    
                        //get unique tee times of the match players 
                        const uniquePlayerTeeTimes: MatchPlayerI[] = players.groupBy('teeTime');
    
                        //determine if they are in the same tee time
                        if (uniquePlayerTeeTimes.length !== 1) {
                            console.log(rule.sameTeeTime.validationMessage, uniquePlayerTeeTimes)
                            errors.push(rule.sameTeeTime.validationMessage);
                        } */

                    }

                    if (validation.players) {

                        //if this match is the event match then get the number of event players
                        const numberOfPlayers: number = this.players.length;

                        if (validation.players.expression === '=') {

                            //determine if there the same number of players as defined in the rule
                            if (numberOfPlayers !== validation.players.number) {
                                errors.push(validation.players.validationMessage);
                            }

                        } else if (validation.players.expression === '>=') {

                            //determine if there the same number of players as defined in the rule
                            if (numberOfPlayers < validation.players.number) {
                                errors.push(validation.players.validationMessage);
                            }

                        }

                    }

                    if (validation.holes) {

                        if (validation.holes.expression === '=') {

                            //determine if there the same number of players as defined in the rule
                            if ((this.parent.numberOfHoles || 18) !== validation.holes.number) {
                                errors.push(validation.holes.validationMessage);
                            }

                        }

                    }

                });

        };

        //for matches we need to check for the correct number of players based on the team size
        if (this._appFunction.isNumeric(this.game) && this._appFunction.isNumeric(this.teamSize)) {

            //get the number of players
            const numberOfPlayers: number = this.players.length;

            //get the team size
            const teamSize: number = this.getTeamSize(this.game, this.teamSize).descriptionHeader;

            //if the minimum number of players doesn't match the team size then add an error
            if (numberOfPlayers < (teamSize * 2)) {
                errors.push('For the Team Size selected a minimum of ' + (teamSize * 2).toString() + ' players must be added to the match.');
            }

            //if the number of players isn't in the multiple of the team size then add an error
            if (numberOfPlayers % teamSize !== 0) {
                errors.push('For the Team Size selected players must be added to the match in multiples of ' + (teamSize).toString() + '.');
            }

        }

        return errors;

    }

    stableFordScore(score: number, par: number): number {

        //calc number strokes over/under...
        const toPar: number = score - par;

        //...and return the stableford equivalent score
        switch (toPar) {

            case -3: //3 under, albatroose
                return this.stablefordPoints.albatross; //default 5
                break;

            case -2: //two under, eagle
                return this.stablefordPoints.eagle; //default 4
                break;

            case -1: //one under, birdie
                return this.stablefordPoints.birdie; //default 3
                break;

            case 0: //par
                return this.stablefordPoints.par; //default 2
                break;

            case 1: //one over, bogey
                return this.stablefordPoints.bogey; //default 1
                break;

            default:
                return this.stablefordPoints.doubleBogey; //default 0
                break;

        }

    }

    checkForPress(event: AppEvent) {

        //if press option is on for match
        if (this.pressOption !== PressOptions.Off) {

            //check for press
            this.subMatches
                .forEach((subMatch) => {
                    subMatch.checkForPress(event);
                });

        }

    }

    reset() {

        //iterate through submatches and reset
        this.subMatches
            .forEach((subMatch) => {
                subMatch.reset();
            });

        //republish scores
        this.parent.events.all
            .forEach((event) => {
                event.players.active.republishHoleScores();
            });

    }

    async delete(): Promise<void> {

        await this._matchDoc
            .ref
            .delete()
            .catch((err) => {
                console.log('app.match.ts AppMatch delete doc error', JSON.stringify(err));
            });

        /* return new Promise<void>((resolve, reject) => {

            try {

                this._matchDoc
                    .ref
                    .delete()
                    .then(() => {
                        resolve();
                    })
                    .catch((err) => {
                        console.log('app.match.ts AppMatch delete doc error', JSON.stringify(err));
                        reject(err);
                    });

            } catch (err) {
                console.log('app.match.ts AppMatch delete error', JSON.stringify(err));
                reject(err);
            }

        }); */

    }

    async save(batch: firebase.firestore.WriteBatch): Promise<AppMatch> {

        if (this._dirty) {

            //make sure match is added to event or trip
            this.parent.matches.addMatch(this, false);

            //save the match document
            batch.set(this._matchDoc.ref, this.data(), { merge: true });

            //should we delete teams (this is done because the match setup changed and teams need to be created)
            this.deleteTeams(batch);

            //...then save teams
            for (const subMatch of this.subMatches) {
                await subMatch.save(batch);
            }

            //clear dirty flag
            this.markAsNotDirty();

        }

        return this;

    }

    private data(): AppMatchI {

        try {

            //if subMatches hasn't been populated yet then go get it from the matches configuration 
            if (!Array.isArray(this.subMatches)) {
                this.getSubMatches(<AppSubMatchI[]>AppConfig.EVENT_MATCH_CONFIGURATION.game[this.game]?.matches);
            }

            //now build subMatch object array to save
            const subMatches: AppSubMatchI[] = [];
            this.subMatches
                .forEach((subMatch) => {
                    subMatches.push(subMatch.data());
                });

            //get member match player ids
            const memberMatchPlayerIds: string[] = [];
            this._memberMatchplayers
                .forEach((player) => {
                    memberMatchPlayerIds.push(player.id);
                });

            return {
                parentId: this.parentId,
                game: this.game,
                grossNetType: this.grossNetType,
                scoringType: this.scoringType,
                teamSize: this.teamSize,
                notes: this.notes || null,
                handicapType: this.handicapType === undefined ? null : this.handicapType,
                subMatches: subMatches,
                playerIds: memberMatchPlayerIds,
                handicapAllowance: this.handicapAllowance,
                stablefordPoints: this.game === GameType.Stableford ? this._stablefordPoints : null, //only save stableford points if game is stableford
                pressOption: this._pressOption,
                pressStokesDown: this._pressOption === PressOptions.Off ? null : this._pressStokesDown,
                organizerMemberId: this.organizerMemberId || this._accountService.member.id,
                updatedDt: firebase.firestore.Timestamp.fromDate(new Date()),
                createdDt: this.createdDt
            };

        }
        catch (err) {
            console.log('app.match.ts AppMatch data error', err, JSON.stringify(err));
            throw err;
        }

    }

}
