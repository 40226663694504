<ion-header class="ion-no-border">

  <ion-toolbar>

    <ion-title><span *ngIf="editMode===formEditMode.new">New&nbsp;</span>Help Topic</ion-title>

    <ion-buttons slot="secondary" *ngIf="helpForm.dirty">
      <ion-button (click)="cancel()">
        Cancel
      </ion-button>
    </ion-buttons>

    <ion-buttons slot="primary">
      <ion-button *ngIf="helpForm.dirty" fill="solid" shape="round" (click)="done()" haptic>Save</ion-button>
      <ion-button *ngIf="!helpForm.dirty" fill="solid" shape="round" color="light" (click)="done()">Close</ion-button>
    </ion-buttons>

  </ion-toolbar>

  <!-- validation hero -->
  <validation-hero [display]="helpForm.touched && helpForm.invalid"></validation-hero>

</ion-header>

<ion-content class="help-detail-content">

  <!-- segment -->
  <div class="ion-padding">
    <ion-segment [(ngModel)]="helpDetailSegment" mode="ios">
      <ion-segment-button value="info">
        <ion-label>Info</ion-label>
      </ion-segment-button>
      <ion-segment-button value="help">
        <ion-label>Help</ion-label>
      </ion-segment-button>
      <ion-segment-button value="whatsNew">
        <ion-label>What's New</ion-label>
      </ion-segment-button>
    </ion-segment>
  </div>

  <form [formGroup]="helpForm">

    <div [hidden]="helpDetailSegment !== 'info'">

      <ion-list>

        <!-- key -->
        <ion-item [class.invalid]="!helpForm.controls.key.valid && helpForm.controls.key.touched" lines="inset">
          <ion-label position="stacked">Key</ion-label>
          <ion-input formControlName="key"></ion-input>
        </ion-item>
        <ion-item lines="none" class="validation"
          *ngIf="helpForm.controls.key.hasError('required') && !helpForm.controls.key.valid && helpForm.controls.key.touched">
          <ion-label autocapitalize="on">Please enter a key.</ion-label>
        </ion-item>

        <!-- name -->
        <ion-item [class.invalid]="!helpForm.controls.name.valid && helpForm.controls.name.touched" lines="inset">
          <ion-label position="stacked">Name</ion-label>
          <ion-input formControlName="name"></ion-input>
        </ion-item>
        <ion-item lines="none" class="validation"
          *ngIf="helpForm.controls.name.hasError('required') && !helpForm.controls.name.valid && helpForm.controls.name.touched">
          <ion-label autocapitalize="on">Please enter a help name.</ion-label>
        </ion-item>

      </ion-list>

    </div>

    <div [hidden]="helpDetailSegment !== 'help'">

      <ion-list>

        <!-- help title -->
        <ion-item [class.invalid]="!helpForm.controls.helpTitle.valid && helpForm.controls.helpTitle.touched"
          lines="inset">
          <ion-label position="stacked">Title</ion-label>
          <ion-input formControlName="helpTitle"></ion-input>
        </ion-item>
        <ion-item lines="none" class="validation"
          *ngIf="helpForm.controls.helpTitle.hasError('required') && !helpForm.controls.helpTitle.valid && helpForm.controls.helpTitle.touched">
          <ion-label autocapitalize="on">Please enter a help title.</ion-label>
        </ion-item>

        <!-- help subject -->
        <ion-item [class.invalid]="!helpForm.controls.helpSubject.valid && helpForm.controls.helpSubject.touched"
          lines="inset">
          <ion-label position="stacked">Subject</ion-label>
          <ion-textarea formControlName="helpSubject" auto-grow="true" autocomplete="on" autocorrect="on"
            autocapitalize="on">
          </ion-textarea>
        </ion-item>
        <ion-item lines="none" class="validation"
          *ngIf="helpForm.controls.helpSubject.hasError('required') && !helpForm.controls.helpSubject.valid && helpForm.controls.helpSubject.touched">
          <ion-label autocapitalize="on">Please enter a help subject.</ion-label>
        </ion-item>

      </ion-list>

    </div>

    <div [hidden]="helpDetailSegment !== 'whatsNew'">

      <ion-list>

        <!-- what's new vesion -->
        <ion-item
          [class.invalid]="!helpForm.controls.whatsNewVersion.valid && helpForm.controls.whatsNewVersion.touched"
          lines="inset">
          <ion-label position="stacked">Version</ion-label>
          <ion-input formControlName="whatsNewVersion"></ion-input>
          <ion-button slot="end" (click)="showWhatsNew(helpForm.controls.whatsNewSubject.value);">
            <ion-icon slot="icon-only" name="eye"></ion-icon>
          </ion-button>
        </ion-item>
        <ion-item lines="none" class="validation"
          *ngIf="helpForm.controls.whatsNewVersion.hasError('required') && !helpForm.controls.whatsNewVersion.valid && helpForm.controls.whatsNewVersion.touched">
          <ion-label autocapitalize="on">Please enter a version (x.x.x).</ion-label>
        </ion-item>

        <!-- what's new subject -->
        <ion-item
          [class.invalid]="!helpForm.controls.whatsNewSubject.valid && helpForm.controls.whatsNewSubject.touched"
          lines="inset">
          <ion-label position="stacked">Subject</ion-label>
          <ion-textarea formControlName="whatsNewSubject" auto-grow="true">
          </ion-textarea>
        </ion-item>
        <ion-item lines="none" class="validation"
          *ngIf="helpForm.controls.whatsNewSubject.hasError('required') && !helpForm.controls.whatsNewSubject.valid && helpForm.controls.whatsNewSubject.touched">
          <ion-label autocapitalize="on">Please enter a subject.</ion-label>
        </ion-item>

      </ion-list>

    </div>

  </form>

</ion-content>