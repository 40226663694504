import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NavController, NavParams } from '@ionic/angular';
import { AppFunction } from '../../app.function';
import { AppConfig } from '../../app.config';
import { AppTee, AppCourseHoleI, AppCourse, AppCourseNineI } from 'src/app/app.club';
import { CourseHolePage } from '../course-hole/course-hole.page';
import { ColorPickerPopoverPage } from '../color-popover/color-picker-popover.page';

@Component({
  selector: 'course-tee',
  templateUrl: './course-tee.page.html',
  styleUrls: ['./course-tee.page.scss'],
})
export class CourseTeePage implements OnInit {

  courseTeeSegment: string = 'information';
  formEditMode: typeof AppConfig.EDIT_MODE = AppConfig.EDIT_MODE;
  editMode: any;
  tee: AppTee;
  teeForm: FormGroup;
  course: AppCourse;

  constructor(
    public builder: FormBuilder,
    public navParams: NavParams,
    public appFunction: AppFunction,
    public appConfig: AppConfig,
    public navCtrl: NavController
  ) {

    //console.log('course-tee.page.ts constructor');

  }

  ngOnInit() {

    //console.log('course-tee.page.ts ngOnInit');

    //build form
    this.teeForm = this.builder.group({
      teeId: ['', Validators.required],
      name: ['', Validators.required],
      distance: ['', Validators.required],
      slope: ['', Validators.required],
      rating: ['', Validators.required],
      par: ['', Validators.required]
    });

    //get course
    this.course = <AppCourse>this.navParams.get('course');

    //get passed in edit mode
    this.editMode = this.navParams.get('editMode');
    if (this.editMode === AppConfig.EDIT_MODE.new) {

      try {

        //create course and initialize
        this.tee = new AppTee();
        this.tee
          .initialize()
          .then(() => {

            //get tee 
            const tee: AppTee = <AppTee>this.navParams.get('tee');
            this.tee.nines = tee.nines;

            //set course and clear tee
            this.tee.courseId = this.course.courseId;
            this.tee.teeId = undefined;

            //default tee id to course id...user needs to add a postfix
            this.teeForm.controls['teeId'].setValue(this.course.courseId + '-');

            //mark drity
            this.teeForm.markAsDirty();

          });

      }
      catch (err) {
        console.log('course-tee.page.ts ngOnInit new tee error', err);
      }

    } else { //update

      //get tee 
      this.tee = <AppTee>this.navParams.get('tee');

      this.teeForm.controls['teeId'].setValue(this.tee.teeId);
      this.teeForm.controls['name'].setValue(this.tee.name);
      this.teeForm.controls['distance'].setValue(this.tee.distance);
      this.teeForm.controls['par'].setValue(this.tee.par);
      this.teeForm.controls['rating'].setValue(this.tee.rating);
      this.teeForm.controls['slope'].setValue(this.tee.slope);

    }

  }

  nameRatingSlopeChange() {
    //console.log('course-tee.page.ts nameRatingSlopeChange');
    this.teeForm.markAsDirty();
  }

  editHole(hole: AppCourseHoleI) {

    this.appFunction
      .modalCtrlCreate({
        component: CourseHolePage,
        componentProps: {
          tee: this.tee,
          number: hole.number
        },
        showBackdrop: false,
        backdropDismiss: true,
        cssClass: 'custom-modal-auto-height'
      })
      .then((modal) => {

        //if hole editor is opened then just mark the form as dirty
        this.teeForm.markAsDirty();

        modal
          .present()
          .catch((err) => {
            console.log('course-tee.page.ts editHole modal present error', err);
          });

      })
      .catch((err) => {
        console.log('course-tee.page.ts editHole modal create error', err);
      });

  }

  colorPicker(clickEvent: Event, teeColor: string) {

    this.appFunction
      .popoverCtrl
      .create({
        component: ColorPickerPopoverPage,
        event: clickEvent,
        cssClass: 'color-picker-popover',
        componentProps: {
          color: this.tee[teeColor]
        }
      })
      .then((popover) => {

        popover
          .present()
          .catch((err) => {
            console.log('course-tee.page.ts colorPicker modal present error', err);
          });

        popover
          .onDidDismiss()
          .then((result) => {
            const x = result['data'];
            this.tee[teeColor] = x;
            this.teeForm.markAsDirty();
          });
      })
      .catch((err) => {
        console.log('course-tee.page.ts colorPicker modal create error', err);
      });

  }

  clearColor(clickEvent: Event, teeColor: string) {

    //prevent the list item click
    clickEvent.stopPropagation();

    //clear color
    this.tee[teeColor] = null;

    //mark form dirty 
    this.teeForm.markAsDirty();

  }

  showAllTeeIds() {

    let teeIds: string = '';
    this.course
      .tees
      .forEach((tee) => {
        teeIds = teeIds + tee.teeId + '\n';
      });

  }

  done() {

    //console.log('course-tee.page.ts done');

    //save the form if dirty
    if (this.teeForm.dirty) {

      //console.log('course-tee.page.ts done dirty');

      //save the form if valid 
      if (this.teeForm.valid) {

        //console.log('course-tee.page.ts done valid');

        this.appFunction
          .loadingCtrl
          .create({ message: 'Saving tee...' })
          .then((loading) => {

            loading.present();

            //get form data
            this.tee.teeId = this.teeForm.controls.teeId.value;
            this.tee.name = this.teeForm.controls.name.value;
            this.tee.distance = this.teeForm.controls.distance.value;
            this.tee.par = this.teeForm.controls.par.value;
            this.tee.slope = this.teeForm.controls.slope.value;
            this.tee.rating = this.teeForm.controls.rating.value;
            this.tee
              .save()
              .then(() => {

                loading.dismiss();

                if (this.editMode === AppConfig.EDIT_MODE.new) {
                  this.appFunction.modalCtrl.dismiss({ tee: this.tee }, 'new');
                } else {
                  this.appFunction.modalCtrl.dismiss({ tee: this.tee }, 'update');
                }

              });

          });

      } else {
        //show any untouched errors
        this.appFunction.setDirtyControlAsTouched(this.teeForm);
      }

    } else {
      this.appFunction.modalCtrl.dismiss(undefined, 'back');
    }

  }

  back() {

    //confirm that user wants to discard changes
    if (this.teeForm.dirty) {

      this.appFunction
        .alertCtrl
        .create({
          header: 'Discard changes?',
          message: 'You have made changes to this tee. Do you want to discard these changes?',
          buttons: [
            {
              text: 'No',
              handler: () => {
              }
            },
            {
              text: 'Yes, discard',
              handler: () => {
                this.appFunction.modalCtrl.dismiss(undefined, 'back');
              }
            }
          ]
        })
        .then((alert) => {
          alert.present();
        });

    } else {
      this.appFunction.modalCtrl.dismiss(undefined, 'back');
    }

  }

}
