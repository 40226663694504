import { Component, OnInit } from '@angular/core';
import { AppFunction } from '../../app.function';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AccountService, AppMember } from '../../app.account';
import { NavController, NavParams } from '@ionic/angular';
import { SocialService } from 'src/app/app.social';
import { AppGroupI, GroupType } from 'src/app/app.group';
import { GroupEventDetailPage } from '../group-event-detail/group-event-detail.page';
import { AppConfig } from 'src/app/app.config';
import { ComponentRef } from '@ionic/core';
import { GroupTripDetailPage } from '../group-trip-detail/group-trip-detail.page';

@Component({
  selector: 'app-member-detail',
  templateUrl: './member-detail.page.html',
  styleUrls: ['./member-detail.page.scss'],
})
export class MemberDetailPage implements OnInit {

  member: AppMember;
  memberForm: FormGroup;
  isFormSubmitted = false;

  constructor(
    public appFunction: AppFunction,
    public builder: FormBuilder,
    public navParams: NavParams,
    public accountService: AccountService,
    public socialService: SocialService,
    public navCtrl: NavController) {

  }

  ngOnInit() {

    //get member
    this.member = <AppMember>this.navParams.get('member');

    //get groups...
    this.member.groups.getGroups(true);

    //...then build form group
    this.memberForm = this.builder.group({
      email: [this.member.email],
      firstName: [this.member.firstName, Validators.required],
      lastName: [this.member.lastName, Validators.required],
      privateAccount: [this.member.privateAccount],
      admin: [this.member.admin],
      postCount: [this.member.postCount, Validators.required]
    });

  }

  deleteMemberConfirmation(member: AppMember) {

    //confirmation
    this.appFunction
      .actionCtrl
      .create({
        header: 'Please confirm',
        buttons: [
          {
            text: 'Delete member',
            role: 'destructive',
            handler: () => {
              this.appFunction.adminDeleteMember(member);
            }
          },
          {
            text: 'No',
            role: 'cancel'
          }
        ]
      })
      .then((action) => {
        action.present();
      });

  }

  async updateGroup(group: AppGroupI) {

    //if authenicated user is sys admin than allow update
    if (this.accountService.member.admin) {

      //open the appropriate group detail page
      const component: ComponentRef = group.type === GroupType.Event ? GroupEventDetailPage : GroupTripDetailPage;
      this.appFunction
        .modalCtrl
        .create({
          component: component,
          presentingElement: await this.appFunction.routerOutlet(),
          cssClass: 'custom-modal', //for md
          componentProps: {
            group: group,
            editMode: AppConfig.EDIT_MODE.update
          }
        })
        .then((modal) => {
          modal
            .present()
            .catch((err) => {
              console.log('member-detail.page.ts modal present error', err);
            });
        })
        .catch((err) => {
          console.log('member-detail.page.ts modal create error', err);
        });

    }

  }

  impersonateMember(member: AppMember) {

    member
      .initializeLoggedInMemberData()
      .then(() => {

        //close member detail page
        this.appFunction
          .modalCtrl
          .dismiss()
          .then(() => {

            //close parent modal
            this.appFunction
              .modalCtrl
              .dismiss()
              .then(() => {
                //then goto home page
                this.navCtrl.navigateRoot('/main/home');
              });

          });

      });

  }

  done() {

    //signal validation toast that form has been submitted
    this.isFormSubmitted = true;

    //save the form if dirty
    if (this.memberForm.dirty) {

      //save the form if valid 
      if (this.memberForm.valid) {

        this.appFunction
          .loadingCtrl
          .create({ message: 'Saving member...' })
          .then((loading) => {

            loading.present();

            //get form data
            this.member.firstName = this.memberForm.controls.firstName.value;
            this.member.lastName = this.memberForm.controls.lastName.value;
            this.member.privateAccount = this.memberForm.controls.privateAccount.value;
            this.member.admin = this.memberForm.controls.admin.value;
            this.member.postCount = this.memberForm.controls.postCount.value;

            //save/update event
            this.member
              .save()
              .then(() => {

                //close page
                loading.dismiss();
                this.appFunction.modalCtrl.dismiss();

              });

          });

      } else {
        //show any untouched errors
        this.appFunction.setDirtyControlAsTouched(this.memberForm);
      }

    } else {
      this.appFunction.modalCtrl.dismiss();
    }

  }

  cancel() {

    //confirm that user wants to discard changes
    if (this.memberForm.dirty) {

      this.appFunction
        .alertCtrl
        .create({
          header: 'Discard changes?',
          message: 'You have made changes to this member. Do you want to discard these changes?',
          buttons: [
            {
              text: 'No',
              handler: () => {
              }
            },
            {
              text: 'Yes, discard',
              handler: () => {
                this.appFunction.modalCtrl.dismiss();
                this.member = undefined;
              }
            }
          ]
        })
        .then((alert) => {
          alert.present();
        });

    } else {
      this.appFunction.modalCtrl.dismiss();
      this.member = undefined;
    }

  }

}
