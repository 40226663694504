import { Pipe, PipeTransform, SecurityContext } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { Platform } from "@ionic/angular";
import { Capacitor } from '@capacitor/core';

@Pipe({
  name: "sanitizeUrl",
})
export class sanitizeUrl implements PipeTransform {
  constructor(private domSanitizer: DomSanitizer, private platform: Platform) {}

  transform(value: string): any {
    try {
      if (this.platform.is("capacitor"))
        return this.domSanitizer.bypassSecurityTrustUrl(
          Capacitor.convertFileSrc(value)
        );
      else {
        return value;
      }
    } catch (err) {
      console.log("sanitize.pipe.ts sanitizeUrl transform error", err);
    }
  }
}
