import { Component, OnInit } from '@angular/core';
import { NavParams } from '@ionic/angular';
import { AppFunction, HelpService, SortByOrder } from '../../app.function';
import { AppEvent, AppEventPlayer, AppTeeTimeI, EventActionCd } from '../../app.event';
import { AccountService } from '../../app.account'
import * as moment from 'moment';
import { AppConfig } from '../../app.config';
import { Subscription } from 'rxjs';
import { PostNewPage } from '../post-new/post-new.page';
import { NotePage } from '../note/note.page';
import { ContactPage } from '../contact/contact.page';
import { MatchPlayerI } from 'src/app/app.match';

export enum EventViewSegment {
  Information = 'information',
  TeeTimes = 'teetimes',
  TimeLine = 'timeline',
}

@Component({
  selector: 'event-view',
  templateUrl: './event-view.page.html',
  styleUrls: ['./event-view.page.scss']
})
export class EventViewPage implements OnInit {

  eventViewSegment: string = 'information';
  event: AppEvent;
  nestedTeeTimes: AppTeeTimeI[] = [];
  note: string = '';
  waitListTeeTime: Date = AppConfig.WAIT_LIST_TEE_TIME;
  loaded: boolean = false;
  eventSubscription: Subscription;
  private modal: HTMLIonModalElement;
  actionCd: EventActionCd;
  ActionCd: typeof EventActionCd = EventActionCd;
  screenKey: string = AppConfig.PAGE.EventView;
  EventViewSegment: typeof EventViewSegment = EventViewSegment;
  isGroupEvent: boolean = true;

  constructor(
    public accountService: AccountService,
    public appFunction: AppFunction,
    public navParams: NavParams,
    public helpService: HelpService) {

    helpService.screenWhatsNew(this.screenKey);

  }

  ngOnInit() {

    //get modal reference (this is a reference to this screen)(there was a reason I had to do this but not forget)
    this.modal = <HTMLIonModalElement>this.navParams.get('modal');

    //get event
    this.event = <AppEvent>this.navParams.get('event');

    //is this a group or private event
    this.isGroupEvent = this.event.group ? true : false;

    //see if actionCd was passed in, then check id logged in player is already in event
    this.actionCd = this.navParams.get('actionCd');

    //if AskToJoin and player is already in event then change actionCd to Joined (this would happen if via a deeplink)
    if (this.actionCd === EventActionCd.AskToJoin && this.event.players.memberPlayer) {
      this.actionCd = EventActionCd.Joined;
    } else if (!this.event.players.memberPlayer) { //if no actionCd and member not in event then change AskToJoin
      this.actionCd = EventActionCd.AskToJoin;
    }

    //get note
    if (this.event.players.memberPlayer) {
      this.note = (<AppEventPlayer>this.event.players.memberPlayer).note;
    }

    //reformat teeTimes json
    this.restructureTeeTimes(<AppEventPlayer[]>this.event.players.active.groupMembersOnly);

    //subscribe to event updates
    this.eventSubscription = this.event
      .updateEvent
      .subscribe((updatedMemberId: string) => {

        //console.log('event-view.page.ts updateEvent', updatedMemberId);

        //only notify if update came from another user
        if (this.loaded) {

          //reformat teeTimes json
          this.restructureTeeTimes(<AppEventPlayer[]>this.event.players.active.groupMembersOnly);

        }

        //this is a hack but I don't want this event when first loading
        this.loaded = true;

      });

    //clean up before closing
    this.modal
      .onWillDismiss()
      .then(() => {

        //console.log('event-view.page.ts onWillDismiss');

        this.eventSubscription.unsubscribe();

        //save player event data
        if (this.event.players.memberPlayer && this.note !== (<AppEventPlayer>this.event.players.memberPlayer).note) {
          (<AppEventPlayer>this.event.players.memberPlayer).note = this.note;
          (<AppEventPlayer>this.event.players.memberPlayer).save();
        }

      });

  }

  joinEvent() {

    //console.log('event-view.page.ts joinEvent');

    this.appFunction
      .loadingCtrl
      .create({ message: 'Joining event...' })
      .then((loading) => {

        loading.present();

        this.event
          .players
          .join(this.accountService.member.id)
          .then(() => {
            //show congrats message
            this.actionCd = EventActionCd.Joined;
          })
          .catch((err) => {
            console.log('event-view.page.ts joinEvent error', JSON.stringify(err));
          })
          .then(() => {
            loading.dismiss();
          });

      });

  }

  async newPost() {

    this.appFunction
      .modalCtrlCreate({
        component: PostNewPage,
        keyboardClose: false,
        presentingElement: await this.appFunction.routerOutlet(),
        cssClass: 'custom-modal', //for md
        backdropDismiss: false,
        componentProps: {
          poster: this.event.group || this.accountService.member
        }
      })
      .then((modal) => {

        modal
          .present()
          .catch((err) => {
            console.log('event-view.page.ts newPost modal present error', err);
          });

      })
      .catch((err) => {
        console.log('event-view.page.ts newPost modal create error', err);
      });

  }

  private leaveEvent() {

    //leave event
    (<AppEventPlayer>this.event.players.memberPlayer).remove()
      .then((removed) => {

        //if player actually remove then...
        if (removed) {

          //...reformat teeTimes json
          this.restructureTeeTimes(<AppEventPlayer[]>this.event.players.active.groupMembersOnly);

          //show Leave actiobn card
          this.actionCd = EventActionCd.Leave;

        }

      })
      .catch((err) => {
        console.log('event-view.page.ts leaveEvent error', JSON.stringify(err));
      });

  }

  leaveEventConfirm() {

    //confirmation
    this.appFunction
      .actionCtrl
      .create({
        header: 'Please confirm',
        buttons: [
          {
            text: 'Leave Event',
            role: 'destructive',
            handler: () => {
              this.leaveEvent();
            }
          },
          {
            text: 'No',
            role: 'cancel'
          }
        ]
      })
      .then((action) => {
        action.present();
      });

  }

  private restructureTeeTimes(players: AppEventPlayer[]) {

    this.nestedTeeTimes = [];
    let currentTeeTime: Date = undefined;
    let nestedTeeTime: AppTeeTimeI;
    const waitListTeeTime: Date = AppConfig.WAIT_LIST_TEE_TIME;

    //restructure from flat to nested
    players
      .forEach((player) => {

        //if the tee time isn't the same then
        if (!moment(player.teeTime.toDate()).isSame(currentTeeTime)) {

          currentTeeTime = player.teeTime.toDate();

          //create new tee time
          nestedTeeTime = {
            teeTime: player.teeTime.toDate(),
            time: moment(player.teeTime.toDate()).isSame(waitListTeeTime) ? 'Waiting for a tee time' : moment(player.teeTime.toDate()).format('h:mm a').toString(),
            players: [],
            playerCount(): number { return 0; }
          };

          //add new tee time
          this.nestedTeeTimes.push(nestedTeeTime);

        }

        //add player to tee time
        nestedTeeTime.players.push(player);

      });

    //make sure tee times are sorted properly
    this.nestedTeeTimes.sortBy('teeTime', SortByOrder.ASC);

  }

  private removeGuest(player: AppEventPlayer) {
    player.remove();
  }

  removeGuestConfirm(player: AppEventPlayer) {

    //confirmation
    this.appFunction
      .actionCtrl
      .create({
        header: 'Please confirm',
        buttons: [
          {
            text: 'Remove guest',
            role: 'destructive',
            handler: () => {
              this.removeGuest(player);
            }
          },
          {
            text: 'Cancel',
            role: 'cancel',
            handler: () => {
            }
          }
        ]
      })
      .then((action) => {
        action.present();
      });

  }

  addGuestConfirm(parentPlayer: MatchPlayerI) {

    parentPlayer
      .guests
      .addConfirm(this.event);

  }

  async updateGuestName(player: AppEventPlayer) {

    this.appFunction
      .modalCtrlCreate({
        component: ContactPage,
        presentingElement: await this.appFunction.routerOutlet(),
        cssClass: 'custom-modal', //for md
        componentProps: {
          firstName: player.firstName,
          lastName: player.lastName
        }
      })
      .then((modal) => {

        //save contact on page close
        modal
          .onDidDismiss()
          .then((result) => {

            if (result.data) {

              //get new name...
              player.firstName = result.data.contact.firstName;
              player.lastName = result.data.contact.lastName;

              //...then save
              player.save();

            }

          });

        modal
          .present()
          .catch((err) => {
            console.log('event-view.page.ts modal present error', err);
          });

      })
      .catch((err) => {
        console.log('event-view.page.ts modal create error', err);
      });

  }

  guestAction(guestPlayer: AppEventPlayer) {

    const options = [

      {
        text: 'Remove guest',
        role: 'destructive',
        handler: () => {
          this.removeGuestConfirm(guestPlayer);
        }
      },
      {
        text: 'Cancel',
        role: 'cancel',
        handler: () => {
        }
      }
    ];

    //add to the beginning 
    options.unshift({
      text: 'Update name',
      role: undefined,
      handler: () => {
        this.updateGuestName(guestPlayer);
      }
    })

    //confirmation
    this.appFunction
      .actionCtrl
      .create({
        header: 'What would you like to do?',
        buttons: options
      })
      .then((action) => {
        action.present();
      });

  }

  confirmPlayerAction() {

    const options = [

      {
        text: 'Leave Event',
        role: 'destructive',
        handler: () => {
          this.leaveEventConfirm();

          //switch back to information view
          this.eventViewSegment = EventViewSegment.Information;
        }
      },
      {
        text: 'Add guest',
        role: undefined,
        handler: () => {
          this.addGuestConfirm(this.event.players.memberPlayer);
        }
      },
      {
        text: 'Cancel',
        role: 'cancel',
        handler: () => {
        }
      }
    ];

    //confirmation
    this.appFunction
      .actionCtrl
      .create({
        header: 'What would you like to do?',
        buttons: options
      })
      .then((action) => {
        action.present();
      });

  }

  displayNote(click: Event, player: AppEventPlayer) {

    //prevent the list item click
    click.stopPropagation();

    //diusplay note
    this.appFunction
      .modalCtrlCreate({
        component: NotePage,
        componentProps: {
          player: player
        },
        cssClass: 'custom-modal-auto-height'
      })
      .then((modal) => {

        modal
          .present()
          .catch((err) => {
            console.log('event-detail.ts modal present error', err);
          });

      })
      .catch((err) => {
        console.log('event-detail.ts modal create error', err);
      });

  }

  done() {
    //close
    this.appFunction.modalCtrl.dismiss();
  }

}
