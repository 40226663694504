<ion-header class="group-trip-view-header ion-no-border">

  <ion-toolbar>

    <ion-buttons slot="primary">
      <ion-button fill="solid" shape="round" color="light" (click)="done()">
        Close
      </ion-button>
    </ion-buttons>

    <ion-title>Trip</ion-title>

    <ion-buttons slot="secondary">
      <ion-button slot="icon-only" [screenKey]="screenKey">
        <ion-icon name="help-circle-outline"></ion-icon>
      </ion-button>
    </ion-buttons>

  </ion-toolbar>

</ion-header>

<ion-content #content class="group-trip-view-content">

  <!-- if group is no longer available (deleted) then dont't display -->
  <ng-container *ngIf="!group.deleted; else groupDeleted">

    <!-- segment -->
    <div class="ion-padding">
      <ion-segment [(ngModel)]="groupViewSegment" [scrollable]="true" (ionChange)="groupSegmentChange($event)"
        mode="ios">
        <ion-segment-button [value]="GroupTripSegment.Information">
          <ion-label>Details</ion-label>
        </ion-segment-button>
        <ion-segment-button [value]="GroupTripSegment.Members">
          <ion-label>Members</ion-label>
        </ion-segment-button>
        <ion-segment-button [value]="GroupTripSegment.Events">
          <ion-label>Events</ion-label>
        </ion-segment-button>
        <ion-segment-button [value]="GroupTripSegment.Matches">
          <ion-label>Matches</ion-label>
        </ion-segment-button>
        <ion-segment-button [value]="GroupTripSegment.Posts">
          <ion-label>Posts</ion-label>
        </ion-segment-button>
      </ion-segment>
    </div>

    <div *ngIf="groupViewSegment === GroupTripSegment.Information">

      <!-- cover and avatar -->
      <div class="coverAvatar" style="margin-top: -10px">

        <!-- cover -->
        <div class="cover" style="padding: 16px;">
          <img [src]="group.cover.URI" style="border-radius: 16px;" draggable="false">
        </div>

        <!-- avatar -->
        <div class="avatar" style="margin-top: -30px;">
          <ion-avatar class="avatar-large avatarImageOverlay">
            <img #imgAvatar [src]="group.avatar.URI" (click)="appFunction.showAvatarView($event, imgAvatar)"
              draggable="false">
          </ion-avatar>
        </div>

      </div>

      <!-- 'in' action card -->
      <ion-card class="actionCard" *ngIf="this.actionCd === ActionCd.In">

        <ion-card-content>
          <ion-card-title>
            Success!
          </ion-card-title>

          <p class="message" *ngIf="!group.allFull">You're in!! Please find more details below.</p>
          <p class="message" *ngIf="isMemberOnWaitlist">You're in but on the wait list!!</p>

        </ion-card-content>

      </ion-card>

      <!-- 'out' action card -->
      <ion-card class="actionCard" *ngIf="this.actionCd === ActionCd.Out">

        <ion-card-content>
          <ion-card-title>
            Sorry you can't make it!
          </ion-card-title>

          <p class="message">You're out!!</p>

        </ion-card-content>

      </ion-card>

      <!-- 'invite' action card -->
      <ion-card class="actionCard" *ngIf="this.actionCd === ActionCd.Invited">

        <ion-card-content>
          <ion-card-title>
            You've been invited!
          </ion-card-title>

          <p class="message">
            Hi {{accountService.member?.firstName}}, {{group.ownerMember.firstName}} has invited you to the
            <b style="color: var(--ion-color-primary);">{{group.name}}</b>.
          </p>

        </ion-card-content>

        <ion-grid class="ion-no-padding ion-nowrap" class="buttonBar">
          <ion-row>

            <ion-col class="ion-text-center ion-no-padding" [size]="6">
              <ion-button expand="block" shape="round" class="primary-action"
                (click)="changeTripStatus(TripAttendanceStatus.In)">Yes, I'm in
              </ion-button>
            </ion-col>

            <ion-col class="ion-text-center" [size]="6">
              <ion-button expand="block" shape="round" class="secondary-action"
                (click)="changeTripStatus(TripAttendanceStatus.Out)">No, I'm out
              </ion-button>
            </ion-col>

          </ion-row>
        </ion-grid>

      </ion-card>

      <!-- group details -->
      <ion-list class="itemLines">

        <ion-list-header>
          <ion-label>Trip Details</ion-label>
        </ion-list-header>

        <ion-item>
          <ion-label>
            <ion-text class="labelPositionStacked">Name</ion-text>
            <h2 class="readOnly ion-text-wrap">{{group.name}}</h2>
          </ion-label>
        </ion-item>

        <ion-item *ngIf="group.description">
          <ion-label>
            <ion-text class="labelPositionStacked">Details</ion-text>
            <h2 class="readOnly ion-text-wrap">{{group.description}}</h2>
          </ion-label>
        </ion-item>

        <ion-item>
          <ion-label>
            <ion-label position="stacked">Your Trip Status</ion-label>
            <trip-status [tripStatus]="groupMember.status" [isMemberOnWaitlist]="isMemberOnWaitlist" class="readOnly">
            </trip-status>
          </ion-label>
        </ion-item>

        <ion-item>
          <ion-label>
            <ion-label position="stacked">Departure Date</ion-label>
            <div class="iconTextSingleLine">
              <ion-icon name="calendar"></ion-icon>
              <ion-text class="readOnly">{{group.departureDt.toDate() | amUtc | amLocal | amDateFormat:'dddd, MMM Do'}}
              </ion-text>
            </div>
          </ion-label>
        </ion-item>

        <ion-item>
          <ion-label>
            <ion-label position="stacked">Return Date</ion-label>
            <div class="iconTextSingleLine">
              <ion-icon name="calendar"></ion-icon>
              <ion-text class="readOnly">{{group.returnDt.toDate() | amUtc | amLocal | amDateFormat:'dddd, MMM Do'}}
              </ion-text>
            </div>
          </ion-label>
        </ion-item>

      </ion-list>

      <!-- event preferences -->
      <ion-list *ngIf="groupMember.status === TripAttendanceStatus.In" class="itemLines">

        <ion-list-header>
          <ion-label>Event Notifications</ion-label>
        </ion-list-header>
        <ion-list-header class="help">
          <ion-text>Customize event notifications.</ion-text>
          <ion-text (click)="helpService.showFeatureHelp('eventCommunicationPreferences')">Learn more.</ion-text>
        </ion-list-header>

        <ion-item>
          <ion-label>
            <ion-text class="labelPositionStacked">Email</ion-text>
            <round-segment (change)="updateEmailPreferences($event)" [value]="this.memberGroupPreferences?.e"
              [buttons]="emailPreference"></round-segment>
          </ion-label>
        </ion-item>

        <ion-item>
          <ion-label>
            <ion-text class="labelPositionStacked">Notification</ion-text>
            <round-segment (change)="updateNotificationPreferences($event)" [value]="this.memberGroupPreferences?.n"
              [buttons]="emailPreference">
            </round-segment>
          </ion-label>
        </ion-item>

      </ion-list>

      <!-- group post preferences -->
      <ion-list *ngIf="groupMember.status === TripAttendanceStatus.In" class="itemLines">

        <ion-list-header>
          <ion-label>Post Notifications</ion-label>
        </ion-list-header>
        <ion-list-header class="help">
          <ion-text>Customize post notifications.</ion-text>
          <ion-text (click)="helpService.showFeatureHelp('postCommunicationPreferences')">Learn more.</ion-text>
        </ion-list-header>

        <ion-item>
          <ion-label>
            <ion-text class="labelPositionStacked">Notification</ion-text>
            <round-segment (change)="updatePostNotificationPreferences($event)" [value]="this.memberGroupPreferences?.p"
              [buttons]="postPreference"></round-segment>
          </ion-label>
        </ion-item>

      </ion-list>

    </div>

    <div *ngIf="groupViewSegment === GroupTripSegment.Members">

      <!-- <ion-searchbar (ionChange)="searchCriteriaChange($event)" (ionClear)="searchCriteriaClear()"
        show-cancel-button="focus" inputmode="search"></ion-searchbar> -->

      <ion-list class="itemLines">

        <ion-list-header>
          <ion-label>Members</ion-label>
        </ion-list-header>

        <!-- list group members that are "in" and with the numberOfPlayers -->
        <ng-container *ngIf="group.invited.in.length > 0;">

          <!-- attendance status header -->
          <ion-item-group>

            <ion-item-divider>
              <ion-label>In</ion-label>
            </ion-item-divider>

            <!-- group members -->
            <ion-item *ngFor="let groupMember of group.invited.in;">

              <avatar #avatar [member]="groupMember.member" slot="start"></avatar>

              <ion-label (click)="avatar.displayProfileSummary($event)">

                <h2>{{groupMember.member.firstName}} {{groupMember.member.lastName}}</h2>
                <p [ngStyle]="{'color': group.isMemberAdmin(groupMember.member) ? 'red': 'initial' }">
                  {{(groupMember.member.id === group.ownerMemberId) ? 'organizer' :
                  group.isMemberAdmin(groupMember.member)
                  ?
                  'administrator' :
                  groupMember.member.email}}
                </p>

              </ion-label>

            </ion-item>

          </ion-item-group>

        </ng-container>

        <!-- list group members that are "waitlist" -->
        <ng-container *ngIf="group.invited.waitList.length > 0;">

          <!-- attendance status header -->
          <ion-item-group class="itemGroupLines">

            <ion-item-divider>
              <ion-label *ngIf="group.allFull">Waitlist</ion-label>
              <ion-label *ngIf="!group.allFull">Invited</ion-label>
            </ion-item-divider>

            <!-- group members -->
            <ion-item *ngFor="let groupMember of group.invited.waitList;">

              <avatar #avatar [member]="groupMember.member" slot="start"></avatar>

              <ion-label (click)="avatar.displayProfileSummary($event)">

                <h2>{{groupMember.member.firstName}} {{groupMember.member.lastName}}</h2>
                <p [ngStyle]="{'color': group.isMemberAdmin(groupMember.member) ? 'red': 'initial' }">
                  {{(groupMember.member.id === group.ownerMemberId) ? 'organizer' :
                  group.isMemberAdmin(groupMember.member)
                  ?
                  'administrator' :
                  groupMember.member.email}}
                </p>
                <p *ngIf="group.allFull">{{TripAttendanceStatus[groupMember.status]}}</p>

              </ion-label>

            </ion-item>

          </ion-item-group>

        </ng-container>

        <!-- list group members that are "out" -->
        <ng-container *ngIf="group.invited.out.length > 0;">

          <!-- attendance status header -->
          <ion-item-group>

            <ion-item-divider>
              <ion-label>Out</ion-label>
            </ion-item-divider>

            <!-- group members -->
            <ion-item *ngFor="let groupMember of group.invited.out;">

              <avatar #avatar [member]="groupMember.member" slot="start"></avatar>

              <ion-label (click)="avatar.displayProfileSummary($event)">

                <h2>{{groupMember.member.firstName}} {{groupMember.member.lastName}}</h2>
                <p [ngStyle]="{'color': group.isMemberAdmin(groupMember.member) ? 'red': 'initial' }">
                  {{(groupMember.member.id === group.ownerMemberId) ? 'organizer' :
                  group.isMemberAdmin(groupMember.member)
                  ?
                  'administrator' :
                  groupMember.member.email}}
                </p>

              </ion-label>

            </ion-item>

          </ion-item-group>

        </ng-container>

      </ion-list>

    </div>

    <div *ngIf="groupViewSegment === GroupTripSegment.Events"
      [ngClass]="{'greyBackgroundForSegment': group.events.all.length > 0}">

      <ng-container *ngIf="group.events.all.length > 0, else noEvents">

        <ion-list>

          <ion-list-header>
            <ion-label>Events</ion-label>
          </ion-list-header>

          <ng-container *ngFor="let event of group.events.all">
            <event-card [event]="event"></event-card>
          </ng-container>

        </ion-list>

      </ng-container>

    </div>

    <div *ngIf="groupViewSegment === GroupTripSegment.Matches">

      <ng-container *ngIf="group.matches.parent.length > 0, else noMatches">

        <ion-list class="itemLines" lines="full">

          <ion-list-header>
            <ion-label>Trip Matches</ion-label>
          </ion-list-header>

          <!-- interate through group matches -->
          <ng-container *ngFor="let match of group.matches.parent">
            <trip-match-live-view [match]="match" [group]="group" [openScorecardMethod]="openScorecard">
            </trip-match-live-view>
          </ng-container>

        </ion-list>

      </ng-container>

    </div>

    <div *ngIf="groupViewSegment === GroupTripSegment.Posts"
      [ngClass]="{'greyBackgroundForSegment': posts?.length > 0}">

      <ng-container *ngIf="posts?.length > 0, else noPosts">

        <ion-list>

          <ion-list-header>
            <ion-label>Posts</ion-label>
          </ion-list-header>

          <ng-container *ngFor="let post of posts;">
            <post-card [post]="post" [content]="content"></post-card>
          </ng-container>

        </ion-list>

        <ion-infinite-scroll (ionInfinite)="getPosts($event)" threshold="1px">
          <ion-infinite-scroll-content loadingSpinner="bubbles" loadingText="Loading more posts...">
          </ion-infinite-scroll-content>
        </ion-infinite-scroll>

        <ion-note *ngIf="noMorePosts" class="instructional"
          style="height: 85px; margin-bottom: 10px; background-color: var(--ion-color-white-b);">No more posts
        </ion-note>

      </ng-container>

    </div>

    <ion-fab #fab vertical="bottom" horizontal="end" slot="fixed" haptic [fabClose]="fab"
      *ngIf="[TripAttendanceStatus.In, TripAttendanceStatus.Out].includes(groupMember.status)">
      <ion-fab-button>
        <ion-icon name="add"></ion-icon>
      </ion-fab-button>
      <ion-fab-list side="top">
        <ion-button *ngIf="groupMember.status === TripAttendanceStatus.In" shape="round"
          (click)="changeTripStatus(TripAttendanceStatus.Out); fab.close();">
          <ion-icon name="exit" slot="start"></ion-icon>
          <ion-label>No, I'm out</ion-label>
        </ion-button>
        <ion-button *ngIf="groupMember.status === TripAttendanceStatus.Out" shape="round"
          (click)="changeTripStatus(TripAttendanceStatus.In); fab.close();">
          <ion-icon name="exit" slot="start"></ion-icon>
          <ion-label>Yes, I'm in</ion-label>
        </ion-button>
        <ion-button *ngIf="groupMember.status === TripAttendanceStatus.In" shape="round"
          (click)="newPost(); fab.close();">
          <ion-icon name="chatbubbles" slot="start"></ion-icon>
          <ion-label>Post Message</ion-label>
        </ion-button>
      </ion-fab-list>
    </ion-fab>

  </ng-container>

</ion-content>

<ng-template #noPosts>
  <div style="text-align: center; font-size: 70px; margin-top: 50px;">
    <ion-icon name="chatbubbles"></ion-icon>
  </div>
  <ion-note class="message" style="margin-top: 12px; margin-bottom: 12px; line-height: 25px;">
    There are no posts.
  </ion-note>
</ng-template>

<ng-template #noMembers>
  <ion-note class="instructional" style="margin-top: 10px;">There are no members</ion-note>
</ng-template>

<ng-template #noEvents>
  <div style="text-align: center; font-size: 70px; margin-top: 50px;">
    <ion-icon name="calendar"></ion-icon>
  </div>
  <ion-note class="message" style="margin-top: 12px; margin-bottom: 12px; line-height: 25px;">
    There are no events.
  </ion-note>
</ng-template>

<ng-template #groupDeleted>
  <div style="text-align: center; font-size: 70px; margin-top: 50px;">
    <ion-icon name="people"></ion-icon>
  </div>
  <ion-note class="message" style="margin-top: 12px; margin-bottom: 12px; line-height: 25px;">
    This group is no long available.
  </ion-note>
</ng-template>

<ng-template #noMatches>
  <div style="text-align: center; font-size: 70px; margin-top: 50px;">
    <ion-icon name="calendar"></ion-icon>
  </div>
  <ion-note class="message" style="margin-top: 12px; margin-bottom: 12px; line-height: 25px;">
    There are no Matches for this trip.
  </ion-note>
</ng-template>