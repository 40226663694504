import { Component, OnInit } from '@angular/core';
import { NavParams } from '@ionic/angular';
import { AccountService } from '../../app.account';
import { ClubService, AppTee, AppCourse } from '../../app.club';
import { AppFunction, leaveToRightAnimation, enterFromRightAnimation } from '../../app.function';
import { CourseTeePage } from '../course-tee/course-tee.page';
import { AppConfig } from 'src/app/app.config';
import { CourseScorecardPage } from '../course-scorecard/course-scorecard.page';

@Component({
  selector: 'course-detail',
  templateUrl: './course-detail.page.html',
  styleUrls: ['./course-detail.page.scss']
})
export class CourseDetailPage implements OnInit {

  course: AppCourse;
  courseDetailSegment: string = 'detail';

  constructor(
    public accountService: AccountService,
    public clubService: ClubService,
    public appFunction: AppFunction,
    public navParams: NavParams) {

    //console.log('course-detail.page.ts constructor');

  }

  ngOnInit() {

    //console.log('course-detail.page.ts ngOnInit');

    //create group class
    this.course = <AppCourse>this.navParams.get('course');

  }

  private async copyTee(tee: AppTee) {

    this.appFunction
      .modalCtrlCreate({
        component: CourseTeePage,
        presentingElement: await this.appFunction.routerOutlet(),
        enterAnimation: enterFromRightAnimation,
        leaveAnimation: leaveToRightAnimation,
        cssClass: 'custom-modal', //for md
        componentProps: {
          editMode: AppConfig.EDIT_MODE.new,
          course: this.course,
          tee: tee
        }
      })
      .then((modal) => {

        modal
          .present()
          .catch((err) => {
            console.log('course-detail.page.ts addTee modal present error', err);
          });

        modal
          .onDidDismiss()
          .then((result) => {

            if (result.role === 'add') {
              this.course.tees.push(result.data.tee);
            }

          });

      })
      .catch((err) => {
        console.log('course-detail.page.ts addTeemodal create error', err);
      });

  }

  async viewTee(tee: AppTee) {

    this.appFunction
      .modalCtrlCreate({
        component: CourseTeePage,
        presentingElement: await this.appFunction.routerOutlet(),
        enterAnimation: enterFromRightAnimation,
        leaveAnimation: leaveToRightAnimation,
        cssClass: 'custom-modal', //for md
        componentProps: {
          action: AppConfig.EDIT_MODE.update,
          course: this.course,
          tee: tee
        }
      })
      .then((modal) => {

        modal
          .present()
          .catch((err) => {
            console.log('course-detail.page.ts viewTee modal present error', err);
          });

      })
      .catch((err) => {
        console.log('course-detail.page.ts viewTee modal create error', err);
      });

  }

  private async deleteTeeConfirm(tee: AppTee) {

    //confirmation
    this.appFunction
      .actionCtrl
      .create({
        header: 'Please confirm',
        buttons: [
          {
            text: 'Delete tee',
            handler: () => {
              this.deleteTee(tee);
            }
          },
          {
            text: 'Cancel',
            role: 'cancel',
            handler: () => {
            }
          }
        ]
      })
      .then((action) => {
        action.present();
      });

  }

  private async deleteTee(tee: AppTee) {
    tee.delete();
  }

  courseActionConfirm(click: Event, tee: AppTee) {

    //prevent the list item click
    click.stopPropagation();

    //confirmation
    this.appFunction
      .actionCtrl
      .create({
        header: 'Please confirm',
        buttons: [
          {
            text: 'View/edit tee',
            handler: () => {
              this.viewTee(tee);
            }
          },
          {
            text: 'Copy tee',
            handler: () => {
              this.copyTee(tee);
            }
          },
          {
            text: 'Delete tee',
            handler: () => {
              this.deleteTeeConfirm(tee);
            }
          },
          {
            text: 'Cancel',
            role: 'cancel',
            handler: () => {
            }
          }
        ]
      })
      .then((action) => {
        action.present();
      });

  }

  async scoreCard() {

    this.appFunction
      .modalCtrlCreate({
        component: CourseScorecardPage,
        presentingElement: await this.appFunction.routerOutlet(),
        enterAnimation: enterFromRightAnimation,
        leaveAnimation: leaveToRightAnimation,
        cssClass: 'scoreCardModal', //make screen wider
        componentProps: {
          course: this.course
        }
      })
      .then((modal) => {

        modal
          .present()
          .catch((err) => {
            console.log('course-detail.ts scoreCard modal present error', err);
          });

      })
      .catch((err) => {
        console.log('course-detail.ts scoreCard modal create error', err);
      });
  }

  done() {

    //console.log('course-detail.page.ts done');

    //console.log('course-details.page.ts done valid');

    this.appFunction
      .loadingCtrl
      .create({ message: 'Saving course...' })
      .then((loading) => {

        loading.present();

        //save 
        this.course
          .save()
          .then(() => {

            loading.dismiss();

            //close this page
            this.appFunction
              .modalCtrl
              .dismiss({ course: this.course }, 'select');

          });

      });

  }

  back() {

    //close this page
    this.appFunction
      .modalCtrl
      .dismiss(undefined, 'cancel');

    //confirm that user wants to discard changes
    /* if (this.courseForm.dirty) {

      this.appFunction
        .alertCtrl
        .create({
          header: 'Discard changes?',
          message: 'You have made changes to your selected course. Do you want to discard these changes?',
          buttons: [
            {
              text: 'No',
              handler: () => {
              }
            },
            {
              text: 'Yes, discard',
              handler: () => {

                //close this page
                this.appFunction
                  .modalCtrl
                  .dismiss(undefined, 'cancel');

              }
            }
          ]
        })
        .then((alert) => {
          alert.present();
        });

    } else {

      //close this page
      this.appFunction
        .modalCtrl
        .dismiss(undefined, 'cancel');

    } */

  }

}
