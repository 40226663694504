<ion-header class="club-detail-header ion-no-border">

  <ion-toolbar>

    <ion-buttons slot="secondary">
      <ion-button (click)="back()">
        <ion-icon name="chevron-back"></ion-icon>
        Back
      </ion-button>
    </ion-buttons>

    <ion-buttons slot="primary">
      <ion-button fill="solid" shape="round" color="light" (click)="done()">
        {{closeLabel}}
      </ion-button>
    </ion-buttons>

    <ion-title>Club</ion-title>

  </ion-toolbar>

</ion-header>

<ion-content class="club-detail-content">

  <div class="ion-padding">

    <ion-segment [(ngModel)]="clubDetailSegment" mode="ios">

      <ion-segment-button value="detail">
        <ion-label>Details</ion-label>
      </ion-segment-button>

      <ion-segment-button value="courses">
        <ion-label>Courses</ion-label>
      </ion-segment-button>

    </ion-segment>

  </div>

  <div [hidden]="clubDetailSegment !== 'detail'">

    <form class="club-detail-form" [formGroup]="clubForm">

      <ion-list class="itemLines">

        <ion-item *ngIf="accountService.member.admin">
          <ion-label>
            <ion-label position="stacked">Club Id</ion-label>
            <div>{{club.clubId}}</div>
          </ion-label>
        </ion-item>

        <ion-item>
          <ion-label position="stacked">Name</ion-label>
          <ion-input formControlName="name"></ion-input>
        </ion-item>

        <ion-item>
          <ion-label position="stacked">Address</ion-label>
          <ion-input formControlName="address"></ion-input>
        </ion-item>

        <ion-item *ngIf="accountService.member.admin">
          <ion-label position="stacked">Latitude</ion-label>
          <ion-input formControlName="latitude"></ion-input>
        </ion-item>

        <ion-item *ngIf="accountService.member.admin">
          <ion-label position="stacked">Longitude</ion-label>
          <ion-input formControlName="longitude"></ion-input>
        </ion-item>

      </ion-list>

    </form>

  </div>

  <div [hidden]="clubDetailSegment !== 'courses'">

    <ion-list class="itemLines">

      <ion-item *ngFor="let course of club.courses;" (click)="openCourse(course)"
        [detail]="accountService.member.admin">

        <ion-label>
          <h2>{{course.name}}</h2>
        </ion-label>

      </ion-item>

    </ion-list>

  </div>

  <ion-fab #fab vertical="bottom" horizontal="end" slot="fixed" haptic
    *ngIf="club.exists && accountService.member.admin">
    <ion-fab-button>
      <ion-icon name="add"></ion-icon>
    </ion-fab-button>
    <ion-fab-list side="top">
      <ion-button shape="round" (click)="exportJSONtoExcel(); fab.close();">
        <ion-icon name="trash" slot="start"></ion-icon>
        <ion-label>Export Club</ion-label>
      </ion-button>
      <ion-button shape="round" (click)="importExceltoJSON(); fab.close();">
        <ion-icon name="trash" slot="start"></ion-icon>
        <ion-label>Import Club</ion-label>
      </ion-button>
    </ion-fab-list>
  </ion-fab>

</ion-content>