import { Component, OnInit, ViewChild } from '@angular/core';
import { IonAccordionGroup, IonDatetime, IonModal, NavController, NavParams } from '@ionic/angular';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AccountService, AppMember } from '../../app.account';
import { AppFunction, HelpService, scorecardEnterFromRightAnimation, scorecardLeaveToRightAnimation } from '../../app.function';
import { GroupService, memberGroupPreferences, AppGroupTrip, AppGroupTripMember, MemberGroupRole, TripAttendanceStatus, GroupTripSegment } from '../../app.group';
import { AppConfig } from '../../app.config';
import { ContactSearchPage } from '../contact-search/contact-search.page';
import { ContactPage } from '../contact/contact.page';
import { EventDetailPage } from '../event-detail/event-detail.page';
import { EmailPage } from '../email/email.page';
import { PostNewPage } from '../post-new/post-new.page';
import { MediaService } from '../../app.media';
import { AppPost } from 'src/app/app.social';
import { Subscription } from 'rxjs';
import { AppEvent, AppEventPlayer, EventService } from 'src/app/app.event';
import { AppMatch } from 'src/app/app.match';
import { EventScoringPage } from '../event-scoring/event-scoring.page';
import * as moment from 'moment';
import firebase from 'firebase/compat/app';
import { MatchEventDetailPage } from '../match-event-detail/match-event-detail.page';
import { GroupTripScorecardPage } from '../group-trip-scorecard/group-trip-scorecard.page';
import { DisplayOptions, PopoverDirective } from 'src/app/directives/popover/popover.directive';
import { Props } from 'tippy.js';
import { MatchMemberDetailPage } from '../match-member-detail/match-member-detail.page';
import confetti from 'canvas-confetti';

@Component({
  selector: AppConfig.PAGE.GroupTripDetail,
  templateUrl: './group-trip-detail.page.html',
  styleUrls: ['./group-trip-detail.page.scss']
})
export class GroupTripDetailPage implements OnInit {

  group: AppGroupTrip;
  groupForm: FormGroup;
  isNew: boolean = false;
  groupDetailSegment: string = GroupTripSegment.Information;
  filteredMembersCriteria: string = '';
  memberGroupPreferences: memberGroupPreferences;
  emailPreference = AppConfig.GROUP_PREFERENCES.EVENT_EMAIL_PREFERENCE.values;
  notificationPreference = AppConfig.GROUP_PREFERENCES.EVENT_NOTIFICATION_PREFERENCE.values;
  postPreference = AppConfig.GROUP_PREFERENCES.POST_NOTIFICATION_PREFERENCE.values;
  tripAttendancePreference = AppConfig.GROUP_PREFERENCES.TRIP_NOTIFICATION_PREFERENCE.values;
  isFormSubmitted: boolean = false;
  noMorePosts: boolean = false;
  localAvatarURL: string;
  localCoverURL: string;
  private _posts: AppPost[];
  private _futurePostSubscription: Subscription;
  minEventDate: string = moment().format('YYYY-MM-DDT00:00');
  minDepartureDate: string = moment().format('YYYY-MM-DDT00:00');
  returnDtAccordianName: string;
  tripDays: number;
  TripAttendanceStatus: typeof TripAttendanceStatus = TripAttendanceStatus;
  screenKey: string = AppConfig.PAGE.GroupTripDetail;
  GroupTripSegment: typeof GroupTripSegment = GroupTripSegment;
  @ViewChild('departureDtAccordian') departureDtAccordian: IonAccordionGroup;
  @ViewChild('returnDtAccordian') returnDtAccordian: IonAccordionGroup;
  @ViewChild('returnDt') returnDt: IonDatetime;
  faGolfBallTee = AppConfig.faGolfBallTee;
  @ViewChild(PopoverDirective) popoverNewEvent: PopoverDirective;
  @ViewChild(PopoverDirective) popoverNewMatch: PopoverDirective;
  @ViewChild(PopoverDirective) popoverNewPost: PopoverDirective;
  @ViewChild(PopoverDirective) popoverNewMember: PopoverDirective;
  @ViewChild(IonModal) congratulations: IonModal;

  //set up popover help config
  createPopupConfig: Partial<Props> = {
    placement: 'bottom-end',
    offset: [0, 5],
  }

  //set up popover help config
  createPopupDisplayConfig: Partial<DisplayOptions> = {
    frequencyOfDisplay: 1,
    closeAfterAnimation: true,
  }

  constructor(
    public builder: FormBuilder,
    public accountService: AccountService,
    public navParams: NavParams,
    public groupService: GroupService,
    public appFunction: AppFunction,
    public eventService: EventService,
    public mediaService: MediaService,
    public helpService: HelpService,
    public navCtrl: NavController) {

    helpService.screenWhatsNew(AppConfig.PAGE.GroupTripDetail);

  }

  ngOnInit() {

    try {

      //build form group
      this.groupForm = this.builder.group({
        name: ['', Validators.required],
        description: '',
        numberOfPlayers: undefined,
        departureDt: [undefined, Validators.required],
        returnDt: [undefined, Validators.required],
        avatarFileURI: '', //only use this to indicate that avatar has been updated
        coverFileURI: '' //only use this to indicate that avatar has been updated
      });

      //get group
      this.group = <AppGroupTrip>this.navParams.get('group');

      //get passed in group
      if (this.group) {

        //set local image
        this.localAvatarURL = this.group.avatar.URI;
        this.localCoverURL = this.group.cover.URI;

        //populate form 
        this.groupForm.controls['name'].setValue(this.group.name);
        this.groupForm.controls['description'].setValue(this.group.description);
        this.groupForm.controls['numberOfPlayers'].setValue(this.group.numberOfPlayers);
        this.groupForm.controls['departureDt'].setValue(this.group.departureDt.toDate().toISOString(), { emitEvent: false });
        this.groupForm.controls['returnDt'].setValue(this.group.returnDt.toDate().toISOString(), { emitEvent: false });

        //calc trip days, this is used in the event the user changes the departure date to recalc return date
        this.tripDays = moment(this.groupForm.controls.returnDt.value).diff(this.groupForm.controls.departureDt.value, 'days');

        //get member preferences (for either group owner or group admin)
        const preferences = this.accountService.member.getPreference(this.group.id);
        this.memberGroupPreferences = new memberGroupPreferences(this.group.id, preferences);

      } else { //edit mode is 'new'

        //set isNew flag
        this.isNew = true;

        //create group class...
        this.group = new AppGroupTrip();

        //...then setup
        this.group
          .initialize()
          .then(() => {

            //set local image
            this.localAvatarURL = this.group.avatar.URI;
            this.localCoverURL = this.group.cover.URI;

            //populate form 
            this.groupForm.controls['numberOfPlayers'].setValue(this.group.numberOfPlayers);

            //set organizer to logged in member
            this.group.ownerMemberId = this.accountService.member.id;

            //push organizer onto main members array
            this.group.addMemberToGroup(this.accountService.member, MemberGroupRole.Owner)
              .then((member) => {
                member.status = TripAttendanceStatus.In;
              });

            //default member preferences
            this.memberGroupPreferences = new memberGroupPreferences(undefined, {});

          });

      }

      //subscribe to departureDt changes
      this.groupForm.controls['departureDt'].valueChanges.subscribe(() => {
        this.departureDtChange();

        //if returnDt is undefined then be sure the return calendar is opened on the depatureDt month
        if ([null, undefined].includes(this.groupForm.controls.returnDt.value)) {
          //reset returnDt so that it displays the same month as departureDt
          this.returnDt.reset(this.groupForm.controls.departureDt.value);
        }

        this.departureDtAccordian.value = undefined;
        this.returnDtAccordian.value = 'returnDt';
      });

      //subscribe to returnDt changes
      this.groupForm.controls['returnDt'].valueChanges.subscribe(() => {
        this.returnDtChange();
        this.returnDtAccordian.value = undefined;
      });

    } catch (err) {
      console.log('group-trip-detail.page.ts ngOnInit error', err);
    }

  }

  ionViewDidLeave() {
    if (this._futurePostSubscription) {
      this._futurePostSubscription.unsubscribe();
    }
  }

  addMemberConfirm() {

    //confirmation
    this.appFunction
      .actionCtrl
      .create({
        header: 'Add member from',
        buttons: [
          {
            text: 'Contacts and Group members',
            handler: () => {
              this.addMemberFromContacts();
            }
          }, {
            text: 'Email address',
            handler: () => {
              this.addMemberFromEmail();
            }
          }, {
            text: 'Cancel',
            role: 'cancel'
          }
        ]
      })
      .then((action) => {
        action.present();
      });

  }

  async addMemberFromContacts() {

    this.appFunction
      .modalCtrlCreate({
        component: ContactSearchPage,
        presentingElement: await this.appFunction.routerOutlet(),
        cssClass: 'custom-modal', //for md
        componentProps: {
          member: this.accountService.member
        }
      })
      .then((modal) => {

        modal
          .onDidDismiss()
          .then((results) => {

            try {

              //if returned array has contacts then...
              if (Array.isArray(results.data.contacts) && results.data.contacts.length > 0) {

                //loop through returned array
                results.data
                  .contacts
                  .forEach((contact) => {

                    //see if contact email already exists in group member list
                    const emailFound: boolean = this.group.members.some((groupMember) => {
                      //cast to get member
                      return (<AppGroupTripMember>groupMember).member.email.trim().toLowerCase() === contact.email.trim().toLowerCase();
                    });

                    //if email not found then it's new to this group
                    if (!emailFound) {

                      //find existing member
                      this.accountService
                        .getMemberByEmail(contact.email)
                        .then((foundMember) => {

                          //if found then... 
                          if (foundMember) {

                            //...add member to group
                            this.group
                              .addMemberToGroup(foundMember, MemberGroupRole.Member)
                              .then(() => {
                                //force groupForm to dirty so new members are saved
                                this.groupForm.markAsDirty();
                              });

                          } else { //elsze if not found...

                            //...create new member
                            const member: AppMember = new AppMember();
                            member
                              .initialize()
                              .then(() => {

                                member.email = contact.email;
                                member.firstName = contact.givenName;
                                member.lastName = contact.familyName;

                                //push new member on the array that is bound to the view
                                this.group
                                  .addMemberToGroup(member, MemberGroupRole.Member)
                                  .then(() => {
                                    //force groupForm to dirty so new members are saved
                                    this.groupForm.markAsDirty();
                                  });

                              });

                          }

                        });

                    }

                  });

              }
            } catch (err) {
              console.log('group-trip-detail.page.ts addMemberFromContacts error processing new contact', err);
            }

          });

        modal
          .present()
          /* .then(() => {
            //make the members segment active
            this.groupDetailSegment = GroupTripSegment.Members;
          }) */
          .catch((err) => {
            console.log('group-trip-detail.page.ts addMemberFromContacts modal present error', err);
          });

      })
      .catch((err) => {
        console.log('group-trip-detail.page.ts addMemberFromContacts modal create error', err);
      });

  }

  async addMemberFromEmail() {

    this.appFunction
      .modalCtrlCreate({
        component: ContactPage,
        presentingElement: await this.appFunction.routerOutlet(),
        cssClass: 'custom-modal', //for md 
        componentProps: {
          showEmail: true
        }
      })
      .then((modal) => {

        modal
          .onDidDismiss()
          .then((results) => {

            //if the returned object contains an email field then...
            if (results.data && results.data.contact && results.data.contact.email) {

              //see if contact email already exists in group member list
              const emailFound: boolean = this.group.members.some((groupMember) => {
                return (<AppGroupTripMember>groupMember).member.email.trim().toLowerCase() === results.data.contact.email.trim().toLowerCase();
              });

              //if email not found then it's new and add to array 
              if (!emailFound) {

                //does member.email already exist?
                this.accountService
                  .getMemberByEmail(results.data.contact.email)
                  .then((foundMember) => {

                    //if found then... 
                    if (foundMember) {

                      //...add member
                      this.group
                        .addMemberToGroup(foundMember, MemberGroupRole.Member)
                        .then(() => {
                          //force groupForm to dirty so new members are saved
                          this.groupForm.markAsDirty();
                        });

                    } else {

                      //otherwise create new member
                      const member: AppMember = new AppMember();
                      member
                        .initialize()
                        .then(() => {

                          member.email = results.data.contact.email;
                          member.firstName = results.data.contact.firstName;
                          member.lastName = results.data.contact.lastName;

                          //push new member on the array that is bound to the view
                          this.group
                            .addMemberToGroup(member, MemberGroupRole.Member)
                            .then(() => {
                              //force groupForm to dirty so new members are saved
                              this.groupForm.markAsDirty();
                            });

                        });

                    }

                  });

              }

            }

          });

        modal
          .present()
          /* .then(() => {
            //make the members segment active
            this.groupDetailSegment = GroupTripSegment.Members;
          }) */
          .catch((err) => {
            console.log('group-trip-detail.page.ts addMemberFromEmail modal present error', err);
          });

      })
      .catch((err) => {
        console.log('group-trip-detail.page.ts addMemberFromEmail modal create error', err);
      });

  }

  private async removeMember(groupMember: AppGroupTripMember) {

    //force groupForm to dirty
    this.groupForm.markAsDirty();

    //update the trip status
    await this.group.invited.changeTripStatus(groupMember, TripAttendanceStatus.Out);

    //remove member
    this.group.removeMemberFromGroup(groupMember.member);

  }

  makeAdmin(member: AppMember) {

    //add and mark dirty so it saves
    this.group.makeAdmin(member);
    this.groupForm.markAsDirty();

  }

  removeAdmin(member: AppMember) {

    //remove and mark dirty so it saves
    this.group.removeAdmin(member);
    this.groupForm.markAsDirty();

  }

  private removeMemberConfirm(groupMember: AppGroupTripMember) {

    //confirmation
    this.appFunction
      .actionCtrl
      .create({
        header: 'Please confirm',
        buttons: [
          {
            text: 'Remove Member',
            role: 'destructive',
            handler: () => {
              this.removeMember(groupMember);
            }
          },
          {
            text: 'Cancel',
            role: 'cancel',
            handler: () => {
            }
          }
        ]
      })
      .then((action) => {
        action.present();
      });

  }

  updateAvatar(click, avatarElement: HTMLImageElement) {

    this.mediaService
      .selectAvatarAction(click, avatarElement, this.group.avatar)
      .then((fileURI) => {

        //if avatar updated then display locally and make dirty
        if (fileURI !== undefined) {

          //set local image
          this.localAvatarURL = fileURI || AppConfig.NO_AVATAR_URI;

          //make form dirty
          this.groupForm.controls.avatarFileURI.markAsDirty();

        }

      })
      .catch((err) => {
        console.log('group-trip-detail.page.ts updateAvatar selectAvatarAction error', err, JSON.stringify(err));
      });

  }

  updateCover() {

    this.mediaService
      .selectCoverAction(this.group.cover)
      .then((fileURI) => {

        //if avatar updated then display locally and make dirty
        if (fileURI !== undefined) {

          //set local image
          this.localCoverURL = fileURI || AppConfig.NO_COVER_URI;

          //make form dirty
          this.groupForm.controls.coverFileURI.markAsDirty();

        }

      })
      .catch((err) => {
        console.log('group-trip-detail.page.ts updateCover selectPictureSource error', err, JSON.stringify(err));
      });

  }

  async newEvent(group: AppGroupTrip) {

    this.appFunction
      .modalCtrlCreate({
        component: EventDetailPage,
        presentingElement: await this.appFunction.routerOutlet(),
        cssClass: 'custom-modal', //for md
        componentProps: {
          group: group,
          editMode: AppConfig.EDIT_MODE.new,
          numberOfTeeTimes: Math.ceil((<number>this.groupForm.controls.numberOfPlayers.value) / 4),
        }
      })
      .then((modal) => {

        //display modal
        modal
          .present()
          .catch((err) => {
            console.log('group-trip-detail.page.ts modal present error', err);
          });

        //when modal is dismissed...
        modal
          .onDidDismiss()
          .then((result) => {

            //...and if event created
            if (result.role === 'new') {

              //get new event
              const newEvent: AppEvent = result.data.event;

              //add "in" members to event
              this.group
                .invited
                .in
                .forEach((member) => {
                  newEvent.players.join(member.id);
                });

            }

          });

      })
      .catch((err) => {
        console.log('group-trip-detail.page.ts modal create error', err);
      });

  }

  private deleteGroup(group: AppGroupTrip) {

    //delete group
    group
      .delete()
      .then(() => {
        this.appFunction.modalCtrl.dismiss();
      });

  }

  deleteGroupConfirmation(group: AppGroupTrip) {

    //confirmation
    this.appFunction
      .alertCtrl
      .create({
        header: 'Please confirm!',
        message: "Once deleted, this Trip (including Members, Events, Matches and Posts) will no longer be available. Are you sure you want to delete this Trip?",
        backdropDismiss: false,
        buttons: [
          {
            text: "No",
            role: 'cancel',
            handler: () => {
              //no action
            }
          },
          {
            text: 'Yes, delete',
            role: 'destructive',
            handler: () => {
              this.deleteGroup(group);
            }
          }
        ]
      })
      .then((action) => {
        action.present();
      });

  }

  updateEmailPreferences(value) {
    this.memberGroupPreferences.e = value;
    this.groupForm.markAsDirty();
  }

  updateNotificationPreferences(value) {
    this.memberGroupPreferences.n = value;
    this.groupForm.markAsDirty();
  }

  updatePostNotificationPreferences(value) {
    this.memberGroupPreferences.p = value;
    this.groupForm.markAsDirty();
  }

  updateTripNotificationPreferences(value) {
    this.memberGroupPreferences.tripNotification = value;
    this.groupForm.markAsDirty();
  }

  decrementCounter(controlName: string) {
    this.groupForm.controls[controlName].setValue(this.groupForm.controls[controlName].value - 1);
    this.groupForm.controls[controlName].markAsDirty();
  }

  incrementCounter(controlName: string) {
    this.groupForm.controls[controlName].setValue(this.groupForm.controls[controlName].value + 1);
    this.groupForm.controls[controlName].markAsDirty();
  }

  //#region social

  get posts(): AppPost[] {

    if (Array.isArray(this._posts)) {

      return this._posts.filter((post) => {
        return post.exists;
      });

    } else {
      return [];
    }

  }

  async newPost() {

    this.appFunction
      .modalCtrlCreate({
        component: PostNewPage,
        presentingElement: await this.appFunction.routerOutlet(),
        cssClass: 'custom-modal', //for md
        keyboardClose: false,
        componentProps: {
          poster: this.group
        }
      })
      .then((modal) => {

        modal
          .present()
          .catch((err) => {
            console.log('group-trip-detail.page.ts modal present error', err);
          });

      })
      .catch((err) => {
        console.log('group-trip-detail.page.ts modal create error', err);
      });

  }

  getPosts(event: any = undefined): Promise<void> {

    return new Promise<void>((resolve) => {

      //if first time get posts
      if (!Array.isArray(this._posts)) {

        this.appFunction
          .loadingCtrl
          .create({ message: 'Getting posts...' })
          .then((loading) => {

            loading.present();

            //get this groups posts
            this.group
              .social
              .getPosts()
              .then((posts) => {

                //set posts
                this._posts = posts;

                //listen for new posts
                this._futurePostSubscription = this.group
                  .social
                  .getFuturePosts()
                  .subscribe((futurePost) => {

                    //push all new posts on the front of the array
                    this._posts.unshift(futurePost);

                  });

                loading.dismiss();
                resolve();

              });

          });

      } else if (event) { //only get next set of posts on infinite scroll 

        //get next batch of (older) posts
        this.group
          .social
          .getNextPosts()
          .then((nextPosts) => {

            nextPosts
              .forEach((nextPost) => {
                this._posts.push(nextPost);
              });

            event.target.complete();

            //when no more posts to display then stop trying to fetch
            if (nextPosts.length === 0) {
              event.target.disabled = true;
              this.noMorePosts = true;
            }

            resolve();

          });

      } else {
        resolve();
      }

    });

  }

  //#endregion social

  async matchEdit(match: AppMatch = undefined) {

    //create match page
    this.appFunction
      .modalCtrlCreate({
        component: MatchEventDetailPage,
        cssClass: 'custom-modal', //for md
        presentingElement: await this.appFunction.routerOutlet(),
        componentProps: {
          parent: this.group,
          match: match,
          matchOptions: AppConfig.TRIP_MATCH_CONFIGURATION
        },
        canDismiss: true
      })
      .then((modal) => {

        //
        modal
          .onDidDismiss()
          .then((result) => {

            if (result.role === 'update') {

              //if event match is successfully added then create team
              const match: AppMatch = result.data.match;
              const isAdded: boolean = this.group.matches.addMatch(match, true);
              if (isAdded) {
                this.group
                  .save()
                  .then(() => {
                    this.group.matches.createTeams(0);
                  });
              };

            }

          });

        modal
          .present()
          .catch((err) => {
            console.log('group-trip-detail.page.ts matchEdit modal present error', err);
          });

      })
      .catch((err) => {
        console.log('group-trip-detail.page.ts matchEdit modal create error', err);
      });

  }

  async newMemberMatch() {

    this.appFunction
      .modalCtrlCreate({
        component: MatchMemberDetailPage,
        cssClass: 'custom-modal', //for md
        presentingElement: await this.appFunction.routerOutlet(),
        componentProps: {
          parent: this.group,
          editMode: AppConfig.EDIT_MODE.new,
          matchOptions: AppConfig.TRIP_MATCH_CONFIGURATION
        },
        canDismiss: true
      })
      .then((modal) => {

        //
        modal
          .onDidDismiss()
          .then((result) => {

            //if match was created then...
            if (result.role === 'update') {
              //...create the teams
              const match: AppMatch = result.data.match;
              match.createTeam(match.players, this.group);
            }

          });

        modal
          .present()
          .catch((err) => {
            console.log('event-scoring.page.ts newMemberMatch modal present error', err);
          });

      })
      .catch((err) => {
        console.log('event-scoring.page.ts newMemberMatch modal create error', err);
      });

  }

  async composeEmail() {

    this.appFunction
      .modalCtrlCreate({
        component: EmailPage,
        presentingElement: await this.appFunction.routerOutlet(),
        cssClass: 'custom-modal' //for md
      })
      .then((modal) => {

        modal
          .present()
          .catch((err) => {
            console.log('group-trip-detail.page.ts modal present error', err);
          });

        modal
          .onDidDismiss()
          .then((results) => {
            if (results.data.action === 'send') {
              this.group.sendGroupMessageEmail(this.isNew, results.data.subject, results.data.message);
            }
          })
          .catch((err) => {
            console.log('group-trip-detail.page.ts modal onDidDismiss error', err);
          });

      })
      .catch((err) => {
        console.log('group-trip-detail.page.ts modal create error', err);
      });

  }

  groupSegmentChange(event: any) {

    //wait for segment to change, this is so the tippy can be created after the segment has changed
    setTimeout(() => {

      if (event.detail.value === GroupTripSegment.Members) {

        //set and show up popover config
        this.createPopupConfig.content = 'Add Members here';
        this.createPopupDisplayConfig.name = 'groupTripNewMemberPopup';
        this.createPopupDisplayConfig.show = this.group.members.length === 1; //only show if no members
        this.popoverNewMember.show(this.createPopupConfig, this.createPopupDisplayConfig);

      } else if (event.detail.value === GroupTripSegment.Posts) {

        //if group exists then get posts
        if (this.group.exists) {
          this.getPosts()
            .then(() => {
              //set and show up popover config
              this.createPopupConfig.content = 'Create Posts here';
              this.createPopupDisplayConfig.name = 'groupTripNewPostPopup';
              this.createPopupDisplayConfig.show = this.posts.length === 0; //only show if no posts
              this.popoverNewPost.show(this.createPopupConfig, this.createPopupDisplayConfig);
            });
        }

      } else if (event.detail.value === GroupTripSegment.Events) {

        if (this.group.exists) {
          //set and show up popover config
          this.createPopupConfig.content = 'Create Events here';
          this.createPopupDisplayConfig.name = 'groupTripNewEventPopup';
          this.createPopupDisplayConfig.show = this.group.events.all.length === 0; //only show if no events
          this.popoverNewEvent.show(this.createPopupConfig, this.createPopupDisplayConfig);
        }

      } else if (event.detail.value === GroupTripSegment.Matches) {

        if (this.group.exists) {
          //set and show up popover config
          this.createPopupConfig.content = 'Create Matches here';
          this.createPopupDisplayConfig.name = 'groupTripNewMatchPopup';
          this.createPopupDisplayConfig.show = this.group.matches.active.length === 0; //only show if no matches
          this.popoverNewMatch.show(this.createPopupConfig, this.createPopupDisplayConfig);

          //not sure this is the best place to do this but...
          //this.group.matches.createTeams(0);

        }

      }

    }, 10);

  }

  private async updateMemberPreference(): Promise<void> {

    //update member preferences for group
    await this.accountService.member.updatePreference(this.memberGroupPreferences);

    /* return new Promise<void>((resolve) => {

      //update member preferences for group
      this.accountService
        .member
        .updatePreference(this.memberGroupPreferences)
        .then(() => {
          resolve();
        });

    }); */

  }

  memberAction(groupMember: AppGroupTripMember) {

    const options: any[] = [];

    //if this memeber not the group owner...
    if (groupMember.member.id !== this.group.ownerMemberId) {

      //remove member from group
      options.push({
        text: 'Remove from trip',
        role: 'destructive',
        handler: () => {
          this.removeMemberConfirm(groupMember);
        }
      });

      //allow resend if member isn't newly invited
      if (!this.group.isNewMember(groupMember)) {
        options.push({
          text: 'Resend trip invite',
          handler: () => {
            this.group.resendInviteEmail(groupMember);
          }
        });
      }

      //either make or remove admin
      if (this.group.isMemberAdmin(groupMember.member)) {

        options.push({
          text: 'Remove administrator status',
          role: 'destructive',
          handler: () => {
            this.removeAdmin(groupMember.member);
          }
        });

      } else {

        options.push({
          text: 'Make group administrator',
          handler: () => {
            this.makeAdmin(groupMember.member);
          }
        });

      }

    }

    options.push({
      text: 'Change trip attendance',
      handler: () => {
        this.changeTripStatusConfirm(groupMember);
      }
    });

    options.push({
      text: 'Cancel',
      role: 'cancel',
      handler: () => {
      }
    });

    //confirmation
    this.appFunction
      .actionCtrl
      .create({
        header: 'What would you like to do?',
        buttons: options
      })
      .then((action) => {
        action.present();
      });

  }

  private changeTripStatusConfirm(groupMember: AppGroupTripMember) {

    const options: any[] = [];

    options.push({
      text: "Yes, in",
      handler: () => {
        this.changeTripStatus(groupMember, TripAttendanceStatus.In);
      }
    });

    options.push({
      text: "No, out",
      handler: () => {
        this.changeTripStatus(groupMember, TripAttendanceStatus.Out);
      }
    });

    options.push({
      text: "Show as invited",
      handler: () => {
        this.changeTripStatus(groupMember, TripAttendanceStatus.Invited);
      }
    });

    options.push({
      text: 'Cancel',
      role: 'cancel',
      handler: () => {
      }
    });

    //confirmation
    this.appFunction
      .actionCtrl
      .create({
        header: 'What is the trip status for ' + groupMember.member.firstName + '?',
        buttons: options
      })
      .then((action) => {
        action.present();
      });

  }

  private changeTripStatus(groupMember: AppGroupTripMember, status: TripAttendanceStatus) {

    this.group.invited.changeTripStatus(groupMember, status);

    //set dirty
    this.groupForm.markAsDirty();

  }

  async eventScoring(click: Event, event: AppEvent) {

    //prevent the list item click
    click.stopPropagation();

    this.appFunction
      .modalCtrlCreate({
        component: EventScoringPage,
        presentingElement: await this.appFunction.routerOutlet(),
        cssClass: 'custom-modal', //for md
        backdropDismiss: false,
        canDismiss: true,
        componentProps: {
          event: event
        }
      })
      .then((modal) => {

        //pass in modal instance
        modal.componentProps.modal = modal;

        //open modal 
        modal
          .present()
          .catch((err) => {
            console.log('group-trip-detail.page.ts eventScoring modal present error', err);
          });

      })
      .catch((err) => {
        console.log('group-trip-detail.page.ts eventScoring modal create error', err);
      });

  }

  departureDtChange() {

    //if "number of trip days" has defined then update returnDt based on new deparetureDt
    if (this.tripDays) {
      this.groupForm.controls.returnDt.setValue(moment(this.groupForm.controls.departureDt.value).add(this.tripDays, 'days').toISOString());
      this.returnDt.reset(this.groupForm.controls.returnDt.value);
    }

  }

  returnDtChange() {

    //if departure dt is defined get the "number of trip days" between departure and return
    if (this.groupForm.controls.departureDt.value) {
      this.tripDays = moment(this.groupForm.controls.returnDt.value).diff(this.groupForm.controls.departureDt.value, 'days');
    }

  }

  get minReturnDate(): string {
    return this.groupForm.controls.departureDt.value ? (new Date(this.groupForm.controls.departureDt.value)).toISOString() : this.minDepartureDate;
  }

  openScorecard = (players: AppEventPlayer[], match: AppMatch = undefined) => {

    this.appFunction
      .modalCtrlCreate({
        component: GroupTripScorecardPage,
        enterAnimation: scorecardEnterFromRightAnimation,
        leaveAnimation: scorecardLeaveToRightAnimation,
        cssClass: 'rotate-tripscorecard-landscape',
        showBackdrop: false,
        componentProps: {
          match: match
        }
      })
      .then((modal) => {

        modal
          .present()
          .catch((err) => {
            console.log('group-trip-detail.page.ts openScorecard modal present error', err);
          });

      })
      .catch((err) => {
        console.log('group-trip-detail.page.ts openScorecard modal create error', err);
      });

  }

  //this a is a call back function, need to do it this way so "this" is in the context of the event-scoring page
  matchUpdate = (args: any): void => {
    //if match has been updated check to see if teams need to be created
    this.group.matches.createTeams(0);
  }

  private async save(): Promise<boolean> {

    try {

      //save the form if dirty or avatar has been updated
      if (this.groupForm.dirty) {

        //signal validation toast that form has been submitted
        this.isFormSubmitted = true;

        //save the form if valid 
        if (this.groupForm.valid) {

          //show loading
          const loading: HTMLIonLoadingElement = await this.appFunction.loadingCtrl.create({ message: 'Saving trip...' });
          loading.present();

          //update values
          this.group.name = this.groupForm.controls.name.value;
          this.group.description = this.groupForm.controls.description.value;
          this.group.numberOfPlayers = this.groupForm.controls.numberOfPlayers.value;
          this.group.departureDt = firebase.firestore.Timestamp.fromDate(moment(this.groupForm.controls.departureDt.value).startOf('day').toDate());
          this.group.returnDt = firebase.firestore.Timestamp.fromDate(moment(this.groupForm.controls.returnDt.value).startOf('day').toDate());

          //create or save group
          await this.group.save();

          //make sure member preference has the group id
          this.memberGroupPreferences.key = this.group.id;

          //save avatar if it has been updated
          if (this.groupForm.controls['avatarFileURI'].dirty) {
            this.group.avatar.save(this.localAvatarURL);
          }

          //save cover if it has been updated
          if (this.groupForm.controls['coverFileURI'].dirty) {
            this.group.cover.save(this.localCoverURL);
          }

          //if group is new then...
          if (this.isNew) {
            this.group.sendNewGroupEmail(this.accountService.member);
          }

          //dismissing loading
          loading.dismiss();

          return true;

        } else {
          //show any untouched errors
          this.appFunction.setDirtyControlAsTouched(this.groupForm);
          return false;
        }

      } else {
        //no updates
        return true;
      }

    } catch (err) {
      console.log('group-trip-detail.page.ts save error', err);
      return false;
    }

    /* return new Promise<void>((resolve, reject) => {

      try {

        //save the form if dirty or avatar has been updated
        if (this.groupForm.dirty) {

          //signal validation toast that form has been submitted
          this.isFormSubmitted = true;

          //save the form if valid 
          if (this.groupForm.valid) {

            this.appFunction
              .loadingCtrl
              .create({ message: 'Saving group...' })
              .then((loading) => {

                loading.present();

                //update values
                this.group.name = this.groupForm.controls.name.value;
                this.group.description = this.groupForm.controls.description.value;
                this.group.numberOfPlayers = this.groupForm.controls.numberOfPlayers.value;
                this.group.departureDt = firebase.firestore.Timestamp.fromDate(moment(this.groupForm.controls.departureDt.value).startOf('day').toDate());
                this.group.returnDt = firebase.firestore.Timestamp.fromDate(moment(this.groupForm.controls.returnDt.value).startOf('day').toDate());

                //create or save group
                this.group
                  .save()
                  .then((group) => {

                    //make sure member preference has the group id
                    this.memberGroupPreferences.key = group.id;

                    //save the array of promises
                    const promiseArray: any[] = [];

                    //save avatar if it has been updated
                    if (this.groupForm.controls['avatarFileURI'].dirty) {

                      const p = this.group
                        .avatar
                        .save(this.localAvatarURL)
                        .catch((err) => {
                          console.log('group-trip-detail.page.ts save saveAvatar error', err, JSON.stringify(err));
                        });

                      promiseArray.push(p);

                    }

                    //save cover if it has been updated
                    if (this.groupForm.controls['coverFileURI'].dirty) {

                      const p = this.group
                        .cover
                        .save(this.localCoverURL)
                        .catch((err) => {
                          console.log('group-trip-detail.page.ts save cover error', err, JSON.stringify(err));
                        });

                      promiseArray.push(p);

                    }

                    //if group is new then...
                    if (this.isNew) {
                      this.group.sendNewGroupEmail(this.accountService.member);
                    }

                    //wait for all promises to return
                    Promise
                      .all(promiseArray)
                      .then(() => {
                        loading.dismiss();
                        resolve();
                      })
                      .catch((err) => {
                        console.log('group-trip-detail.page.ts done promise.all error', err, JSON.stringify(err));
                        reject();
                      });

                  })
                  .catch((err) => {
                    loading.dismiss();
                    console.log('group-trip-detail.page.ts group save error', err, JSON.stringify(err));
                    reject();
                  });

              });

          } else {
            //show any untouched errors
            this.appFunction.setDirtyControlAsTouched(this.groupForm);
            reject();
          }

        } else {
          //no updates
          resolve();
        }

      } catch (err) {
        console.log('group-trip-detail.page.ts save error', err);
        reject();
      }

    }); */

  }

  private congrats() {

    //plane shape
    const plane = confetti.shapeFromPath({
      path: 'M 511.06 286.261 c -0.387 -10.849 -7.42 -20.615 -18.226 -25.356 l -193.947 -74.094 C 298.658 78.15 285.367 3.228 256.001 3.228 c -29.366 0 -42.657 74.922 -42.885 183.583 L 19.167 260.904 C 8.345 265.646 1.33 275.412 0.941 286.261 L 0.008 311.97 c -0.142 3.886 1.657 7.623 4.917 10.188 c 3.261 2.564 7.597 3.684 11.845 3.049 c 0 0 151.678 -22.359 198.037 -29.559 c 1.85 82.016 4.019 127.626 4.019 127.626 l -51.312 24.166 c -6.046 2.38 -10.012 8.206 -10.012 14.701 v 9.465 c 0 4.346 1.781 8.505 4.954 11.493 c 3.155 2.987 7.403 4.539 11.74 4.292 l 64.83 -3.667 c 2.08 14.436 8.884 25.048 16.975 25.048 c 8.091 0 14.877 -10.612 16.975 -25.048 l 64.832 3.667 c 4.336 0.246 8.584 -1.305 11.738 -4.292 c 3.174 -2.988 4.954 -7.148 4.954 -11.493 v -9.465 c 0 -6.495 -3.966 -12.321 -10.012 -14.701 l -51.329 -24.166 c 0 0 2.186 -45.61 4.037 -127.626 c 46.358 7.2 198.036 29.559 198.036 29.559 c 4.248 0.635 8.602 -0.485 11.845 -3.049 c 3.261 -2.565 5.041 -6.302 4.918 -10.188 L 511.06 286.261 Z',
      //matrix: [0.020491803278688523, 0, 0, 0.020491803278688523, -7.172131147540983, -5.9016393442622945]
    });

    //flag shape
    const flag = confetti.shapeFromPath({
      path: 'M 295.475 255.203 c -27.271 -5.469 -62.153 -8.807 -99.657 -9.601 V 132.575 V 32.291 V 11.725 L 29.803 72.149 l 131.016 47.688 v 125.767 c -37.503 0.794 -72.385 4.132 -99.656 9.601 C 32.686 260.913 0 270.615 0 295.164 s 32.686 34.251 61.162 39.961 c 31.473 6.312 73.079 9.787 117.156 9.787 c 44.077 0 85.684 -3.475 117.157 -9.787 c 28.476 -5.71 61.162 -15.412 61.162 -39.961 S 323.951 260.913 295.475 255.203 Z',
      //matrix: [0.03333333333333333, 0, 0, 0.03333333333333333, -5.566666666666666, -5.533333333333333]
    });

    const defaults = {
      scalar: 4,
      particleCount: 100,
      spread: 30,
      startVelocity: 70,
      origin: { y: 1 }
    };

    confetti({
      ...defaults,
      shapes: [plane],
      colors: ['#0d47a1', '#01bfff']
    });

    confetti({
      ...defaults,
      shapes: [flag],
      colors: ['#048f45', '#98eb78']
    });

  }

  done() {

    //save group
    this
      .save()
      .then((close) => {

        //if saved then...
        if (close) {

          //reset dirty flag
          this.groupForm.markAsPristine();

          //save organizer's preferences
          if (this.group.exists && this.memberGroupPreferences.dirty) {
            this.updateMemberPreference();
          }

          //if the trip is new then don't dismiss the modal, if it is an update then dismiss
          if (this.isNew) {

            this.isNew = false;

            //show confetti
            this.congrats();

            //show congratulations modal
            this.congratulations.isOpen = true;

          }

        }

      });

  }

  cancel() {

    //confirm that user wants to discard changes
    if (this.groupForm.dirty) {

      this.appFunction
        .alertCtrl
        .create({
          header: 'Discard changes?',
          message: 'You have made changes to your Group. Do you want to discard these changes?',
          buttons: [
            {
              text: 'No',
              handler: () => {
              }
            },
            {
              text: 'Yes, discard',
              handler: () => {
                //reset group
                this.group.reset();

                //close
                this.appFunction.modalCtrl.dismiss();
              }
            }
          ]
        })
        .then((alert) => {
          alert.present();
        });

    } else {
      this.appFunction.modalCtrl.dismiss();
    }

  }

}