import { Component, OnInit } from '@angular/core';
import { NavParams } from '@ionic/angular';
import { AppFunction } from '../../app.function';
import { AppEvent, AppEventPlayer } from '../../app.event';
import { AppMatch, MatchPlayerI } from '../../app.match';
import { GameType, HandicapAllowance, HandicapType } from 'src/app/app.config';

@Component({
  selector: 'event-scorecard',
  templateUrl: './event-scorecard.page.html',
  styleUrls: ['./event-scorecard.page.scss']
})
export class EventScorecardPage implements OnInit {

  players: MatchPlayerI[];
  match: AppMatch;
  event: AppEvent;
  HandicapType: typeof HandicapType = HandicapType;
  HandicapAllowance: typeof HandicapAllowance = HandicapAllowance;
  GameType: typeof GameType = GameType;
  selectedScorecard: string;

  constructor(
    public appFunction: AppFunction,
    public navParams: NavParams
  ) {

  }

  ngOnInit() {

    //get passed in data
    this.players = <AppEventPlayer[]>this.navParams.get('players');
    this.match = <AppMatch>this.navParams.get('match'); //match only passed in when displaying the scorecard for a match  
    this.event = <AppEvent>this.navParams.get('event');

    //show match live view if match is passed in, else show player scorecard
    this.selectedScorecard = this.match ? 'matchLiveView' : 'playerScorecard';

  }

  get activePlayers(): MatchPlayerI[] {

    //return players that have their info confirmed and have their nines setup
    return this.players.filter((player) => {
      return player.infoConfirmed && player.nines;
    });

  }

  get isTeams(): boolean {
    return this.match?.subMatches[0].teams.length >= 2;
  }

  teamId(index, item) {
    return item.team.id;
  }

  done() {
    //close screen
    this.appFunction.modalCtrl.dismiss();
  }

}
