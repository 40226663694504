<ion-header class="team-popover-header ion-no-border">

	<ion-toolbar>

		<ion-title>Build Team</ion-title>

		<ion-buttons slot="secondary">
			<ion-button fill="solid" shape="round" color="light" (click)="cancel()">Not Right
				Now</ion-button>
		</ion-buttons>

		<ion-buttons slot="primary">
			<ion-button *ngIf="isDirty" fill="solid" shape="round" (click)="createTeams()">Save</ion-button>
		</ion-buttons>

	</ion-toolbar>

</ion-header>

<ion-content class="team-popover-content ion-padding" forceOverscroll="false">

	<h3 style="margin-top: 0;">
		<ion-text>{{subMatch?.name}}</ion-text>
	</h3>

	<div>
		<ion-text>This match requires {{this.numberOfPlayersPerTeam}} player teams. Please drag the players to the
			desired team.</ion-text>
	</div>

	<!-- <div id="team-popover-content"> -->

	<div #dragulaScroll id="dragulaScroll" class="ion-padding">

		<!-- drop/destination containers -->
		<div class="playerContainer" *ngFor="let team of teams; let teamNumber = index;">

			<ion-label>Team {{(teamNumber+1).toString()}}</ion-label>

			<!-- we had to use _players here because dragulaModel doesn't work with the getter "all" -->
			<ion-list [dragula]="bagName" [dragulaModel]="team.players._players">

				<ion-item delayDragLift class="doDragTeamPlayer" *ngFor="let player of team.players.all;">
					<avatar [member]="player.member" slot="start" style="margin-right: 5px;"></avatar>
					<ion-label>
						<h2>{{player.firstName}} {{player.lastName}}</h2>
						<h4 *ngIf="player.teeHandicapDisplay()">Tee Handicap {{player.teeHandicapDisplay()}}</h4>
					</ion-label>
				</ion-item>

				<ion-item *ngIf="team.players.all?.length < this.numberOfPlayersPerTeam"
					style="margin-top: 5px; border: 1px dashed;">
					<ion-label style="padding-left: 5px;">Drop players here</ion-label>
				</ion-item>

			</ion-list>

		</div>

		<!-- source container, players without a team -->
		<div class="playerContainer" *ngIf="playersWithoutTeam && playersWithoutTeam.length > 0">
			<ion-label>Drag players to a team</ion-label>
			<ion-list [dragula]="bagName" [dragulaModel]="playersWithoutTeam">
				<ion-item delayDragLift class="doDragTeamPlayer" *ngFor="let player of playersWithoutTeam;">
					<avatar #avatar [member]="player.member" slot="start" style="margin-right: 5px;"></avatar>
					<ion-label>
						<h2>{{player.firstName}} {{player.lastName}}</h2>
						<h4 *ngIf="player.teeHandicapDisplay()">Tee Handicap {{player.teeHandicapDisplay()}}</h4>
					</ion-label>
				</ion-item>
			</ion-list>
		</div>

	</div>

	<!-- </div> -->

</ion-content>

<!-- <ion-footer class="ion-padding ion-no-border" style="padding-top: 0;">
	<ion-button expand="full" fill="solid" shape="round" (click)="createTeams();">Done</ion-button>
	<a (click)="cancel();" style="display: block; text-align: center; margin-top: 10px;">Not right now</a>
</ion-footer> -->