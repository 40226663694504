import { Injectable, Inject, Renderer2, RendererFactory2, Injector, NgZone } from "@angular/core";
import { createAnimation, ModalOptions } from "@ionic/core";
import { trigger, state, style, transition, animate, keyframes } from "@angular/animations";
import { Platform, LoadingController, ModalController, ActionSheetController, AlertController, ToastController, PopoverController, AnimationController } from "@ionic/angular";
import { FormGroup, FormControl, ValidatorFn } from "@angular/forms";
import { AppConfig, FirestoreUpdateType } from "./app.config";
import { AngularFirestore } from "@angular/fire/compat/firestore";
import { AngularFireStorage } from "@angular/fire/compat/storage";
import { HttpClient } from "@angular/common/http";
import { AngularFireAuth } from "@angular/fire/compat/auth";
import { memberPreferenceI, AccountService, AppMember, AppMessageType, AppMessageStatus, AppMessageI } from "./app.account";
import { Diagnostic } from "@ionic-native/diagnostic/ngx";
import { ActivatedRoute } from "@angular/router";
import firebase from "firebase/compat/app";
import { DOCUMENT } from "@angular/common";
import tinycolor from "tinycolor2";
import * as compareVersion from "compare-versions";
import { Subject, BehaviorSubject, Observable } from "rxjs";
import { Market } from "@ionic-native/market/ngx";
import { HelpViewPage } from "./pages/help-view/help-view.page";
import { AppRate } from "@awesome-cordova-plugins/app-rate/ngx";
import { Storage } from "@ionic/storage";
import { PushNotifications } from "@capacitor/push-notifications";
import { Device } from "@capacitor/device";
import { Haptics, ImpactStyle } from "@capacitor/haptics";
import { FCM } from "@capacitor-community/fcm";
import { distinctUntilChanged } from "rxjs/operators";
import { App } from "@capacitor/app";
import { AppUpdatePage } from "./pages/app-update/app-update.page";
import { AppGroupI } from "./app.group";
import { AppEvent } from "./app.event";
import { WhatsNewPage } from "./pages/whats-new/whats-new.page";
import { DeepLinkOpen, FirebaseDynamicLinks, LinkConfig, SocialMetaTagParameters } from '@pantrist/capacitor-firebase-dynamic-links';
import { environment } from '../environments/environment';
import { PluginListenerHandle } from "@capacitor/core";
import * as SentryAngular from "@sentry/angular-ivy";
import { ScreenOrientation, OrientationLockOptions } from '@capacitor/screen-orientation';
import { DeeplinkWelcomeMessagePage } from "./pages/deeplink-welcome-message/deeplink-welcome-message.page";
import { SocialService } from "./app.social";
import posthog, { CaptureOptions, Properties } from 'posthog-js';

export class ChatMessageType {
  public static readonly MSG_REQUEST: string = "message_request";
  public static readonly MSG_RESPONSE: string = "message_response"
}

export interface ChatMessageI {
  type: ChatMessageType;
  message: string;
}

export interface DeepLinkParmsI {
  route: string,
  page: string,
  id: string,
  segment: string,
  actionCd: number,
  actionCdMessage: string,
  email: string,
  welcomeMessage: string,
  emailHasAccount: boolean,
  additionalData: any
}

export enum DeepLinkStatus {
  sent = 0,
  visited = 1
}

export enum PublishSubscribeTopics {
  UpdateEventPlayerScore = 'UpdateEventPlayerScore',
}

export enum DeepLinkCampaign {
  TripInvite = 'TripInvite',
  EventInvite = 'EventInvite',
  GroupMemberAdded = 'GroupMemberAdded',
  EventMemberJoined = 'EventMemberJoined',
  EventMemberDropped = 'EventMemberDropped',
  NewlyRegisteredMember = 'NewlyRegisteredMember',
  NewGroup = 'NewGroup',
}

export enum DeepLinkChannel {
  email = 'email',
  push = 'push'
}

export interface DeepLinkI {
  createdDt: firebase.firestore.Timestamp,
  status: DeepLinkStatus,
  parms: DeepLinkParmsI,
  campaign: DeepLinkCampaign,
}

export interface AppKeyValueI {
  key: string;
  value: object;
}

export interface AppRemoteConfigurationI {
  minimumVersion: string;
  latestVersion: string;
  newVersionMessage: string;
  maintenance: boolean;
  maintenanceMessage: string;
  firebaseLogLevel: firebase.firestore.LogLevel;
}

export interface AppUpdateI {
  appUpdateType: AppUpdateType;
  appUpdateMessage: string;
}

export interface AppClassI {
  class: string;
}

export enum AppUpdateType {
  /**
   * A mandatory update is required
   */
  MANDATORY,

  /**
   * An optional update is available
   */
  OPTIONAL,

  /**
   * Nothing to see here
   */
  NOP,
}

@Injectable({
  providedIn: "root",
})
export class AppFunction {

  public remoteConfigurations: Subject<AppRemoteConfigurationI> = new Subject<AppRemoteConfigurationI>();
  public shutDown: Subject<void> = new Subject<void>();
  public firestore: firebase.firestore.Firestore;
  public firestorage: firebase.storage.Storage;
  public helpContent: any = {};
  public teeColor: any = {};
  static openProfiles: number = 0; //count of open profile-summary pages
  static serviceLocator: Injector;
  private _locationStatus = new BehaviorSubject<string>(undefined);
  private _remoteConfigurations: AppRemoteConfigurationI;
  private _queuedToasts: any[] = [];
  private _toast: HTMLIonToastElement = undefined;
  private _unsubscribe: any[] = [];

  constructor(
    public alertCtrl: AlertController,
    public actionCtrl: ActionSheetController,
    public toastCtrl: ToastController,
    public modalCtrl: ModalController,
    public loadingCtrl: LoadingController,
    private angularFirestore: AngularFirestore,
    private angularFireStorage: AngularFireStorage,
    public http: HttpClient,
    public afAuth: AngularFireAuth,
    public platform: Platform,
    public diagnostic: Diagnostic,
    public activatedRoute: ActivatedRoute,
    public popoverCtrl: PopoverController,
    public market: Market,
    public avatarViewService: AvatarViewService,
    public animateSplashService: AnimateSplashService,
    public ngZone: NgZone,
    public injector: Injector,
    public _appRate: AppRate,
    public storage: Storage,
    public animationCtrl: AnimationController,
  ) {

    console.log('app.function.ts AppFunction constructor');

    //set global injector
    AppFunction.serviceLocator = injector;

    //set firestore references
    this.firestore = angularFirestore.firestore;
    this.firestorage = angularFireStorage.storage;

    //listen for location status changes
    this.platform
      .ready()
      .then(() => {

        if (this.platform.is('capacitor')) {
          this.diagnostic.registerLocationStateChangeHandler((status: string) => {
            console.log("app.function.ts constructor registerLocationStateChangeHandler", status);
            this._locationStatus.next(status);
          });
        }

      });

    //clean up
    this.shutDown
      .subscribe(() => {

        console.log("app.function.ts AppFunction shutdown");

        //reset
        AppFunction.openProfiles = 0;

        //interate through unsubscribe array
        let unsubscribeCount: number = 0;
        this._unsubscribe.forEach((unsubscribe) => {
          if (typeof unsubscribe === "function") {
            //console.log('unsubscribe is function', unsubscribe);

            //unsubscribe
            unsubscribe();
            unsubscribeCount++;
          } else if (typeof unsubscribe.unsubscribe !== "undefined") {
            //console.log('unsubscribe has a function', unsubscribe);

            //unsubscribe
            unsubscribe.unsubscribe();
            unsubscribeCount++;
          } else {
            console.log("app.function.ts AppFunction shutdown. unsubscribe is recognised", unsubscribe);
          }
        });

        //clear unsubscribe
        this._unsubscribe = [];

        console.log('app.function.ts AppFunction shutdown. ' + unsubscribeCount.toString() + ' unsubscribes were executed.');

      });

  }

  setFirebaseLogLevel(level: firebase.firestore.LogLevel) {
    firebase.firestore.setLogLevel(level);
  }

  modalCtrlCreate(opts: ModalOptions): Promise<HTMLIonModalElement> {

    return new Promise(async (resolve, reject) => {

      //get the current top modal
      const topModal: HTMLIonModalElement = await this.modalCtrl.getTop();

      //check if modal is already open
      if (topModal?.component.valueOf()['name'] === opts.component.valueOf()['name']) {
        reject('app.function.ts AppFunction modalCtrlCreate modal already open' + opts.component.valueOf()['name']);
      } else {
        resolve(this.modalCtrl.create(opts));
      }

    });

  }

  postHogCapture(event_name: string, member?: AppMember, properties?: Properties, options?: CaptureOptions) {

    //if properties is not defined then create it
    if (!properties) {
      properties = {};
    }

    //add environment to properties
    properties['environment'] = environment.POSTHOG_CONFIG.environment;
    properties['app_version'] = AppConfig.APP_VERSION;
    properties['member_id'] = member.id;
    properties['member_email'] = member.email;

    //capture event
    posthog.capture(event_name, properties, options);

  }

  arrayIntersection(a: any[], b: any[]): any[] {
    return a.filter((value) => b.includes(value));
  }

  //this method overrides the % operator which doesn't handle negative numbers
  mod(n, m) {
    return ((n % m) + m) % m;
  }

  getJSONProperty(object: any, property: string | number, propertyAttributes: object = undefined): object {

    //if property doesn't exist then create it
    if (!object.hasOwnProperty(property)) {
      if (propertyAttributes) {
        object[property] = propertyAttributes;
      }
    }

    //return object
    return object[property];

  }

  convertJSONToArray(object: object): any[] {
    return Object.values(object);
  }

  convertArrayToJSON(array: any[], property: string): object {

    const jsonObject = {};

    array.forEach(arrayItem => {
      jsonObject[arrayItem[property].id] = arrayItem;
    });

    return jsonObject;

  }

  getUserAuthProviders(email: string): Promise<any> {

    return new Promise<any>((resolve, reject) => {

      //data to send to cloud function
      const data = { email: email };

      //delete member auth record from firebase
      this.http
        .post(AppConfig.CLOUD_FUNCTIONS.getUserAuthProviders, data, { headers: { 'Access-Control-Allow-Origin': AppConfig.CLOUD_FUNCTIONS.getUserAuthProviders } })
        .toPromise()
        .then((userRecord) => {
          console.log('app.function.ts AppFunction getUserAuthProviders success', userRecord);
          resolve(userRecord);
        })
        .catch((err) => {
          console.log('app.function.ts AppFunction getUserAuthProviders post error', err);
          reject();
        });

    });

  }

  getMemberByEmail(email: string): Promise<any> {

    return new Promise<any>((resolve, reject) => {

      //data to send to cloud function
      const data = { email: email };


      this.http
        .post(AppConfig.CLOUD_FUNCTIONS.getMemberByEmail, data, { headers: { 'Access-Control-Allow-Origin': AppConfig.CLOUD_FUNCTIONS.getMemberByEmail } })
        .toPromise()
        .then((memberDocument) => {
          console.log('app.function.ts AppFunction getMemberByEmail success', memberDocument);
          resolve(memberDocument);
        })
        .catch((err) => {
          console.log('app.function.ts AppFunction getMemberByEmail post error', err);
          reject();
        });

    });

  }

  castToAppGroupI(appGroupTrip: any): AppGroupI {
    return <AppGroupI>appGroupTrip;
  }

  castToAppEvent(appEvent: any): AppEvent {
    return <AppEvent>appEvent;
  }

  registerUnsubscribe(unsubscribe) {
    this._unsubscribe.push(unsubscribe);
  }

  setDirtyControlAsTouched(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach((field) => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.setDirtyControlAsTouched(control);
      }
    });
  }

  setValidators(
    formGroup: FormGroup,
    formControl: string,
    validators: ValidatorFn | ValidatorFn[] = undefined
  ) {
    //remove validators
    formGroup.get(formControl).clearValidators();

    //if new validations were passed in then set
    if (validators) {
      formGroup.get(formControl).setValidators(validators);
    }

    //update
    formGroup.get(formControl).updateValueAndValidity();
  }

  sendEmail(request: any): Promise<void> {

    return new Promise<void>((resolve, reject) => {

      const data = {
        request: request,
      };

      //get service
      const accountService: AccountService = AppFunction.serviceLocator.get(AccountService);
      accountService.user
        .getIdToken()
        .then((token) => {

          this.http
            .post(AppConfig.CLOUD_FUNCTIONS.sendEmail, data, {
              headers: { Authorization: "Bearer " + token },
            })
            .toPromise()
            .then(() => {
              resolve();
            })
            .catch((err) => {
              console.log("app.function.ts sendEmail post error", err);
              reject();
            });

        })
        .catch((err) => {
          console.log("app.function.ts sendEmail currentUser error", err);
          reject();
        });

    });

  }

  sendNotification(
    memberId: string, //this is who is sending the push notification
    memberIds: string[], //this is who is to receive the push notification
    title: string,
    body: string,
    imageURL: string,
    extras: object,
    url: string = undefined): Promise<void> {

    return new Promise<void>((resolve, reject) => {

      //only send if there are any members that want push notifications
      if (memberIds.length > 0) {

        const data = {
          request: {
            title: title,
            body: body,
            imageURL: imageURL,
            memberIds: memberIds,
            url: url,
            memberId: memberId,
            extras: extras,
          },
        };

        //get service
        const accountService: AccountService = AppFunction.serviceLocator.get(AccountService);
        accountService.user
          .getIdToken()
          .then((token) => {
            this.http
              .post(AppConfig.CLOUD_FUNCTIONS.sendNotification, data, {
                headers: { Authorization: "Bearer " + token },
              })
              .toPromise()
              .then(() => {
                resolve();
              })
              .catch((err) => {
                console.log('app.function.ts sendNotification error', err);
                SentryAngular.captureMessage('app.function.ts sendNotification error: ' + err, 'error');
                reject();
              });
          })
          .catch((err) => {
            console.log('app.function.ts sendNotification getIdToken error', err);
            reject();
          });

      } else {
        //no op
        resolve();
      }
    });
  }

  deleteUserAuth(member: AppMember): Promise<void> {

    return new Promise<void>((resolve, reject) => {

      const data = { email: member.email };

      //call self delete member cloud function
      const accountService: AccountService = AppFunction.serviceLocator.get(AccountService);
      accountService.user
        .getIdToken()
        .then((token) => {

          //delete member auth record from firebase
          this.http
            .post(AppConfig.CLOUD_FUNCTIONS.deleteUserAuth, data, {
              headers: { Authorization: "Bearer " + token },
            })
            .toPromise()
            .then(() => {
              resolve();
            })
            .catch((err) => {
              console.log('app.function.ts AppFunction deleteUserAuth error', err);
              reject();
            });

        })
        .catch((err) => {
          console.log('app.function.ts AppFunction deleteUserAuth getIdToken error', err);
          reject();
        });

    });

  }

  memberSelfDelete(member: AppMember): Promise<void> {

    return new Promise<void>((resolve, reject) => {

      //these will be used in the confirmation email once member has been deleted 
      const email: string = member.email;
      const firstName: string = member.firstName;
      const lastName: string = member.lastName;
      const personalizations = [];

      //show spinner
      this.loadingCtrl
        .create({ message: 'Deleting profile...' })
        .then((loading) => {

          loading.present();

          //first delete auth record...
          this.deleteUserAuth(member)
            .then(() => {

              //...then delete member avatar
              const accountService: AccountService = AppFunction.serviceLocator.get(AccountService);
              accountService.member
                .avatar
                .delete(false)
                .then(() => {

                  //...then set member data (we still need member doc for old events etc.)
                  accountService.member.email = 'Deleted';
                  accountService.member.firstName = 'Deleted';
                  accountService.member.lastName = 'Deleted';
                  accountService.member.avatarFileName = '';

                  //...then save member
                  accountService.member
                    .save()
                    .then(() => {

                      //now remove member from any groups
                      accountService.member
                        .groups
                        .all
                        .forEach((group) => {

                          group.removeMemberFromGroup(accountService.member);

                          //now save
                          const batch: firebase.firestore.WriteBatch = this.firestore.batch();
                          group.save(batch)
                            .then(() => {
                              batch.commit();
                              console.log('app.function.ts AppFunction memberSelfDelete member removed from group');
                            });

                        });

                      //configure member's delete profile confirmation email
                      personalizations.push({
                        "subject": 'Double Ace Group Profile Delete Confirmation',
                        "templateId": AppConfig.SENDGRID_TEMPLATES.MemberDeleteConfirmation,
                        "to": {
                          "name": firstName + ' ' + lastName,
                          "email": email
                        },
                        "from": {
                          "name": 'Double Ace Golf (no reply)',
                          "email": AppConfig.NOREPLY_EMAIL
                        },
                        "replyTo": {
                          "name": 'Double Ace Golf Support',
                          "email": AppConfig.SUPPORT_EMAIL
                        },
                        "dynamic_template_data": {
                          "subject": 'Double Ace Group Profile Delete Confirmation',
                          "firstName": firstName
                        },
                        "hideWarnings": true
                      });

                      //configure DAG's support delete profile notification email
                      personalizations.push({
                        "subject": 'Double Ace Group Profile Delete Notification',
                        "templateId": AppConfig.SENDGRID_TEMPLATES.MemberDeleteConfirmationSupport,
                        "to": {
                          "name": 'Double Ace Golf Support',
                          "email": AppConfig.SUPPORT_EMAIL
                        },
                        "from": {
                          "name": 'Double Ace Golf (no reply)',
                          "email": AppConfig.NOREPLY_EMAIL
                        },
                        "replyTo": {
                          "name": 'Double Ace Golf Support',
                          "email": AppConfig.SUPPORT_EMAIL
                        },
                        "dynamic_template_data": {
                          "subject": 'Double Ace Group Profile Delete Notification',
                          "firstName": firstName,
                          "lastName": lastName,
                          "email": email
                        },
                        "hideWarnings": true
                      });

                      //send email
                      this.sendEmail(personalizations)
                        .then(() => {
                          //return
                          loading.dismiss();
                          resolve();
                        });

                    });

                });

            })
            .catch((err) => {
              console.log('app.function.ts AppFunction memberSelfDelete deleteUserAuth error', err);
              reject();
            });

        });

    });

  }

  adminDeleteMember(member: AppMember) {

    try {

      //create batch transaction
      const batch: firebase.firestore.WriteBatch = this.firestore.batch();

      this.loadingCtrl
        .create({})
        .then((loading) => {

          loading.present();

          //delete member document
          member.delete(batch);

          //array of promises
          const promiseArray: any[] = [];

          //remove member from groups
          member
            .groups
            .all
            .forEach((group) => {

              //remove member from group
              group.removeMemberFromGroup(member);

              const p = group
                .save(batch)
                .then(() => {
                  console.log('member-detail.page.ts remove member from group success (3)', group.name);
                });

              promiseArray.push(p);

            });

          //remove member from active joined events
          /* member TODO
            .events
            .forEach((event) => {
 
              const p = event
                .players
                .delete(batch, member.id)
                .then(() => {
                  console.log('member-detail.ts remove member from event success (4)', event.eventDt);
                });
 
              promiseArray.push(p);
 
            });*/

          const socialService: SocialService = AppFunction.serviceLocator.get(SocialService);

          //delete member posts
          const q = socialService
            .deleteUserPosts(batch, member.id)
            .then(() => {
              console.log('member-detail.page.ts delete user posts success (5)');
            });

          promiseArray.push(q);

          //delete member post comments
          const r = socialService
            .deleteUserPostComments(batch, member.id)
            .then(() => {
              console.log('member-detail.page.ts deleteUserPostComments success (6)');
            });

          promiseArray.push(r);

          //delete member post likes
          const s = socialService
            .deleteUserPostLikes(batch, member.id)
            .then(() => {
              console.log('member-detail.page.ts deleteUserPostLikes success (7)');
            });

          promiseArray.push(s);

          //delete following
          const t = socialService
            .deleteUserFollowing(batch, member.id)
            .then(() => {
              console.log('member-detail.page.ts deleteUserFollowing success (8)');
            });

          promiseArray.push(t);

          //delete followed
          const u = socialService
            .deleteUserFollowed(batch, member.id)
            .then(() => {
              console.log('member-detail.page.ts deleteUserFollowed success (9)');
            });

          promiseArray.push(u);

          Promise
            .all(promiseArray)
            .then(() => {

              console.log('member-detail.page.ts deleteMember all promises returned (10)');

              batch
                .commit()
                .then(() => {

                  console.log('member-detail.page.ts deleteMember batch commit success (11)');

                  //if email is no longer in members collection, then delete from auth
                  this.firestore
                    .collection(AppConfig.COLLECTION.Members)
                    .where('email', '==', member.email)
                    .get()
                    .then((querySnapshot) => {

                      //if there are no members with this email, then delete from auth
                      if (querySnapshot.size === 0) {

                        this.deleteUserAuth(member)
                          .then(() => {
                            console.log('member-detail.page.ts deleteUserAuth auth delete success (12)');
                          })
                          .catch((err) => {
                            console.log('member-detail.page.ts deleteUserAuth auth delete error (12)', err);
                          });

                      }

                    });

                  //create message document to force logoff of deleted member
                  this.firestore
                    .collection(AppConfig.COLLECTION.Messages)
                    .add(<AppMessageI>{
                      memberId: member.id,
                      type: AppMessageType.Logoff, //force logoff for member.id
                      status: AppMessageStatus.Active,
                      updatedDt: firebase.firestore.FieldValue.serverTimestamp(),
                      createdDt: firebase.firestore.FieldValue.serverTimestamp()
                    })
                    .then(() => {
                      console.log('member-detail.page.ts add message document to force logoff success (12)');
                    }).
                    catch((err) => {
                      console.log('member-detail.page.ts add message document to force logoff error (12)', err);
                    });

                  //close page
                  loading.dismiss();
                  this.modalCtrl.dismiss();

                })
                .catch((err) => {
                  console.log('member-detail.page.ts deleteMember batches error (10)', err);
                });

            })
            .catch((err) => {
              console.log('member-detail.page.ts deleteMember all promises error', err);
            });

        });

    } catch (err) {
      console.log('app.function.ts adminDeleteMember error', err);
    }


  }

  revokeUserAuth(member: AppMember): Promise<void> {

    //console.log('app.function.ts revokeUserAuth');

    return new Promise<void>((resolve, reject) => {

      //these will be used in the confirmation email once member has been deleted 
      const data = { email: member.email };

      //call auth revoke member cloud function
      const accountService: AccountService = AppFunction.serviceLocator.get(AccountService);
      accountService.user
        .getIdToken()
        .then((token) => {

          //revoke auth token from firebase
          this.http
            .post(AppConfig.CLOUD_FUNCTIONS.revokeUserAuth, data, {
              headers: { Authorization: "Bearer " + token },
            })
            .toPromise()
            .then(() => {
              resolve();
            })
            .catch((err) => {
              console.log('app.function.ts AppFunction revokeUserAuth post error', err);
              reject();
            });

        })
        .catch((err) => {
          console.log('app.function.ts AppFunction revokeUserAuth error', err);
          reject();
        });

    });

  }

  buzz() {
    //console.log('app.function.ts buzz');

    if (this.platform.is('capacitor')) {
      Haptics.impact({ style: ImpactStyle.Medium });
    }
  }

  async getDeviceInformation() {
    return await Device.getInfo();
  }

  async getDeviceId() {
    return await Device.getId();
  }

  passwordsMatch(group: FormGroup): any {
    const password = group.controls.password;
    const confirm = group.controls.confirmPassword;

    // Don't kick in until user touches both fields
    if (password.pristine || confirm.pristine) {
      return null;
    }

    // Mark group as touched so we can add invalid class easily
    group.markAsTouched();

    if (password.value === confirm.value) {
      return null;
    }

    return {
      passwordsMatch: true,
    };
  }

  decomposeObjectToArray(object: object): AppKeyValueI[] {
    const objectArray = [];

    //split object into an array of objects
    if (object) {
      Object.keys(object).forEach((key) => {
        objectArray.push({ key: key, value: object[key] });
      });
    }

    return objectArray;
  }

  decomposeMemberPreference(object: object): memberPreferenceI[] {
    const objectArray = [];

    //split object into an array of objects
    if (object) {
      Object.keys(object).forEach((key) => {
        objectArray.push({ key: key, value: object[key] });
      });
    }

    return objectArray;
  }

  getRemoteNotificationsStatus(): Promise<boolean> {

    return new Promise<boolean>((resolve, reject) => {

      //only check this is on mobile
      if (this.platform.is('mobile')) {

        this.diagnostic
          .isRemoteNotificationsEnabled()
          .then((isEnabled) => {
            console.log(
              "app.function.ts getRemoteNotificationsStatus",
              isEnabled
            );

            //notification have been enabled on the phone\or maybe it is for this app
            if (isEnabled) {
              //if ios check to be sure the device has been registered. this should always be true based on the call to firebase's FCM
              if (this.platform.is('ios')) {
                this.diagnostic
                  .isRegisteredForRemoteNotifications()
                  .then((isRegistered) => {
                    console.log(
                      "isRegisteredForRemoteNotifications",
                      isRegistered
                    );
                    resolve(true);
                  })
                  .catch((err) => {
                    console.log(
                      "app.function.ts getRemoteNotificationsStatus isRegisteredForRemoteNotifications",
                      err
                    );
                    reject(err);
                  });
              } else {
                //android
                resolve(true);
              }
            } else {
              this.alertCtrl
                .create({
                  header: "Please Confirm!",
                  message:
                    "Notifications for Double Ace Golf have been turned off. Would you like to turn them on?",
                  backdropDismiss: false,
                  buttons: [
                    {
                      text: "Not now",
                      handler: () => {
                        resolve(false);
                      },
                    },
                    {
                      text: "Yes",
                      handler: () => {
                        //popup dialog to enable permission
                        this.diagnostic
                          .switchToSettings()
                          .then(() => {
                            resolve(false);
                          })
                          .catch((err) => {
                            console.log(
                              "app.function.ts remoteNotifications isRegisteredForRemoteNotifications",
                              err
                            );
                            reject(err);
                          });
                      },
                    },
                  ],
                })
                .then((alert) => {
                  alert.present();
                });
            }
          })
          .catch((err) => {
            console.log(
              "app.function.ts getRemoteNotificationsStatus isRegisteredForRemoteNotifications",
              err
            );
            reject();
          });
      } else {
        resolve(true);
      }
    });
  }

  getContactStatus(): Promise<boolean> {

    return new Promise<boolean>((resolve, reject) => {

      //only check this is on mobile
      if (this.platform.is('capacitor')) {

        this.diagnostic
          .getContactsAuthorizationStatus()
          .then((status) => {
            //console.log('app.function.ts getContactStatus getContactsAuthorizationStatus', status);

            //check to see if we already have permission
            if (status === this.diagnostic.permissionStatus.GRANTED)
              resolve(true);
            if (status === this.diagnostic.permissionStatus.NOT_REQUESTED) {
              //or not_determined means that app has never asked user for access

              //request access
              this.diagnostic.requestContactsAuthorization().then((status) => {
                if (status === this.diagnostic.permissionStatus.GRANTED) {
                  resolve(true);
                } else {
                  resolve(false);
                }
              });
            } else if (
              status === this.diagnostic.permissionStatus.DENIED_ONCE ||
              status === this.diagnostic.permissionStatus.DENIED
            ) {
              //contact access have been granted on the phone\or maybe it is for this app

              this.alertCtrl
                .create({
                  header: "Please Confirm!",
                  message:
                    "Access to your contacts for Double Ace Golf have been turned off. Would you like access turned on?",
                  backdropDismiss: false,
                  buttons: [
                    {
                      text: "Not now",
                      handler: () => {
                        resolve(false);
                      },
                    },
                    {
                      text: "Yes",
                      handler: () => {
                        //popup dialog to enable permission
                        this.diagnostic
                          .switchToSettings()
                          .then(() => {
                            resolve(false);
                          })
                          .catch((err) => {
                            console.log(
                              "app.function.ts getContactStatus",
                              err
                            );
                            reject(err);
                          });
                      },
                    },
                  ],
                })
                .then((alert) => {
                  alert.present();
                });
            }
          })
          .catch((err) => {
            console.log(
              "app.function.ts getContactStatus getContactsAuthorizationStatus",
              err
            );
            reject();
          });
      } else {
        resolve(false);
      }
    });
  }

  getLocationStatus(): Promise<string> {

    return new Promise<string>((resolve, reject) => {

      if (this.platform.is('capacitor')) {

        this.diagnostic
          .getLocationAuthorizationStatus()
          .then((status) => {
            //console.log('app.function.ts getLocationStatus', status);

            switch (status) {
              case AppConfig.NOT_DETERMINED:
                console.log(
                  "app.function.ts getLocationStatus requestLocationAuthorization"
                );

                this.diagnostic
                  .requestLocationAuthorization()
                  .then((status) => {
                    resolve(status);
                  })
                  .catch((err) => {
                    console.log(
                      "app.function.ts getLocationStatus requestLocationAuthorization not_determined",
                      err
                    );
                  });

                break;

              case this.diagnostic.permissionStatus.NOT_REQUESTED:
                resolve(status);
                break;

              case this.diagnostic.permissionStatus.DENIED:
                this.alertCtrl
                  .create({
                    header: "Please Confirm!",
                    message:
                      "Location Services for Double Ace Golf have been turned off. Would you like to turn them on?",
                    buttons: [
                      {
                        text: "Not now",
                        handler: () => {
                          resolve(status);
                        },
                      },
                      {
                        text: "Yes",
                        handler: () => {
                          if (this.platform.is('ios')) {
                            //popup dialog to enable permission
                            this.diagnostic
                              .switchToSettings()
                              .then(() => {
                                resolve(status);
                              })
                              .catch((err) => {
                                console.log(
                                  "app.function.ts remoteNotifications isRegisteredForRemoteNotifications",
                                  err
                                );
                              });
                          } else {
                            //android

                            //popup dialog to enable permission
                            this.diagnostic.switchToLocationSettings();
                            resolve(status);
                          }
                        },
                      },
                    ],
                  })
                  .then((alert) => {
                    alert.present();
                  });

                break;

              case this.diagnostic.permissionStatus.GRANTED:
                resolve(status);
                break;

              case this.diagnostic.permissionStatus.GRANTED_WHEN_IN_USE:
                resolve(status);
                break;

              case this.diagnostic.permissionStatus.DENIED_ALWAYS:
                resolve(status);
                break;
            }
          })
          .catch((err) => {
            console.log(
              "app.function.ts getLocationStatus getLocationAuthorizationStatus error",
              err
            );
          });
      } else {
        //this._locationStatus.next(undefined);
        resolve(undefined);
      }

    });
  }

  /* TODO: this fixes an issue in ionic 4 and the keyboard where the input doesn't scroll into view when the keyboard opens */
  focusInput(event: Event): void {
    let total = 0;
    let container = null;

    const _rec = (obj) => {
      total += obj.offsetTop;
      const par = obj.offsetParent;
      if (par && par.localName !== "ion-content") {
        _rec(par);
      } else {
        container = par;
      }
    };
    _rec(event.target);
    container.scrollToPoint(0, total - 50, 800);
  }

  /**
   * Get and listen to remote configuration updates.
   *
   * Returns a promise of the remote configuration(AppRemoteConfigurationI).
   */
  getRemoteConfig(): Promise<void> {

    return new Promise<void>((resolve) => {

      //identify platform
      let platform: string = "web";
      if (this.platform.is('ios')) {
        platform = 'ios';
      } else if (this.platform.is("android")) {
        platform = "android";
      }

      //get configs
      this.firestore.collection(AppConfig.COLLECTION.Configuration).onSnapshot(
        (foundConfigurations) => {
          //console.log('app.function.ts AppFunction getRemoteConfig success', platform);

          //if configuration found
          if (
            foundConfigurations.docs &&
            foundConfigurations.docs[0] &&
            foundConfigurations.docs[0].get(platform)
          ) {
            //get platform configurations
            this._remoteConfigurations =
              foundConfigurations.docs[0].get(platform);

            //console.log('app.function.ts AppFunction getRemoteConfig config found', this._remoteConfigurations);

            //publish platform configurations
            this.remoteConfigurations.next({
              minimumVersion: this._remoteConfigurations.minimumVersion,
              latestVersion: this._remoteConfigurations.latestVersion,
              newVersionMessage: this._remoteConfigurations.newVersionMessage,
              maintenance: this._remoteConfigurations.maintenance,
              maintenanceMessage: this._remoteConfigurations.maintenanceMessage,
              firebaseLogLevel: this._remoteConfigurations.firebaseLogLevel,
            });
          } else {
            //no configurations found
            console.log(
              "app.function.ts AppFunction getRemoteConfig No configurations found for platform",
              { platform: platform }
            );
          }

          //if config doc exists (which it should)
          if (foundConfigurations.docs && foundConfigurations.docs[0]) {
            //feature help
            this.helpContent = foundConfigurations.docs[0].get("help");

            //tee color config
            this.teeColor = foundConfigurations.docs[0].get("teeColor");
          }

          //get help
          if (foundConfigurations.docs && foundConfigurations.docs[0]) {
          }
        },
        (err) => {
          console.log(
            "app.function.ts AppFunction getRemoteConfig Error retrieving remote configurations",
            err
          );
        }
      );

      //return
      resolve();
    });
  }

  /**
   * Evaluates what kind of app update is required, if any.
   *
   * Returns a promise that resolves with an alert type.
   */
  appVersionCheck(): Promise<AppUpdateI> {

    return new Promise<AppUpdateI>((resolve) => {

      if (this.platform.is('capacitor')) {

        //get app version (installed) number
        App
          .getInfo()
          .then(async (appInfo) => {

            //get values from saved version
            const minimum: string = this._remoteConfigurations.minimumVersion;
            const latest: string = this._remoteConfigurations.latestVersion;
            let appUpdate: AppUpdateI;

            //console.log('app.function.ts AppFunction versionCheck (installed, minimum, latest)', appInfo.version, minimum, latest);

            if (compareVersion.compare(appInfo.version, minimum, "<")) {

              console.log(
                "app.function.ts AppFunction versionCheck AppUpdateType.MANDATORY",
                compareVersion.compare(appInfo.version, minimum, "<")
              );

              appUpdate = {
                appUpdateType: AppUpdateType.MANDATORY,
                appUpdateMessage: this._remoteConfigurations.newVersionMessage,
              };

            } else if (compareVersion.compare(appInfo.version, latest, "<")) {

              console.log(
                "app.function.ts AppFunction versionCheck AppUpdateType.OPTIONAL",
                compareVersion.compare(appInfo.version, minimum, "<")
              );

              appUpdate = {
                appUpdateType: AppUpdateType.OPTIONAL,
                appUpdateMessage: this._remoteConfigurations.newVersionMessage,
              };

            } else {
              appUpdate = {
                appUpdateType: AppUpdateType.NOP,
                appUpdateMessage: "",
              };
            }

            //if NOP then just return
            if (appUpdate.appUpdateType === AppUpdateType.NOP) {
              resolve(appUpdate);
            } else { //else open new app modal

              this.modalCtrlCreate({
                component: AppUpdatePage,
                presentingElement: await this.routerOutlet(),
                cssClass: "custom-modal", //for md
                backdropDismiss: false,
                canDismiss: false,
                componentProps: {
                  type: appUpdate.appUpdateType,
                  message: appUpdate.appUpdateMessage,
                },
              })
                .then((modal) => {
                  modal
                    .present()
                    .then(() => {
                      resolve(appUpdate);
                    })
                    .catch((err) => {
                      console.log(
                        "app.function.ts appVersionCheck modal present error",
                        err
                      );
                    });
                })
                .catch((err) => {
                  console.log(
                    "app.function.ts appVersionCheck modal create error",
                    err
                  );
                });
            }
          })
          .catch(() => {
            resolve({ appUpdateType: AppUpdateType.NOP, appUpdateMessage: '' });
          });
      } else {
        resolve({ appUpdateType: AppUpdateType.NOP, appUpdateMessage: '' });
      }

    });

  }

  /**
   * Open platform app store.
   */
  openAppStore() {

    if (this.platform.is('capacitor')) {

      const appStoreId: string = this.platform.is('ios')
        ? "id" + AppConfig.APP_STORE_ID.iOS
        : AppConfig.APP_STORE_ID.android;

      this.market.open(appStoreId);

    }

  }

  private showToast() {

    if (!this._toast) {

      //get first toast in list
      const nextToast = this._queuedToasts.pop();

      this.toastCtrl
        .create({
          message: nextToast.message, //'The event email/notification has been sent.',
          position: nextToast.position, //'bottom',
          duration: nextToast.duration, //4000,
          color: nextToast.color, //'secondary',
          cssClass: nextToast.cssClass, //'tabs-bottom'
          buttons: [
            {
              text: nextToast.closeButtonText,
              role: "cancel",
              handler: () => {
                //noop
              },
            },
          ],
        })
        .then((toast) => {
          this._toast = toast;

          toast.present();

          toast
            .onDidDismiss()
            .then(() => {
              this._toast = undefined;
              if (this._queuedToasts.length > 0) {
                this.showToast();
              }
            });

        });

    }

  }

  queueToast(toast: any) {
    //because Ionic doesn't properly display simultaneous toasts queue and show one toast at a time
    this._queuedToasts.push(toast);
    this.showToast();
  }

  async routerOutlet() {
    //This is a work around for getting router outlet within a service
    return (
      (await this.modalCtrl.getTop()) ||
      document.getElementById("ion-router-outlet-content")
    );
  }

  showAvatarView(click, imageToAnimate: HTMLImageElement) {
    //prevent the list item click
    click.stopPropagation();

    this.avatarViewService.show(click, imageToAnimate);
  }

  appRate() {

    if (this.platform.is('capacitor')) {

      this._appRate.setPreferences({
        simpleMode: true,
        displayAppName: AppConfig.APP_NAME,
        promptAgainForEachNewVersion: true,
        usesUntilPrompt: AppConfig.APP_RATE_PROMPT_COUNT,
        useLanguage: "en",
        storeAppURL: {
          ios: AppConfig.APP_STORE_ID.iOS,
          android:
            "market://details?id=" +
            AppConfig.APP_STORE_ID.android,
        },
        customLocale: {
          title: "Would you mind rating %@?",
          message:
            "It won’t take more than a minute and helps to promote our app. Thanks for your support!",
          cancelButtonLabel: "No, Thanks",
          laterButtonLabel: "Remind Me Later",
          rateButtonLabel: "Rate It Now",
          yesButtonLabel: "Yes!",
          noButtonLabel: "Not really",
          appRatePromptTitle: "Do you like using %@",
          feedbackPromptTitle: "Mind giving us some feedback?",
        }
      });

      this._appRate.promptForRating(false);

    }


  }

  getToken(memberId: string): Promise<void> {

    return new Promise<void>((resolve, reject) => {

      if (this.platform.is('capacitor') && this.platform.is('mobile')) {

        PushNotifications
          .requestPermissions()
          .then((result) => {

            if (result.receive === "granted") {

              // Register with Apple / Google to receive push via APNS/FCM
              PushNotifications
                .register()
                .then(() => {

                  FCM.getToken()
                    .then((r) => {
                      //save token for user
                      this.saveTokenToFirestore(memberId, r.token)
                        .then(() => {
                          resolve();
                        })
                        .catch((err) => {
                          console.log(
                            "app.function.ts getToken error",
                            JSON.stringify(err)
                          );
                          reject();
                        });
                    })
                    .catch((err) => console.log(err));
                });

            } else {
              console.log(
                "app.function.ts getToken result of requestPermission is granted === false"
              );
              reject();
            }
          });
      } else {
        console.log("app.function.ts getToken not mobile");
        resolve();
      }

    });

  }

  private saveTokenToFirestore(memberId: string, token: string): Promise<void> {

    if (!token) return;

    const devicesRef = this.firestore.collection(AppConfig.COLLECTION.Devices);

    const docData = {
      token,
      memberId: memberId,
    };

    return devicesRef
      .doc(token)
      .set(docData)
      .then(() => {
        //console.log('app.function.ts saveTokenToFirestore token save success');
      })
      .catch((err) => {
        console.log(
          "app.function.ts saveTokenToFirestore token save error",
          err
        );
      });
  }

  newGuid(): string {
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
      /[xy]/g,
      function (c) {
        var r = (Math.random() * 16) | 0,
          v = c == "x" ? r : (r & 0x3) | 0x8;
        return v.toString(16);
      }
    );
  }

  isNumeric(val: any): boolean {
    return !(val instanceof Array) && val - parseFloat(val) + 1 >= 0;
  }

  /* copyCollection() {
    this.firestore
      .collection(AppConfig.COLLECTION.Courses)
      //.limit(1)
      .get()
      .then((query) => {
        let count: number = 0;

        console.log("copyCollection get documents", query.size);

        query.forEach((doc) => {
          //console.log('copy', doc.id, doc.data())
          //this.firestore.collection(AppConfig.COLLECTION.CoursesV2).doc(doc.data().id).set(doc.data());
          count++;
        });

        console.log("copyCollection get documents complete", count);
      });
  } */

  async screenLock(orientation: OrientationLockOptions) {

    if (this.platform.is('capacitor') && this.platform.is('mobile')) {
      ScreenOrientation.lock(orientation);
    }

  }

  async screenUnlock() {

    if (this.platform.is('capacitor') && this.platform.is('mobile')) {
      ScreenOrientation.unlock();
    }

  }

}

@Injectable({
  providedIn: 'root',
})
export class DeepLinkService {

  deepLink: BehaviorSubject<DeepLinkParmsI> = new BehaviorSubject<DeepLinkParmsI>(undefined);
  private listenerHandler: PluginListenerHandle = undefined;
  private _currentDeepLink: DeepLinkParmsI = undefined;

  constructor(public appFunction: AppFunction,
    public accountService: AccountService) {

    //clean up
    this.appFunction
      .shutDown
      .subscribe(() => {
        //clear deep link
        this.clearDeepLink();
      });

  }

  get currentDeepLink(): DeepLinkParmsI {
    return this._currentDeepLink;
  }

  createDeepLink(campaign: DeepLinkCampaign, channel: DeepLinkChannel, queryParms: DeepLinkParmsI, socialMeta: SocialMetaTagParameters): Promise<string> {

    return new Promise<string>((resolve, reject) => {

      //create deep link document
      this.appFunction
        .firestore
        .collection(AppConfig.COLLECTION.DeepLinks)
        .add(<DeepLinkI>{
          createdDt: firebase.firestore.Timestamp.fromDate(new Date()),
          status: DeepLinkStatus.sent,
          parms: queryParms,
          campaign: campaign,
          channel: channel
        })
        .then((deeplinkDocument) => {

          //if channel is email create the deep link
          const uri: string = environment.actionCodeSettings.dynamicLinkUri + '?code=' + deeplinkDocument.id;
          if (channel === DeepLinkChannel.email) {

            const config: LinkConfig = {
              domainUriPrefix: 'https://' + environment.actionCodeSettings.dynamicLinkDomain, //can't put https:// in the environment file
              uri: uri,
              socialMeta: {
                title: socialMeta.title,
                description: socialMeta.description
              },
              iosParameters: {
                bundleId: environment.actionCodeSettings.iOS.bundleId,
                appStoreId: AppConfig.APP_STORE_ID.iOS
              },
              androidParameters: {
                packageName: environment.actionCodeSettings.android.packageName
              },
              webApiKey: environment.FIREBASE_CONFIG.apiKey  //this is needed for the web app, GoogleService-Info.plist is used by iOS
            };

            FirebaseDynamicLinks
              .createDynamicShortLink(config)
              .then((shortLink) => {
                resolve(shortLink.value);
              })
              .catch((err) => {
                console.log('app.function.ts DeepLinkService createDeepLink createDynamicShortLink err', err);
                reject();
              });

          } else { //otherwise channel is push notification
            resolve(uri);
          }

        })
        .catch((err) => {
          console.log('app.function.ts DeepLinkService createDeepLink add', err);
          reject();
        });

    });

  }

  processDeepLink(deeplinkUrl: string) {

    try {

      //unpack deeplink document id (code)
      const url: URL = new URL(deeplinkUrl);
      const urlParams: URLSearchParams = new URLSearchParams(url.search);
      const deeplinkDocumentId: string = urlParams.get('code');

      //fetch requested deeplink document
      this.appFunction
        .firestore
        .collection(AppConfig.COLLECTION.DeepLinks)
        .doc(deeplinkDocumentId)
        .get()
        .then((foundDeepLink) => {

          //get deeplink saved parms
          this._currentDeepLink = foundDeepLink.data().parms;

          //determine if the deeplink email is for a new or existing user
          this.appFunction
            .getUserAuthProviders(this._currentDeepLink.email)
            .then((userAuthProviders) => {

              try {

                console.log('app.function.ts DeepLinkService processDeepLink userAuthProviders', userAuthProviders, userAuthProviders.userRecord ? true : false);

                //publish deep link...
                this.deepLink.next({
                  route: this._currentDeepLink?.route, //this is the angular path 
                  page: this._currentDeepLink?.page, //this is the (modal) page to open
                  id: this._currentDeepLink?.id, //this is the firestore doc id 
                  segment: this._currentDeepLink?.segment, //this is the segment within the page
                  actionCd: this._currentDeepLink?.actionCd, //this is the action cd of the page
                  email: this._currentDeepLink?.email?.trim(), //this is the email address that this link is intended for
                  welcomeMessage: this._currentDeepLink?.welcomeMessage, //this is the splash screen to show in the event of a new app download or member is logged out
                  emailHasAccount: userAuthProviders.userRecord ? true : false,
                  additionalData: this._currentDeepLink?.additionalData,
                  actionCdMessage: this._currentDeepLink?.actionCdMessage,
                });

                //...mark as visited with timestamp
                foundDeepLink
                  .ref
                  .set(
                    {
                      status: DeepLinkStatus.visited,
                      visitedDt: firebase.firestore.Timestamp.fromDate(new Date())
                    },
                    { merge: true }
                  )
                  .catch((err) => {
                    console.log('deeplink app.function.ts DeepLinkService processDeepLink event set err', err);
                    SentryAngular.captureException(err, {
                      tags: {
                        deeplinkDocumentId: deeplinkDocumentId,
                        method: 'deeplink app.function.ts DeepLinkService processDeepLink event set err'
                      }
                    });
                  });

              } catch (err) {

                console.log('deeplink app.function.ts DeepLinkService processDeepLink event set err', err);
                SentryAngular.captureException(err, {
                  tags: {
                    deeplinkDocumentId: deeplinkDocumentId,
                    method: 'deeplink app.function.ts DeepLinkService processDeepLink event set err'
                  }
                });

              }

            });

        })
        .catch((err) => {
          console.log('app.function.ts DeepLinkService processDeepLink get deeplink document err', err);
          SentryAngular.captureException(err, {
            tags: {
              deeplinkDocumentId: deeplinkDocumentId,
              deeplinkUrl: deeplinkUrl,
              method: 'app.function.ts DeepLinkService processDeepLink get deeplink document err'
            }
          });
        });

    } catch (err) {
      console.log('app.function.ts DeepLinkService processDeepLink err', err);
      SentryAngular.captureException(err, {
        tags: {
          method: 'app.function.ts DeepLinkService processDeepLink err',
          deeplinkUrl: deeplinkUrl
        }
      });
    }

  }

  listenForDeepLinks() {

    //only allow to be called once
    if (this.listenerHandler === undefined) {

      //listen for deeplinks TODO: should we regigister to unsubscribe?
      this.listenerHandler = FirebaseDynamicLinks.addListener('deepLinkOpen', (deeplink: DeepLinkOpen) => {
        this.processDeepLink(deeplink.url);
      });

      //register deeplinks listener for unsubscribe I DON'T THINK WE SHOULF SHUT THIS OFF 
      //this.appFunction.registerUnsubscribe(this.listenerHandler);

    }

  }

  clearDeepLink() {

    console.log('app.function.ts DeepLinkService clearDeepLink');

    this._currentDeepLink = undefined;
    this.deepLink.next(null);
  }

  displayWelcomeMessage(deepLinkParms: DeepLinkParmsI): Promise<void> {

    console.log('app.function.ts DeepinkService displayWelcomeMessage');

    return new Promise((resolve) => {

      if (deepLinkParms?.welcomeMessage) {

        //display deeplink welcome message
        this.appFunction
          .modalCtrlCreate({
            component: DeeplinkWelcomeMessagePage,
            componentProps: {
              deepLinkParms: deepLinkParms
            }
          })
          .then((modal) => {

            //show the modal
            modal
              .present()
              .catch((err) => {
                console.log('app.function.ts DeepinkService displayWelcomeMessage DeeplinkWelcomeMessagePage modal present error', err);
              })
              .finally(() => {
                resolve();
              });

          })
          .catch((err) => {
            console.log('app.function.ts DeepinkService displayWelcomeMessage DeeplinkWelcomeMessagePage modal create error', err);
          });

      } else {
        resolve();
      }

    });

  }

}

interface AppHelpTopicI {
  key: string;
  name: string;
  help: {
    title: string;
    subject: string;
  };
  whatsNew: {
    version: string;
    subject: string;
  };
}

export class AppHelpTopic implements AppHelpTopicI {

  id: string; //docId
  key: string;
  name: string;
  help: any;
  whatsNew: any;
  private _appFunction: AppFunction;
  private _helpTopicDoc: firebase.firestore.DocumentSnapshot;

  constructor() {

    //get services
    this._appFunction = AppFunction.serviceLocator.get(AppFunction);

    //clean up on app logout
    this._appFunction
      .shutDown
      .subscribe(() => {
        //console.log('app.function.ts AppHelpTopic shutdown event', this.id);
      });

  }

  initialize(helpTopicDoc: firebase.firestore.QueryDocumentSnapshot = undefined): Promise<void> {

    return new Promise<void>((resolve) => {

      try {

        if (helpTopicDoc) {
          //console.log('app.function.ts AppHelpTopic initialize');

          //save document refenence
          this._helpTopicDoc = helpTopicDoc;
          this.id = helpTopicDoc.id;

          //listen for help topic updates
          const helpTopicDocSnapShotUnsubscribe = helpTopicDoc.ref.onSnapshot(
            (helpTopicDocUpdate) => {
              this.update(helpTopicDocUpdate);
              resolve();
            }
          );

          this._appFunction.registerUnsubscribe(
            helpTopicDocSnapShotUnsubscribe
          );

        } else {

          //create new course doc ref
          const helpTopicDoc: firebase.firestore.DocumentReference =
            this._appFunction.firestore
              .collection(AppConfig.COLLECTION.Help)
              .doc();

          //now get it, it should be empty as this is a new group object
          helpTopicDoc.get()
            .then((newHelpTopicDoc) => {
              //save doc, this will be used when saving
              this._helpTopicDoc = newHelpTopicDoc;
              this.id = helpTopicDoc.id;
            });

        }
      } catch (err) {
        console.log("app.function.ts AppHelpTopic initialize", err);
      }

    });

  }

  private update(updatedHelpTopic: firebase.firestore.DocumentSnapshot) {
    try {
      //console.log('app.function.ts AppHelpTopic update', updatedHelpTopic.data());

      if (updatedHelpTopic.exists) {
        //update high level attributes
        this.key = updatedHelpTopic.data().key;
        this.name = updatedHelpTopic.data().name;
        this.help = updatedHelpTopic.data().help;
        this.whatsNew = updatedHelpTopic.data().whatsNew;
      }
    } catch (err) {
      console.log("app.club.ts AppCourse update error", JSON.stringify(err));
    }
  }

  save(): Promise<AppHelpTopic> {

    return new Promise<AppHelpTopic>((resolve, reject) => {
      try {
        //create batch transaction
        const batch: firebase.firestore.WriteBatch = this._appFunction.firestore.batch();
        batch.set(this._helpTopicDoc.ref, this.data(), { merge: true });

        batch
          .commit()
          .then(() => {
            //console.log('app.function.ts AppHelpTopic save set success');
            resolve(this);
          })
          .catch((err) => {
            console.log("app.function.ts AppHelpTopic save set error", err);
            reject(err);
          });
      } catch (err) {
        console.log(
          "app.function.ts AppHelpTopic save set error",
          err,
          JSON.stringify(err)
        );
        reject(err);
      }
    });
  }

  private data(): AppHelpTopicI {
    try {
      return {
        key: this.key,
        name: this.name,
        whatsNew: this.whatsNew,
        help: this.help,
      };
    } catch (err) {
      console.log(
        "app.function.ts AppHelpTopic data error",
        err,
        JSON.stringify(err)
      );
      throw err;
    }
  }
}

@Injectable({
  providedIn: 'root',
})
export class HelpService {
  private _helpTopics: AppHelpTopic[];

  constructor(
    private appFunction: AppFunction,
    public deepLinkService: DeepLinkService) {

  }

  getHelpTopics(): Promise<AppHelpTopic[]> {

    return new Promise<AppHelpTopic[]>((resolve) => {

      if (Array.isArray(this._helpTopics)) {
        resolve(this._helpTopics);
      } else {

        //initialize array
        this._helpTopics = [];

        //get help topics
        this.appFunction.firestore
          .collection(AppConfig.COLLECTION.Help)
          .orderBy('name')
          .onSnapshot((foundHelpTopics) => {
            //console.log('app.function.ts getHelpTopics success', clubId);

            foundHelpTopics.docChanges().forEach((foundHelpTopic) => {
              if (foundHelpTopic.type === FirestoreUpdateType.Added) {
                const newHelpTopic: AppHelpTopic = new AppHelpTopic();
                newHelpTopic.initialize(foundHelpTopic.doc).then(() => {
                  this._helpTopics.push(newHelpTopic);
                });
              }
            });

            resolve(this._helpTopics);

          });

      }

    });

  }

  getTopicByKey(helpTopicKey): AppHelpTopic {
    //console.log('app.function.ts getTopicByKey', helpTopicKey);

    return this._helpTopics.find((helpTopic) => {
      return helpTopic.key === helpTopicKey;
    });
  }

  showFeatureHelp(key) {

    //get help
    const helpTopic = this.appFunction.helpContent[key];

    //show help modal
    this.appFunction.modalCtrlCreate({
      component: HelpViewPage,
      componentProps: {
        title: helpTopic.title,
        subject: helpTopic.help,
      },
      cssClass: "custom-modal-auto-height",
      enterAnimation: enterFromBottomAnimation,
      leaveAnimation: leaveToBottomAnimation,
    })
      .then((modal) => {
        modal
          .present()
          .catch((err) => {
            console.log("app.function.ts showFeatureHelp modal present error", err);
          });
      })
      .catch((err) => {
        console.log("app.function.ts showFeatureHelp modal create error", err);
      });

  }

  async showScreenHelp(helpTopic) {

    //show help modal
    this.appFunction.modalCtrlCreate({
      component: HelpViewPage,
      presentingElement: await this.appFunction.routerOutlet(),
      cssClass: "custom-modal", //for md
      backdropDismiss: false,
      canDismiss: true,
      componentProps: {
        title: helpTopic.help.title,
        subject: helpTopic.help.subject,
      },
    })
      .then((modal) => {
        modal
          .present()
          .catch((err) => {
            console.log("app.function.ts showScreenHelp modal present error", err);
          });
      })
      .catch((err) => {
        console.log("app.function.ts showScreenHelp modal create error", err);
      });

  }

  async showWhatsNew(helpTopic) {

    //show help modal
    this.appFunction.modalCtrlCreate({
      component: WhatsNewPage,
      presentingElement: await this.appFunction.routerOutlet(),
      cssClass: 'whats-new-modal', //"custom-modal", //for md
      backdropDismiss: false,
      canDismiss: true,
      componentProps: {
        title: "What's new!",
        subject: helpTopic.whatsNew.subject,
      },
    })
      .then((modal) => {
        modal
          .present()
          .then(() => {
            //update local database that whatsNew was displayed
            this.appFunction.storage.set(
              helpTopic.name,
              helpTopic.whatsNew.version
            );
          })
          .catch((err) => {
            console.log('app.function.ts showWhatsNew modal present error', err);
          });
      })
      .catch((err) => {
        console.log('app.function.ts showWhatsNew modal create error', err);
      });

  }

  screenWhatsNew(key: string) {

    //only show help if there isn't an active deeplink (there's probably a better/cleaner way to do this)
    if (this.deepLinkService.currentDeepLink === undefined) {

      //the setTimeout allows the screen to open befire showing the whats new dialog...better user experience
      setTimeout(() => {

        //get help topic
        const helpTopic: AppHelpTopic = this.getTopicByKey(key);

        //get whatsNew version
        const whatsNewVersion: string = helpTopic?.whatsNew?.version;

        //check to see if help topic version is great than current app version
        if (this.appFunction.platform.is('capacitor') && whatsNewVersion?.length > 0) {

          //get app version (installed) number
          App
            .getInfo()
            .then((appInfo) => {

              //the installed app has to be equal or greater than the help topic version
              if (compareVersion.compare(appInfo.version, whatsNewVersion, ">=")) {

                //now check to see if the help topic has already been displayed to the user
                this.appFunction.storage
                  .get(helpTopic.name)
                  .then((mostRecentVersionDisplayedForHelpTopic) => {
                    //if help topic version is greater than what was last displayed to user...
                    if (
                      compareVersion.compare(
                        whatsNewVersion,
                        mostRecentVersionDisplayedForHelpTopic || "0.0.0",
                        ">"
                      )
                    ) {
                      this.showWhatsNew(helpTopic);
                    }
                  })
                  .catch((err) => {
                    console.log('app.function.ts storage.get(AppConfig.TOUCH_ID_ACTIVE) error', JSON.stringify(err));
                  });

              }

            });

        }

      }, 1000);

    }

  }

}

@Injectable({
  providedIn: "root",
})
export class ScreenSizeService {
  private isDesktop = new BehaviorSubject<boolean>(false);

  constructor() { }

  onResize(size) {
    this.isDesktop.next(<boolean>(size > 568));
  }

  isDesktopView(): Observable<boolean> {
    return this.isDesktop.asObservable().pipe(distinctUntilChanged());
  }
}

@Injectable({
  providedIn: "root",
})
export class AvatarViewService {
  private background: HTMLElement;
  private img: HTMLImageElement;
  private container: HTMLElement;
  private renderer: Renderer2;

  animateAvatar = (
    backdropElement: HTMLElement,
    imgElement: HTMLImageElement
  ) => {
    //get image center (y or vertical)
    const imageVerticalCenter: number =
      imgElement.offsetTop + imgElement.offsetHeight / 2;

    //get backdrop center (y or vertical)
    const backdropVerticalCenter = backdropElement.offsetHeight / 2;

    //calc how much to move based on centers (y or vertical)
    const moveVerticalAmount: number =
      backdropVerticalCenter - imageVerticalCenter;

    //get image center (x or horizontal)
    const imageHorizontalCenter: number =
      imgElement.offsetLeft + imgElement.offsetWidth / 2;

    //get backdrop center (x or horizontal)
    const backdropHorizontalCenter = backdropElement.offsetWidth / 2;

    //calc how much to move based on centers (horizontal)
    const moveHorizontalAmount: number =
      backdropHorizontalCenter - imageHorizontalCenter;

    //determine how much to scale, use min on backdropelement so that it scales properly on mobile and web
    const scale: number =
      Math.min(backdropElement.offsetWidth, backdropElement.offsetHeight) /
      (imgElement.offsetWidth + 10);

    return createAnimation()
      .addElement(imgElement!)
      .duration(200)
      .fromTo(
        "transform",
        "translateX(0px) translateY(0px) scale(1) ",
        "translateX(" +
        moveHorizontalAmount.toString() +
        "px) translateY(" +
        moveVerticalAmount.toString() +
        "px) scale(" +
        scale +
        ")"
      );
  };

  animateBackground = (baseElement: HTMLElement) => {
    return createAnimation()
      .addElement(baseElement!)
      .duration(300)
      .easing("ease-in")
      .fromTo("background", "transparent", "var(--ion-color-secondary)");
  };

  constructor(private _renderer: RendererFactory2) {
    this.renderer = _renderer.createRenderer(null, null);

    //get app element
    this.container = document.documentElement;

    /* create main div that will serve as the backdrop */
    this.background = this.renderer.createElement("div");
    this.background.style.backgroundColor = "transparent";
    this.background.style.position = "absolute";
    this.background.style.display = "none";
    this.background.style.top = "0";
    this.background.style.right = "0";
    this.background.style.bottom = "0";
    this.background.style.left = "0";
    this.background.style.willChange = "opacity";
    this.background.style.opacity = "1";
    this.renderer.appendChild(this.container, this.background);

    /* create the img */
    this.img = this.renderer.createElement("img");
    this.img.style.position = "absolute";
    this.img.style.borderRadius = "50%";
    this.img.style.opacity = "1";
    this.img.style.objectFit = "cover";
    this.img.style.willChange = "transform, scale, translateY";
    this.renderer.appendChild(this.background, this.img);

    /* don't animate until image is loaded, this will happen in the show method */
    this.img.onload = () => {

      createAnimation()
        .addAnimation([
          this.animateAvatar(this.background, this.img),
          this.animateBackground(this.background),
        ])
        .play()
        .catch((err) => {
          console.log("avatar-view.directive.ts host click play error", err);
        });

    };
  }

  show(event, imageToAnimate: HTMLImageElement) {
    try {

      //create image element
      const img: HTMLImageElement = <HTMLImageElement>imageToAnimate;

      //set image position
      this.img.style.width = img.clientWidth.toString() + "px";
      this.img.style.height = img.clientHeight.toString() + "px";
      this.img.style.left = (event.target.x + img.clientLeft).toString() + "px";
      this.img.style.top = (event.target.y + img.clientTop).toString() + "px";

      //add background click handler to close, we do this here because we remove the handle when hiding
      this.background.onclick = () => {
        this.hide();
      };

      //now show the background
      this.background.style.display = "inline";

      //set image source, the animation runs after te image loads, see constructor
      this.img.src = img.src;

    } catch (err) {
      console.log("app.function.ts show error", err);
    }
  }

  private hide() {

    //prevent the double click (and reanimation of the close)
    this.background.onclick = undefined;

    //reverse the animation
    this.animateAvatar(this.background, this.img)
      .direction("reverse")
      .play()
      .then(() => {
        this.animateBackground(this.background)
          .direction("reverse")
          .afterStyles({ display: "none" })
          .play()
          .then(() => {
            this.img.src = "";
          });
      });
  }
}

export enum SplashScreenType {
  SplashScreen,
  Spinner,
}

@Injectable({
  providedIn: 'root',
})
export class AnimateSplashService {

  private background: HTMLDivElement;
  private top: HTMLDivElement;
  private middle: HTMLDivElement;
  private bottom: HTMLDivElement;
  private text: HTMLDivElement;
  private logo: HTMLImageElement;
  private leftText: HTMLDivElement;
  private rightText: HTMLDivElement;
  private container: HTMLElement;
  private renderer: Renderer2;
  private loader: HTMLIonLoadingElement;
  private splashScreenType: SplashScreenType;

  animateText = (textElement: HTMLDivElement) => {
    return createAnimation()
      .addElement(textElement!)
      .duration(400)
      .fromTo('opacity', 0, 1);
  };

  animateHide = (backgroundElement: HTMLDivElement) => {
    return createAnimation()
      .addElement(backgroundElement!)
      .duration(400)
      .fromTo('opacity', 1, 0);
  };

  constructor(
    private _renderer: RendererFactory2,
    public loadingCtrl: LoadingController,) {

    this.renderer = _renderer.createRenderer(null, null);

    //get app element
    this.container = document.documentElement;

    /* create main div that will serve as the backdrop */
    this.background = this.renderer.createElement("div");
    this.background.style.position = "absolute";
    this.background.style.display = "none";
    this.background.style.top = "0";
    this.background.style.right = "0";
    this.background.style.bottom = "0";
    this.background.style.left = "0";
    this.background.style.backgroundImage = "url(../assets/images/SplashScreenBackground.png)";
    this.background.style.backgroundSize = "cover";
    this.background.style.backgroundPosition = "center";
    this.renderer.appendChild(this.container, this.background);

    /* create the top div that will not contain anything */
    this.top = this.renderer.createElement("div");
    this.top.style.backgroundColor = "transparent";
    this.top.style.height = "33%";
    this.top.style.width = "100%";
    this.renderer.appendChild(this.background, this.top);

    /* create the middle div that will contain the logo */
    this.middle = this.renderer.createElement("div");
    this.middle.style.backgroundColor = "transparent";
    this.middle.style.height = "33%";
    this.middle.style.width = "100%";
    this.middle.style.display = "flex";
    this.renderer.appendChild(this.background, this.middle);

    /* create the middle div that will contain the logo */
    this.logo = this.renderer.createElement("img");
    this.logo.style.height = "100%";
    this.logo.style.width = "auto";
    this.logo.style.margin = "auto";
    this.logo.style.paddingLeft = "60px";
    this.logo.src = "../assets/images/SplashScreenLogo.png";
    this.renderer.appendChild(this.middle, this.logo);

    /* create the bottom div that will contain the animated text */
    this.bottom = this.renderer.createElement("div");
    this.bottom.style.backgroundColor = "transparent";
    this.bottom.style.height = "33%";
    this.bottom.style.width = "100%";
    this.bottom.style.marginTop = "20px";
    this.renderer.appendChild(this.background, this.bottom);

    /* create the text div that will contain the animated text */
    this.text = this.renderer.createElement("div");
    this.text.style.backgroundColor = "transparent";
    this.text.style.margin = "auto";
    this.text.style.width = "fit-content";
    this.renderer.appendChild(this.bottom, this.text);

    /* create the left text for "DOUBLE ACE" */
    this.leftText = this.renderer.createElement("div");
    this.leftText.innerHTML = "DOUBLE ACE";
    this.leftText.style.color = "var(--ion-color-primary)";
    this.leftText.style.textAlign = "right";
    this.leftText.style.paddingRight = "2px";
    this.leftText.style.fontSize = "25px";
    this.leftText.style.display = "inline-block";
    this.leftText.style.margin = "0 auto";
    this.leftText.style.fontFamily = "inherit";
    this.leftText.style.fontWeight = "500";
    this.leftText.style.opacity = "0";
    this.leftText.style.willChange = "opacity";
    this.renderer.appendChild(this.text, this.leftText);

    /* create the left text for "GOLF" */
    this.rightText = this.renderer.createElement("div");
    this.rightText.innerHTML = "GOLF";
    this.rightText.style.color = "var(--ion-color-secondary)";
    this.rightText.style.paddingLeft = "2px";
    this.rightText.style.fontSize = "25px";
    this.rightText.style.display = "inline-block";
    this.rightText.style.margin = "0 auto";
    this.rightText.style.fontFamily = "inherit";
    this.rightText.style.fontWeight = "500";
    this.rightText.style.opacity = "0";
    this.rightText.style.willChange = "opacity";
    this.renderer.appendChild(this.text, this.rightText);
  }

  show(splashScreenType: SplashScreenType = SplashScreenType.SplashScreen, spinnerMessage: string = undefined): Promise<void> {

    return new Promise<void>((resolve) => {

      try {

        //only show if this hasn't been called yet
        if (this.splashScreenType === undefined) {

          //save this for when we hide
          this.splashScreenType = splashScreenType;

          //now open the desired splash (splash or spinner)
          if (splashScreenType === SplashScreenType.SplashScreen) {

            //now show the background
            this.background.style.display = "inline";

            //animate DOUBLE ACE
            createAnimation()
              .addAnimation([this.animateText(this.leftText)])
              .delay(400)
              .play()
              .then(() => {
                //animate GOLF
                createAnimation()
                  .addAnimation([this.animateText(this.rightText)])
                  .delay(300)
                  .play()
                  .then(() => {

                    resolve();
                  })
                  .catch((err) => {
                    console.log(
                      "app.function.ts AnimateSplashService right text error",
                      err
                    );
                    resolve();
                  });
              })
              .catch((err) => {
                console.log(
                  "app.function.ts AnimateSplashService left text error",
                  err
                );
                resolve();
              });

          } else { //splinner

            this.loadingCtrl
              .create({ message: spinnerMessage })
              .then((loading) => {

                this.loader = loading;
                this.loader
                  .present()
                  .then(() => {
                    resolve();
                  });

              });

          }

        } else {
          //just return
          resolve();
        }

      } catch (err) {
        console.log('app.function.ts AnimateSplashService show error', err);
        resolve();
      }

    });
  }

  hide(): Promise<void> {

    return new Promise<void>((resolve) => {

      setTimeout(() => {

        if (this.splashScreenType === SplashScreenType.SplashScreen) {

          //animation to hide splash screen
          createAnimation()
            .addAnimation([this.animateHide(this.background)])
            .play()
            .then(() => {

              //now hide the background
              this.background.style.display = "none";

              //clear reference
              this.splashScreenType = undefined;

              //return 
              resolve();

            })
            .catch((err) => {
              console.log("app.function.ts AnimateSplashService hide error", err);
            });

        } else if (this.splashScreenType === SplashScreenType.Spinner) {

          this.loader
            .dismiss()
            .then(() => {

              //clear reference
              this.splashScreenType = undefined;

              //return 
              resolve();

            });

        } else {
          resolve();
        }

      }, 150);

    });

  }

}

export const centerPopoverAnimation = (baseEl: any) => {

  try {

    const backdropAnimation = createAnimation()
      .addElement(baseEl.shadowRoot.querySelector("ion-backdrop"))
      .fromTo("opacity", 0.01, "var(--backdrop-opacity)")
      .beforeStyles({
        "pointer-events": "none",
      })
      .afterClearStyles(["pointer-events"]);

    // You could also define the popover-content
    // transform here if you wanted it to
    // still have a scale effect
    const transformAnimation = createAnimation()
      .addElement(baseEl.shadowRoot.querySelector(".popover-content"))
      .afterStyles({
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
      });

    const wrapperAnimation = createAnimation()
      .addElement(baseEl.shadowRoot.querySelector(".popover-wrapper"))
      .addElement(baseEl.shadowRoot.querySelector(".popover-viewport"))
      .fromTo("opacity", 0.01, 1);

    return createAnimation()
      .addElement(baseEl)
      .easing("cubic-bezier(0.36,0.66,0.04,1)")
      .duration(300)
      .addAnimation([backdropAnimation, wrapperAnimation, transformAnimation]);

  } catch (err) {
    console.log("app.function.ts centerPopoverAnimation error", err);
  }

};

/**
 * Color picker for course tees
 */
export interface IonColor {
  key: string;
  value: string;
  friendlyName: string;
}

@Injectable({
  providedIn: "root",
})
export class ColorService {

  private ionPrefix: string = ".ion-color-";
  public colorList: IonColor[];

  constructor(
    @Inject(DOCUMENT) private document: Document,
    appFunction: AppFunction
  ) {
    //get tee color config
    this.colorList = appFunction.teeColor;
    this.colorList.sortBy('friendlyName', SortByOrder.ASC);

    this.colorList.forEach((c) => this.addIonColor(c.key, c.value));
  }

  public getColorValue(colorKey: string): string {
    let idx = this.colorList.map((c) => c.key).indexOf(colorKey);
    return idx == -1 ? undefined : this.colorList[idx].value;
  }

  public addIonColor(name: string, baseColor: string) {
    const namePattern = /^[a-zA-Z][\-_0-9A-Za-z]+$/;

    if (!namePattern.test(name)) {
      throw new Error(
        `Invalid color name: ${name} should match /^[a-zA-Z][\-_0-9A-Za-z]$/`
      );
      return;
    }
    const color = new tinycolor(baseColor);

    if (!color.isValid()) {
      throw new Error(`Invalid color value: ${baseColor}`);
      return;
    }

    const hex = color.toString("hex6");
    const rgb = color.toRgb();
    const contrast = tinycolor(color.getBrightness() > 150 ? "#222" : "#eee");
    const contrastRgb = contrast.toRgb();

    const css = `${this.ionPrefix + name} {
	--ion-color-base: ${hex};
  --ion-color-base-rgb: ${rgb.r},${rgb.g},${rgb.b};
  --ion-color-contrast: ${contrast.toString("hex6")};
  --ion-color-contrast-rgb: ${contrastRgb.r},${contrastRgb.g},${contrastRgb.b};
  --ion-color-shade: ${color.darken().toString("hex6")};
  --ion-color-tint: ${color.lighten().toString("hex6")};
 }
 `;
    const docStyle = this.document.createElement("style");
    docStyle.type = "text/css";
    docStyle.innerHTML = css;
    this.document.getElementsByTagName("head")[0].appendChild(docStyle);
  }
}

export const AvatarBorder = trigger("borderState", [
  state("on", style({ "border-color": "#2196f3" })),
  state("off", style({ "border-color": "#bfbfbf" })),
  transition("on => off", [
    animate("1000ms", keyframes([style({ "border-color": "#bfbfbf" })])),
  ]),
  transition("off => on", [
    animate("1000ms", keyframes([style({ "border-color": "#2196f3" })])),
  ]),
]);

export const animateSectionOpen = (sectionToAnimate: HTMLElement) => {

  return createAnimation()
    .addElement(sectionToAnimate!)
    .beforeStyles({
      "border-top": "solid thin lightgrey",
      "margin-top": "12px",
    })
    .duration(300)
    .fromTo(
      "height",
      "0px",
      (sectionToAnimate.scrollHeight + 12).toString() + "px"
    );
};

export const animateSectionClose = (sectionToAnimate: HTMLElement) => {

  return createAnimation()
    .addElement(sectionToAnimate!)
    .afterClearStyles(["margin-top", "border-top"])
    .duration(300)
    .fromTo("height", sectionToAnimate.scrollHeight.toString() + "px", "0px");
};

function getDescendantProp(obj, desc) {
  var arr = desc.split(".");
  while (arr.length && (obj = obj[arr.shift()]));
  return obj;
}

declare global {
  interface Array<T> {
    sortBy(...propertyName): void;
    groupBy(prop): Array<T>;
    unique(): Array<T>;
  }

  interface String {
    capitalize(): string;
  }
}

export enum SortByOrder {
  ASC = 0,
  DESC = 1
}

Array.prototype.sortBy = function <T>(...propertyName) {

  try {

    const sortArguments = arguments;

    this.sort(function (objA, objB) {
      let result: number = 0;
      for (
        var argIndex = 0;
        argIndex < sortArguments.length && result === 0;
        argIndex += 2
      ) {

        const propertyName: string = sortArguments[argIndex];
        result =
          getDescendantProp(objA, propertyName) <
            getDescendantProp(objB, propertyName)
            ? -1
            : getDescendantProp(objA, propertyName) >
              getDescendantProp(objB, propertyName)
              ? 1
              : 0;

        //Reverse if sort order is false (DESC)
        result *= !sortArguments[argIndex + 1] ? 1 : -1;

      }

      return result;

    });
  } catch (err) {
    console.log('app.function.ts sortBy error', err);
  }
};

Array.prototype.groupBy = function <T>(property: string): any[] {
  const group_to_values = this.reduce(function (groups, item) {
    const propertyValue = item[property];
    groups[propertyValue] = groups[propertyValue] || [];
    groups[propertyValue].push(item);

    //console.log('groupBy', groups, item[property]);

    return groups;
  }, {});

  return Object.keys(group_to_values).map(function (key) {
    return { groupKey: key, groupData: group_to_values[key] };
  });
};

Array.prototype.unique = function <T>(): any[] {
  return Array.from(new Set(this.map((a) => a.id))).map((id) => {
    return this.find((a) => a.id === id);
  });
};

String.prototype.capitalize = function () {
  return this.charAt(0).toUpperCase() + this.slice(1);
};

export const enterFromRightAnimation = (baseEl: HTMLElement) => {

  const wrapperAnimation = createAnimation()
    .addElement(baseEl.shadowRoot.querySelector(".modal-wrapper")!)
    .fromTo("transform", "translateX(350px)", "translateX(0px)")
    .fromTo("opacity", "0.01", "1"); //fix md style issue

  const backdropAnimation = createAnimation()
    .addElement(baseEl.shadowRoot.querySelector("ion-backdrop")!)
    .fromTo("opacity", 0.01, 0.32);

  return createAnimation()
    .addElement(baseEl)
    .easing("cubic-bezier(0.36,0.66,0.04,1)")
    .duration(400)
    .beforeAddClass("show-modal")
    .addAnimation([backdropAnimation, wrapperAnimation]);

};

export const leaveToRightAnimation = (baseEl: HTMLElement) => {
  return enterFromRightAnimation(baseEl).direction("reverse");
};

export const scorecardEnterFromRightAnimation = (baseEl: HTMLElement) => {

  const wrapperAnimation = createAnimation()
    .addElement(baseEl.shadowRoot.querySelector(".modal-wrapper")!)
    .fromTo("transform", "translateY(350px)", "translateY(0px)")
    .fromTo("opacity", "0.01", "1"); //fix md style issue

  return createAnimation()
    .addElement(baseEl)
    .easing("cubic-bezier(0.36,0.66,0.04,1)")
    .duration(400)
    .beforeAddClass("show-modal")
    .addAnimation([wrapperAnimation]);

};

export const scorecardLeaveToRightAnimation = (baseEl: HTMLElement) => {
  return scorecardEnterFromRightAnimation(baseEl).direction("reverse");
};

export const enterFromBottomAnimation = (baseEl: HTMLElement) => {

  const wrapperAnimation = createAnimation()
    .addElement(baseEl.shadowRoot.querySelector(".modal-wrapper")!)
    .fromTo("transform", "translateY(350px)", "translateY(0px)")
    .fromTo("opacity", "0.01", "1"); //fix md style issue

  const backdropAnimation = createAnimation()
    .addElement(baseEl.shadowRoot.querySelector("ion-backdrop")!)
    .fromTo("opacity", 0.01, 0.32);

  return (
    createAnimation()
      .addElement(baseEl)
      .easing("cubic-bezier(0.25, 0.1, 0.25, 1)")
      .duration(300)
      .beforeAddClass("show-modal")
      .addAnimation([backdropAnimation, wrapperAnimation])
  );

};

export const leaveToBottomAnimation = (baseEl: HTMLElement) => {
  return enterFromBottomAnimation(baseEl).direction("reverse");
};
