import { Component, OnInit, ViewChild } from '@angular/core';
import { IonModal, NavParams } from '@ionic/angular';
import { Contacts, ContactFindOptions, ContactFieldType } from '@ionic-native/contacts/ngx';
import { AppFunction } from '../../app.function';
import { AppConfig } from '../../app.config';
import { ContactPage } from '../contact/contact.page';
import { AppMember } from 'src/app/app.account';
import { AppGroupEventMember, AppGroupTripMember } from 'src/app/app.group';
import { Capacitor } from '@capacitor/core';

@Component({
  selector: 'contact-search',
  templateUrl: './contact-search.page.html',
  styleUrls: ['./contact-search.page.scss'],
})
export class ContactSearchPage implements OnInit {

  filteredContacts: any[] = [];
  selectedContacts: any[] = [];
  private _emailContacts: any[] = [];
  private _contacts: any[] = [];
  private _groupMembers: any[] = [];
  private _member: AppMember;
  showSelectedContactModal: boolean = false;
  @ViewChild(IonModal) modal: IonModal;

  constructor(
    public appFunction: AppFunction,
    public contacts: Contacts,
    public navParams: NavParams) {

  }

  ngOnInit() {

    const p = new Promise<void>((resolve) => {

      this.appFunction
        .getContactStatus()
        .then((authorized) => {

          //if authorized to get contacts then go get'em
          if (authorized) {

            this.appFunction
              .loadingCtrl
              .create({ message: 'Getting contacts...' })
              .then((loading) => {

                loading
                  .present()
                  .then(() => {

                    //get contacts
                    const options = new ContactFindOptions();
                    options.multiple = true;

                    //this is needed to speed up the retrieval of the contacts
                    options.desiredFields = ['id', 'name', 'givenName', 'familyName', 'emails', 'photos'];

                    //define fields to get
                    const contactFields: ContactFieldType[] = []
                    contactFields.push('id');
                    contactFields.push('name');
                    contactFields.push('name.givenName');
                    contactFields.push('name.familyName');
                    contactFields.push('emails');
                    contactFields.push('photos');

                    this.contacts
                      .find(contactFields, options)
                      .then((foundContacts) => {
                        //console.log('contact-search.ts ngOnInit get contacts successful. Number found:', foundContacts.length.toString());

                        //get list of names that contain emails
                        for (var i = 0; i < foundContacts.length; i++) {

                          if (foundContacts[i].name !== null) {

                            if (foundContacts[i].emails !== null) {

                              //console.log('contact: ', JSON.stringify(foundContacts[i].emails));

                              for (var j = 0; j < foundContacts[i].emails.length; j++) {
                                if (foundContacts[i].emails[j].value !== null) {
                                  //console.log('contact-search.ts ngOnInit email found', foundContacts[i].name.givenName, foundContacts[i].name.familyName, foundContacts[i].emails[j].value);

                                  let photo: string = 'assets/images/avatar.png';
                                  if (foundContacts[i].photos !== null && foundContacts[i].photos[0] !== null) {

                                    photo = Capacitor.convertFileSrc(foundContacts[i].photos[0].value);
                                    //console.log('contact-search.ts ngOnInit photo found', photo);
                                  }

                                  this._contacts.push({ 'givenName': foundContacts[i].name.givenName, 'familyName': foundContacts[i].name.familyName, 'email': foundContacts[i].emails[j].value.trim(), 'photo': photo, 'hidden': false });
                                }
                              }
                            }
                          }
                        }

                        //console.log('contact-search.ts ionViewWillEnter contacts found with email addresses', JSON.stringify(this.filteredContacts));
                        loading.dismiss();

                        //
                        resolve();

                      })
                      .catch((err) => {
                        console.log('contact-search.ts ngOnInit get contacts error', JSON.stringify(err));
                        loading.dismiss();

                        //ContactError.PERMISSION_DENIED_ERROR display information message to set permissions
                      });

                  });

              });

          } else {
            resolve();
          }

        })
        .catch(() => {
          resolve();
        });

    });

    //once the contact have returned finish up
    p.then(() => {

      //get passed in member so we can...
      this._member = this.navParams.get('member');

      //..get the member's groups and associated group members
      this._member
        .groups
        .all
        .forEach((group) => {

          //for each member...
          group
            .members
            .forEach((groupMember) => {

              //cast to get member
              const member: AppMember = (<AppGroupEventMember | AppGroupTripMember>groupMember).member;

              //...add to the email contacts
              this._groupMembers.push({ 'givenName': member.firstName, 'familyName': member.lastName, 'email': member.email.trim(), 'photo': member.avatar.URI, 'hidden': false });
            })

        });

      //merge both arrays
      this._emailContacts = this._groupMembers.concat(this._contacts);

      //dedup
      const map = new Map();
      this._emailContacts
        .forEach(contact => {
          if (!map.has(contact.email)) {
            map.set(contact.email, contact);
          }
        });

      //convert map back to array
      this._emailContacts = Array.from(map.values());

      //now sort
      this._emailContacts.sortBy('givenName', false, 'familyName', false, 'email', false);

      //now display
      this.filteredContacts = this._emailContacts;

    });

  }

  addContact(contact) {

    try {

      //console.log('contact-search.page.ts addContact', JSON.stringify(contact));
      //console.log('contact-search.page.ts first', contact.givenName);
      //console.log('contact-search.page.ts last', contact.familyName);
      //console.log('contact-search.page.ts email', contact.email, (contact.email || '').toString().match(AppConfig.REGEX.email));

      //get values ready...
      const firstName: string = (contact.givenName || '').trim();
      const lastName: string = (contact.familyName || '').trim();
      const email: string = (contact.email || '').trim();

      //check for valid givenName, familyName, and email
      if (firstName.length > 0 && lastName.length > 0 && email.match(AppConfig.REGEX.email) !== null) {

        //add to return 
        this.selectedContacts.push(contact);
        contact.hidden = true;

        //show the selected contact modal
        this.showSelectedContactModal = true;

      } else {

        //something is not valid with the contact so allow user to see and correct the values
        this.appFunction
          .modalCtrl
          .create({
            component: ContactPage,
            componentProps: {
              firstName: firstName,
              lastName: lastName,
              email: email
            },
          })
          .then((modal) => {

            modal
              .onDidDismiss()
              .then((results) => {

                //if the returned object contains an email field then...
                if (results.data?.contact) {

                  contact.givenName = results.data.contact?.firstName;
                  contact.familyName = results.data.contact?.lastName;
                  contact.email = results.data.contact?.email;

                  //add to return 
                  this.selectedContacts.push(contact);
                  contact.hidden = true;

                  //show the selected contact modal
                  this.showSelectedContactModal = true;

                }

              });

            modal
              .present()
              .catch((err) => {
                console.log('contact-search.ts addContact modal present error', err);
              });

          })
          .catch((err) => {
            console.log('contact-search.ts addContact modal create error', err);
          });

      }

    } catch (err) {
      console.log('Error', 'contact-search.page.ts', undefined, 'addContact', undefined, undefined, { contact: contact }, err);
    }

  }

  searchCriteriaChange(event: any) {

    //only perform a search if the user has entered criteria
    if (event.target.value && event.target.value.length > 0) {

      this.filteredContacts = this._emailContacts
        .filter((contact) => {

          //combine given, family and email together lower case
          const searchIn: string = contact.givenName?.toLowerCase() + contact.familyName?.toLowerCase() + contact.email?.toLowerCase();

          //perform search
          return (searchIn.indexOf(event.target.value.toLowerCase().replace(' ', '')) >= 0);
        });

    } else {
      this.filteredContacts = this._emailContacts;
    }

  }

  searchCriteriaClear(event: any) {
    this.filteredContacts = this._emailContacts;
  }

  removeSelectedContact(contact: any) {

    let contactIndex = this.selectedContacts.indexOf(contact);
    this.selectedContacts.splice(contactIndex, 1);

    //hide the selected contact modal if no contact are selected
    this.showSelectedContactModal = this.selectedContacts.length > 0 ? true : false;

  }

  contactSelected(email: string) {
    return this.selectedContacts.some(function (contact) {
      return contact.email === email;
    });
  }

  done() {

    //dismiss the modal
    this.modal.dismiss()
      .then(() => {
        this.appFunction.modalCtrl.dismiss({ contacts: this.selectedContacts });
      });

  }

}
