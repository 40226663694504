import { Component, OnInit } from '@angular/core';
import { NavController, NavParams } from '@ionic/angular';
import { FormGroup } from '@angular/forms';
import { AppFunction, HelpService } from '../../app.function';
import { AppMatch, AppMatchParentI } from 'src/app/app.match';

@Component({
  selector: 'match-event-detail',
  templateUrl: './match-event-detail.page.html',
  styleUrls: ['./match-event-detail.page.scss']
})
export class MatchEventDetailPage implements OnInit {

  parent: AppMatchParentI;
  match: AppMatch;
  matchForm: FormGroup;
  isFormSubmitted: boolean = false;
  screenKey: string = 'match-format-detail';
  matchOptions: any;

  constructor(
    public appFunction: AppFunction,
    public navParams: NavParams,
    public navCtrl: NavController,
    public helpService: HelpService) {

    helpService.screenWhatsNew(this.screenKey);

  }

  ngOnInit() {
    //get match
    this.match = <AppMatch>this.navParams.get('match');
    this.parent = <AppMatchParentI>this.navParams.get('parent');
    this.matchOptions = this.navParams.get('matchOptions');
  }

  matchChange(matchFromComponent) {
    this.match = matchFromComponent;
  }

  matchFormChange(matchFormFromComponent) {
    this.matchForm = matchFormFromComponent;
  }

  done() {

    try {

      //save the form if dirty or avatar has been updated
      if (this.matchForm.dirty) {

        //signal validation toast that form has been submitted
        this.isFormSubmitted = true;

        //save the form if valid 
        if (this.matchForm.valid) {

          //get form data
          this.match.handicapType = this.matchForm.controls.handicapType.value;
          this.match.handicapAllowance = this.matchForm.controls.handicapAllowance.value;
          this.match.notes = this.matchForm.controls.notes.value;
          this.match.teamSize = this.matchForm.controls.teamSize.value;
          this.match.game = this.matchForm.controls.game.value;
          this.match.grossNetType = this.matchForm.controls.grossNetType.value;
          this.match.scoringType = this.matchForm.controls.scoringType.value;
          this.match.pressOption = this.matchForm.controls.pressOption.value;
          this.match.pressStokesDown = this.matchForm.controls.pressStokesDown.value;
          this.match.stablefordPoints = this.matchForm.controls.stablefordPoints.value;

          //return
          this.appFunction.modalCtrl.dismiss({ match: this.match }, 'update');


        } else {
          //show any untouched errors
          this.appFunction.setDirtyControlAsTouched(this.matchForm);
        }

      } else {
        //no updates
        this.appFunction.modalCtrl.dismiss({ match: this.match }, 'cancel');
      }

    } catch (err) {
      console.log('match-detail.page.ts done error', err);
    }

  }

  cancel() {

    //confirm that user wants to discard changes
    if (this.matchForm.dirty) {

      this.appFunction
        .alertCtrl
        .create({
          header: 'Discard changes?',
          message: 'You have made changes to your Match. Do you want to discard these changes?',
          buttons: [
            {
              text: 'No',
              handler: () => {
              }
            },
            {
              text: 'Yes, discard',
              handler: () => {
                this.appFunction.modalCtrl.dismiss({}, 'cancel');
              }
            }
          ]
        })
        .then((alert) => {
          alert.present();
        });

    } else {
      this.appFunction.modalCtrl.dismiss({}, 'cancel');
    }

  }

}