<ion-header class="privacy-policy-header legal-header ion-no-border">

  <ion-toolbar>

    <ion-buttons slot="primary">

      <ion-button fill="solid" shape="round" color="light" (click)="dismiss()">
        <span>Close</span>
      </ion-button>

    </ion-buttons>

    <ion-title>
      Privacy Policy
    </ion-title>

  </ion-toolbar>

</ion-header>

<ion-content class="privacy-policy-content legal-content">

  <div style="padding: 10px;">

    <div class="main-header">
      PRIVACY POLICY
    </div>
    <div class="sub-header">
      Last Updated March 13, 2022
    </div>
    <div class="paragraph">
      <span>Double Ace Golf LLC</span>
      <span>&nbsp;(“we” or “us” or “our”) respects the privacy of our users (“user” or “you”). This Privacy Policy
        explains how
        we collect, use, disclose, and safeguard your information when you visit our mobile application (the
        “Application”).
        Please read this Privacy Policy carefully. IF YOU DO NOT AGREE WITH THE TERMS OF THIS PRIVACY POLICY, PLEASE DO
        NOT
        ACCESS THE APPLICATION.
        <br>
        <br>We reserve the right to make changes to this Privacy Policy at any time and for any reason. We will alert
        you about
        any changes by updating the “Last updated” date of this Privacy Policy. You are encouraged to periodically
        review
        this Privacy Policy to stay informed of updates. You will be deemed to have been made aware of, will be subject
        to,
        and will be deemed to have accepted the changes in any revised Privacy Policy by your continued use of the
        Application
        after the date such revised Privacy Policy is posted. &nbsp;
        <br>
        <br>This Privacy Policy does not apply to the third-party online/mobile store from which you install the
        Application
        or make payments, including any in-game virtual items, which may also collect and use data about you. &nbsp;We
        are
        not responsible for any of the data collected by any such third party. &nbsp;
      </span>
    </div>
    <div class="header">
      COLLECTION OF YOUR INFORMATION
    </div>
    <div class="paragraph">
      We may collect information about you in a variety of ways. The information we may collect via the Application
      depends on
      the content and materials you use, and includes:
    </div>
    <div class="sub-header">
      Personal Data
    </div>
    <div class="paragraph">
      Demographic and other personally identifiable information (such as your name and email address) that you
      voluntarily give
      to us when choosing to participate in various activities related to the Application, such as chat, posting
      messages
      in comment sections or in our forums, liking posts, sending feedback, and responding to surveys. If you choose to
      share
      data about yourself via your profile, online chat, or other interactive areas of the Application, please be
      advised
      that all data you disclose in these areas is public and your data will be accessible to anyone who accesses the
      Application.
    </div>
    <div class="sub-header">
      Mobile Device Access
    </div>
    <div class="paragraph">
      <span>We may request access or permission to certain features from your mobile device, including your mobile
        device’s
        <span>camera</span>,
        <span>contacts</span>,
        <span>storage</span>,
        <span>SMS messages</span>, and other features. If you wish to change our access or permissions, you may do so in
        your device’s
        settings.
      </span>
    </div>
    <div class="sub-header">
      Push Notification
    </div>
    <div class="paragraph">
      We may request to send you push notifications regarding your account or the Application. If you wish to opt-out
      from receiving
      these types of communications, you may turn them off in your device’s settings.
    </div>
    <div class="header">
      USE OF YOUR INFORMATION
    </div>
    <div class="paragraph">
      Having accurate information about you permits us to provide you with a smooth, efficient, and customized
      experience. Specifically,
      we may use information collected about you via the Application to:
    </div>
    <div>
      ● Create and manage your account.
    </div>
    <div>
      ● Enable user-to-user communications.
    </div>
    <div>
      ● Notify you of updates to the Application.
    </div>
    <div class="header" style="margin-top: 20px">
      DISCLOSURE OF YOUR INFORMATION
    </div>
    <div class="paragraph">
      We may share information we have collected about you in certain situations. Your information may be disclosed as
      follows:
    </div>
    <div class="sub-header">
      By Law or to Protect Rights
    </div>
    <div class="paragraph">
      If we believe the release of information about you is necessary to respond to legal process, to investigate or
      remedy potential
      violations of our policies, or to protect the rights, property, and safety of others, we may share your
      information
      as permitted or required by any applicable law, rule, or regulation. This includes exchanging information with
      other
      entities for fraud protection and credit risk reduction.
    </div>
    <div class="sub-header">
      Interaction with Others
    </div>
    <div class="paragraph">
      If you interact with other users of the Application, those users may see your name, profile photo, and
      descriptions of your
      activity, including sending invitations to other users, chatting with other users, liking posts, following blogs.
    </div>
    <div class="sub-header">
      Online Postings
    </div>
    <div class="paragraph">When you post comments, contributions or other content to the Application, your posts may be
      viewed by all users and
      may be publicly distributed outside the Application in perpetuity.
    </div>
    <div class="sub-header">
      Other Third Parties
    </div>
    <div class="paragraph">We may share your information with advertisers and investors for the purpose of conducting
      general business analysis.
      We may also share your information with such third parties for marketing purposes, as permitted by law.
    </div>
    <div class="sub-header">
      Sale or Bankruptcy
    </div>
    <div class="paragraph">If we reorganize or sell all or a portion of our assets, undergo a merger, or are acquired by
      another entity, we may
      transfer your information to the successor entity. &nbsp;If we go out of business or enter bankruptcy, your
      information
      would be an asset transferred or acquired by a third party. You acknowledge that such transfers may occur and that
      the transferee may decline honor commitments we made in this Privacy Policy. We are not responsible for the
      actions
      of third parties with whom you share personal or sensitive data, and we have no authority to manage or control
      third-party
      solicitations. If you no longer wish to receive correspondence, emails or other communications from third parties,
      you are responsible for contacting the third party directly.
    </div>
    <div class="header">
      THIRD-PARTY WEBSITES
    </div>
    <div class="paragraph">The Application may contain links to third-party websites and applications of interest,
      including advertisements and
      external services, that are not affiliated with us. Once you have used these links to leave the Application, any
      information
      you provide to these third parties is not covered by this Privacy Policy, and we cannot guarantee the safety and
      privacy
      of your information. Before visiting and providing any information to any third-party websites, you should inform
      yourself
      of the privacy policies and practices (if any) of the third party responsible for that website, and should take
      those
      steps necessary to, in your discretion, protect the privacy of your information. We are not responsible for the
      content
      or privacy and security practices and policies of any third parties, including other sites, services or
      applications
      that may be linked to or from the Application.
    </div>
    <div class="header">
      SECURITY OF YOUR INFORMATION
    </div>
    <div class="paragraph">We use administrative, technical, and physical security measures to help protect your
      personal information. While we
      have taken reasonable steps to secure the personal information you provide to us, please be aware that despite our
      efforts, no security measures are perfect or impenetrable, and no method of data transmission can be guaranteed
      against
      any interception or other type of misuse. Any information disclosed online is vulnerable to interception and
      misuse
      by unauthorized parties. Therefore, we cannot guarantee complete security if you provide personal information.
    </div>
    <div class="header">
      POLICY FOR CHILDREN
    </div>
    <div class="paragraph">We do not knowingly solicit information from or market to children under the age of 13. If
      you become aware of any data
      we have collected from children under age 13, please contact us using the contact information provided below.
    </div>
    <div class="header">
      CONTROLS FOR DO-NOT-TRACK FEATURES
    </div>
    <div class="paragraph">Most web browsers and some mobile operating systems include a Do-Not-Track (“DNT”) feature or
      setting you can activate
      to signal your privacy preference not to have data about your online browsing activities monitored and collected.
      &nbsp;No
      uniform technology standard for recognizing and implementing DNT signals has been finalized. As such, we do not
      currently
      respond to DNT browser signals or any other mechanism that automatically communicates your choice not to be
      tracked
      online. If a standard for online tracking is adopted that we must follow in the future, we will inform you about
      that
      practice in a revised version of this Privacy Policy.
    </div>
    <div class="header">
      OPTIONS REGARDING YOUR INFORMATION
    </div>
    <div class="sub-header">Account Information
    </div>
    <div class="paragraph">
      You may at any time review or change the information in your account or terminate your account by:
    </div>
    <div>
      ● Logging into user account settings and updating the user account.
    </div>
    <div>
      ● Contacting us using the contact information provided.
    </div>
    <div>
      ● support&#64;doubleacegolf.com
    </div>
    <div class="paragraph" style="margin-top: 20px">
      Upon your request to terminate your account, we will deactivate or delete your account and information from our
      active databases.
      However, some information may be retained in our files to prevent fraud, troubleshoot problems, assist with any
      investigations,
      enforce our Terms of Use and/or comply with legal requirements.
    </div>
    <div class="sub-header">
      Emails and Communications
    </div>
    <div class="paragraph">
      If you no longer wish to receive correspondence, emails, or other communications from us, you may opt-out by:
    </div>
    <div>
      ● Contacting us using the contact information provided below.
    </div>
    <div>
      ● support&#64;doubleacegolf.com
    </div>
    <div class="paragraph" style="margin-top: 20px">
      If you no longer wish to receive correspondence, emails, or other communications from third parties, you are
      responsible
      for contacting the third party directly.
    </div>
    <div class="header">
      CALIFORNIA PRIVACY RIGHTS
    </div>
    <div class="paragraph">
      California Civil Code Section 1798.83, also known as the “Shine The Light” law, permits our users who are
      California residents
      to request and obtain from us, once a year and free of charge, information about categories of personal
      information
      (if any) we disclosed to third parties for direct marketing purposes and the names and addresses of all third
      parties
      with which we shared personal information in the immediately preceding calendar year. If you are a California
      resident
      and would like to make such a request, please submit your request in writing to us using the contact information
      provided
      below.
    </div>
    <div class="paragraph">
      If you are under 18 years of age, reside in California, and have a registered account with the Application, you
      have the
      right to request removal of unwanted data that you publicly post on the Application. To request removal of such
      data,
      please contact us using the contact information provided below, and include the email address associated with your
      account and a statement that you reside in California. &nbsp;We will make sure the data is not publicly displayed
      on
      the Application, but please be aware that the data may not be completely or comprehensively removed from our
      systems.
    </div>
    <div class="header">
      CONTACT US
    </div>
    <div class="paragraph">
      If you have questions or comments about this Privacy Policy, please contact us at:
    </div>
    <div>
      Double Ace Golf LLC
    </div>
    <div>
      PO Box 854
    </div>
    <div>
      Newtown, PA, 18940
    </div>
    <div>
      United States
    </div>
    <div>
      support&#64;doubleacegolf.com
    </div>

  </div>

</ion-content>