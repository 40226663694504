import { Component, Input } from '@angular/core';
import { ScoringMode, } from 'src/app/app.event';
import { AppMatch, TotalScoreLeaderboardEventTeamI } from 'src/app/app.match';
import { GameType, HandicapType } from '../../app.config';
import { MatchMemberDetailPage } from '../../pages/match-member-detail/match-member-detail.page';
import { MatchFormatViewPage } from '../../pages/match-format-view/match-format-view.page';
import { AppFunction } from '../../app.function';
import { AccountService } from '../../app.account';
import { AppConfig } from '../../app.config';
import { AppGroupTrip } from 'src/app/app.group';

@Component({
  selector: 'trip-match-live-view',
  templateUrl: './trip-match-live-view.component.html',
  styleUrls: ['./trip-match-live-view.component.scss']
})
export class TripMatchLiveViewComponent {

  //get from the host html
  @Input() match: AppMatch;
  @Input() group: AppGroupTrip;
  @Input() callback;
  @Input() openScorecardMethod: (...args: any) => void;
  HandicapType: typeof HandicapType = HandicapType;
  ScoringMode: typeof ScoringMode = ScoringMode;
  GameType: typeof GameType = GameType;
  private _currentRoundIndex: number = -1;
  private _currentRoundId: string;

  constructor(
    public appFunction: AppFunction,
    public accountService: AccountService
  ) {

  }

  viewMatchFormat() {

    this.appFunction
      .modalCtrlCreate({
        component: MatchFormatViewPage,
        componentProps: {
          match: this.match,
        },
        cssClass: 'custom-modal-auto-height',
        canDismiss: true
      })
      .then((modal) => {

        modal
          .present()
          .catch((err) => {
            console.log('match.component.ts viewMatchFormat modal present error', err);
          });

      })
      .catch((err) => {
        console.log('match.component.ts viewMatchFormat modal create error', err);
      });

  }

  async editMatchFormat() {

    this.appFunction
      .modalCtrlCreate({
        component: MatchMemberDetailPage,
        presentingElement: await this.appFunction.routerOutlet(),
        componentProps: {
          match: this.match,
          editMode: AppConfig.EDIT_MODE.update,
          matchOptions: AppConfig.TRIP_MATCH_CONFIGURATION
        },
        cssClass: 'custom-modal', //for md
        canDismiss: true
      })
      .then((modal) => {

        modal
          .present()
          .catch((err) => {
            console.log('match.component.ts editMatchFormat modal present error', err);
          });

        modal
          .onDidDismiss()
          .then((result) => {

            //if match was updated...
            if (result.role === 'update') {

              //...signal back to parent
              this.callback();

            }

          });

      })
      .catch((err) => {
        console.log('match.component.ts editMatchFormat modal create error', err);
      });

  }

  currentEventIndex(): number {

    this._currentRoundIndex = -1;

    //interate through the trip events and determine which is the most current event. use a for loop so we can break out of it
    for (let i = 0; i < this.group.events.all.length; i++) {

      //get the event
      const event = this.group.events.all[i];

      //if the event is active or complete then it is the current round
      if ([ScoringMode.ScoringActive, ScoringMode.ScoringComplete].includes(event.players.active.scoringMode)) {
        this._currentRoundIndex = i;
        this._currentRoundId = event.id;

        //if the event is active then we are done
        if (event.players.active.scoringMode === ScoringMode.ScoringActive) {
          break;
        }
      }

    }

    /* //interate through the trip events and determine which is the most current event
    this.group.events.all.forEach((event) => {

      //if the event is active or complete then it is the current round
      if ([ScoringMode.ScoringActive, ScoringMode.ScoringComplete].includes(event.players.active.scoringMode)) {
        this._currentRoundIndex++;
        this._currentRoundId = event.id;

        //if the event is active then we are done
        if (event.players.active.scoringMode === ScoringMode.ScoringActive) {
          return;
        }
      }

    }); */

    //if there is no current index (is -1) then set to 0, we do this when the trip hasn't started yet
    if (this._currentRoundIndex === -1) {
      this._currentRoundIndex = 0;
      this._currentRoundId = this.group.events.all[0]?.id;
    }

    return this._currentRoundIndex;

  }

  currentEventTeam(teamId: string): TotalScoreLeaderboardEventTeamI {
    return this.match.subMatches[0].totalScoreLeaderboard.events[this._currentRoundId]?.teams[teamId];
  }

  aggregateEventIndex(): number {
    return this.group.events.all.length
  }

  aggregateEventId(): string {
    //this is the submatch id
    return this.match.subMatches[0].id;
  }

}
