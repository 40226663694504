import { Injectable } from '@angular/core';
import { Router, NavigationExtras } from '@angular/router';
import { NavController } from '@ionic/angular';
import { AppConfig, FirestoreUpdateType } from './app.config';
import { AppFunction, DeepLinkService, SortByOrder } from './app.function';
import { MediaService, imageManagementI } from './app.media';
import { GroupService, AppGroupI, AppGroupTrip, GroupType } from './app.group';
import { AppFollowing, AppPost, SocialService } from './app.social'
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { User } from '@firebase/auth-types'
import firebase from 'firebase/compat/app';
import { TouchID } from '@ionic-native/touch-id/ngx';
import { Storage } from '@ionic/storage';
import { Observable, Subscription, Subscriber, BehaviorSubject, Subject } from 'rxjs';
import { AppEvent, EventService, EventType } from './app.event';
import * as moment from 'moment';
import { environment } from '../environments/environment';
import { PushNotifications } from '@capacitor/push-notifications';
import * as SentryAngular from "@sentry/angular-ivy";

export interface memberPreferenceI {
    key: string;
    value: any;
    dirty: boolean;
}

export interface AppMemberI {
    email: string;
    emailSearch: string;
    firstName: string;
    firstNameSearch: string;
    lastName: string;
    lastNameSearch: string;
    admin: boolean;
    avatarFileName: string;
    privateAccount: boolean;
    preferences: {}
    lastLoginDt: firebase.firestore.Timestamp;
    updatedDt: firebase.firestore.Timestamp;
    createdDt: firebase.firestore.Timestamp;
    appVersion: string;
    postCount: number;
    //ghinNumber: number;
    handicapIndex: number;
    handicapIndexDt: firebase.firestore.Timestamp;
}

export class AppMember implements AppMemberI {

    id: string;
    dynamicData: any = {};
    exists: boolean = false;
    updatehandicapIndex: Subject<number> = new Subject<number>();
    private _lastLoginDt: firebase.firestore.Timestamp;
    private _groups: AppGroupI[];
    private _events: AppEvent[] = [];
    private _firstName: string;
    private _lastName: string;
    private _admin: boolean = false;
    private _avatarFileName: string = '';
    private _privateAccount: boolean = false;
    private _preferences: {}
    private _createdDt: firebase.firestore.Timestamp;
    private _appVersion: string;
    private _postCount: number = 0;
    //private _ghinNumber: number;
    private _handicapIndex: number;
    private _handicapIndexDt: firebase.firestore.Timestamp;
    private _dirty: boolean = false;
    private _email: string;
    private _preferencesDecomposed: memberPreferenceI[] = [];
    private _appFunction: AppFunction;
    private _mediaService: MediaService;
    private _eventService: EventService;
    private _appGroupService: GroupService;
    private _appSocialService: SocialService;
    private _appEventService: EventService;
    private _accountService: AccountService;
    private _memberDoc: firebase.firestore.DocumentSnapshot;
    private _getHandicapIndexError: string;

    constructor() {

        try {

            //get services
            this._appFunction = AppFunction.serviceLocator.get(AppFunction);
            this._appGroupService = AppFunction.serviceLocator.get(GroupService);
            this._mediaService = AppFunction.serviceLocator.get(MediaService);
            this._appSocialService = AppFunction.serviceLocator.get(SocialService);
            this._appEventService = AppFunction.serviceLocator.get(EventService);
            this._accountService = AppFunction.serviceLocator.get(AccountService);
            this._eventService = AppFunction.serviceLocator.get(EventService);

            //clean up on app logout
            this._appFunction
                .shutDown
                .subscribe(() => {
                    //console.log('app.account.ts AppMember shutdown', this.id);
                });

        }
        catch (err) {
            console.log('app.account.ts AppMember constructor', err);
            throw err;
        }

    }

    //call this to initialize the object
    async initialize(memberDoc: firebase.firestore.QueryDocumentSnapshot = undefined): Promise<AppMember> {

        try {

            //if member doc passed in then this is an existing member, else this is a new member
            if (memberDoc) {
                //save document refenence
                this._memberDoc = memberDoc;
            } else {
                //create new document refenence
                this._memberDoc = await this._appFunction.firestore.collection(AppConfig.COLLECTION.Members).doc().get();
            }

            //save id
            this.id = this._memberDoc.id;

            //add to member service cache
            this._accountService.addMember(this);

            //update
            this.update(this._memberDoc);

            //return
            return this;

        } catch (err) {
            //write sentry error
            SentryAngular.captureException(err, {
                tags: {
                    email: this._accountService.member.email,
                    method: 'app.account.ts AppMember initialize error'
                }
            });
        }

        /* return new Promise<AppMember>(async (resolve) => {

            try {

                try {

                    //if member doc passed in then this is an existing member, else this is a new member
                    if (memberDoc) {
                        //save document refenence
                        this._memberDoc = memberDoc;
                    } else {
                        //create new document refenence
                        this._memberDoc = await this._appFunction.firestore.collection(AppConfig.COLLECTION.Members).doc().get();
                    }

                    //save id
                    this.id = this._memberDoc.id;

                    //add to member service cache
                    this._accountService.addMember(this);

                    //listen for member updates
                    const memberSnapShotUnsubscribe = this._memberDoc
                        .ref
                        .onSnapshot((memberUpdate) => {
                            this.update(memberUpdate);
                        });

                    this._appFunction.registerUnsubscribe(memberSnapShotUnsubscribe);

                    //return
                    resolve(this);

                } catch (err) {
                    //write sentry error
                    SentryAngular.captureException(err, {
                        tags: {
                            email: this._accountService.member.email,
                            method: 'app.account.ts AppMember initialize error'
                        }
                    });
                }

            }
            catch (err) {
                console.log('app.account.ts AppMember initialize', err);
                throw err;
            }

        }); */

    }

    update(updatedMember: firebase.firestore.DocumentSnapshot) {

        try {

            this._appFunction
                .ngZone
                .run(() => {

                    if (updatedMember.exists) {

                        //if email is undefined write console
                        if (!updatedMember.data().email) {
                            console.log('app.member.ts AppMember update email is undefined', updatedMember.id, updatedMember.data());
                        }

                        //update high level attributes
                        this._email = updatedMember.data().email;
                        this._firstName = (updatedMember.data().firstName || '').capitalize();
                        this._lastName = (updatedMember.data().lastName || '').capitalize();
                        this._avatarFileName = updatedMember.data().avatarFileName || '';
                        this._privateAccount = updatedMember.data().privateAccount || false;
                        this._admin = updatedMember.data().admin || false;
                        this._preferences = updatedMember.data().preferences || {};
                        this._createdDt = updatedMember.data().createdDt || undefined;
                        this._appVersion = updatedMember.data().appVersion;
                        this._postCount = updatedMember.data().postCount || 0;
                        //this._ghinNumber = updatedMember.data().ghinNumber || undefined;
                        this._handicapIndex = updatedMember.data().handicapIndex || undefined;
                        this._handicapIndexDt = updatedMember.data().handicapIndexDt || undefined;
                        this._memberDoc = updatedMember;
                        this._lastLoginDt = updatedMember.data().lastLoginDt || undefined;
                        this.exists = true;

                        //decompose preferences object into an array
                        this._preferencesDecomposed = this._appFunction.decomposeMemberPreference(this.preferences);

                    } else {
                        this.exists = false;
                    }

                });

        } catch (err) {
            console.log('app.member.ts AppMember update error', JSON.stringify(err));
            throw err;
        }

    }

    //#region properties

    get email(): string {
        return this._email;
    }

    set email(email: string) {
        this._email = email;
        this._dirty = true;
    }

    get firstName(): string {
        return this._firstName;
    }

    set firstName(firstName: string) {
        this._firstName = firstName;
        this._dirty = true;
    }

    get lastName(): string {
        return this._lastName;
    }

    set lastName(lastName: string) {
        this._lastName = lastName;
        this._dirty = true;
    }

    get admin(): boolean {
        return this._admin;
    }

    set admin(admin: boolean) {
        this._admin = admin;
        this._dirty = true;
    }

    get avatarFileName(): string {
        return this._avatarFileName;
    }

    set avatarFileName(avatarFileName: string) {
        this._avatarFileName = avatarFileName;
        this._dirty = true;
    }

    get privateAccount(): boolean {
        return this._privateAccount;
    }

    set privateAccount(privateAccount: boolean) {
        this._privateAccount = privateAccount;
        this._dirty = true;
    }

    get appVersion(): string {
        return this._appVersion;
    }

    set appVersion(appVersion: string) {
        this._appVersion = appVersion;
        this._dirty = true;
    }

    get lastLoginDt(): firebase.firestore.Timestamp {
        return this._lastLoginDt;
    }

    set lastLoginDt(lastLoginDt: firebase.firestore.Timestamp) {
        this._lastLoginDt = lastLoginDt;
        this._dirty = true;
    }

    get emailSearch(): string {
        return this.email.trim().toUpperCase()
    }

    get firstNameSearch(): string {
        return this.firstName ? this.firstName.trim().toUpperCase() : null; //could be null if importing via contacts
    }

    get lastNameSearch(): string {
        return this.lastName ? this.lastName.trim().toUpperCase() : null; //could be null if importing via contacts
    }

    get updatedDt(): firebase.firestore.Timestamp {
        return firebase.firestore.Timestamp.fromDate(new Date());
    };


    /* get ghinNumber(): number {
        return this._ghinNumber;
    } */

    /* set ghinNumber(ghinNumber: number) {

        //if ghin is different
        if (this._ghinNumber !== ghinNumber) {

            this._ghinNumber = ghinNumber;
            this._dirty = true;

            //if there's a new ghin then clear handicap index so that getHandicapIndex() can call ghin api
            this.handicapIndexDt = undefined

            //get handicap index
            this.getHandicapIndex();

        }

    } */

    get handicapIndex(): number {
        return this._handicapIndex;
    }

    set handicapIndex(newHandicapIndex: number) {

        //if handicap is new or different then set new handicapDt
        if ((this._handicapIndex !== newHandicapIndex) || (this._handicapIndex === undefined && newHandicapIndex !== undefined)) {

            //set new
            this._handicapIndex = newHandicapIndex;

            //now set date
            this.handicapIndexDt = firebase.firestore.Timestamp.now();

            //make dirty
            this._dirty = true;

        } else if ([null, undefined].includes(newHandicapIndex)) { //if clearing out the index then clear out the date

            this._handicapIndex = undefined;
            this._handicapIndexDt = undefined;

            //make dirty
            this._dirty = true;
        }

    }

    get handicapIndexDt(): firebase.firestore.Timestamp {
        return this._handicapIndexDt;
    }

    set handicapIndexDt(handicapIndexDt: firebase.firestore.Timestamp) {
        this._handicapIndexDt = handicapIndexDt;
        this._dirty = true;
    }

    get createdDt(): firebase.firestore.Timestamp {
        return this._createdDt;
    }

    get preferences(): any {
        return this._preferences;
    }

    set preferences(preferences: any) {
        this._preferences = preferences;
        this._dirty = true;
    }

    get postCount(): number {
        return this._postCount;
    }

    set postCount(postCount: number) {
        this._postCount = postCount;
        this._dirty = true;
    }

    get dirty(): boolean {
        return this._dirty;
    }

    get getHandicapIndexError(): string {
        return this._getHandicapIndexError;
    }

    set getHandicapIndexError(errorMessage: string) {
        this._getHandicapIndexError = errorMessage;
    }

    //#endregion

    get eventsAndTrips(): (AppEvent | AppGroupTrip)[] {

        //return events...
        const events: AppEvent[] = this._events
            .filter((event) => {
                return event.exists && !event.deleted //that aren't canceled
                    && [EventType.GroupEvent, EventType.Personal].includes(event.type) //that are of event or personal type (no trip group events)
                    && moment().startOf('day').isSameOrBefore(moment(event.eventDt.toDate()).endOf('day')); //that are today or in the future
            });

        //return trip groups...
        const trips: AppGroupTrip[] = <AppGroupTrip[]>this._groups
            .filter((group) => {
                return group.exists && !group.deleted //that aren't canceled
                    && (<AppGroupTrip>group).type === GroupType.Trip //that are of trip group type
                    && moment().startOf('day').isSameOrBefore((<AppGroupTrip>group).returnDt.toDate()); //that are today or in the future
            });

        //marge arrays
        const eventsAndTrips: (AppEvent | AppGroupTrip)[] = [...events, ...trips];

        //sort events by event date
        eventsAndTrips.sortBy('sortByDt', SortByOrder.ASC);

        //return
        return eventsAndTrips;

    }

    get groupsEvent(): AppGroupI[] {

        return this._groups
            .filter((group) => {
                return group.exists && !group.deleted && group.type === GroupType.Event;
            });

    }

    addEvent(newEvent: AppEvent) {

        //see if event already displayed
        //this is done because nonGroupEvents are query by player and creater which could bring the same event... 
        //...back in the event (probably most cases) the creater is also a player  
        const index: number = this._events.findIndex((event) => {
            return event.id === newEvent.id;
        });

        //if event not found then add it
        if (index === -1) {
            this._events.push(newEvent);
        }

    }

    //avatar methods
    avatar = new class implements imageManagementI {

        constructor(private parentMember: AppMember) {
        }

        save(localFileURI: string): Promise<void> {

            return new Promise<void>((resolve, reject) => {

                this.delete(false)
                    .then(() => {

                        //upload aand save avatar reference
                        this
                            .saveAvatarToStorage(localFileURI)
                            .then(() => {
                                resolve();
                            })
                            .catch((err) => {
                                console.log('app.account.ts AppMember saveAvatar saveAvatarToStorage error', JSON.stringify(err));
                                reject(err);
                            });

                    });

            });

        }

        private saveAvatarToStorage(localFileURI: string): Promise<void> {

            return new Promise<void>((resolve, reject) => {

                //now save avatar file
                this.parentMember
                    ._mediaService
                    .saveMedia(localFileURI, this.parentMember._appFunction.newGuid(), AppConfig.MEDIA_STORAGE.AVATAR)
                    .then((avatarURL) => {

                        //set avatar name
                        this.parentMember.avatarFileName = avatarURL;

                        //now save new avatar name
                        this.parentMember
                            .save()
                            .then(() => {
                                resolve();
                            })
                            .catch((err) => {
                                console.log('app.account.ts AppMember saveAvatarToStorage save error', JSON.stringify(err));
                                reject(err);
                            });

                    });

            });

        }

        delete(save: boolean): Promise<void> {

            return new Promise<void>((resolve, reject) => {

                if (this.parentMember.avatarFileName.trim().length > 0) {

                    this.parentMember
                        ._mediaService
                        .deleteMedia(this.parentMember.avatarFileName)
                        .then(() => {

                            //now optionally save
                            if (save) {

                                //clear out avatar
                                this.parentMember.avatarFileName = '';

                                this.parentMember
                                    .save()
                                    .then(() => {
                                        resolve();
                                    })
                                    .catch((err) => {
                                        console.log('app.account.ts AppMember deleteAvatar save error', JSON.stringify(err));
                                        reject(err);
                                    });

                            } else {
                                //no op
                                resolve();
                            }

                        })
                        .catch((err) => {
                            console.log('app.account.ts AppMember deleteAvatar remove old avatar error', err, JSON.stringify(err));
                            reject();
                        });

                } else {
                    //noop, no image to delete
                    console.log('app.account.ts AppMember deleteAvatar no image to delete');
                    resolve()
                }

            });

        }

        get URI(): string {

            if (this.parentMember.avatarFileName.length > 0) {
                return this.parentMember.avatarFileName;
            } else {
                return AppConfig.NO_AVATAR_URI;
            }

        }

        get URIEmail(): string {

            if (this.parentMember.avatarFileName.length > 0) {
                return this.parentMember.avatarFileName;
            } else {
                return AppConfig.NO_AVATAR_URI_EMAIL;
            }

        }

    }(this);

    //social methods
    social = new class {

        private _followers: AppFollowing[];
        private _following: AppFollowing[];
        private _posts: AppPost[];
        private _lastPostCreatedDt: firebase.firestore.Timestamp = firebase.firestore.Timestamp.now();
        private _futurePostsUnsubscribe: any;

        constructor(private parentMember: AppMember) {
        }

        //listen for changes
        get following(): AppFollowing[] {

            //return only "active" 
            return this._following?.filter((following) => {
                return following.exists;
            }) || [];

        }

        //listen for changes
        get followers(): AppFollowing[] {

            //return only "active" 
            return this._followers?.filter((follower) => {
                return follower.exists;
            }) || [];

        }

        //get list of members that "this.member" is following
        getFollowing(initialize: boolean = false): Promise<AppFollowing[]> {

            return new Promise<AppFollowing[]>((resolve, reject) => {

                try {

                    //return following if already queried
                    if (initialize) {

                        //initialize the array
                        this._following = [];

                        const promiseArray: any[] = [];

                        //create snapshot listener
                        const followingSnapshotUnsubscribe = this.parentMember._appFunction.firestore
                            .collection(AppConfig.COLLECTION.Following)
                            .where('memberId', '==', this.parentMember.id)
                            .onSnapshot((foundFollowingMembers) => {

                                //get social following updates
                                foundFollowingMembers
                                    .docChanges()
                                    .forEach((foundFollowingMember) => {

                                        if (foundFollowingMember.type === FirestoreUpdateType.Added) {

                                            const q = this.parentMember
                                                ._appSocialService
                                                .getFollowing(foundFollowingMember.doc.id, foundFollowingMember.doc)
                                                .toPromise()
                                                .then((following) => {
                                                    this._following.push(following);
                                                });

                                            promiseArray.push(q);

                                        }

                                    });

                                Promise.all(promiseArray)
                                    .then(() => {
                                        resolve(this.following);
                                    });

                            }, (err) => {
                                console.log('app.account.ts getFollowing onSnapshot error', err);
                                reject(err);
                            });

                        this.parentMember._appFunction.registerUnsubscribe(followingSnapshotUnsubscribe);

                    } else {
                        resolve(this.following);
                    }

                } catch (err) {
                    console.log('app.account.ts AppMember social getFollowing error', err);
                    reject(err);
                }


            });

        }

        //get list of members that follow "this.member"  
        getFollowers(initialize: boolean = false): Promise<AppFollowing[]> {

            return new Promise<AppFollowing[]>((resolve, reject) => {

                try {

                    //only query once...
                    if (initialize) {

                        //make array
                        this._followers = [];

                        //create the array for promises
                        const promiseArray: any[] = [];

                        //create snapshot listener
                        const followedSnapshotUnsubscribe = this.parentMember._appFunction.firestore
                            .collection(AppConfig.COLLECTION.Following)
                            .where('followedMemberId', '==', this.parentMember.id)
                            .onSnapshot((foundFollowers) => {

                                //get social following updates
                                foundFollowers
                                    .docChanges()
                                    .forEach((foundFollower) => {

                                        if (foundFollower.type === FirestoreUpdateType.Added) {
                                            //.if (foundFollower.type === FirestoreUpdateType.Added && foundFollower.doc.metadata.fromCache === false) {

                                            const q = this.parentMember
                                                ._appSocialService
                                                .getFollowing(foundFollower.doc.id, foundFollower.doc)
                                                .toPromise()
                                                .then((following) => {
                                                    this._followers.push(following);
                                                });

                                            promiseArray.push(q);

                                        }

                                    });

                                Promise.all(promiseArray)
                                    .then(() => {
                                        resolve(this.followers);
                                    });

                            }, (err) => {
                                console.log('app.account.ts getFollowers onSnapshot error', err);
                                reject(err);
                            });

                        this.parentMember._appFunction.registerUnsubscribe(followedSnapshotUnsubscribe);

                    } else {
                        resolve(this.followers);
                    }

                } catch (err) {
                    console.log('app.account.ts AppMember social getFollowers error', err);
                    reject(err);
                }

            });

        }

        get requestedFollowerCount(): number {

            //determine if there is a change in private account requests
            return this._followers.filter((follower) => {
                return follower.status === AppConfig.SOCIAL_STATUS.Requested;
            }).length;

        }

        requestToFollow(member: AppMember): Promise<void> {

            return new Promise<void>((resolve, reject) => {

                try {

                    //just in case we haven't retrieve followers for this member
                    this.getFollowers()
                        .then((followers) => {

                            //find a follower record for the passed in member
                            const memberFollower: AppFollowing = followers.find((follow) => {
                                return follow.member.id === member.id;
                            });

                            //if memberFollowing found then update...
                            if (memberFollower) {

                                //if the member's account is private then...
                                if (this.parentMember.privateAccount) {
                                    memberFollower.status = AppConfig.SOCIAL_STATUS.Requested;
                                } else {
                                    memberFollower.status = AppConfig.SOCIAL_STATUS.Following;
                                }

                                //...then save
                                memberFollower
                                    .save()
                                    .then(() => {
                                        resolve();
                                    })
                                    .catch((err) => {
                                        console.log('app.account.ts AppMember social requestToFollow', err);
                                        reject(err);
                                    });

                            } else {

                                //create class
                                const newMemberFollower: AppFollowing = new AppFollowing();

                                //initialize
                                newMemberFollower
                                    .initialize()
                                    .then(() => {

                                        //set ids
                                        newMemberFollower.followedMemberId = this.parentMember.id;
                                        newMemberFollower.memberId = member.id;

                                        //if the member's account is private then...
                                        if (this.parentMember.privateAccount) {
                                            newMemberFollower.status = AppConfig.SOCIAL_STATUS.Requested;
                                        } else {
                                            newMemberFollower.status = AppConfig.SOCIAL_STATUS.Following;
                                        }

                                        //now save...
                                        newMemberFollower
                                            .save()
                                            .then(() => {
                                                resolve();
                                            })
                                            .catch((err) => {
                                                console.log('app.account.ts AppMember social requestToFollow save', err);
                                                reject(err);
                                            });

                                    });

                            }

                        });

                }
                catch (err) {
                    console.log('app.account.ts AppMember requestToFollow error', err, JSON.stringify(err));
                    reject(err);
                }

            });

        }

        isFollower(member: AppMember): AppFollowing {

            try {

                return this.followers.find((follower) => {
                    return follower.member.id === member.id;
                });

            } catch (err) {
                console.log('app.account.ts AppMember isFollower error', err, JSON.stringify(err));
            }

        }

        getPosts(): Promise<AppPost[]> {

            return new Promise<AppPost[]>((resolve) => {

                //console.log('app.account.ts getPosts');

                if (Array.isArray(this._posts)) {
                    resolve(this._posts);
                } else {

                    //initialize array
                    this._posts = [];

                    //get first set of posts
                    this.getNextPosts()
                        .then((posts) => {
                            resolve(posts);
                        });
                }

            });

        }

        getNextPosts(): Promise<AppPost[]> {

            return new Promise<AppPost[]>((resolve, reject) => {

                try {

                    const promiseArray: any[] = [];

                    //array for this call
                    const nextPosts: AppPost[] = [];

                    //create snapshot listener
                    this.parentMember._appFunction.firestore
                        .collection(AppConfig.COLLECTION.Posts)
                        .where('memberId', '==', this.parentMember.id)
                        .where('createdDt', '<', this._lastPostCreatedDt)
                        .orderBy('createdDt', 'desc')
                        .limit(AppConfig.NUMBER_OF_POSTS_TO_FETCH)
                        .get({ source: 'server' })
                        .then((foundPosts) => {

                            //get social following updates
                            foundPosts
                                .docs
                                .forEach((foundPost) => {

                                    //create following object with data retrieved from following collection
                                    const post: AppPost = new AppPost();

                                    //once object has been initialized...
                                    const p = post
                                        .initialize(foundPost)
                                        .then(() => {

                                            //save last retrieved post date (this will be used to get the next set of posts)
                                            this._lastPostCreatedDt = post.createdDt;

                                            //posts for this call
                                            nextPosts.push(post)

                                            //add to member's posts
                                            this._posts.push(post);

                                        });

                                    promiseArray.push(p);

                                });

                            //wait for all promises to return
                            Promise
                                .all(promiseArray)
                                .then(() => {
                                    resolve(nextPosts);
                                })
                                .catch((err) => {
                                    console.log('app.account.ts getNextPosts error', err);
                                    reject(err);
                                });

                        })
                        .catch((err) => {
                            console.log('app.account.ts AppMember getNextPosts get error', { memberId: this.parentMember.id, lastPostCreatedDt: this._lastPostCreatedDt }, err);
                            reject(err);
                        });

                } catch (err) {
                    console.log('app.account.ts AppMember getNextPosts error', err);
                    reject(err);
                }

            });

        }

        getFuturePosts(): Observable<AppPost> {

            return new Observable((observer) => {

                try {

                    //only allow this once 
                    if (!this._futurePostsUnsubscribe) {

                        //get all new posts after "now"
                        const now: firebase.firestore.Timestamp = firebase.firestore.Timestamp.now();

                        //create snapshot listener
                        const futurePostsUnsubscribe = this.parentMember
                            ._appFunction
                            .firestore
                            .collection(AppConfig.COLLECTION.Posts)
                            .where('memberId', '==', this.parentMember.id)
                            .where('createdDt', '>=', now)
                            .onSnapshot((foundPosts) => {

                                //console.log('app.account.ts getFuturePosts onSnapshot new member posts', foundPosts.size);

                                //get social following updates
                                foundPosts
                                    .docChanges()
                                    .forEach((foundPost) => {

                                        if (foundPost.type === FirestoreUpdateType.Added) {

                                            //create post object with data retrieved from post collection
                                            const post: AppPost = new AppPost();

                                            //once object has been initialized...
                                            const p = post
                                                .initialize(foundPost.doc)
                                                .then(() => {

                                                    //add to the front of member's posts
                                                    this._posts.unshift(post)

                                                });

                                            //...then publish the new post
                                            p.then(() => {
                                                observer.next(post);
                                            });

                                        }

                                    });

                            });

                        this.parentMember._appFunction.registerUnsubscribe(futurePostsUnsubscribe);

                    }

                } catch (error) {
                    console.log('app.account.ts AppMember getFuturePosts error', error);
                    observer.error(error);
                }

            });

        }

        updatePostCount(value: number): Promise<void> {

            return new Promise<void>((resolve, reject) => {

                //now increment count
                this.parentMember
                    ._appFunction
                    .firestore
                    .collection(AppConfig.COLLECTION.Members)
                    .doc(this.parentMember.id)
                    .set({ postCount: firebase.firestore.FieldValue.increment(value) }, { merge: true })
                    .then(() => {
                        //console.log('app.account.ts AppMember updatePostCount add success');
                        resolve();
                    })
                    .catch((err) => {
                        console.log('app.account.ts AppMember updatePostCount add error', JSON.stringify(err));
                        reject(err);
                    });

            });

        }

    }(this);

    //group methods
    groups = new class {

        constructor(private parentMember: AppMember) {
        }

        get all(): AppGroupI[] {

            return this.parentMember._groups
                .filter((group) => {
                    return group.exists && !group.deleted;
                });

        }

        getGroups(initialize: boolean = false): Promise<void> {

            return new Promise<void>((resolve, reject) => {

                try {

                    //only allow to run once
                    if (initialize) {

                        //initialize array
                        this.parentMember._groups = [];

                        const promiseArray: any[] = [];

                        //get member groups
                        const groupUnsubscribe = this.parentMember._appFunction
                            .firestore
                            .collection(AppConfig.COLLECTION.Groups)
                            .where('searchMemberIds', 'array-contains', this.parentMember.id) //logged in member is a member of the group
                            .where('deleted', '==', false)
                            .onSnapshot((foundGroups) => {

                                //for each group found...
                                foundGroups
                                    .docChanges()
                                    .forEach((foundGroup) => {

                                        if (foundGroup.type === FirestoreUpdateType.Added) {

                                            //get group from cache service
                                            const p = this.parentMember._appGroupService
                                                .getGroup(foundGroup.doc.id, foundGroup.doc)
                                                .toPromise()
                                                .then((group) => {
                                                    //add member group
                                                    this.parentMember._groups.push(<AppGroupI>group);
                                                });

                                            promiseArray.push(p);

                                        }

                                    });

                                Promise
                                    .all(promiseArray)
                                    .then(() => {
                                        resolve();
                                    });

                            }, (err) => {
                                console.log('app.account.ts AppMember getGroups onSnapshot error', { member: this }, err);
                                reject();
                            });

                        this.parentMember._appFunction.registerUnsubscribe(groupUnsubscribe);

                    } else {
                        //noop
                        resolve();
                    }

                } catch (err) {
                    console.log('app.account.ts AppMember getGroups error', err);
                    reject(err);
                }

            });

        }

        getNonGroupEvents(): Promise<void> {

            return new Promise<void>((resolve, reject) => {

                try {

                    //get now for retrieving events
                    const today: firebase.firestore.Timestamp = firebase.firestore.Timestamp.fromDate(moment().startOf('day').toDate());

                    const p = new Promise<void>((resolve, reject) => {

                        //get events where member is a player
                        const getEventsPlayerUnsubscribe = this.parentMember._appFunction
                            .firestore
                            .collection(AppConfig.COLLECTION.Events)
                            .where('groupId', '==', null) //non group events
                            .where('eventDt', '>', today) //any future event
                            .where('memberIds', 'array-contains', this.parentMember.id) //logged in user in is event
                            .where('deleted', '==', false)
                            .onSnapshot((foundEvents) => {

                                //for each found event...
                                foundEvents
                                    .docChanges()
                                    .forEach((foundEvent) => {

                                        //try to find event...
                                        const foundLocalEvent: AppEvent = this.parentMember._appEventService.events.find((event) => {
                                            return event.id === foundEvent.doc.id;
                                        })

                                        if (foundEvent.type === FirestoreUpdateType.Added) {

                                            //...if not found then create
                                            if (!foundLocalEvent) {

                                                this.parentMember._appEventService
                                                    .getEvent(undefined, foundEvent.doc.id, foundEvent.doc)
                                                    .then((event) => {

                                                        //update event with data
                                                        event.update(foundEvent.doc, foundEvent.type as FirestoreUpdateType);

                                                        //console.log('app.event.ts getEvents onSnapshot new', foundEvent);
                                                        this.parentMember.addEvent(event);

                                                    });

                                            } else { //this would happen if the event was created during this session and and saved for the first time 

                                                //this would happen if the event was created during this session and and saved for the first time 
                                                foundLocalEvent.update(foundEvent.doc, FirestoreUpdateType.Modified);

                                            }

                                        } else {
                                            //for updated or removed matches
                                            foundLocalEvent?.update(foundEvent.doc, foundEvent.type as FirestoreUpdateType);
                                        }

                                    });

                                resolve();

                            }, (err) => {
                                console.log('app.event.ts getEvents getEventsPlayerUnsubscribe onSnapshot error', err);
                                reject();
                            });

                        //store snapshot in the event it should be removed
                        this.parentMember._appFunction.registerUnsubscribe(getEventsPlayerUnsubscribe);

                    });

                    const q = new Promise<void>((resolve, reject) => {

                        const promiseArray: any[] = [];

                        //get events where member is the creator
                        //we do this because a the logged in player may not be a player but they still need to be able to manage the event
                        const getEventsCreatorUnsubscribe = this.parentMember._appFunction
                            .firestore
                            .collection(AppConfig.COLLECTION.Events)
                            .where('groupId', '==', null) //non group events
                            .where('eventDt', '>', today) //any future event
                            .where('createdMemberId', '==', this.parentMember.id) //logged in user in is event
                            .where('deleted', '==', false)
                            .onSnapshot((foundEvents) => {

                                //for each found event...
                                foundEvents
                                    .docChanges()
                                    .forEach((foundEvent) => {

                                        //for new events
                                        if (foundEvent.type === FirestoreUpdateType.Added) {

                                            const p = this.parentMember._appEventService
                                                .getEvent(undefined, foundEvent.doc.id, foundEvent.doc)
                                                .then((event) => {

                                                    //console.log('app.event.ts getEvents onSnapshot new', foundEvent);
                                                    this.parentMember.addEvent(event);

                                                });

                                            promiseArray.push(p);

                                        }

                                    });

                                Promise
                                    .all(promiseArray)
                                    .then(() => {
                                        resolve();
                                    });

                            }, (err) => {
                                console.log('app.event.ts getEvents getEventsCreatorUnsubscribe onSnapshot error', err);
                                reject();
                            });

                        //store snapshot in the event it should be removed
                        this.parentMember._appFunction.registerUnsubscribe(getEventsCreatorUnsubscribe);

                    });

                    Promise
                        .all([p, q])
                        .then(() => {
                            resolve();
                        })
                        .catch((err) => {
                            console.log('app.event.ts getEvents promise.all error', err);
                            reject();
                        });

                } catch (err) {
                    console.log('app.account.ts AppMember getNonGroupEvents error', err);
                    reject();
                }

            });

        }

    }(this);

    //#region preferences 

    updatePreference(preference: memberPreferenceI): Promise<void> {

        return new Promise<void>((resolve) => {

            //find preference
            const index: number = this._preferencesDecomposed.findIndex((_preference) => {
                return _preference.key === preference.key;
            });

            //if found then update
            if (index > -1) {
                this._preferencesDecomposed[index].value = preference.value();
            } else { //new preference so add

                //preference.key

                const memberPreference: memberPreferenceI = {
                    key: preference.key,
                    value: preference.value(),
                    dirty: preference.dirty
                };

                this._preferencesDecomposed.push(memberPreference);

            }

            //if dirty save
            if (preference.dirty) {

                //allow the save
                this._dirty = true;

                this.save()
                    .then(() => {
                        resolve();
                    });

            } else { //else, quitely return
                resolve();
            }

        });

    }

    async removePreference(preferenceId: string): Promise<void> {

        //find preference
        const index: number = this._preferencesDecomposed.findIndex((preference) => {
            return preference.key === preferenceId;
        });

        //if found then remove
        if (index > -1) {

            this._preferencesDecomposed.splice(index, 1);

            //now save
            await this.save();

        }

        /*  return new Promise<void>((resolve) => {
 
             //find preference
             const index: number = this._preferencesDecomposed.findIndex((preference) => {
                 return preference.key === preferenceId;
             });
 
             //if found then remove
             if (index > -1) {
 
                 this._preferencesDecomposed.splice(index, 1);
 
                 //now save
                 this.save()
                     .then(() => {
                         resolve();
                     });
 
             } else {
                 resolve();
             }
 
         }); */

    }

    getPreference(preferenceId: string): memberPreferenceI {

        //find preference
        const index: number = this._preferencesDecomposed.findIndex((preference) => {
            return preference.key === preferenceId;
        });

        //if found then return
        if (index > -1) {
            return this._preferencesDecomposed[index].value;
        } else {
            return <memberPreferenceI>{};
        }

        /* return new Promise<memberPreferenceI>((resolve) => {

            //console.log('app.account.ts getPreference');

            //find preference
            const index: number = this._preferencesDecomposed.findIndex((preference) => {
                return preference.key === preferenceId;
            });

            //if found then return
            if (index > -1) {
                resolve(this._preferencesDecomposed[index].value);
            } else {
                resolve(<memberPreferenceI>{});
            }

        }); */

    }

    //#endregion

    getAppMessages(): Promise<void> {

        return new Promise<void>((resolve, reject) => {

            try {

                //get messages for member
                const getMessageUnsubscribe = this._appFunction
                    .firestore
                    .collection(AppConfig.COLLECTION.Messages)
                    .where('memberId', '==', this.id)
                    .where('status', '==', AppMessageStatus.Active)
                    .onSnapshot((foundMessages) => {

                        //for each found event...
                        foundMessages
                            .docChanges()
                            .forEach((foundMessage) => {

                                //process app message
                                switch (foundMessage.doc.data().type) {

                                    case AppMessageType.Logoff:

                                        //set status to inactive...
                                        foundMessage.doc
                                            .ref
                                            .set(
                                                {
                                                    status: AppMessageStatus.Inactive,
                                                    updatedDt: firebase.firestore.Timestamp.now()
                                                },
                                                { merge: true }
                                            )
                                            .then(() => {
                                                //...then logoff
                                                this._accountService.logout();
                                            });

                                }

                            });

                        resolve();

                    }, (err) => {
                        console.log('app.account.ts AppMember getMessages onSnapshot error', err);
                        reject();
                    });

                //store snapshot in the event it should be removed
                this._appFunction.registerUnsubscribe(getMessageUnsubscribe);

            } catch (err) {
                console.log('app.account.ts AppMember getAppMessages error', err);
                reject();
            }

        });

    }

    async initializeLoggedInMemberData(): Promise<void> {

        //set member
        this._accountService.member = this;

        //clear events
        this._events = [];

        //request social data for logged in member
        await this.social.getFollowers(true);
        await this.social.getFollowing(true);
        await this.groups.getGroups(true);
        await this.groups.getNonGroupEvents();

        //subscribe to new Events
        this._eventService.newEvent
            .subscribe((event) => {
                this.addEvent(event);
            });

        //get app messages
        await this.getAppMessages();

        /* //new promise
        return new Promise<void>((resolve) => {

            //set member
            this._accountService.member = this;

            //clear events
            this._events = [];

            //request social data for logged in member
            const q = this.social.getFollowers(true);
            const r = this.social.getFollowing(true);
            const s = this.groups.getGroups(true);
            const t = this.groups.getNonGroupEvents();

            //subscribe to new Events
            this._eventService.newEvent
                .subscribe((event) => {
                    this.addEvent(event);
                });

            //get app messages
            const u = this.getAppMessages();

            Promise
                .all([q, r, s, t, u])
                .then(() => {
                    resolve();
                });

        }); */

    }

    async save(): Promise<AppMember> {

        try {

            if (this._dirty) {
                await this._memberDoc.ref.set(this.data());
            }

            return this;

        } catch (err) {
            console.log('app.account.ts AppMember save', err);
        }

        /* return new Promise<AppMember>((resolve, reject) => {

            try {

                if (this._dirty) {

                    this._memberDoc
                        .ref
                        .set(this.data())
                        .then(() => {
                            //console.log('app.account.ts AppMember save set success');
                            resolve(this);
                        })
                        .catch((err) => {
                            console.log('app.account.ts AppMember save', err);
                            reject(err);
                        });

                } else {
                    //console.log('app.account.ts AppMember save not dirty, noop');
                    resolve(this);
                }

            } catch (err) {
                console.log('app.account.ts AppMember save', err);
                reject(err);
            }

        }); */

    }

    delete(batch: firebase.firestore.WriteBatch) {

        try {
            batch.delete(this._memberDoc.ref);
        }
        catch (err) {
            console.log('app.account.ts AppMember delete error', err, JSON.stringify(err));
        }

        /* return new Promise<void>((resolve, reject) => {

            try {
                batch.delete(this._memberDoc.ref);
                resolve();
            }
            catch (err) {
                console.log('app.account.ts AppMember delete error', err, JSON.stringify(err));
                reject(err);
            }

        }); */

    }

    /* getHandicapIndex(): Promise<void> {

        return new Promise<void>((resolve, reject) => {

            try {

                //console.log('app.account.ts getHandicapIndex');

                //https://api2.ghin.com/api/v1/golfermethods.asmx/HandicapHistory.json?username=GHIN2020&password=GHIN2020&club=0&ghin_number=7031177&revCount=0&assoc=0&service=0&date_begin=2020-1-1&date_end=2020-12-31
                //https://api2.ghin.com/api/v1/public/login.json?ghinNumber=7031177&lastName=spring

                //get today
                const today = moment();

                //get last date index was returned...1607970223 if undefined...1607970223 represents 1/1/1970
                const handicapIndexDt = moment(new Date((this.handicapIndexDt ? this.handicapIndexDt.toDate() : '1/1/1970'))); // the date to be checked

                //calc the number of days since last check
                const daysSinceLastCheck: number = today.startOf('day').diff(handicapIndexDt.startOf('day'), 'days');

                //last name and ghin number required. and only if we didn't get today.
                if (this.lastName.length > 0 && this.ghinNumber && this.ghinNumber.toString().length === 7 && daysSinceLastCheck > 0) {

                    this._appFunction
                        .http
                        .get(`https://api2.ghin.com/api/v1/public/login.json?ghinNumber=${this.ghinNumber}&lastName=${this.lastName}`)
                        .toPromise()
                        .then((results) => {

                            //multiple records could be returned
                            const courses = this._appFunction.decomposeObjectToArray(results);

                            //console.log('app.account.ts AppMember getHandicapIndex success courses', results);
                            //console.log('app.account.ts AppMember getHandicapIndex success courses', courses);
                            //console.log('app.account.ts AppMember getHandicapIndex success courses value', courses[0].value[0]);

                            //if ghin number/last name combo found then...
                            if (courses[0].value[0]) {

                                //The handicap index is the same in each course record so just get it from the first course
                                const handicapIndex: number = courses[0].value[0].Value;

                                //set index info. the date is used to prevent over calling the end point. only needs to be called once a day
                                this.handicapIndex = handicapIndex;

                                //clear any previous errors
                                this.getHandicapIndexError = undefined;

                                //now set date, we do this so we only retrieve once a day
                                this.handicapIndexDt = firebase.firestore.Timestamp.now();

                                //save index info
                                this
                                    .save()
                                    .then(() => {

                                        //let listeners kow that there's an update
                                        this.updatehandicapIndex.next(handicapIndex);

                                        resolve();

                                    });

                            } else {

                                //set error message
                                this.getHandicapIndexError = "The handicap index was not found. Please confirm that the provided last name and GHIN are valid."

                                //the ghin and last name combo couldn't be found - should we log this? - noop
                                console.log('app.account.ts AppMember getHandicapIndex required data did not return any data');
                                resolve();
                            }

                        })
                        .catch((err) => {
                            console.log('app.account.ts AppMember getHandicapIndex error', err, JSON.stringify(err));
                            reject(err);
                        });

                } else {
                    //noop
                    //console.log('app.account.ts AppMember getHandicapIndex no check based on data', this.ghinNumber, this.lastName, daysSinceLastCheck);
                    resolve();
                }

            } catch (err) {
                console.log('app.account.ts AppMember error', err, JSON.stringify(err));
                reject(err);
            }

        });

    } */

    private data(): AppMemberI {

        try {

            //build preference object
            const preferences: object = {};
            this._preferencesDecomposed.forEach((preference) => {
                preferences[preference.key] = preference.value;
            });

            return {
                email: this.email,
                emailSearch: this.emailSearch,
                firstName: this.firstName ? this.firstName.trim() : null, //could be null if importing via contacts
                firstNameSearch: this.firstNameSearch,
                lastName: this.lastName ? this.lastName.trim() : null, //could be null if importing via contacts
                lastNameSearch: this.lastNameSearch,
                avatarFileName: this.avatarFileName,
                privateAccount: this.privateAccount,
                createdDt: this.createdDt || firebase.firestore.Timestamp.fromDate(new Date()),
                admin: this.admin,
                preferences: preferences,
                appVersion: AppConfig.APP_VERSION + AppConfig.APP_VERSION_CODE,
                updatedDt: this.updatedDt,
                postCount: this.postCount,
                lastLoginDt: this.lastLoginDt || null,
                //ghinNumber: this.ghinNumber ? this.ghinNumber : null,
                handicapIndex: this.handicapIndex ? this.handicapIndex : null,
                handicapIndexDt: this.handicapIndexDt ? this.handicapIndexDt : null
            };

        }
        catch (err) {
            console.log('app.account.ts AppMember data', err);
            throw err;
        }

    }

}

export interface AppNavigationI {
    url: string;
    extras: Object;
}

export interface AppMessageI {
    memberId: string;
    type: number;
    status: number;
    updatedDt: firebase.firestore.Timestamp;
    createdDt: firebase.firestore.Timestamp;
}

export enum AppMessageType {
    Logoff = 1
}

export enum AppMessageStatus {
    Active = 1,
    Inactive = 2 //dismissed for visual messages, processed for non visual messages
}

@Injectable({
    providedIn: 'root'
})
export class AccountService {

    user: User = undefined;
    member: AppMember = undefined;
    authProvider: string = 'password';
    isTouchIdAvailable: boolean = false;
    isTouchId: boolean = false;
    isFirstTime: boolean = true;
    navigation: BehaviorSubject<AppNavigationI> = new BehaviorSubject<AppNavigationI>(undefined);
    newRegistration: boolean = false;  //signals that this seesion is a new registration
    private _memberCache: { [key: string]: AppMember } = {};
    private _memberCacheRequest: {
        memberId: string,
        obseverer: Subscriber<AppMember>,
        processed: boolean
    }[] = [];
    private navigationURL: AppNavigationI = { url: '/main/home', extras: {} };
    private navigationSubscribe: Subscription = undefined;

    constructor(
        public router: Router,
        public appFunction: AppFunction,
        public eventService: EventService,
        public groupService: GroupService,
        public afAuth: AngularFireAuth,
        public touchId: TouchID,
        public storage: Storage,
        public navCtrl: NavController,
        public deepLinkService: DeepLinkService) {

        console.log('app.account.ts constructor');

        //clean up
        this.appFunction
            .shutDown
            .subscribe(() => {

                console.log('app.account.ts AccountService shutdown');

                //clear variables
                this.user = undefined;
                this.member = undefined;
                this._memberCache = {};
                this._memberCacheRequest = [];

                //unsubscribe from navigation
                if (this.navigationSubscribe !== undefined) {
                    this.navigationSubscribe.unsubscribe();
                    this.navigationSubscribe = undefined;
                    this.navigation.next(undefined);
                }

                //if login token is invalid then turn off TOUCH_ID_ACTIVE
                this.storage.set(AppConfig.TOUCH_ID_ACTIVE, false);

            });

    }

    addMember(member: AppMember): void {
        //save to chache
        this._memberCache[member.id] = member;
    }

    login(email: string): Promise<void> {

        return new Promise<void>((resolve, reject) => {

            this.afAuth
                .setPersistence(firebase.auth.Auth.Persistence.LOCAL)
                .then(() => {
                    this.afAuth
                        .sendSignInLinkToEmail(email, environment.actionCodeSettings)
                        .then(async () => {
                            await this.storage.set('email', email);
                            resolve();
                        })
                        .catch((err) => {
                            console.log('app.account.ts login sendSignInLinkToEmail error', { email: email }, err);
                            SentryAngular.captureException(err, {
                                tags: {
                                    email: email,
                                    method: 'app.account.ts login sendSignInLinkToEmail error'
                                }
                            });
                            reject(err);
                        });

                })
                .catch((err) => {
                    console.log('app.account.ts login setPersistence error', { email: email }, err);
                    SentryAngular.captureException(err, {
                        tags: {
                            email: email,
                            method: 'app.account.ts login setPersistence error'
                        }
                    });
                    reject(err);
                });

        });

    }

    loginWithPassword(email: string, password: string): Promise<firebase.auth.UserCredential> {

        return new Promise<firebase.auth.UserCredential>((resolve, reject) => {

            this.afAuth
                .setPersistence(firebase.auth.Auth.Persistence.LOCAL)
                .then(() => {

                    this.afAuth
                        .signInWithEmailAndPassword(email, password)
                        .then((UserCredential) => {
                            resolve(UserCredential);
                        })
                        .catch((err) => {
                            console.log('app.account.ts loginWithPassword signInWithEmailAndPassword error', { email: email }, err);
                            SentryAngular.captureException(err, {
                                tags: {
                                    email: email,
                                    method: 'app.account.ts loginWithPassword signInWithEmailAndPassword error'
                                }
                            });
                            reject(err);
                        });

                })
                .catch((err) => {
                    console.log('app.account.ts loginWithPassword setPersistence error', { email: email }, err);
                    SentryAngular.captureException(err, {
                        tags: {
                            email: email,
                            method: 'app.account.ts loginWithPassword setPersistence error'
                        }
                    });
                    reject(err);
                });

        });

    }

    register(email: string, firstName: string, lastName: string): Promise<User> {

        return new Promise<User>((resolve, reject) => {

            const data = {
                email: email,
                lastName: lastName,
                firstName: firstName
            }

            this.appFunction
                .http
                .post(AppConfig.CLOUD_FUNCTIONS.createUserAuth, data, { headers: { 'Access-Control-Allow-Origin': AppConfig.CLOUD_FUNCTIONS.createUserAuth } })
                .toPromise()
                .then((userRecord: User) => {
                    resolve(userRecord);
                })
                .catch((err) => {
                    console.log('app.account.ts register error', err);
                    SentryAngular.captureException(err, {
                        tags: {
                            email: email,
                            method: 'app.account.ts register error'
                        }
                    });
                    reject(err);
                });

        });

    }

    registerWithPassword(email: string, password: string, firstName: string, lastName: string): Promise<User> {

        return new Promise<User>((resolve, reject) => {

            const data = {
                email: email,
                password: password,
                lastName: lastName,
                firstName: firstName
            }

            this.appFunction
                .http
                .post(AppConfig.CLOUD_FUNCTIONS.createUserAuthWithPassword, data, { headers: { 'Access-Control-Allow-Origin': AppConfig.CLOUD_FUNCTIONS.createUserAuth } })
                .toPromise()
                .then((userRecord: User) => {
                    resolve(userRecord);
                })
                .catch((err) => {
                    console.log('app.account.ts registerWithPassword error', err);
                    SentryAngular.captureException(err, {
                        tags: {
                            email: email,
                            method: 'registerWithPassword'
                        }
                    });
                    reject(err);
                });

        });

    }

    sendNewRegistrationEmail(member: AppMember): Promise<void> {

        return new Promise<void>((resolve, reject) => {

            try {

                const personalizations = [];
                personalizations.push({
                    "subject": 'Welcome to ' + AppConfig.APP_NAME,
                    "templateId": AppConfig.SENDGRID_TEMPLATES.NewMemberRegistration,
                    "to": {
                        "name": member.firstName + ' ' + member.lastName,
                        "email": member.email
                    },
                    "from": {
                        "name": member.firstName + ' ' + member.lastName,
                        "email": AppConfig.NOREPLY_EMAIL
                    },
                    "replyTo": {
                        "name": member.firstName + ' ' + member.lastName,
                        "email": member.email
                    },
                    "dynamic_template_data": {
                        "firstName": member.firstName,
                        "lastName": member.lastName,
                        "email": member.email
                    },
                    "hideWarnings": true
                });

                this.appFunction
                    .sendEmail(personalizations)
                    .then(() => {
                        //reset new registration flag
                        this.newRegistration = false;
                        resolve();
                    })
                    .catch((err) => {
                        console.log('app.group.ts AccountService sendNewRegiatrationEmail sendEmail error', err, JSON.stringify(err));
                        reject(err);
                    });

            } catch (err) {
                console.log('app.group.ts AccountService sendNewRegiatrationEmail error', err, JSON.stringify(err));
                reject();
            }

        });

    }

    logout() {

        //clean up before logoff
        this.appFunction.shutDown.next();

        //
        return this.afAuth.signOut();

    }

    currentUser(): Promise<User> {

        return new Promise<User>((resolve) => {

            this.afAuth
                .onAuthStateChanged((user) => {
                    resolve(user);
                })
                .then((unsubscribe) => {
                    this.appFunction.registerUnsubscribe(unsubscribe);
                    //onAuthStateChangedUnsubscribe = unsubscribe;
                })
                .catch((err) => {
                    console.log('app.account.ts currentUser onAuthStateChanged error', err);
                });

        });

    }

    getMemberByEmail(email: string): Promise<AppMember> {

        return new Promise<AppMember>((resolve, reject) => {

            try {

                //convert to array and find cached member
                const memberCacheArray: AppMember[] = Object.values(this._memberCache);
                const cachedMember: AppMember = memberCacheArray.find((member) => {
                    return member.email.trim().toLowerCase() === email.trim().toLowerCase();
                });

                //if found then return
                if (cachedMember) {
                    resolve(cachedMember);
                } else { //else go get it

                    //look for member based on email (emailSearch) 
                    this.appFunction
                        .firestore
                        .collection(AppConfig.COLLECTION.Members)
                        .where('emailSearch', '==', email.trim().toUpperCase())
                        .get()
                        .then((members) => {

                            //member not found
                            if (members.empty) {
                                //this isn't necessarily an error so we'll just resolve undefined, let calling function determine if it's an error
                                resolve(undefined);
                            } else if (members.docs.length > 1) { //more than one member found
                                console.log('app.account.ts AccountService getMemberByEmail more than 1 member document found for given email address: ' + email);
                                SentryAngular.captureMessage('app.account.ts AccountService getMemberByEmail more than 1 member document found for given email address: ' + email, 'error');
                                reject(new Error('app.account.ts AccountService getMemberByEmail more than 1 member document found for given email address'));
                            } else { //one member found

                                members
                                    .docs
                                    .forEach((foundMember) => {

                                        //create...
                                        const appMember: AppMember = new AppMember();

                                        //...then initialize
                                        appMember
                                            .initialize(foundMember)
                                            .then(() => {
                                                //...and then return
                                                resolve(appMember);
                                            })

                                    });

                            }

                        })
                        .catch((err) => {
                            console.log('app.account.ts AccountService getMemberByEmail get error', JSON.stringify(err));
                            SentryAngular.captureException(err, {
                                tags: {
                                    email: email,
                                    method: 'app.account.ts AccountService getMemberByEmail error'
                                }
                            });
                            reject(err);
                        });

                }

            } catch (err) {
                console.log('app.account.ts AccountService getMemberByEmail error', JSON.stringify(err));
                reject(err);
            }

        });

    }

    private returnMemberCachedRequests(memberCacheRequest: typeof this._memberCacheRequest, memberId: string, member: AppMember): void {

        //return to those waiting/previsously requested
        memberCacheRequest
            .forEach((request) => {

                if (request.memberId === memberId && request.processed === false) {

                    //return member and close observable
                    request.obseverer.next(member);
                    request.obseverer.complete();

                    //mark as processed
                    request.processed = true;

                }

            });

    }

    getMember(memberId: string, memberDoc: firebase.firestore.QueryDocumentSnapshot = undefined): Observable<AppMember> {

        return new Observable((observer) => {

            //look for cached member
            const cachedMember: AppMember = this._memberCache[memberId];

            //if named member and found
            if (cachedMember) {
                observer.next(cachedMember);
                observer.complete();
            } else if (memberDoc) {

                //create...
                const member: AppMember = new AppMember();

                //...initialize
                member
                    .initialize(memberDoc)
                    .then(() => {

                        //return this request
                        observer.next(member);
                        observer.complete();

                        //return to those waiting/previsously requested
                        this.returnMemberCachedRequests(this._memberCacheRequest, member.id, member);

                    });

            } else { //query firestore

                try {

                    //look for cached request that hasn't been processed
                    const existingRequest: boolean = this._memberCacheRequest.some((request) => {
                        return request.memberId === memberId && request.processed === false;
                    });

                    //store request
                    this._memberCacheRequest.push({ memberId: memberId, obseverer: observer, processed: false });

                    //if member hasn't been requested then go get it...
                    if (!existingRequest) {

                        this.appFunction
                            .firestore
                            .collection(AppConfig.COLLECTION.Members)
                            .doc(memberId)
                            .get()
                            .then((foundMember) => {

                                //if member found...
                                if (foundMember.exists) {

                                    //create...
                                    const appMember: AppMember = new AppMember();

                                    //...and then initialize
                                    appMember
                                        .initialize(foundMember)
                                        .then(() => {
                                            //return to those waiting/previsously requested
                                            this.returnMemberCachedRequests(this._memberCacheRequest, appMember.id, appMember);
                                        });

                                } else {

                                    console.log('app.account.ts AccountService getMember member not found: ' + memberId, foundMember?.exists, JSON.stringify(foundMember?.data()));
                                    SentryAngular.captureMessage(`app.account.ts AccountService getMember member not found: ${memberId}, EXISTS: ${foundMember?.exists}, DATA: ${JSON.stringify(foundMember?.data())}, ENV: ${JSON.stringify(environment, null, 0)}`, 'error');

                                    //return to those waiting/previsously requested
                                    this.returnMemberCachedRequests(this._memberCacheRequest, memberId, undefined);

                                }

                            })
                            .catch((err) => {
                                console.log('app.account.ts AccountService getMember error', err, JSON.stringify(err));
                                SentryAngular.captureException(err, {
                                    tags: {
                                        memberId: memberId,
                                        method: 'app.account.ts AccountService getMember firestore get'
                                    }
                                });
                            });

                    }

                } catch (err) {

                    console.log('app.account.ts AccountService getMember block error: ' + memberId);
                    SentryAngular.captureException(err, {
                        tags: {
                            memberId: memberId,
                            method: 'app.account.ts AccountService getMember block error: ' + memberId
                        }
                    });

                    //return to those waiting/previsously requested
                    this.returnMemberCachedRequests(this._memberCacheRequest, memberId, undefined);

                }

            }

        });

    }

    restorePassword(email: string): Promise<void> {

        console.log('app.account.ts restorePassword');

        return new Promise<void>((resolve, reject) => {

            this.afAuth
                .sendPasswordResetEmail(email, { url: environment.passwordResetContinueUrl })
                .then(() => {
                    resolve();
                })
                .catch((err) => {
                    console.log('app.account.ts restorePassword error', err, JSON.stringify(err));
                    reject(err)
                });

        });

    }

    isBiometricAvailable(): Promise<any> {

        return new Promise<any>((resolve, reject) => {

            if (this.appFunction.platform.is('capacitor') && this.appFunction.platform.is('ios')) {

                //get touch id availability
                this.touchId
                    .isAvailable()
                    .then((biometryType) => {

                        //set type
                        const biometryDisplayName: string = (biometryType === 'face') ? 'Face ID' : 'Touch ID';
                        const biometryIconName: string = (biometryType === 'face') ? 'faceid' : 'touchid';
                        const biometryImage: string = (biometryType === 'face') ? 'assets/images/FaceId.png' : 'assets/images/TouchId.png';

                        //if available then get touch id status (turned on by user)
                        this.storage
                            .get(AppConfig.TOUCH_ID_ACTIVE)
                            .then((value) => {
                                resolve({ biometryIconName: biometryIconName, biometryDisplayName: biometryDisplayName, touchIdActive: value, biometryImage: biometryImage });
                            })
                            .catch((err) => {
                                console.log('app.account.ts isBiometricAvailable get TOUCH_ID_ACTIVE error', JSON.stringify(err));
                                resolve({ biometryIconName: biometryIconName, biometryDisplayName: biometryDisplayName, touchIdActive: false, biometryImage: biometryImage });
                            });

                    })
                    .catch((err) => {
                        console.log('account.ts isBiometricAvailable get touchID.isAvailable error', JSON.stringify(err));
                        reject();
                    });

            } else {
                //not ios
                reject();
            }

        });

    }

    setBiometricStatus(status: boolean) {
        this.storage.set(AppConfig.TOUCH_ID_ACTIVE, status);
    }

    setFirstTimeStatus(status: boolean) {

        this.storage
            .set(AppConfig.FIRST_TIME, status)
            .then(() => {
                this.isFirstTime = status;
            });

    }

    verifyBiometric() {

        //request user for face id/touch id
        this.touchId
            .verifyFingerprint('Sign in to DoubleAceGolf')
            .then((res) => {

                //console.log('app.account.ts verifyFingerprint success');

                //navigate to the requested page
                this.navigation.next(this.navigationURL);

            })
            .catch((err) => {
                console.log('app.account.ts verifyFingerprint error', JSON.stringify(err));
                throw err;
            });

    }

    memberSearch(event: any): Promise<AppMember[]> {

        return new Promise<AppMember[]>(async (resolve) => {

            //only perform a search if the user has entered criteria
            if (event.target.value && event.target.value.length > 0) {

                const searchCriteria: string = event.target.value.toUpperCase();
                const strlength: number = searchCriteria.length;
                const strFrontCode: string = searchCriteria.slice(0, strlength - 1);
                const strEndCode: string = searchCriteria.slice(strlength - 1, searchCriteria.length);
                const startcode: string = searchCriteria;
                const endcode: string = strFrontCode + String.fromCharCode(strEndCode.charCodeAt(0) + 1);

                // define queries
                const firstName = this.appFunction
                    .firestore
                    .collection(AppConfig.COLLECTION.Members)
                    .orderBy('firstNameSearch')
                    .startAt(startcode)
                    .endAt(endcode + '~')
                    .get();

                const lastName = this.appFunction
                    .firestore
                    .collection(AppConfig.COLLECTION.Members)
                    .orderBy('lastNameSearch')
                    .startAt(startcode)
                    .endAt(endcode + '~')
                    .get();

                const email = this.appFunction
                    .firestore
                    .collection(AppConfig.COLLECTION.Members)
                    .orderBy('emailSearch')
                    .startAt(startcode)
                    .endAt(endcode + '~')
                    .get();

                // get queries
                const [firstNameResults, lastNameResults, emailResults] = await Promise.all([
                    firstName,
                    lastName,
                    email
                ]);

                //mash everything into one array
                const firstLast = (firstNameResults.docs).concat(lastNameResults.docs);
                const firstLastEmail = (firstLast).concat(emailResults.docs);

                //clear previous results
                const searchMembers: AppMember[] = [];

                //save the array of promises
                const promiseArray: any[] = [];

                //if any members found then...
                firstLastEmail
                    .forEach((foundMember) => {

                        //console.log('app.account.ts memberSearch member found', foundMember.data());

                        const p = this.getMember(foundMember.id, foundMember)
                            .toPromise()
                            .then((member) => {
                                searchMembers.push(member);
                            });

                        promiseArray.push(p);

                    });

                Promise
                    .all(promiseArray)
                    .then(() => {
                        resolve(searchMembers);
                    });

            } else {
                resolve([]);
            }

        });

    }

    private listenForNavigations() {

        //only call once
        if (!this.navigationSubscribe) {

            this.navigationSubscribe = this.navigation
                .subscribe((navigation) => {

                    //only navigate if url is present, it may not be during the bootstrap of the app
                    if (navigation?.url) {

                        //see if url is a deeplink
                        if (navigation.url.includes('code')) {
                            this.deepLinkService.processDeepLink(navigation.url);
                        } else { //not a deeplink, just a regular navigation

                            //get data that was passed through the notification
                            const extras: Object = {};
                            Object
                                .keys(navigation.extras)
                                .forEach((key) => {
                                    //console.log('app.account.ts listenForNavigations extra, key : ' + key + ', value : ' + navigation.extras[key]);
                                    extras[key] = navigation.extras[key];
                                });

                            //build extras object to pass in navigateRoot
                            const navigationExtras: NavigationExtras = {
                                state: extras
                            };

                            //navigate to the requested page
                            this.navCtrl
                                .navigateRoot(navigation.url, navigationExtras)
                                .catch((err) => {
                                    console.log('app.account.ts AccountService listenForNavigations navigateRoot error' + navigation, err);
                                    throw err;
                                });

                        }

                    }

                });

        }

    }

    listenForPushNotifications() {

        //get a messaging token and subscribe to notifications
        if (this.appFunction.platform.is('capacitor')) {

            try {

                const p = this.appFunction
                    .getToken(this.member.id)
                    .then(() => {

                        //show us the notification payload if the app is aleady open on our device
                        PushNotifications.addListener('pushNotificationReceived',
                            (push) => {

                                /* {
                                     "notification": {
                                        "subtitle": "",
                                        "body": "There is an event update for Dan's Friday Group",
                                        "badge": 1,
                                        "data": {
                                                "aps": {
                                                    "alert": {
                                                            "title": "Event Update",
                                                            "body": "There is an event update for Dan's Friday Group"
                                                    }
                                                },
                                                "google.c.a.e": "1",
                                                "url": "/main/home",
                                                "google.c.sender.id": "738686140611",
                                                "gcm.message_id": "1604350123082815",
                                                "memberId": "" //sending member id
                                        },
                                        "id": "012093BF-6D34-4C79-946E-67A1BC968AE6",
                                        "title": "Event Update"
                                    )
                                } */

                                let header: string;
                                let message: string;
                                let sendingMemberId: string = push.notification.data.memberId; //represents the member who trigger this notification

                                //parse message
                                if (this.appFunction.platform.is('ios')) {
                                    header = push.notification.data.aps.alert.title;
                                    message = push.notification.data.aps.alert.body;
                                } if (this.appFunction.platform.is('android')) {
                                    header = push.notification.title;
                                    message = push.notification.body;
                                }

                                //only show the toast if the logged in member wasn't who trigger the notification
                                if (sendingMemberId !== this.member.id) {

                                    //show message in a toast
                                    this.appFunction
                                        .toastCtrl
                                        .create({
                                            header: header,
                                            message: message,
                                            duration: 4000,
                                            color: 'secondary',
                                            position: 'bottom',
                                            buttons: [
                                                {
                                                    text: 'Ok',
                                                    role: 'cancel'
                                                }
                                            ]
                                        })
                                        .then((toast) => {
                                            toast.present();
                                        });

                                }

                            }
                        );

                        //method called when tapping on a notification
                        PushNotifications.addListener('pushNotificationActionPerformed',
                            (push) => {

                                console.log('app.account.ts listenForPushNotifications pushNotificationActionPerformed', JSON.stringify(push));

                                /* {
                                    "notification": {
                                        "badge": 1,
                                        "id": "08AA6535-82B0-4FDC-AB62-8197307238D7",
                                        "title": "Event Update",
                                        "body": "There is an event update for Dan's Friday Group",
                                        "data": {
                                            "url": "/main/home",
                                            "google.c.sender.id": "738686140611",
                                            "google.c.a.e": "1",
                                            "gcm.message_id": "1604350221240360",
                                            "aps": {
                                                    "alert": {
                                                        "title": "Event Update",
                                                        "body": "There is an event update for Dan's Friday Group"
                                                    }
                                            },
                                            "memberId": "" //sending member id
                                            *****other pass through data*****
                                        },
                                        "subtitle": ""
                                    },
                                    "actionId": "tap"
                                } */

                                //trigger navigation
                                this.navigation.next({ url: push.notification.data.url, extras: push.notification.data });

                            }
                        );

                    })
                    .catch((err) => {
                        console.log('app.account.ts listenForPushNotifications getToken error', JSON.stringify(err));
                    });
            } catch (err) {
                console.error(err);
                throw err;
            }
        }

    }

    authStateChange(): Promise<void> {

        return new Promise<void>((resolve) => {

            this.afAuth
                .onAuthStateChanged(async (user) => {

                    if (this.isFirstTime) { //if this is the first time then show intro

                        //...so show introduction, will set 'firstTime' on the last page of the introduction
                        this.navCtrl
                            .navigateRoot('/introduction')
                            .then(() => {
                                //set first time value to local storage
                                this.setFirstTimeStatus(false);
                            })
                            .catch((err) => {
                                console.log('app.account.ts authStateChange navigate introduction error', err);
                            });

                    } else if (user) { //if valid credential exists then...

                        //set user
                        this.user = user;

                        //set auth provider id (this is used in the registration page...couldn't we just use "users")
                        this.authProvider = user.providerData[0].providerId;

                        //get email
                        const email: string = user.providerData[0].email;
                        if (email) {

                            //get member
                            this.getMemberByEmail(email)
                                .then((member) => {

                                    //member record not found, this shouldn't happen but has been an error in the past, probably should log an error
                                    if (member === undefined) {

                                        //a valid credential was found but no member record (based on email) was found
                                        SentryAngular.captureMessage('app.account.ts AccountService authStateChange onAuthStateChanged a valid credential was found but no member record (' + email + ') was found', {
                                            tags: {
                                                email: email,
                                                method: 'app.account.ts AccountService authStateChange onAuthStateChanged a valid credential was found but no member record(' + email + ') was found. This probably means member had valid auth token but there is no member record.'
                                            }
                                        });

                                        //member was able to sign in but no member record was found...this is a problem
                                        this.user = undefined;

                                        //pass email to register page
                                        const navigationExtras: NavigationExtras = {
                                            state: {
                                                email: email
                                            }
                                        }

                                        //we don't have a member record yet, let's have them register
                                        this.navCtrl
                                            .navigateRoot('/register', navigationExtras)
                                            .catch((err) => {
                                                console.log('app.account.js authListener show register page error', err);
                                            });

                                    } else {

                                        //initialize member
                                        const q = member.initializeLoggedInMemberData();

                                        Promise
                                            .all([q])
                                            .then(() => {

                                                //log app launch event
                                                this.appFunction.postHogCapture('app_launch', member);

                                                //update member's app version and last login date
                                                member.appVersion = AppConfig.APP_VERSION + AppConfig.APP_VERSION_CODE;
                                                member.lastLoginDt = firebase.firestore.Timestamp.fromDate(new Date());
                                                member.save();

                                                //if this is a new registration then send new registration email
                                                if (this.newRegistration) {
                                                    this.sendNewRegistrationEmail(member);
                                                }

                                                //get/listen for navigation events
                                                this.listenForNavigations();

                                                //if touch id is available and turned on for the user then...
                                                if (this.isTouchIdAvailable && this.isTouchId) {

                                                    //the user will be presented the touch id dialog on the login page
                                                    this.navCtrl
                                                        .navigateRoot('/login')
                                                        .catch((err) => {
                                                            console.log('app.account.ts AccountService authStateChange navigateRoot /login show login page error', { email: user.email }, err);
                                                        });

                                                } else {
                                                    //navigate to the requested page
                                                    this.navigation.next(this.navigationURL);
                                                }

                                            })
                                            .catch((err) => {
                                                console.log('app.account.ts AccountService authStateChange member boot process', { member: member }, err);
                                            });

                                    }

                                })
                                .catch((err) => {

                                    //found an auth user but no member record...this is a problem
                                    SentryAngular.captureException(err, {
                                        tags: {
                                            email: email,
                                            method: 'app.account.ts AccountService authStateChange getMemberByEmail error'
                                        }
                                    });

                                    const navigationExtras: NavigationExtras = {
                                        state: {
                                            email: email
                                        }
                                    }

                                    //so we have auth credentials but no member account. member is new so route to the registration flow. in theory this shouldn't happen...i think
                                    this.navCtrl
                                        .navigateRoot('/login', navigationExtras)
                                        .then(() => {

                                            this.appFunction
                                                .alertCtrl
                                                .create({
                                                    header: 'Login Error',
                                                    message: "A login error has occurred for " + email + ". This error has been sent to the Double Ace Golf system administrators.",
                                                    buttons: [
                                                        {
                                                            text: 'OK',
                                                            handler: () => {
                                                            }
                                                        }
                                                    ]
                                                })
                                                .then((alert) => {
                                                    alert.present();
                                                });

                                        })
                                        .catch((err) => {
                                            console.log('app.account.js authStateChange show login page error', err);
                                        });

                                });

                        } else {
                            console.log('app.account.ts AccountService authStateChange onAuthStateChanged valid credential but no email found in the credential payload', user);
                            SentryAngular.captureMessage('app.account.ts AccountService authStateChange onAuthStateChanged valid credential but no email found in the credential payload.', {
                                tags: {
                                    user: user.toJSON().toString()
                                }
                            });
                        }

                    } else if (window.location.href.indexOf('email-link') > -1 && this.afAuth.isSignInWithEmailLink(window.location.href)) {

                        console.log('AccountService authStateChange looks like we have an email link...', window.location.href);

                        // Additional state parameters can also be passed via URL.
                        // This can be used to continue the user's intended action before triggering
                        // the sign-in operation.
                        // Get the email if available. This should be available if the user completes
                        // the flow on the same device where they started it.
                        const email = await this.storage.get("email");
                        if (!email) {
                            // User opened the link on a different device. To prevent session fixation
                            // attacks, ask the user to provide the associated email again. For example:
                            // TODO: handle this somehow...
                        }
                        // The client SDK will parse the code from the link for you.
                        this.afAuth.signInWithEmailLink(email, window.location.href)
                            .then(function (result) {
                                // Clear email from storage.
                                //window.localStorage.removeItem('emailForSignIn');

                                const user = result.user;
                                console.log(user);

                                // You can access the new user via result.user
                                // Additional user info profile not available via:
                                // result.additionalUserInfo.profile == null
                                // You can check if the user is new or existing:
                                // result.additionalUserInfo.isNewUser
                            })
                            .catch(function (error) {
                                // Some error occurred, you can inspect the code: error.code
                                // Common errors could be invalid email and invalid or expired OTPs.
                                console.log(error);

                                if (error.code == "auth/invalid-action-code") {

                                    this.navCtrl
                                        .navigateRoot('/login')
                                        .catch((err) => {
                                            console.log('app.account.js authListener show login page error', err);
                                        });

                                }
                            });

                    } else { //user is not valid, shutdown and show login screen

                        console.log('app.account.ts authStateChange onAuthStateChanged not isFirstTime and user is not valid so show login page');

                        //clean up before logoff
                        //this.appFunction.shutDown.next();

                        //the user will need to login via the login page
                        this.navCtrl
                            .navigateRoot('/login')
                            .catch((err) => {
                                console.log('app.account.js authListener show login page error', err);
                            });

                    }

                    /* }); */

                })
                .then(() => {
                    //once auth has run get unsubscroibe and return to app.component.ts
                    resolve();
                })
                .catch((err) => {
                    console.log('app.account.ts AccountService authStateChange onAuthStateChanged error', err);
                    throw err;
                });

        });

    }

}
