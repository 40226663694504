<ion-header class="course-tee-header ion-no-border">

  <ion-toolbar>

    <ion-buttons slot="secondary">
      <ion-button (click)="back();">
        <ion-icon name="chevron-back"></ion-icon>
        Back
      </ion-button>
    </ion-buttons>

    <ion-title>Tee</ion-title>

    <ion-buttons slot="primary">
      <ion-button *ngIf="teeForm.dirty" fill="solid" shape="round" (click)="done()" haptic>Save</ion-button>
      <ion-button *ngIf="!teeForm.dirty" fill="solid" shape="round" color="light" (click)="done()">Close</ion-button>
    </ion-buttons>

  </ion-toolbar>

</ion-header>

<ion-content class="course-tee-content">

  <div class="ion-padding">

    <ion-segment [(ngModel)]="courseTeeSegment" mode="ios">

      <ion-segment-button value="information">
        Details
      </ion-segment-button>

      <ion-segment-button value="holes">
        Holes
      </ion-segment-button>

    </ion-segment>

  </div>

  <div [hidden]="courseTeeSegment !== 'information'">

    <form class="course-tee-form" [formGroup]="teeForm">

      <ion-list class="itemLines">

        <!-- color picker -->
        <ion-item>

          <div class="circle" slot="start">
            <div class="leftCircle"
              [ngStyle]="{'background-color': tee.firstTeeColor ? tee.firstTeeColor.value : 'initial'}"></div>
            <div class="rightCircle"
              [ngStyle]="{'background-color': tee.secondTeeColor ? tee.secondTeeColor.value : (tee.firstTeeColor ? tee.firstTeeColor.value : 'initial')}">
            </div>
          </div>

          <ion-chip outline="true" (click)="colorPicker($event, 'firstTeeColor')">
            <ion-label *ngIf="!tee.firstTeeColor" style="margin-top: 0px;">Add a color</ion-label>
            <ion-label *ngIf="tee.firstTeeColor" style="margin-top: 0px;">{{tee.firstTeeColor.friendlyName}}</ion-label>
            <ion-icon name="close-circle" *ngIf="tee.firstTeeColor" (click)="clearColor($event, 'firstTeeColor')">
            </ion-icon>
          </ion-chip>

          <ion-chip outline="true" (click)="colorPicker($event, 'secondTeeColor')">
            <ion-label *ngIf="!tee.secondTeeColor" style="margin-top: 0px;">Add a color</ion-label>
            <ion-label *ngIf="tee.secondTeeColor" style="margin-top: 0px;">{{tee.secondTeeColor.friendlyName}}
            </ion-label>
            <ion-icon name="close-circle" *ngIf="tee.secondTeeColor" (click)="clearColor($event, 'secondTeeColor')">
            </ion-icon>
          </ion-chip>

        </ion-item>

        <!-- tee id -->
        <ion-item>
          <ion-label position="stacked">
            Tee Id
          </ion-label>
          <ion-input formControlName="teeId"></ion-input>

          <ion-icon name="eye-outline" slot="end" (click)="showAllTeeIds()" style="align-self: end; margin-right: 0px;"
            color="primary">
          </ion-icon>

        </ion-item>
        <ion-item lines="none" class="validation"
          *ngIf="teeForm.controls.teeId.hasError('required') && !teeForm.controls.teeId.valid && teeForm.controls.teeId.touched">
          <ion-label>Please enter a tee id.</ion-label>
        </ion-item>

        <!-- name -->
        <ion-item>
          <ion-label position="stacked">
            Tee name
          </ion-label>
          <ion-input formControlName="name"></ion-input>
        </ion-item>
        <ion-item lines="none" class="validation"
          *ngIf="teeForm.controls.name.hasError('required') && !teeForm.controls.name.valid && teeForm.controls.name.touched">
          <ion-label>Please enter a tee name.</ion-label>
        </ion-item>

        <!-- distance -->
        <ion-item>
          <ion-label position="stacked" maxlength="4">
            Total distance (yards)
          </ion-label>
          <ion-input formControlName="distance"></ion-input>
        </ion-item>
        <ion-item lines="none" class="validation"
          *ngIf="teeForm.controls.distance.hasError('required') && !teeForm.controls.distance.valid && teeForm.controls.distance.touched">
          <ion-label>Please enter the total distance.</ion-label>
        </ion-item>

        <!-- rating -->
        <ion-item>
          <ion-label position="stacked" maxlength="4">
            Rating
          </ion-label>
          <ion-input formControlName="rating"></ion-input>
        </ion-item>
        <ion-item lines="none" class="validation"
          *ngIf="teeForm.controls.rating.hasError('required') && !teeForm.controls.rating.valid && teeForm.controls.rating.touched">
          <ion-label>Please enter the rating from these tees.</ion-label>
        </ion-item>

        <!-- slope -->
        <ion-item>
          <ion-label position="stacked" maxlength="3">
            Slope
          </ion-label>
          <ion-input formControlName="slope"></ion-input>
        </ion-item>
        <ion-item lines="none" class="validation"
          *ngIf="teeForm.controls.slope.hasError('required') && !teeForm.controls.slope.valid && teeForm.controls.slope.touched">
          <ion-label>Please enter the slope from these tees.</ion-label>
        </ion-item>

        <!-- par -->
        <ion-item>
          <ion-label position="stacked">
            Total par
          </ion-label>
          <ion-input formControlName="par" maxlength="2"></ion-input>
        </ion-item>
        <ion-item lines="none" class="validation"
          *ngIf="teeForm.controls.par.hasError('required') && !teeForm.controls.par.valid && teeForm.controls.par.touched">
          <ion-label>Please enter the par from these tees.</ion-label>
        </ion-item>

      </ion-list>

    </form>

  </div>

  <div [hidden]="courseTeeSegment !== 'holes'">

    <ion-list class="itemGroupLines">

      <ion-item-group *ngFor="let nine of tee.nines; let i = index">

        <!-- show name of the nine -->
        <ion-item-divider>
          <ion-label slot="start">{{nine.name}}</ion-label>
        </ion-item-divider>

        <ion-item>
          <ion-label position="stacked">Name</ion-label>
          <ion-input [(ngModel)]="nine.name" (ngModelChange)="nameRatingSlopeChange()"></ion-input>
        </ion-item>

        <ion-item>
          <ion-label position="stacked">Rating</ion-label>
          <ion-input type="number" inputmode="decimal" [(ngModel)]="nine.rating"
            (ngModelChange)="nameRatingSlopeChange()">
          </ion-input>
        </ion-item>

        <ion-item lines="none">
          <ion-label position="stacked">Slope</ion-label>
          <ion-input type="number" inputmode="numeric" [(ngModel)]="nine.slope"
            (ngModelChange)="nameRatingSlopeChange()"></ion-input>
        </ion-item>

        <!-- show name of the nine -->
        <ion-item-divider>

          <!-- <ion-label slot="start">{{nine.name}}</ion-label> -->
          <ion-label slot="start">Hole</ion-label>

          <div class="ion-text-end" style="width: 93px;">Par</div>
          <div class="ion-text-end" style="width: 100px;">Distance</div>
          <div class="ion-text-end" style="width: 100px;">Hdcp</div>

        </ion-item-divider>

        <!-- show the holes -->
        <ion-item *ngFor="let hole of nine.holes; let i = index" (click)="editHole(hole);" detail="true">

          <div class="numberCircle hole" slot="start">{{hole.number}}</div>

          <div class="ion-text-end" style="width: 65px;">{{hole.par}}</div>
          <div class="ion-text-end" style="width: 100px;">{{hole.distance}}</div>
          <div class="ion-text-end" style="width: 100px;">{{hole.hdcp}}</div>

        </ion-item>

      </ion-item-group>

    </ion-list>

  </div>

</ion-content>