import { Component } from '@angular/core';
import { AppFunction } from '../../app.function';
import { GroupEventDetailPage } from '../group-event-detail/group-event-detail.page';
import { AppGroupEvent, AppGroupI, GroupService } from 'src/app/app.group';
import { AppConfig } from 'src/app/app.config';
import { GroupTripDetailPage } from '../group-trip-detail/group-trip-detail.page';

@Component({
  selector: 'group-search',
  templateUrl: './group-search.page.html',
  styleUrls: ['./group-search.page.scss'],
})
export class GroupSearchPage {

  private _searchGroups: AppGroupI[] = [];
  firstSearch: boolean = false;

  constructor(
    public appFunction: AppFunction,
    public groupService: GroupService) {

  }

  searchCriteriaChange(event: any) {

    //if the search criteria is empty, clear the search results
    if (event.detail.value.length > 0) {

      this.groupService
        .searchGroupOnName(event.detail.value)
        .then((groupsFound) => {

          this._searchGroups = groupsFound;

          //indicate that at least the first search has occurred
          this.firstSearch = true;

        });

    } else {
      this.searchCriteriaClear();
    }

  }

  searchCriteriaClear() {
    this._searchGroups = [];
  }

  async selectGroup(group: AppGroupI) {

    //if group is of type event, show the group detail page
    if (group instanceof AppGroupEvent) {

      this.appFunction
        .modalCtrl
        .create({
          component: GroupEventDetailPage,
          presentingElement: await this.appFunction.routerOutlet(),
          cssClass: 'custom-modal', //for md
          backdropDismiss: false,
          componentProps: {
            group: group,
            editMode: AppConfig.EDIT_MODE.update
          }
        })
        .then((modal) => {

          modal
            .present()
            .catch((err) => {
              console.log('group-search.page.ts selectGroup AppGroupEvent modal present error', err);
            });

        })
        .catch((err) => {
          console.log('group-search.page.ts selectGroup AppGroupEvent modal create error', err);
        });

    } else { //else show the trip detail page


      this.appFunction
        .modalCtrl
        .create({
          component: GroupTripDetailPage,
          presentingElement: await this.appFunction.routerOutlet(),
          cssClass: 'custom-modal', //for md
          componentProps: {
            group: group,
            //editMode: AppConfig.EDIT_MODE.update
          }
        })
        .then((modal) => {

          modal
            .present()
            .catch((err) => {
              console.log('group-search.page.ts selectGroup AppGroupTrip modal present error', err);
            });
        })
        .catch((err) => {
          console.log('group-search.page.ts selectGroup AppGroupTrip modal create error', err);
        });

    }

  }

  get searchGroups(): AppGroupI[] {

    //don't show deleted members
    return this._searchGroups.filter((group) => {
      return group.exists;
    });

  }

  done() {
    this.appFunction.modalCtrl.dismiss();
  }

}