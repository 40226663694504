import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppFunction, enterFromRightAnimation, leaveToRightAnimation } from '../../app.function';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AppConfig } from '../../app.config';
import { AccountService } from '../../app.account';
import { PreferencesPage } from '../preferences/preferences.page';
import { DeviceId, DeviceInfo } from '@capacitor/device';

@Component({
  selector: 'support',
  templateUrl: './support.page.html',
  styleUrls: ['./support.page.scss'],
})
export class SupportPage implements OnInit {

  supportForm: FormGroup;
  subjectId: number = 0;
  subject = AppConfig.SUPPORT_SUBJECT;
  isFormSubmitted = false;

  constructor(

    public builder: FormBuilder,
    public router: Router,
    public appFunction: AppFunction,
    public accountService: AccountService) {

  }

  ngOnInit() {

    this.supportForm = this.builder.group({
      message: ['', Validators.required]
    });

  }

  async selectSubject() {

    this.appFunction
      .modalCtrlCreate({
        component: PreferencesPage,
        presentingElement: await this.appFunction.routerOutlet(),
        enterAnimation: enterFromRightAnimation,
        leaveAnimation: leaveToRightAnimation,
        cssClass: 'custom-modal', //for md
        componentProps: {
          title: 'Subject',
          value: this.subjectId,
          preferences: AppConfig.SUPPORT_SUBJECT
        }
      })
      .then((modal) => {

        modal
          .present()
          .catch((err) => {
            console.log('support.ts modal present error', err);
          });

        modal
          .onDidDismiss()
          .then((result) => {
            this.subjectId = result.data.value;
            this.supportForm.markAsDirty();
          });

      })
      .catch((err) => {
        console.log('support.ts modal create error', err);
      });

  }

  done() {

    const personalizations = [];

    if (this.supportForm.dirty) {

      //signal validation toast that form has been submitted
      this.isFormSubmitted = true;

      //save the form if valid 
      if (this.supportForm.valid) {

        this.appFunction
          .loadingCtrl
          .create({})
          .then(async (loading) => {

            loading.present();

            const deviceInfo: DeviceInfo = await this.appFunction.getDeviceInformation();
            const deviceId: DeviceId = await this.appFunction.getDeviceId();

            personalizations.push({
              "templateId": AppConfig.SENDGRID_TEMPLATES.Support,
              "to": {
                "email": AppConfig.SUPPORT_EMAIL
              },
              "from": {
                "email": AppConfig.SUPPORT_EMAIL
              },
              "replyTo": {
                "email": this.accountService.member.email
              },
              "dynamic_template_data": {
                "subject": this.subject[this.subjectId].description,
                "message": this.supportForm.controls.message.value,
                "firstName": this.accountService.member.firstName,
                "lastName": this.accountService.member.lastName,
                "email": this.accountService.member.email,
                "memberId": this.accountService.member.id,
                "platform": deviceInfo.platform,
                "cordova": 'not available in capacitor, update template',
                "isVirtual": deviceInfo.isVirtual,
                "model": deviceInfo.model,
                "serial": 'not available in capacitor, update template',
                "uuid": deviceId.identifier,
                "name": deviceInfo.name,
                "version": deviceInfo.osVersion,
                "manufacturer": deviceInfo.manufacturer,
                "operatingSystem": deviceInfo.operatingSystem,
                "appVersion": AppConfig.APP_VERSION + AppConfig.APP_VERSION_CODE
              },
              "hideWarnings": true
            });

            this.appFunction
              .sendEmail(personalizations)
              .then(() => {

                loading.dismiss();

                this.appFunction
                  .modalCtrl
                  .dismiss()
                  .then(() => {

                    this.appFunction
                      .toastCtrl
                      .create({
                        message: 'Your support request has been sent.',
                        position: 'top',
                        duration: 3000,
                        color: 'primary'
                      })
                      .then((toast) => {
                        toast.present();
                      });

                  });

              });

          });

      } else {
        //show any untouched errors
        this.appFunction.setDirtyControlAsTouched(this.supportForm);
      }

    } else {
      this.appFunction.modalCtrl.dismiss();
    }

  }

  cancel() {

    //confirm that user wants to discard changes
    if (this.supportForm.dirty) {

      this.appFunction
        .alertCtrl
        .create({
          header: 'Discard changes?',
          message: 'You have made changes to your Support request. Do you want to discard these changes?',
          buttons: [
            {
              text: 'No',
              handler: () => {
              }
            },
            {
              text: 'Yes, discard',
              handler: () => {
                this.appFunction.modalCtrl.dismiss();
              }
            }
          ]
        })
        .then((alert) => {
          alert.present();
        });

    } else {
      this.appFunction.modalCtrl.dismiss();
    }

  }

}
