import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { IonAccordionGroup, IonDatetime, NavParams } from '@ionic/angular';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DragulaService } from 'ng2-dragula';
import { AppFunction, DeepLinkCampaign, DeepLinkChannel, DeepLinkParmsI, DeepLinkService, HelpService } from '../../app.function';
import { AppEvent, AppEventPlayer, AppTeeTimeI, CommunicationType, EventActionCd, EventType } from '../../app.event';
import { AppMatch } from '../../app.match';
import { AppGroupEvent, AppGroupI, AppGroupTrip, GroupType, memberGroupPreferences } from '../../app.group';
import { ContentService } from '../../app.content';
import { AccountService, AppMember } from '../../app.account'
import { AppConfig, EventEmailPreference } from '../../app.config';
import { enterFromRightAnimation, leaveToRightAnimation } from '../../app.function';
import * as moment from 'moment';
import * as autoScroll from 'dom-autoscroller';
import { Subscription } from 'rxjs';
import { MemberListPage } from '../member-list/member-list.page';
import { NotePage } from '../note/note.page';
import { ClubSearchPage } from '../club-search/club-search.page';
import { ClubDetailPage } from '../club-detail/club-detail.page';
import { PostNewPage } from '../post-new/post-new.page';
import firebase from 'firebase/compat/app';
import { AppTee, ClubService } from 'src/app/app.club';
import { MatchEventDetailPage } from '../match-event-detail/match-event-detail.page';
import { Props } from 'tippy.js';
import { DisplayOptions, PopoverDirective } from 'src/app/directives/popover/popover.directive';
import { environment } from 'src/environments/environment';

export enum EventDetailSegment {
  Information = 'information',
  TeeTimes = 'teetimes',
  TimeLine = 'timeline',
}

enum EventDetailPopover {
  AddPlayers = 'addPlayers',
}

@Component({
  selector: AppConfig.PAGE.EventDetail,
  templateUrl: './event-detail.page.html',
  styleUrls: ['./event-detail.page.scss'],
  providers: [DragulaService],
})
export class EventDetailPage implements OnInit {

  eventForm: FormGroup;
  event: AppEvent;
  AppEventPlayer: typeof AppEventPlayer = AppEventPlayer;
  formEditMode: typeof AppConfig.EDIT_MODE = AppConfig.EDIT_MODE;
  editMode: number;
  eventDetailSegment: string = EventDetailSegment.Information;
  eventDateMin: string = moment().format('YYYY-MM-DD').toString();
  eventDateMax: string = moment().add(1, 'year').format('YYYY-MM-DD').toString();
  teeTimes: AppTeeTimeI[] = [];
  waitListTeeTime: AppTeeTimeI = {
    teeTime: AppConfig.WAIT_LIST_TEE_TIME,
    time: 'Wait list',
    players: [],
    playerCount(): number { return 0; }
  };
  bagName: string = 'teetime-bag';
  eventSubscription: Subscription;
  loaded: boolean = false;
  scroll;
  WAIT_LIST_TEE_TIME: Date = AppConfig.WAIT_LIST_TEE_TIME;
  datePickerObj: any = {
    fromDate: new Date(), //today
    showTodayButton: false, // default true
    showCloseButton: false, // default true
    closeOnSelect: true, // default false
    titleLabel: 'Select an Event Date', // default null
    dateFormat: 'MMMM, DD YYYY', // default DD MMM YYYY
    clearButton: false
  };
  isFormSubmitted: boolean = false;
  isGroupEvent: boolean = true;
  expandGroupEvent: boolean = false;
  screenKey: string = AppConfig.PAGE.EventDetail;
  minEventDate: string = moment().startOf('day').format('YYYY-MM-DDT00:00'); //get midnight of today 
  maxEventDate: string;
  @ViewChild('eventDtAccordian') eventDtAccordian: IonAccordionGroup;
  @ViewChild('firstTeeTimeAccordian') firstTeeTimeAccordian: IonAccordionGroup;
  @ViewChild('courseAccordion') courseAccordion: IonAccordionGroup;
  @ViewChild('teeAccordion') teeAccordion: IonAccordionGroup;
  @ViewChild('nines', { read: ElementRef }) ninesItem: ElementRef;
  @ViewChild('startingHole', { read: ElementRef }) startingHoleItem: ElementRef;
  @ViewChild('eventDt') eventDt: IonDatetime;
  EventDetailSegment: typeof EventDetailSegment = EventDetailSegment;
  actionCd: EventActionCd;
  ActionCd: typeof EventActionCd = EventActionCd;
  actionCdMessage: string;
  @ViewChild(PopoverDirective) addPlayers: PopoverDirective;

  constructor(
    public builder: FormBuilder,
    public appFunction: AppFunction,
    public accountService: AccountService,
    public dragulaService: DragulaService,
    public contentService: ContentService,
    public navParams: NavParams,
    public helpService: HelpService,
    public clubService: ClubService,
    public deepLinkService: DeepLinkService
  ) {

    helpService.screenWhatsNew(this.screenKey);

    // TODO: figure out why this is needed...it was to fix a bug
    delete window['TapticEngine'];

  }

  ngOnInit() {

    try {

      this.dragulaService
        .setOptions(this.bagName, {
          isContainer: function (el) {
            return false; //elements in drake.containers will be taken into account
          },
          moves: function (el, source, handle, sibling) {
            return el.classList.contains('doDragEventPlayer');
          },
          accepts: function (el, target, source, sibling) {
            return true; // elements can be dropped in any of the `containers` by default
          },
          invalid: function (el, handle) {
            return el.classList.contains('doNotDrag');
          },
          direction: 'vertical',              // X axis is considered when determining where an element would be dropped
          copy: false,                        // elements are moved by default, not copied
          copySortSource: false,              // elements in copy-source containers can be reordered
          revertOnSpill: true,                // spilling will put the element back where it was dragged from, if this is true
          removeOnSpill: false,               // spilling will `.remove` the element, if this is true
          //mirrorContainer: document.getElementById('event-detail-content'), // set the element that gets mirror elements appended
          mirrorContainer: document.body, // set the element that gets mirror elements appended
          ignoreInputTextSelection: true      // allows users to select input text, see details below
        });

      this.dragulaService
        .drop
        .subscribe(() => {
          this.appFunction.buzz();
          this.eventForm.markAsDirty();
        });

      //build form group
      this.eventForm = this.builder.group({
        description: ['', Validators.required],
        eventDt: ['', [Validators.required]],
        firstTeeTime: ['', Validators.required],
        numberTeeTimes: AppConfig.NUMBER_TEE_TIMES,
        teeTimeInterval: AppConfig.TEE_TIME_INTERVAL,
        club: [undefined, Validators.required],
        course: [undefined, Validators.required],
        tee: [undefined, Validators.required],
        numberOfHoles: [undefined],
        startingHoleIndex: [undefined],
        nineHolesOnlyIndex: [undefined],
        players: [[]]
      });

      //get deep link parms
      this.actionCd = this.navParams.get('actionCd');
      this.actionCdMessage = this.navParams.get('actionCdMessage');

      //get passed in edit mode
      this.editMode = this.navParams.get('editMode');
      if (this.editMode === AppConfig.EDIT_MODE.update) {

        //get event
        this.event = <AppEvent>this.navParams.get('event');

        //populate form 
        const teeTimeDateTime = moment(new Date(this.event.eventDt.toDate())).format();
        this.eventForm.controls['description'].setValue(this.event.description, { emitEvent: false });
        this.eventForm.controls['numberTeeTimes'].setValue(this.event.numberTeeTimes, { emitEvent: false });
        this.eventForm.controls['firstTeeTime'].setValue(teeTimeDateTime, { emitEvent: false });
        this.eventForm.controls['eventDt'].setValue(teeTimeDateTime, { emitEvent: false });
        this.eventForm.controls['teeTimeInterval'].setValue(this.event.teeTimeInterval, { emitEvent: false });
        this.eventForm.controls['club'].setValue(this.event.club, { emitEvent: false });
        this.eventForm.controls['course'].setValue(this.event.course, { emitEvent: false });
        this.eventForm.controls['tee'].setValue(this.event.tee, { emitEvent: false });
        this.eventForm.controls['numberOfHoles'].setValue(this.event.numberOfHoles, { emitEvent: false });
        this.eventForm.controls['startingHoleIndex'].setValue(this.event.startingHoleIndex, { emitEvent: false });
        this.eventForm.controls['nineHolesOnlyIndex'].setValue(this.event.nineHolesOnlyIndex, { emitEvent: false });

        //update tee times
        this.teeTimes = this.updateTeeTimes();

        //do we need this validator
        this.turnOnNineHolesOnlyIndexValidator(this.event.numberOfHoles === 9);

      } else { //new event

        //get group
        const group = <AppGroupEvent>this.navParams.get('group');

        //is this a group or private event
        this.isGroupEvent = group ? true : false;

        //create event class
        this.event = new AppEvent();

        //set create id and init
        this.event.createdMemberId = this.accountService.member.id;
        this.event
          .initialize(group)
          .then(() => {

            //default number of tee times
            const numberOfTeeTimes: number = this.navParams.get('numberOfTeeTimes');

            //populate form
            const teeTimeDateTime = moment().format();
            this.eventForm.controls['numberTeeTimes'].setValue(numberOfTeeTimes || AppConfig.NUMBER_TEE_TIMES, { emitEvent: false }); //if no group specified then default to 1 tee time
            this.eventForm.controls['firstTeeTime'].setValue(teeTimeDateTime, { emitEvent: false });
            this.eventForm.controls['teeTimeInterval'].setValue((<AppGroupEvent>this.event.group)?.teeTimeInterval || AppConfig.TEE_TIME_INTERVAL, { emitEvent: false });
            this.eventForm.controls['club'].setValue(this.event.club, { emitEvent: false });
            this.eventForm.controls['tee'].setValue(this.event.tee, { emitEvent: false });
            this.eventForm.controls['course'].setValue(this.event.course, { emitEvent: false });
            this.eventForm.controls['tee'].setValue(this.event.tee, { emitEvent: false });
            this.eventForm.controls['numberOfHoles'].setValue(this.event.numberOfHoles, { emitEvent: false });
            this.eventForm.controls['startingHoleIndex'].setValue(this.event.startingHoleIndex, { emitEvent: false });
            this.eventForm.controls['nineHolesOnlyIndex'].setValue(this.event.nineHolesOnlyIndex, { emitEvent: false });

            //if this event is from a trip group then get the min and max dates and set the event date to the trip departure date
            if (this.event.group?.type === GroupType.Trip) {
              this.minEventDate = moment((<AppGroupTrip>this.event.group).departureDt.toDate()).format('YYYY-MM-DDT00:00');
              this.maxEventDate = moment((<AppGroupTrip>this.event.group).returnDt.toDate()).format('YYYY-MM-DDT00:00');
              this.eventForm.controls['eventDt'].setValue((<AppGroupTrip>this.event.group).departureDt.toDate().toISOString(), { emitEvent: false });
              this.eventDt.reset((<AppGroupTrip>this.event.group).departureDt.toDate().toISOString());
            } else {
              //else set the event date to today
              this.eventForm.controls['eventDt'].setValue(teeTimeDateTime, { emitEvent: false });
            }

            //if this is a not group event then add logged in member/event organizer as player
            if (!this.isGroupEvent) {

              this.event
                .players
                .addPlayer(this.accountService.member.firstName, this.accountService.member.lastName, this.accountService.member.email)
                .then(() => {
                  //update tee times
                  this.teeTimes = this.updateTeeTimes();
                });

            }

          });

      }

      //subscribe to nineHolesOnlyIndex changes
      this.eventForm.controls['nineHolesOnlyIndex'].valueChanges.subscribe(() => {
        this.eventForm.markAsDirty();
      });

      //subscribe to eventDt changes
      this.eventForm.controls['eventDt'].valueChanges.subscribe(() => {
        this.teeTimeChange();
        this.eventDtAccordian.value = undefined;
        this.firstTeeTimeAccordian.value = 'firstTeeTime';
      });

      //subscribe to firstTeeTime changes
      this.eventForm.controls['firstTeeTime'].valueChanges.subscribe(() => {
        this.teeTimeChange();
      });

      //subscribe to event updates (what does this do?)
      this.eventSubscription = this.event
        .updateEvent
        .subscribe((lastUpdatedMemberId: string) => {

          //only notify if update came from another user
          if (this.loaded && lastUpdatedMemberId !== this.accountService.member.id) {

            //update tee times
            this.teeTimes = this.updateTeeTimes();

          }

          //this is a hack but I don't want this event when first loading
          this.loaded = true;

        });

    } catch (err) {
      console.log('event-detail.page.ts ngOnInit error', err);
      throw err;
    }

  }

  ionViewWillUnload() {
    this.eventSubscription.unsubscribe();
  }

  ionViewDidLeave() {
    this.cleanUpForm();
  }

  ionViewDidEnter() {

    //set up popover help config
    const addPlayersConfig: Partial<Props> = {
      placement: 'top-end',
      content: 'Tap here to add players',
      animation: 'bounce-up',
    }

    //set up popover help config
    const addPlayersDisplayConfig: Partial<DisplayOptions> = {
      name: EventDetailPopover.AddPlayers,
      //only show if new event and group event (i.e. not trip)
      show: this.editMode === AppConfig.EDIT_MODE.new && this.event.group?.type === GroupType.Event,
      frequencyOfDisplay: 1,
      closeAfterAnimation: true,
    }

    //show add players popover
    this.addPlayers.show(addPlayersConfig, addPlayersDisplayConfig);

  }

  private selectTeeTimesSegment() {

    //need settimeout because elements aren't available until after this method runs
    setTimeout(
      () => {

        //my container that I want to scroll has #dragulaScroll
        const stepContainer: HTMLDivElement = document.querySelector('#dragulaScroll');
        const drake = this.dragulaService.find(this.bagName);

        this.scroll = autoScroll(stepContainer, {
          margin: 30,
          maxSpeed: 10,
          scrollWhenOutside: true,
          autoScroll: function () {

            //console.log('dragging', drake.drake.dragging, this.down);

            if (this.down && drake.drake.dragging) {
              stepContainer.style.overflow = 'hidden';
              //console.log('prevent scroll');
            } else {
              stepContainer.style.overflow = 'scroll';
              //console.log('allow scroll');
            }

            return this.down && drake.drake.dragging;

          }
        });

      }
      , 0);

  }

  private selectInformationSegment() {
    this.cleanUpForm();
  }

  eventDetailSegmentChange(event: any) {

    if (event.detail.value === EventDetailSegment.Information) {
      this.selectInformationSegment();
    } else if (event.detail.value === EventDetailSegment.TeeTimes) {
      this.selectTeeTimesSegment();
    }

  }

  async addMemberFromGroup() {

    //get a flat list of currently selected member ids, this is used by the member search page
    const currentMemberObjectIds: string[] = [];
    this.event
      .players
      .active
      .all
      .forEach((player: AppEventPlayer) => {
        currentMemberObjectIds.push(player.memberId);
      });

    //create member search page
    this.appFunction
      .modalCtrlCreate({
        component: MemberListPage,
        presentingElement: await this.appFunction.routerOutlet(),
        componentProps: {
          members: this.event.group instanceof AppGroupTrip ? (<AppGroupTrip>this.event.group).invited.in : this.event.group.members,
          currentMemberObjectIds: currentMemberObjectIds
        }
      })
      .then((modal) => {

        //process newly selected members
        modal
          .onDidDismiss()
          .then((result) => {

            //if returned array has contacts then...
            if (Array.isArray(result?.data?.selectedMembers) && result?.data?.selectedMembers.length > 0) {

              //loop through returned array
              result
                .data
                .selectedMembers
                .forEach((selectedMember: AppMember) => {

                  //if member is not part of event then it's new and add to array 
                  if (!this.event.players.is(selectedMember)) {

                    //push new player on new player array, this player will get saved during "Done"
                    const player: AppEventPlayer = new AppEventPlayer();

                    player
                      .initialize(selectedMember.id, this.event)
                      .then(() => {

                        //add to list of players
                        this.event.players.add(player);

                        //get tee times
                        this.teeTimes = this.updateTeeTimes();

                        //force eventForm to dirty so that new members are saved to the event/players
                        this.eventForm.markAsDirty();

                      });

                  }

                });

            }

          });

        modal
          .present()
          .then(() => {
            //make tee time segment active
            this.eventDetailSegment = EventDetailSegment.TeeTimes;
          })
          .catch((err) => {
            console.log('event-detail.page.ts modal present error', err);
          });


      })
      .catch((err) => {
        console.log('event-detail.page.ts modal create error', err);
      });

  }

  addPlayerConfirm(): Promise<void> {

    return new Promise<void>((resolve) => {

      //confirmation
      this.appFunction
        .actionCtrl
        .create({
          header: 'Add player from',
          buttons: [
            {
              text: 'Contacts and Group members',
              handler: () => {

                if (this.appFunction.platform.is('mobile')) {

                  this.event
                    .players
                    .addFromContacts()
                    .then(() => {

                      //make tee time segment active
                      this.eventDetailSegment = EventDetailSegment.TeeTimes;

                      //update tee times
                      this.teeTimes = this.updateTeeTimes();

                      resolve();

                    });

                } else {

                  this.appFunction
                    .alertCtrl
                    .create({
                      header: 'Contacts not available',
                      subHeader: "Contacts are not available on the web. Please use Double Ace Golf on your mobile device to access your contacts.",
                      buttons: [
                        {
                          text: 'OK',
                          handler: () => {
                          }
                        }
                      ]
                    })
                    .then((alert) => {

                      alert
                        .onDidDismiss()
                        .then(() => {

                          resolve();

                        });

                      alert.present();

                    });

                }
              }
            },
            {
              text: 'Name only',
              handler: () => {

                this.event
                  .players
                  .addJustName()
                  .then(() => {

                    //make tee time segment active
                    this.eventDetailSegment = EventDetailSegment.TeeTimes;

                    //update tee times
                    this.teeTimes = this.updateTeeTimes();

                    resolve();

                  });

              }
            },
            {
              text: 'Cancel',
              role: 'cancel'
            }
          ]
        })
        .then((action) => {
          action.present();
        });

    });

  }

  private removeMemberFromEvent(player: AppEventPlayer) {

    //remove player from event
    this.event
      .players
      .remove(player)
      .then((removed) => {

        //update tee times only if a player was actually removed
        if (removed) {
          this.teeTimes = this.updateTeeTimes();

          //force eventForm to dirty so that we ask the organizer to send out new tee times. This is sort of hacky.
          this.eventForm.markAsDirty();
        }

      });

  }

  private removeMemberFromEventConfirm(player: AppEventPlayer) {

    //confirmation
    this.appFunction
      .actionCtrl
      .create({
        header: 'Please confirm',
        buttons: [
          {
            text: 'Remove player',
            role: 'destructive',
            handler: () => {
              this.removeMemberFromEvent(player);
            }
          },
          {
            text: 'Cancel',
            role: 'cancel'
          }
        ]
      })
      .then((action) => {
        action.present();
      });

  }

  confirmPlayerAction(player: AppEventPlayer) {

    //confirmation
    this.appFunction
      .actionCtrl
      .create({
        header: 'Please confirm',
        buttons: [
          {
            text: 'Remove player from event',
            handler: () => {
              this.removeMemberFromEventConfirm(player);
            }
          },
          {
            text: 'Add guest to player',
            handler: () => {

              //guests (because of a design issue) can't be added until the event is first created/saved
              if (this.event.exists) {

                player
                  .guests
                  .addConfirm(this.event)
                  .then(() => {
                    //force eventForm to dirty so guest(s) are saved
                    this.eventForm.markAsDirty();
                  })

              } else {

                //can't add guests yet
                this.appFunction
                  .alertCtrl
                  .create({
                    header: 'Notice',
                    message: 'You may not add guests until this event is first saved.',
                    buttons: ['OK']
                  })
                  .then((alert) => {
                    alert.present();
                  });

              }

            }
          },
          {
            text: 'Cancel',
            role: 'cancel'
          }
        ]
      })
      .then((action) => {
        action.present();
      });

  }

  guestRemoveConfirm(player: AppEventPlayer) {

    //confirmation
    this.appFunction
      .actionCtrl
      .create({
        header: 'Please confirm',
        buttons: [
          {
            text: 'Remove guest',
            role: 'destructive',
            handler: () => {
              //delete player
              player.remove();
            }
          },
          {
            text: 'Cancel',
            role: 'cancel'
          }
        ]
      })
      .then((action) => {
        action.present();
      });

  }

  async matchEdit(match: AppMatch = undefined) {

    //create/update match page
    this.appFunction
      .modalCtrlCreate({
        component: MatchEventDetailPage,
        presentingElement: await this.appFunction.routerOutlet(),
        componentProps: {
          parent: this.event,
          match: match,
          matchOptions: AppConfig.EVENT_MATCH_CONFIGURATION
        }
      })
      .then((modal) => {

        //process newly selected members
        modal
          .onDidDismiss()
          .then((result) => {

            if (result.role === 'update') {
              this.event.matches.addMatch(result.data.match, true);
              this.eventForm.markAsDirty();
            }

          });

        modal
          .present()
          .catch((err) => {
            console.log('event-detail.page.ts matchDetails modal present error', err);
          });


      })
      .catch((err) => {
        console.log('event-detail.page.ts matchDetails modal create error', err);
      });

  }

  getTeeTimeDateTime(eventTeeTimeDate: string, eventFirstTeeTime: string): Date {

    const year = moment(eventTeeTimeDate).get('year');
    const month = moment(eventTeeTimeDate).get('month');
    const day = moment(eventTeeTimeDate).get('date');
    const hour = moment(eventFirstTeeTime).get('hour');
    const minute = moment(eventFirstTeeTime).get('minute');
    const date = new Date(year, month, day, hour, minute);
    return date;

  }

  teeTimeChange() {

    try {

      //get difference in first tee times
      const oldFirstTeeTime: Date = this.event.eventDt?.toDate();
      const newFirstTeeTime: Date = this.getTeeTimeDateTime(this.eventForm.controls.eventDt.value, this.eventForm.controls.firstTeeTime.value);
      const changeFirstTeeTime: number = moment(newFirstTeeTime).diff(oldFirstTeeTime, 'minutes');

      //update current player tee times
      this.event
        .players
        .active
        .all
        .forEach((player) => {

          //if not on wait list then update player tee time
          if (!moment((<AppEventPlayer>player).teeTime.toDate()).isSame(AppConfig.WAIT_LIST_TEE_TIME)) {
            (<AppEventPlayer>player).teeTime = firebase.firestore.Timestamp.fromDate(moment((<AppEventPlayer>player).teeTime.toDate()).add(changeFirstTeeTime, 'minutes').toDate());
          }

        });

      //recalculate tee times
      this.teeTimes = this.updateTeeTimes();

      //update event tee time
      this.event.eventDt = firebase.firestore.Timestamp.fromDate(newFirstTeeTime);

    } catch (err) {
      console.log('event-details.page.ts teeTimeChange error', err);
    }

  }

  displayNote(player: AppEventPlayer) {

    this.appFunction
      .modalCtrlCreate({
        component: NotePage,
        componentProps: {
          player: player
        },
        cssClass: 'custom-modal-auto-height'
      })
      .then((modal) => {

        modal
          .present()
          .catch((err) => {
            console.log('event-detail.page.ts modal present error', err);
          });

      })
      .catch((err) => {
        console.log('event-detail.page.ts modal create error', err);
      });

  }

  private updateTeeTimes(): AppTeeTimeI[] {

    try {

      let teeTime: AppTeeTimeI = undefined;
      let teeTimeFound: boolean;
      const teeTimes: AppTeeTimeI[] = [];
      const firstTeeTime: Date = new Date(this.getTeeTimeDateTime(this.eventForm.controls.eventDt.value, this.eventForm.controls.firstTeeTime.value));

      //loop tee times and build main array of tee times
      for (var i = 0; i <= Number(this.eventForm.controls.numberTeeTimes.value) - 1; i++) {

        //get tee time
        let nextTeeTime: Date = moment(firstTeeTime).add(this.eventForm.controls.teeTimeInterval.value * i, 'minutes').toDate();

        //add new tee time for display
        teeTime = {
          teeTime: nextTeeTime,
          time: moment(nextTeeTime).format('h:mm a').toString(),
          players: [],
          playerCount(): number {
            let playerCount: number = 0;

            this.players
              .forEach((player: AppEventPlayer) => {
                playerCount = playerCount + player.guests.all.length + 1;
              });

            return playerCount;

          }
        };

        //push on player tee time
        teeTimes.push(teeTime);

      }

      //reset wait list tee time player list
      this.waitListTeeTime.players = [];
      teeTimes.push(this.waitListTeeTime);

      //now loop through each player
      this.event
        .players
        .active
        .groupMembersOnly //only include group members and not guests
        .forEach((player) => {

          //reset flag
          teeTimeFound = false;

          //for each player find the correct tee time
          teeTimes
            .forEach((teeTime) => {

              //if tee time not previously found then...
              if (!teeTimeFound) {

                //if the player already has a tee time that matches current tee time then...
                //TODO: this causes a defect when the teetime interval is changed. The first tee time matches but all others don't match to the tee times set before the interval is changed 
                if (player.teeTime && moment((<AppEventPlayer>player).teeTime.toDate()).isSame(teeTime.teeTime)) {

                  teeTime.players.push(<AppEventPlayer>player);

                  //signal that we found a tee time for player
                  teeTimeFound = true;

                } else if ((<AppEventPlayer>player).teeTime && moment((<AppEventPlayer>player).teeTime.toDate()).isSame(AppConfig.WAIT_LIST_TEE_TIME) && (teeTime.playerCount() + ((<AppEventPlayer>player).guests.all.length + 1)) <= 4) {
                  //if player doesn't have a tee time and current teetime has room then...

                  teeTime.players.push(<AppEventPlayer>player);

                  //signal that we found a tee time for player
                  teeTimeFound = true;

                  //mark form as dirty
                  this.eventForm.markAsDirty();

                }

              }

            });

          //if tee time slot was never found then put in wait list
          if (!teeTimeFound) {
            this.waitListTeeTime.players.push(<AppEventPlayer>player);
          }

        });

      //set array
      return teeTimes;

    } catch (err) {
      console.log('event-details.page.ts updateTeeTimes error', err);
    }

  }

  deleteEventConfirm() {

    //confirmation
    this.appFunction
      .alertCtrl
      .create({
        header: 'Please confirm!',
        message: "Once canceled, this Event (including scores and matches) will no longer be available. Are you sure you want to cancel this Event?",
        backdropDismiss: false,
        buttons: [
          {
            text: "No",
            role: 'cancel',
            handler: () => {
              //no action
            }
          },
          {
            text: 'Yes, cancel',
            role: 'destructive',
            handler: () => {
              this.deleteEvent();
            }
          }
        ]
      })
      .then((action) => {
        action.present();
      });

  }

  confirmClubAction() {

    //if club selected then ask if new search or club edit
    if (this.event.club) {

      //confirmation
      this.appFunction
        .actionCtrl
        .create({
          header: 'Please confirm',
          buttons: [
            {
              text: 'View/edit club',
              handler: () => {
                this.viewClub();
              }
            },
            {
              text: 'Change club',
              handler: () => {
                this.searchClub();
              }
            },
            {
              text: 'Cancel',
              role: 'cancel',
              handler: () => {
              }
            }
          ]
        })
        .then((action) => {
          action.present();
        });

    } else { //else just search
      this.searchClub();
    }

  }

  private async searchClub() {

    this.appFunction
      .modalCtrlCreate({
        component: ClubSearchPage,
        presentingElement: await this.appFunction.routerOutlet(),
        cssClass: 'custom-modal' //for md
      })
      .then((modal) => {

        modal
          .present()
          .catch((err) => {
            console.log('event-detail.page.ts modal present error', err);
          });

        modal
          .onDidDismiss()
          .then((result) => {

            //set org 
            if (result.role === 'select') {

              //set local value
              this.eventForm.controls['club'].setValue(result.data.club);

              //...if only one course then auto select course
              if (this.eventForm.controls.club.value.courses.length === 1) {
                this.eventForm.controls['course'].setValue(this.eventForm.controls.club.value.courses[0]);

                //...and open tees dropdown, need setTimeout to wait for tee accordian to made visible
                setTimeout(() => {
                  this.teeAccordion.value = 'tee';
                }, 0);

              } else {
                //...else clear course
                this.eventForm.controls['course'].setValue(undefined);

                //...and open course dropdown, need setTimeout to wait for course accordian to made visible
                setTimeout(() => {
                  this.courseAccordion.value = 'course';
                }, 0);
              }

              //when changing course we must reset tee
              this.eventForm.controls['tee'].setValue(undefined);
              this.eventForm.controls['nineHolesOnlyIndex'].setValue(undefined);

              //set number of holes and update validator
              if (this.eventForm.controls.course.value?.holes === 9) {
                this.eventForm.controls['numberOfHoles'].setValue(9);
                this.turnOnNineHolesOnlyIndexValidator(true);

              } else {
                this.eventForm.controls['numberOfHoles'].setValue(18);
                this.turnOnNineHolesOnlyIndexValidator(false);

              }

              //mark as dirty
              this.eventForm.markAsDirty();

              //send a support email if any data is missing from selected course
              this.clubService.validateClubData(result.data.club);

            }

          });

      })
      .catch((err) => {
        console.log('event-detail.page.ts modal create error', err);
      });

  }

  private async viewClub() {

    this.appFunction
      .modalCtrlCreate({
        component: ClubDetailPage,
        presentingElement: await this.appFunction.routerOutlet(),
        enterAnimation: enterFromRightAnimation,
        leaveAnimation: leaveToRightAnimation,
        cssClass: 'custom-modal', //for md
        componentProps: {
          club: this.event.club,
          closeLabel: 'Close'
        }
      })
      .then((modal) => {

        modal
          .present()
          .catch((err) => {
            console.log('event-detail.page.ts modal present error', err);
          });

      })
      .catch((err) => {
        console.log('event-detail.page.ts modal create error', err);
      });

  }

  async selectCourse(course: string) {

    this.eventForm.controls['course'].setValue(course);

    //clear tee values
    this.eventForm.controls['tee'].setValue(undefined);

    //open tees dropdown, need setTimeout to wait for tee accordian to made visible
    setTimeout(() => {
      this.teeAccordion.value = 'tee';
    }, 0);

    //set the number of holes. This really only matters if the course is an 18 hole course.
    this.eventForm.controls['numberOfHoles'].setValue(this.eventForm.controls.course.value.holes);

    //make dirty so it saves
    this.eventForm.markAsDirty();

  }

  async selectTee(tee: AppTee) {

    this.eventForm.controls['tee'].setValue(tee);

    //make dirty so it saves
    this.eventForm.markAsDirty();

  }

  private turnOnNineHolesOnlyIndexValidator(on: boolean) {

    if (on) {

      //add validator
      this.appFunction
        .setValidators(
          <FormGroup>(
            this.eventForm
          ),
          'nineHolesOnlyIndex',
          Validators.required
        );

    } else {

      //add validator
      this.appFunction
        .setValidators(
          <FormGroup>(
            this.eventForm
          ),
          'nineHolesOnlyIndex'
        );

    }

  }

  get waitListCount(): number {

    let waitListCount: number = 0;

    this.waitListTeeTime.players.forEach((player) => {
      waitListCount = waitListCount + player.guests.all.length + 1;
    });

    return waitListCount;

  }

  private cleanUpForm() {

    if (this.dragulaService.find(this.bagName) !== undefined) {
      this.dragulaService.destroy(this.bagName);
    }

    if (this.scroll) {
      this.scroll.destroy();  // destroy when don't use auto-scroll
    }

  }

  decrementCounter(controlName: string) {
    this.eventForm.controls[controlName].setValue(this.eventForm.controls[controlName].value - 1);
    this.eventForm.controls[controlName].markAsDirty();
    this.teeTimeChange();
  }

  incrementCounter(controlName: string) {
    this.eventForm.controls[controlName].setValue(this.eventForm.controls[controlName].value + 1);
    this.eventForm.controls[controlName].markAsDirty();
    this.teeTimeChange();
  }

  async newPost() {

    this.appFunction
      .modalCtrlCreate({
        component: PostNewPage,
        keyboardClose: false,
        presentingElement: await this.appFunction.routerOutlet(),
        cssClass: 'custom-modal', //for md
        backdropDismiss: false,
        componentProps: {
          poster: this.event.group || this.accountService.member //if no group then post as logged in memeber
        }
      })
      .then((modal) => {

        modal
          .present()
          .catch((err) => {
            console.log('event-detail.page.ts newPost modal present error', err);
          });

      })
      .catch((err) => {
        console.log('event-detail.page.ts newPost modal create error', err);
      });

  }

  selectHoles(numberOfHoles: number) {

    //turn on/off nine hole validation
    this.turnOnNineHolesOnlyIndexValidator(numberOfHoles === 9);

    //set the number of holes
    this.eventForm.controls['numberOfHoles'].setValue(numberOfHoles);

    //mark as dirty
    this.eventForm.markAsDirty();

  }

  selectGroup(group: AppGroupI) {

    //close group selection
    this.expandGroupEvent = false;

    //set group
    this.event.group = group;
    this.event.groupId = group?.id;

  }

  private deleteEvent() {

    this.appFunction
      .loadingCtrl
      .create({ message: 'Canceling event...' })
      .then((loading) => {

        loading.present();

        this.event
          .delete()
          .then(() => {

            this.sendEventCancellationCommunications();

            loading.dismiss();
            this.appFunction.modalCtrl.dismiss({ event: this.event }, 'delete');

          })
          .catch((err) => {
            console.log('event-details.page.ts deleteEvent error', JSON.stringify(err));
          });

      });

  }

  private async sendEventEmail(communicationType: CommunicationType): Promise<void> {

    const personalizations = [];
    let showGroupAvatar: string = 'none';
    let showPartnerContent: string = 'none';
    let partnerContent: string = '';
    let teeTimeHTML: string = '';

    //create field html if there are confirmed players
    if (this.event.players.active.all.length > 0) {

      //for each tee time...
      this
        .teeTimes
        .forEach((teeTime) => {

          //only show tee time if it has players 
          if (teeTime.players.length > 0) {

            //create header row for tee time
            teeTimeHTML = teeTimeHTML + '<tr><th style="text-align: left; padding-bottom: 5px;">' + teeTime.time + '</th></tr>';

            //create html row for players
            teeTime
              .players
              .forEach((player) => {

                //create row for member
                teeTimeHTML = teeTimeHTML + '<tr><td>';
                teeTimeHTML = teeTimeHTML + '<img style="border-radius: 50%; display: inline-block; max-width: 100%; width: 30px; height: 30px" src="' + (player.URI === AppConfig.NO_AVATAR_URI ? AppConfig.NO_AVATAR_URI_EMAIL : player.URI) + '">'; //getting the Firebase no avatar URI...very hacky
                teeTimeHTML = teeTimeHTML + '<span style="vertical-align: top; line-height: 30px"> ' + player.firstName + ' ' + player.lastName + '</span>';
                teeTimeHTML = teeTimeHTML + '</td></tr>';

                //create row for guests
                player.guests.all.forEach((guest) => {
                  teeTimeHTML = teeTimeHTML + '<tr><td>';
                  teeTimeHTML = teeTimeHTML + '<img style="border-radius: 50%; display: inline-block; max-width: 100%; width: 30px; height: 30px" src="' + (guest.URI === AppConfig.NO_AVATAR_URI ? AppConfig.NO_AVATAR_URI_EMAIL : guest.URI) + '">'; //getting the Firebase no avatar URI...very hacky
                  teeTimeHTML = teeTimeHTML + '<span style="vertical-align: top; line-height: 30px"> ' + guest.firstName + ' ' + guest.lastName + ' (guest of ' + player.firstName + ' ' + player.lastName + ')' + '</span>';
                  teeTimeHTML = teeTimeHTML + '</td></tr>';
                });

              });

          }

        });

    } else {
      teeTimeHTML = '<tr><td>No players have joined this event.</td></tr>';
    }

    //complete the field html
    teeTimeHTML = '<table>' + teeTimeHTML + '</table>';

    //if the group has an avatar then show
    if (this.event.avatar.length > 0) {
      showGroupAvatar = 'table-row';
    }

    //get partner content
    const content = await this.contentService.getPartnerContent((<AppGroupEvent>this.event.group)?.clubId);
    if (content) {
      partnerContent = content;
      showPartnerContent = 'block';
    }

    //should we send to all group members or just event players
    const members: AppMember[] = (communicationType === CommunicationType.GroupMembers ?
      this.event.members :
      this.event.players.active.members
    );

    //send to selected players
    for (let member of members) {

      //if member is is not undefined (which could occur for a name only guest)
      if (member) {

        const memberAndGuests: {
          member: AppMember,
          type: number
        }[] = [];
        let showMemberTeeTime: string = 'none';
        let showJoinEvent: string = 'inline';
        let showMemberWaitList: string = 'none'
        let memberTeeTime: string = '';

        //insert member to members array (type 0 is group member)
        memberAndGuests.push({ member: member, type: 0 });

        //get player document
        const player: AppEventPlayer = <AppEventPlayer>this.event.players.getPlayerByMemberId(member.id);
        if (player) {

          memberTeeTime = moment(player.teeTime.toDate()).format('h:mm a').toString();

          //player already joined event so don't show
          showJoinEvent = 'none';

          //don't show the time if on wait list
          if (moment(player.teeTime.toDate()).isSame(AppConfig.WAIT_LIST_TEE_TIME)) {
            showMemberWaitList = 'inline';
          } else {
            showMemberTeeTime = 'inline';
          }

          //now insert guests (if any) into member array (type 1 is guest to the event)
          player
            .guests
            .all
            .filter((guest) => {
              return guest.member; //only return guests that are also members 
            })
            .forEach((guest) => {
              memberAndGuests.push({ member: guest.member, type: 1 });
            });
        }

        //send an email for each member and guest
        for (let memberAndType of memberAndGuests) {

          const member = memberAndType.member;
          const type = memberAndType.type;

          //get email preferences
          const preference = <memberGroupPreferences>member.getPreference(this.event.group?.id);

          //nameless players (guests) don't have/return preferences 
          if (preference) {

            //determine if this user wants this email
            const sendEmail: boolean =
              (this.editMode === AppConfig.EDIT_MODE.new && preference.e === EventEmailPreference.First) ||
              (preference.e === EventEmailPreference.All || preference.e === undefined) //member wants all emails or member hasn't set preferences

            //if event update email (e) preference is set or is undefined (will then assume true)
            if (sendEmail) {

              //if showJoinEvent is true then create deep link to join event
              let deeplink: string;
              if (showJoinEvent) {

                //create deep link parms object
                const deepLinkParms: DeepLinkParmsI = {
                  route: '/main/home',
                  page: AppConfig.PAGE.EventView,
                  id: this.event.id,
                  segment: '',
                  actionCd: EventActionCd.AskToJoin,
                  actionCdMessage: null,
                  email: member.email,
                  welcomeMessage: 'Hi ' + member.firstName + ', you have been invited to play in the <b>' + this.event.name + '</b> by ' + this.accountService.member.firstName + ' ' + this.accountService.member.lastName + '.',
                  emailHasAccount: null,
                  additionalData: { groupId: this.event.group?.id || null, memberId: member.id }
                }

                //create deep link
                deeplink = await this.deepLinkService
                  .createDeepLink(DeepLinkCampaign.EventInvite, DeepLinkChannel.email, deepLinkParms, { title: 'Welcome to Double Ace Golf', description: 'For the best experience please leave the checkbox below selected.' });

              }

              //create matches json array
              const matches = [];
              this.event
                .matches
                .parent
                .forEach((match) => {
                  matches.push({
                    "gameName": match.getGame(match.game).name,
                    "gameDescription": match.getGame(match.game).description,
                    "handicapTypeDescription": match.getHandicapType(match.handicapType).name,
                    "grossNetTypeDescription": match.getGrossNetValueType(match.grossNetType).description,
                    "teamSizeDescription": match.getTeamSize(match.game, match?.teamSize).description,
                    "scoringTypeDescription": match.getTeamScoring(match.game, match.teamSize, match.scoringType).description,
                    "gameNotes": match.notes
                  });
                });

              //if club only has one course then just show club name, else show club name and course name
              const clubName: string = (this.event.club.courses.length === 1) ? this.event.club.name : this.event.club.name + ' - ' + this.event.course.name;

              //setup email
              personalizations.push({
                "subject": 'Event Notification',
                "templateId": type === 0 ? AppConfig.SENDGRID_TEMPLATES.EventUpdate.Member : AppConfig.SENDGRID_TEMPLATES.EventUpdate.Guest,
                "to": {
                  "name": member.firstName + ' ' + member.lastName,
                  "email": member.email
                },
                "from": {
                  "name": this.accountService.member.firstName + ' ' + this.accountService.member.lastName,
                  "email": AppConfig.NOREPLY_EMAIL
                },
                "replyTo": {
                  "name": this.accountService.member.firstName + ' ' + this.accountService.member.lastName,
                  "email": this.accountService.member.email
                },
                "dynamic_template_data": {
                  "subject": 'Event Notification',
                  "firstName": member.firstName,
                  "teeTime": memberTeeTime,
                  "groupName": this.event.name,
                  "groupAvatarURI": this.event.avatarEmail,
                  "showMemberTeeTime": showMemberTeeTime,
                  "showMemberWaitList": showMemberWaitList,
                  "showJoinEvent": showJoinEvent,
                  "showGroupAvatar": showGroupAvatar,
                  "eventDt": moment(this.eventForm.controls.eventDt.value).format('dddd MMMM D, YYYY').toString(),
                  "eventTime": moment(this.eventForm.controls.firstTeeTime.value).format('h:mm a').toString(),
                  "fullField": teeTimeHTML,
                  "description": this.eventForm.controls.description.value,
                  "showPartnerContent": showPartnerContent,
                  "partnerContent": partnerContent,
                  "clubName": clubName + ', ' + this.event.startingHoleDescription + ', ' + this.event.numberOfHolesDescription,
                  "clubAddress": this.event.club.fullAddress(),
                  "numberOfTeeTimes": this.eventForm.controls.numberTeeTimes.value,
                  "matches": matches,
                  "deepLink": deeplink
                },
                "custom_args": {
                  memberId: member.id,
                  eventId: this.event.id,
                  environment: environment.POSTHOG_CONFIG.environment
                },
                "hideWarnings": true
              });

            }

          }

        };
      }
    }

    await this.appFunction.sendEmail(personalizations);

  }

  private async sendEventPushNotification(communicationType: CommunicationType): Promise<void> {

    //send to each member of the group
    const members: AppMember[] = await this.event.getNotificationDistributionList(this.editMode, communicationType);

    const title: string = (this.editMode === AppConfig.EDIT_MODE.new) ? 'New Event' : 'Event Update';
    const message: string = (this.editMode === AppConfig.EDIT_MODE.new) ? 'A new event scheduled for ' + moment(this.eventForm.controls.eventDt.value).format('dddd MMMM D, YYYY').toString() + ' has been created for ' + this.event.name : 'There is an event update for ' + this.event.name;

    //loop through each member and send push notification
    for (let member of members) {

      //create deep link parms object
      const deepLinkParms: DeepLinkParmsI = {
        route: '/main/home',
        page: AppConfig.PAGE.EventView,
        id: this.event.id,
        segment: '',
        actionCd: EventActionCd.AskToJoin,
        actionCdMessage: null,
        email: member.email,
        welcomeMessage: 'Hi ' + member.firstName + ', you have been invited to play in the <b>' + this.event.name + '</b> by ' + this.accountService.member.firstName + ' ' + this.accountService.member.lastName + '.',
        emailHasAccount: null,
        additionalData: { groupId: this.event.group?.id || null, memberId: member.id }
      }

      //create deep link
      const deeplink = await this.deepLinkService
        .createDeepLink(DeepLinkCampaign.EventInvite, DeepLinkChannel.push, deepLinkParms, { title: 'Welcome to Double Ace Golf', description: 'For the best experience please leave the checkbox below selected.' });

      const memberIds = [];
      memberIds.push({ memberId: member.id });

      await this.appFunction
        .sendNotification(this.accountService.member.id, memberIds, title, message, this.event.avatar, { eventId: this.event.id }, deeplink);

    };

    /*  return new Promise<void>((resolve, reject) => {
 
       //send to each member of the group
       this.event
         .getNotificationDistributionList(this.editMode, communicationType)
         .then((members) => {
 
           const title: string = (this.editMode === AppConfig.EDIT_MODE.new) ? 'New Event' : 'Event Update';
           const message: string = (this.editMode === AppConfig.EDIT_MODE.new) ? 'A new event scheduled for ' + moment(this.eventForm.controls.eventDt.value).format('dddd MMMM D, YYYY').toString() + ' has been created for ' + this.event.name : 'There is an event update for ' + this.event.name;
 
           //loop through each member and send push notification
           members
             .forEach(async (member) => {
 
               //if showJoinEvent is true then create deep link to join event
               let deeplink: string;
 
               //create deep link parms object
               const deepLinkParms: DeepLinkParmsI = {
                 route: '/main/home',
                 page: AppConfig.PAGE.EventView,
                 id: this.event.id,
                 segment: '',
                 actionCd: EventActionCd.AskToJoin,
                 actionCdMessage: null,
                 email: member.email,
                 welcomeMessage: 'Hi ' + member.firstName + ', you have been invited to play in the <b>' + this.event.name + '</b> by ' + this.accountService.member.firstName + ' ' + this.accountService.member.lastName + '.',
                 emailHasAccount: null,
                 additionalData: { groupId: this.event.group?.id || null, memberId: member.id }
               }
 
               //create deep link
               await this.deepLinkService
                 .createDeepLink(DeepLinkCampaign.EventInvite, DeepLinkChannel.push, deepLinkParms, { title: 'Welcome to Double Ace Golf', description: 'For the best experience please leave the checkbox below selected.' })
                 .then((eventInviteDeepLink) => {
                   deeplink = eventInviteDeepLink;
                 })
                 .catch((err) => {
                   reject(err);
                 });
 
               const memberIds = [];
               memberIds.push({ memberId: member.id });
 
               this.appFunction
                 .sendNotification(this.accountService.member.id, memberIds, title, message, this.event.avatar, { eventId: this.event.id }, deeplink)
                 .then(() => {
                   resolve();
                 })
                 .catch((err) => {
                   reject(err);
                 });
 
             });
 
         });
 
     }); */

  }

  private sendEventCommunications(communicationType: CommunicationType): Promise<void> {

    return new Promise<void>((resolve, reject) => {

      //send communications
      const p = this.sendEventPushNotification(communicationType);
      const q = this.sendEventEmail(communicationType);

      Promise
        .all([p, q])
        .then(() => {

          this.appFunction
            .queueToast({
              message: 'The event communications have been sent.',
              position: 'top',
              duration: 4000,
              color: 'secondary',
              closeButtonText: 'Ok'
            });

          resolve();

        })
        .catch((err) => {
          console.log('event-details.page.ts sendEventUpdateCommunications error', JSON.stringify(err));
          reject(err);
        });

    });

  }

  private async sendEventCancellationEmail(): Promise<void> {

    const personalizations = [];
    let showGroupAvatar: string = 'none';

    //if the group has an avatar then show
    if (this.event.avatar.length > 0) {
      showGroupAvatar = 'table-row';
    }

    //send to each member of the group
    this.event
      .members
      .forEach((member) => {

        const members: AppMember[] = [];

        //insert member to members array (type 0 is group member)
        members.push(member);

        //if the player has a tee time then show it
        const player: AppEventPlayer = <AppEventPlayer>this.event.players.getPlayer(member.id);
        if (player) {
          //now insert guests (if any) into member array (type 1 is guest to the event)
          player
            .guests
            .all
            .filter((guest) => {
              return guest.member; //only return guests that are also members 
            })
            .forEach((guest) => {
              members.push(guest.member);
            });
        }

        //send an email for each member and guest
        members
          .forEach((member) => {

            //get email preferences
            const preference = <memberGroupPreferences>member.getPreference(this.event.group?.id);

            //determine if this user wants this email
            const sendEmail: boolean =
              (this.editMode === AppConfig.EDIT_MODE.new && preference.e === EventEmailPreference.First) || //member only wants new event emails
              (preference.e === EventEmailPreference.All || preference.e === undefined) //member wants all emails or member hasn't set preferences

            //if event update email (e) preference is set or is undefined (will then assume true)
            if (sendEmail) {

              personalizations.push({
                "subject": 'Event Cancellation',
                "templateId": AppConfig.SENDGRID_TEMPLATES.EventCancellation,
                "to": {
                  "name": member.firstName + ' ' + member.lastName,
                  "email": member.email
                },
                "from": {
                  "name": this.accountService.member.firstName + ' ' + this.accountService.member.lastName,
                  "email": AppConfig.NOREPLY_EMAIL
                },
                "replyTo": {
                  "name": this.accountService.member.firstName + ' ' + this.accountService.member.lastName,
                  "email": this.accountService.member.email
                },
                "dynamic_template_data": {
                  "subject": 'Event Cancellation',
                  "groupName": this.event.name,
                  "groupAvatarURI": this.event.avatarEmail,
                  "showGroupAvatar": showGroupAvatar,
                  "eventDt": moment(this.eventForm.controls.eventDt.value).format('dddd MMMM D, YYYY').toString()
                },
                "custom_args": {
                  memberId: member.id,
                  eventId: this.event.id,
                  environment: environment.POSTHOG_CONFIG.environment
                },
                "hideWarnings": true
              });

            }

          });

      });

    await this.appFunction.sendEmail(personalizations);

  }

  private sendEventCancellationNotification(): Promise<void> {

    return new Promise<void>((resolve, reject) => {

      //send cancelation to all group members
      this.event
        .getNotificationDistributionList(AppConfig.EDIT_MODE.new, CommunicationType.GroupMembers) //force to new so cancelation is always sent to all members
        .then((members) => {

          const title: string = 'Event Cancellation';
          const message: string = 'The ' + this.event.name + ' event scheduled for ' + moment(this.eventForm.controls.eventDt.value).format('dddd MMMM D, YYYY').toString() + ' has been canceled';

          const memberIds = [];
          members.forEach((member) => {
            memberIds.push({ memberId: member.id });
          });

          this.appFunction
            .sendNotification(this.accountService.member.id, memberIds, title, message, undefined, {})
            .then(() => {
              resolve();
            })
            .catch((err) => {
              reject(err);
            });

        });

    });

  }

  private sendEventCancellationCommunications(): Promise<void> {

    return new Promise<void>((resolve, reject) => {

      try {

        if (this.event.group?.type === GroupType.Event) {

          //send communications
          const p = this.sendEventCancellationNotification();
          const q = this.sendEventCancellationEmail();

          Promise
            .all([p, q])
            .then(() => {

              this.appFunction
                .queueToast({
                  message: 'The event cancellation email/notification has been sent.',
                  position: 'top',
                  duration: 4000,
                  color: 'secondary',
                  closeButtonText: 'Ok'
                });

              resolve();


            })
            .catch((err) => {
              reject(err);
            });

        } else {
          resolve();
        }
      } catch (err) {
        console.log('event-details.page.ts sendEventCancellationCommunications error', JSON.stringify(err));
        reject(err);
      }

    });

  }

  sendAdhocEventUpdate() {

    //if this is a group event then give option for all group members or just event players
    if (this.event.group) {

      //ask to send communications
      this.appFunction
        .actionCtrl
        .create({
          header: 'Notify group of this event update?',
          buttons: [
            {
              text: 'Cancel',
              role: 'cancel'
            },
            {
              text: 'Yes, only Event players',
              handler: () => {
                this.sendEventCommunications(CommunicationType.EventPlayers)
                  .catch((err) => {
                    console.log('event-detail.page.ts sendEventUpdate just event players sendCommunications', err);
                  });

              }
            },
            {
              text: 'Yes, all Group members',
              handler: () => {
                this.sendEventCommunications(CommunicationType.GroupMembers)
                  .catch((err) => {
                    console.log('event-detail.page.ts sendEventUpdate all group members sendCommunications', err);
                  });

              }
            }]
        })
        .then((action) => {
          action.present();
        });

    } else { //this is a non-group event...send to just event players

      this.sendEventCommunications(CommunicationType.EventPlayers)
        .catch((err) => {
          console.log('event-detail.page.ts sendEventUpdate non-group sendCommunications', err);
        });

    }

  }

  private async shouldSendEventCommunications(loading: HTMLIonLoadingElement): Promise<void> {

    return new Promise((resolve) => {

      //send event communications for events
      if (this.event.group?.type === GroupType.Event) {

        //if event is new then send communications
        if (this.editMode === AppConfig.EDIT_MODE.new) {

          //send and resolve
          this.sendEventCommunications(CommunicationType.GroupMembers);
          resolve();

        } else { //else ask if communications should be sent

          const buttons: any[] = [];

          //always add cancel button
          buttons.push({
            text: 'Not now',
            role: 'cancel'
          });

          //if group event then add option to send to all group members
          if (this.event.group) {

            buttons.push({
              text: 'Yes, only Event players',
              handler: (data) => {
                this.sendEventCommunications(CommunicationType.EventPlayers)
                  .catch((err) => {
                    console.log('event-detail.page.ts done sendCommunications', err);
                  });
              }
            });

            buttons.push({
              text: 'Yes, all Group members',
              handler: () => {
                this.sendEventCommunications(CommunicationType.GroupMembers)
                  .catch((err) => {
                    console.log('event-detail.page.ts done sendCommunications', err);
                  });
              }
            });

          } else { //else no group

            buttons.push({
              text: 'Yes, all event players',
              handler: () => {
                this.sendEventCommunications(CommunicationType.EventPlayers)
                  .catch((err) => {
                    console.log('event-detail.page.ts done sendCommunications', err);
                  });
              }
            });

          }

          //ask to send communications
          this.appFunction
            .alertCtrl
            .create({
              header: 'Notification!',
              message: 'Send event update?',
              buttons: buttons
            })
            .then((alert) => {

              //added this because the alert wouldn't show on top of the loading control
              loading.hidden = true;

              alert.present();

              alert
                .onDidDismiss()
                .then(() => {
                  resolve();
                });

            });

        }

      } else {
        resolve();
      }

    });

  }

  private closePage(loading: HTMLIonLoadingElement) {

    loading.dismiss();

    //now close screen
    this.appFunction
      .modalCtrl
      .dismiss({ event: this.event }, 'new')
      .then(() => {

        //if new event and not via group trip then show toast
        if (this.editMode === AppConfig.EDIT_MODE.new && this.event.type !== EventType.GroupTrip) {

          this.appFunction
            .queueToast({
              message: 'Your new event is awaiting you on the Home screen.',
              position: 'top',
              duration: 4000,
              color: 'secondary',
              closeButtonText: 'Ok'
            });

        }

      });

  }

  done() {

    //save the form if dirty
    if (this.eventForm.dirty) {

      //signal validation toast that form has been submitted
      this.isFormSubmitted = true;

      //save the form if valid 
      if (this.eventForm.valid) {

        this.appFunction
          .loadingCtrl
          .create({ message: 'Saving event...' })
          .then((loading) => {

            loading.present();

            //get form data
            this.event.description = this.eventForm.controls.description.value;
            this.event.numberTeeTimes = this.eventForm.controls.numberTeeTimes.value;
            this.event.eventDt = firebase.firestore.Timestamp.fromDate(this.getTeeTimeDateTime(this.eventForm.controls.eventDt.value, this.eventForm.controls.firstTeeTime.value));
            this.event.teeTimeInterval = this.eventForm.controls.teeTimeInterval.value;
            this.event.numberOfHoles = this.eventForm.controls.numberOfHoles.value;
            this.event.startingHoleIndex = this.eventForm.controls.startingHoleIndex.value;
            this.event.nineHolesOnlyIndex = Number(this.eventForm.controls.nineHolesOnlyIndex.value);

            //if number of holes is 18 then no need to set nineHolesOnlyIndex
            //TODO: move this to the event class save method
            if (this.event.numberOfHoles === 18) {
              this.event.nineHolesOnlyIndex = undefined;
            }

            //if number of holes is 9 then set startingHoleIndex to 0 
            //TODO: or is it the index of the first hole? could be 0 or 9 TEST THIS
            //TODO: move this to the event class save method
            if (this.event.numberOfHoles === 9) {

              //if nine holes only index is not set then set to 0
              if (!this.appFunction.isNumeric(this.event.nineHolesOnlyIndex)) {
                this.event.nineHolesOnlyIndex = 0;
              }

              this.event.startingHoleIndex = this.eventForm.controls.tee.value.nines[this.event.nineHolesOnlyIndex].holes[0].number - 1;

            }

            //set club, course and tee data
            this.event
              .setClub(this.eventForm.controls.club.value.clubId,
                this.eventForm.controls.course.value.courseId,
                this.eventForm.controls.tee.value.teeId)
              .then(() => {

                //save/update event
                this.event
                  .save()
                  .then(() => {

                    //update player position and tee time
                    let position: number = 0;
                    this
                      .teeTimes
                      .forEach((teeTime) => {

                        teeTime
                          .players
                          .forEach((player) => {

                            position++;
                            player.position = position;
                            player.teeTime = firebase.firestore.Timestamp.fromDate(teeTime.teeTime);
                            player.eventId = this.event.id;
                            player.save();

                            //now update the player's guest(s)
                            player
                              .guests
                              .all
                              .forEach((guest) => {
                                guest.position = player.position;
                                guest.teeTime = firebase.firestore.Timestamp.fromDate(teeTime.teeTime);
                                guest.eventId = this.event.id;
                                guest.save();
                              });

                          });

                      });

                    //send communications
                    this.shouldSendEventCommunications(loading)
                      .then(() => {
                        loading.dismiss();
                        this.closePage(loading);
                      });

                  });

              });

          });

      } else {
        //show any untouched errors
        this.appFunction.setDirtyControlAsTouched(this.eventForm);
      }

    } else {
      this.appFunction.modalCtrl.dismiss({ event: this.event }, 'update');
    }

  }

  cancel() {

    //confirm that user wants to discard changes
    if (this.eventForm.dirty) {

      this.appFunction
        .alertCtrl
        .create({
          header: 'Discard changes?',
          message: 'You have made changes to your Event. Do you want to discard these changes?',
          buttons: [
            {
              text: 'No',
              handler: () => {
              }
            },
            {
              text: 'Yes, discard',
              handler: () => {

                //if existing event then...
                if (this.editMode === AppConfig.EDIT_MODE.update) {

                  //...remove players that were added but not saved
                  this.event
                    .players
                    .active
                    .groupMembersOnly
                    .forEach((player) => {

                      //if player hasn't been saved then remove it
                      if (!(<AppEventPlayer>player).saved) {
                        (<AppEventPlayer>player).remove();
                      }

                    });

                }

                this.appFunction.modalCtrl.dismiss({ event: undefined }, 'cancel');

              }
            }
          ]
        })
        .then((alert) => {
          alert.present();
        });

    } else {
      this.appFunction.modalCtrl.dismiss({ event: undefined }, 'cancel');
    }

  }

}
