<div class="scorecardPage">
  <!-- because this page is being rotated to landscape we are using pain html markup. ionic's page html wasn't behaving as expected. -->

  <div class="header">
    <ion-toolbar>
      <!-- segment -->
      <ion-segment [(ngModel)]="selectedScorecard" slot="start" mode="ios">
        <ion-segment-button value="matchScorecard" *ngIf="match">
          <ion-label>{{match.getGame(match.game).name}} Scorecard</ion-label>
        </ion-segment-button>
      </ion-segment>
      <ion-buttons slot="primary">
        <ion-button fill="solid" shape="round" color="light" (click)="done()">Close</ion-button>
      </ion-buttons>
    </ion-toolbar>
  </div>

  <div class="content">

    <!-- MATCH SCORECARD -->
    <div *ngFor="let subMatch of match.subMatches;" class="subMatchContainer">
      <ng-container *ngTemplateOutlet="scorecard; context: { $implicit: subMatch }"></ng-container>
    </div>

    <ion-note class="instructional" style="margin-top: 10px;" *ngIf="match?.handicapType===HandicapType.LowBall">
      Strokes are based on low ball of the match.
    </ion-note>

    <ion-note class="instructional" style="margin-top: 10px;"
      *ngIf="match?.handicapAllowance!==HandicapAllowance.Percent100">
      Strokes are based on the event match handicap allowance.
    </ion-note>

  </div>

</div>

<ng-template #scorecard let-subMatch>

  <!-- *********** STROKE PLAY *********** -->
  <ion-grid *ngIf="match.game === GameType.StrokePlay">

    <!-- match name -->
    <ion-row class="matchName">
      <ion-col size="12">
        {{match.getGame(match.game)?.name}}
      </ion-col>
    </ion-row>

    <!-- match config -->
    <ion-row class="matchConfig">
      <ion-col size="12">
        {{match.config()}}
      </ion-col>
    </ion-row>

    <!-- match scoring -->
    <ion-grid class="matchScoring">
      <ion-row style="height: initial;">
        <ion-col size="5">

          <!-- the first of three grids -->
          <ion-grid>

            <!-- header row -->
            <ion-row class="ion-align-items-center scorecardHeader backgroundHoleNumber">
              <ion-col class="firstColumn" size="2">
                <ion-text class="ion-float-left">Pos</ion-text>
              </ion-col>

              <ion-col size="4">
                <ion-text class="ion-float-left">Player</ion-text>
              </ion-col>

              <ion-col size="2">
                <ion-text class="ion-float-right">{{'R' + (currentEventIndex() + 1).toString()}}</ion-text>
              </ion-col>

              <ion-col size="2">
                <ion-text class="ion-float-right">Thru</ion-text>
              </ion-col>

              <ion-col size="2">
                <ion-text class="ion-float-right">Total</ion-text>
              </ion-col>

            </ion-row>

            <!-- player rows -->
            <ng-container
              *ngIf="this.match.subMatches[0].totalScoreLeaderboard.events[aggregateEventId()]?.teamsList?.length > 0 && this.match.parent.events.all.length > 0; else noteams1;">

              <!-- use the aggregate event to drive the display of the teams -->
              <ion-row class="backgroundPlayer"
                *ngFor="let teamScoreCard of this.match.subMatches[0].totalScoreLeaderboard.events[aggregateEventId()].teamsList;">

                <!-- position -->
                <ion-col class="firstColumn" size="2">
                  {{teamScoreCard.tiedIndicator + teamScoreCard.position}}
                </ion-col>

                <!-- team -->
                <ion-col class="ion-text-nowrap ion-float-left fadeText" size="4">{{teamScoreCard.team.name}}</ion-col>

                <!-- most current event to par score -->
                <ion-col size="2">
                  <ion-text class="ion-float-right">
                    <ion-text>{{currentEventTeam(teamScoreCard.team.id)?.teamToPar <= 0 ? '' : '+' }}</ion-text>
                        <ion-text>{{currentEventTeam(teamScoreCard.team.id)?.teamToPar === 0 ? 'E' :
                          currentEventTeam(teamScoreCard.team.id)?.teamToPar}}</ion-text>
                    </ion-text>
                </ion-col>

                <!-- most current event hole thru index -->
                <ion-col size="2">
                  <ion-text class="ion-float-right">
                    {{currentEventTeam(teamScoreCard.team.id)?.holeThruMatchIndex + 1}}
                  </ion-text>
                </ion-col>

                <!-- aggregate event score -->
                <ion-col size="2">
                  <ion-text class="ion-float-right">
                    {{teamScoreCard.teamToPar}}
                  </ion-text>
                </ion-col>

              </ion-row>

            </ng-container>

          </ion-grid>

        </ion-col>

        <ion-col class=" scroll" size="6">

          <!-- the second of three grids -->
          <table>

            <!-- header row -->
            <tr class="ion-align-items-center scorecardHeader  backgroundHoleNumber">
              <td *ngFor="let event of this.match.parent.events.all; let roundNumber = index;"
                [ngClass]="{currentRound: roundNumber === currentEventIndex()}">
                <ion-label class="ion-float-right">{{'R'
                  + (roundNumber + 1).toString()}}</ion-label>
              </td>
            </tr>

            <!-- player rows -->
            <ng-container
              *ngIf="this.match.subMatches[0].totalScoreLeaderboard.events[aggregateEventId()]?.teamsList && this.match.parent.events.all.length > 0; else noteams2;">

              <ng-container
                *ngFor="let teamScoreCard of this.match.subMatches[0].totalScoreLeaderboard.events[aggregateEventId()].teamsList">

                <!-- use the aggregate event to drive the display of the teams -->
                <tr class="backgroundPlayer">

                  <!-- position -->
                  <td *ngFor="let event of this.match.parent.events.all; let roundNumber = index;"
                    style="margin-top: auto; margin-bottom: auto;"
                    [ngClass]="{currentRound: roundNumber === currentEventIndex()}">
                    <ion-label
                      class="ion-float-right">{{this.match.subMatches[0].totalScoreLeaderboard.events[event.id]?.teams[teamScoreCard.team.id]?.teamScore
                      || '-'}}
                    </ion-label>
                  </td>

                </tr>

              </ng-container>

            </ng-container>

          </table>

        </ion-col>

        <ion-col size="1">

          <!-- the third of three grids -->
          <ion-grid class="matchTotal">

            <!-- header row -->
            <ion-row class="ion-align-items-center scorecardHeader backgroundHoleNumber">
              <ion-col class="lastColumn" size="12">
                <ion-text class="ion-float-right">Strokes</ion-text>
              </ion-col>
            </ion-row>

            <!-- player rows -->
            <ng-container
              *ngIf="this.match.subMatches[0].totalScoreLeaderboard.events[aggregateEventId()]?.teamsList?.length > 0 && this.match.parent.events.all.length > 0; else noteams3;">

              <!-- use the aggregate event to drive the display of the teams -->
              <ion-row class="ion-align-items-center scorecardPlayer backgroundPlayer"
                *ngFor="let teamScoreCard of this.match.subMatches[0].totalScoreLeaderboard.events[aggregateEventId()].teamsList;">

                <!-- aggregate event score -->
                <ion-col class="lastColumn" size="12">
                  <ion-text class="ion-float-right">
                    {{teamScoreCard.teamScore}}
                  </ion-text>
                </ion-col>

              </ion-row>

            </ng-container>

          </ion-grid>

        </ion-col>

      </ion-row>
    </ion-grid>

  </ion-grid>

  <!-- *********** STABLEFORD PLAY *********** -->
  <ion-grid *ngIf="match.game === GameType.Stableford">

    <!-- match name -->
    <ion-row class=" matchName">
      <ion-col size="12">
        {{match.getGame(match.game)?.name}}
      </ion-col>
    </ion-row>

    <!-- match config -->
    <ion-row class=" matchConfig">
      <ion-col size="12">
        {{match.config()}}
      </ion-col>
    </ion-row>

    <!-- match scoring -->
    <ion-grid class=" matchScoring">
      <ion-row style="height: initial;">
        <ion-col size="5">

          <!-- the first of three grids -->
          <ion-grid>

            <!-- header row -->
            <ion-row class="ion-align-items-center scorecardHeader backgroundHoleNumber">
              <ion-col class="firstColumn" size="2">
                <ion-text class="ion-float-left">Pos</ion-text>
              </ion-col>

              <ion-col size="4">
                <ion-text class="ion-float-left">Player</ion-text>
              </ion-col>

              <ion-col size="2">
                <ion-text class="ion-float-right">{{'R' + (currentEventIndex() + 1).toString()}}</ion-text>
              </ion-col>

              <ion-col size="2">
                <ion-text class="ion-float-right">Thru</ion-text>
              </ion-col>

              <ion-col size="2">
                <ion-text class="ion-float-right">Total</ion-text>
              </ion-col>

            </ion-row>

            <!-- player rows - display scores and position when the teams have scores for the sub match -->
            <ng-container
              *ngIf="this.match.subMatches[0].totalScoreLeaderboard.events[aggregateEventId()]?.teamsList?.length > 0 && this.match.parent.events.all.length > 0; else noteams1;">

              <!-- use the aggregate event to drive the display of the teams -->
              <ion-row class="ion-align-items-center backgroundPlayer"
                *ngFor="let teamScoreCard of this.match.subMatches[0].totalScoreLeaderboard.events[aggregateEventId()].teamsList;">

                <!-- position -->
                <ion-col class="firstColumn" size="2">
                  {{teamScoreCard.tiedIndicator + teamScoreCard.position}}
                </ion-col>

                <!-- team -->
                <ion-col class="ion-text-nowrap ion-float-left fadeText" size="4">{{teamScoreCard.team.name}}</ion-col>

                <!-- most current event to par score -->
                <ion-col size="2">
                  <ion-text class="ion-float-right">
                    <ion-text>{{currentEventTeam(teamScoreCard.team.id)?.teamScore}}</ion-text>
                  </ion-text>
                </ion-col>

                <!-- most current event hole thru index -->
                <ion-col size="2">
                  <ion-text class="ion-float-right">
                    {{currentEventTeam(teamScoreCard.team.id)?.holeThruMatchIndex + 1}}
                  </ion-text>
                </ion-col>

                <!-- aggregate event score -->
                <ion-col size="2">
                  <ion-text class="ion-float-right">
                    {{teamScoreCard.teamToPar}}
                  </ion-text>
                </ion-col>

              </ion-row>

            </ng-container>

          </ion-grid>

        </ion-col>

        <ion-col class=" scroll" size="6">

          <!-- the second of three grids -->
          <table>

            <!-- header row -->
            <tr class="ion-align-items-center scorecardHeader backgroundHoleNumber">
              <td *ngFor="let event of this.match.parent.events.all; let roundNumber = index;">
                <ion-text class="ion-float-right">{{'R' + (roundNumber + 1).toString()}}</ion-text>
              </td>
            </tr>

            <!-- player rows -->
            <ng-container
              *ngIf="this.match.subMatches[0].totalScoreLeaderboard.events[aggregateEventId()]?.teamsList && this.match.parent.events.all.length > 0; else noteams2;">

              <ng-container
                *ngFor="let teamScoreCard of this.match.subMatches[0].totalScoreLeaderboard.events[aggregateEventId()].teamsList">

                <!-- use the aggregate event to drive the display of the teams -->
                <tr class="backgroundPlayer">

                  <!-- position -->
                  <td *ngFor="let event of this.match.parent.events.all;"
                    style="margin-top: auto; margin-bottom: auto;">
                    <ion-text
                      class="ion-float-right">{{this.match.subMatches[0].totalScoreLeaderboard.events[event.id]?.teams[teamScoreCard.team.id]?.teamScore
                      || '-'}}
                    </ion-text>
                  </td>

                </tr>

              </ng-container>

            </ng-container>

          </table>

        </ion-col>

        <ion-col size="1">

          <!-- the third of three grids -->
          <ion-grid class="matchTotal">

            <!-- header row -->
            <ion-row class="ion-align-items-center scorecardHeader backgroundHoleNumber">
              <ion-col class="lastColumn" size="12">
                <ion-text class="ion-float-right">Strokes</ion-text>
              </ion-col>
            </ion-row>

            <!-- player rows - display scores and position when the teams have scores for the sub match -->
            <ng-container
              *ngIf="this.match.subMatches[0].totalScoreLeaderboard.events[aggregateEventId()]?.teamsList?.length > 0 && this.match.parent.events.all.length > 0; else noteams3;">

              <!-- use the aggregate event to drive the display of the teams -->
              <ion-row class="ion-align-items-center scorecardPlayer backgroundPlayer"
                *ngFor="let teamScoreCard of this.match.subMatches[0].totalScoreLeaderboard.events[aggregateEventId()].teamsList;">

                <!-- aggregate event score -->
                <ion-col class="lastColumn" size="12">
                  <ion-text class="ion-float-right">
                    {{teamScoreCard.teamScore}}
                  </ion-text>
                </ion-col>

              </ion-row>

            </ng-container>

          </ion-grid>

        </ion-col>

      </ion-row>
    </ion-grid>

  </ion-grid>

</ng-template>

<!-- no teams yet -->
<ng-template #noteams1>
  <ion-row>
    <ion-col size="12">
      <ion-label style="padding-left: 3px;">No scores have been posted for this match.</ion-label>
    </ion-col>
  </ion-row>
</ng-template>

<!-- no teams yet -->
<ng-template #noteams2>
  <tr>
    <td size="12">
      <ion-label class="ion-float-right">
      </ion-label>
    </td>
  </tr>
</ng-template>

<!-- no teams yet -->
<ng-template #noteams3>
  <ion-row>
    <ion-col size="12">
      <ion-label class="ion-float-right">
      </ion-label>
    </ion-col>
  </ion-row>
</ng-template>