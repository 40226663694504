import { Component, OnInit } from '@angular/core';
import { NavParams } from '@ionic/angular';
import { AppEvent, AppEventPlayer, } from 'src/app/app.event';
import { AppTeam, AppSubMatch, MatchPlayerI } from 'src/app/app.match';
import { AppFunction } from '../../app.function';
import { DragulaService } from 'ng2-dragula';
import { GameType } from 'src/app/app.config';
import * as autoScroll from 'dom-autoscroller';

@Component({
  selector: 'team-popover',
  templateUrl: './team-popover.page.html',
  styleUrls: ['./team-popover.page.scss'],
  providers: [DragulaService]
})
export class TeamPopoverPage implements OnInit {

  players: AppEventPlayer[];
  playersWithoutTeam: AppEventPlayer[];
  subMatch: AppSubMatch;
  event: AppEvent;
  teams: AppTeam[];
  bagName: string = 'players-bag';
  isDirty: boolean = false;
  numberOfPlayersPerTeam: number;
  scroll;

  constructor(
    public appFunction: AppFunction,
    private navParams: NavParams,
    public dragulaService: DragulaService) {
  }

  ngOnInit() {

    this.dragulaService
      .setOptions(this.bagName, {
        isContainer: function (el) {
          return false; //elements in drake.containers will be taken into account
        },
        moves: function (el, source, handle, sibling) {
          return el.classList.contains('doDragTeamPlayer');
        },
        accepts: function (el, target, source, sibling) {
          return true; // elements can be dropped in any of the `containers` by default
        },
        invalid: function (el, handle) {
          return el.classList.contains('doNotDrag');
        },
        direction: 'vertical',              // X axis is considered when determining where an element would be dropped
        copy: false,                        // elements are moved by default, not copied
        copySortSource: false,              // elements in copy-source containers can be reordered
        revertOnSpill: true,                // spilling will put the element back where it was dragged from, if this is true
        removeOnSpill: false,               // spilling will `.remove` the element, if this is true
        //mirrorContainer: document.getElementById('team-popover-content'),     // set the element that gets mirror elements appended
        //mirrorContainer: document.body,
        ignoreInputTextSelection: true      // allows users to select input text, see details below
      });

    this.dragulaService
      .drop
      .subscribe((item) => {

        //let the user know that all is good
        this.appFunction.buzz();

        //set isDirty for the screen
        this.isDirty = true;

        //just mark all teams as dirty so that they save (DO WE NEED THIS ANYMORE)
        this.teams
          .forEach((team) => {
            team.markAsDirty();
          });

      });

    //get passed in data
    this.players = this.navParams.get('players');
    this.playersWithoutTeam = this.navParams.get('playersWithoutTeam');
    this.subMatch = this.navParams.get('subMatch');
    this.event = <AppEvent>this.subMatch.match.parent;

    //indicate that member was prompted to create team. this will ensure that we only ask once
    this.subMatch.teamCreatePrompted = true;

    //get the number of players per team
    this.numberOfPlayersPerTeam = this.subMatch.match.getTeamSize(this.subMatch.match.game, this.subMatch.match.teamSize).descriptionHeader;

    //get sub-match position (we do this so we can pre-configure the second and third spins)
    const subMatchPostion: number = this.subMatch
      .match
      .subMatches
      .findIndex((subMatch) => {
        return subMatch.id === this.subMatch.id;
      });

    //find any two man teams (belonging to this tee time) for the given submatch (DOES THIS CODE EVER RUN???)
    this.teams = this.subMatch
      .teams
      .filter((subMatchTeam) => {

        //look for where the players in the subMatchTeam...
        return subMatchTeam
          .players
          .all
          .some((subMatchPlayer) => {

            //...intersect with players in teeTimePlayers
            return this.players
              .some((teeTimePlayer) => {
                return teeTimePlayer.id === subMatchPlayer.id;
              });

          })

      });

    //now determine if we have enough teams for the players
    const promiseArray: any[] = [];

    //get the number of teams needed
    const numberOfTeamsNeeded: number = Math.ceil(this.players.length / this.numberOfPlayersPerTeam) - this.teams.length;
    if (numberOfTeamsNeeded > 0) {

      for (let index = 0; index <= (numberOfTeamsNeeded - 1); index++) {

        const team: AppTeam = new AppTeam();

        //add the necessary teams 
        const p = team
          .initialize(this.subMatch)
          .then(() => {
            //push team on local array so dragular works
            this.teams.push(team);
          });

        promiseArray.push(p)

      }

      //once all teams are created
      Promise
        .all(promiseArray)
        .then(() => {

          //for spins
          if ([GameType.Spins9, GameType.Spins18].includes(this.subMatch.match.game)) {

            switch (subMatchPostion) {
              case 0:

                //noop
                break;

              case 1:

                //split the players from first submatch, first team into two teams 
                this.addPlayerToTeam(<AppEventPlayer>this.subMatch.match.subMatches[0].teams[0].players.all[0], this.teams[0]);
                this.addPlayerToTeam(<AppEventPlayer>this.subMatch.match.subMatches[0].teams[0].players.all[1], this.teams[1]);

                //mark as dirty
                this.isDirty = true;

                break;

              case 2:

                //get first player from second submatch, first team...and find playing partner 
                const firstPlayer: MatchPlayerI = this.subMatch.match.subMatches[1].teams[0].players.all[0];
                this.addPlayerToTeam(firstPlayer, this.teams[0]);
                this.addPlayerToTeam(this.findTeamPlayer(firstPlayer), this.teams[0]);

                //get second player from second submatch, first team...and find playing partner 
                const secondPlayer: MatchPlayerI = this.subMatch.match.subMatches[1].teams[0].players.all[1];
                this.addPlayerToTeam(secondPlayer, this.teams[1]);
                this.addPlayerToTeam(this.findTeamPlayer(secondPlayer), this.teams[1]);

                //mark as dirty
                this.isDirty = true;

                break;

            }

          }

        });

    }

    //need settimeout because elements aren't available until after this method runs
    setTimeout(
      () => {

        //my container that I want to scroll has #dragulaScroll
        const stepContainer: HTMLDivElement = document.querySelector('#dragulaScroll');
        const drake = this.dragulaService.find(this.bagName);

        this.scroll = autoScroll(stepContainer, {
          margin: 30,
          maxSpeed: 10,
          scrollWhenOutside: true,
          autoScroll: function () {

            //console.log('dragging', drake.drake.dragging, this.down);

            if (this.down && drake.drake.dragging) {
              stepContainer.style.overflow = 'hidden';
              //console.log('prevent scroll');
            } else {
              stepContainer.style.overflow = 'scroll';
              //console.log('allow scroll');
            }

            return this.down && drake.drake.dragging;

          }
        });

      }
      , 0);

  }

  findTeamPlayer(playerNeedsPartner: MatchPlayerI): AppEventPlayer {

    //get first player from second submatch, second team
    const alreadyPlayedTogether: AppEventPlayer[] = [];

    //now find who this player has already been teamed up/played with
    this.subMatch
      .match
      .subMatches
      .forEach((subMatch) => {

        subMatch
          .teams
          .forEach((team) => {

            //dtermine if playerNeedsPartner has already been in this team...
            const playedTogether: boolean = team.players.all.some((player) => {
              return player.id === playerNeedsPartner.id;
            });

            //...and if so then add to alreadyplayed array
            if (playedTogether) {
              alreadyPlayedTogether.push(...<AppEventPlayer[]>team.players.all);
            }

          });

      });

    //now find which player...
    const notYetPlayedTogether: AppEventPlayer[] = this.players
      .filter((teeTimePlayer) => {

        const index: number = alreadyPlayedTogether.findIndex((player) => {
          return player.id === teeTimePlayer.id;
        });

        //not found so this is the player we're lookig for
        return index === -1;

      });

    return notYetPlayedTogether[0];

  }

  addPlayerToTeam(player: MatchPlayerI, team: AppTeam) {

    //push player onto team
    team.players.add(player);

    //now find and remove player from playersWithoutTeam
    const playerWithoutTeamIndex: number = this.playersWithoutTeam.findIndex((playerWithoutTeam) => {
      return playerWithoutTeam.id === player.id;
    });
    this.playersWithoutTeam.splice(playerWithoutTeamIndex, 1);

  }

  createTeams() {

    //have all players been assigned to a team?
    if (this.playersWithoutTeam.length === 0) {

      //if yes then be sure no team has the appropriate number of players (per the match configuration)
      const properlySizedTeams: boolean = this.teams
        .every((team) => {
          return team.players.all.length <= this.numberOfPlayersPerTeam;
        });

      //if all good then save the teams and close this popover
      if (properlySizedTeams) {

        //make sure the sub match has the new teams before saving
        this.teams
          .forEach((team) => {
            this.subMatch.addTeam(team);
          });

        //save event (TODO: we should only save the submatch, not the entire event?)
        this.event
          .save()
          .then(() => {

            this.appFunction
              .modalCtrl
              .getTop()
              .then((p) => {
                p.dismiss();
              });

          });

      } else {

        //display message
        this.appFunction
          .alertCtrl
          .create({
            header: "Oops, something isn't quite right!",
            message: 'Be sure that no team has more than two players.',
            buttons: [
              {
                text: 'OK',
                handler: () => {
                }
              }
            ]
          })
          .then((alert) => {
            alert.present();
          });

      }

    } else {

      //display message
      this.appFunction
        .alertCtrl
        .create({
          header: 'Oops, you forgot something!',
          message: 'Be sure to drag all players to the desired team.',
          buttons: [
            {
              text: 'OK',
              handler: () => {
              }
            }
          ]
        })
        .then((alert) => {
          alert.present();
        });

    }

  }

  cancel() {

    //confirm that user wants to discard changes
    if (this.isDirty) {

      this.appFunction
        .alertCtrl
        .create({
          header: 'Discard changes?',
          message: 'You have made changes to your teams. Do you want to discard these changes?',
          buttons: [
            {
              text: 'No',
              handler: () => {
              }
            },
            {
              text: 'Yes, discard',
              handler: () => {

                this.appFunction
                  .modalCtrl
                  .getTop()
                  .then((p) => {
                    p.dismiss();
                  });

              }
            }
          ]
        })
        .then((alert) => {
          alert.present();
        });

    } else {

      this.appFunction
        .modalCtrl
        .getTop()
        .then((p) => {
          p.dismiss();
        });

    }

  }

}
