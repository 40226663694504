import { Component, Input, ViewChild } from '@angular/core';
import { AppEvent, ScoringMode, } from 'src/app/app.event';
import { AppMatch, AppSubMatch } from 'src/app/app.match';
import { GameType, HandicapType } from '../../app.config';
import { MatchMemberDetailPage } from '../../pages/match-member-detail/match-member-detail.page';
import { MatchFormatViewPage } from '../../pages/match-format-view/match-format-view.page';
import { AppFunction } from '../../app.function';
import { AccountService } from '../../app.account';
import { AppConfig } from '../../app.config';
import { IonSlides } from '@ionic/angular';

@Component({
  selector: 'event-match-live-view',
  templateUrl: './event-match-live-view.component.html',
  styleUrls: ['./event-match-live-view.component.scss']
})
export class EventMatchLiveViewComponent {

  //get from the host html
  @Input() match: AppMatch;
  @Input() event: AppEvent;
  @Input() callback;
  @Input() openScorecardMethod: (...args: any) => void;
  @ViewChild('matchSlides') matchSlides: IonSlides;
  HandicapType: typeof HandicapType = HandicapType;
  ScoringMode: typeof ScoringMode = ScoringMode;
  GameType: typeof GameType = GameType;

  slideOpts = {
    pagination: {
      el: '.swiper-pagination',
      type: 'bullets'
    }
  };

  constructor(
    public appFunction: AppFunction,
    public accountService: AccountService
  ) {

  }

  viewMatchFormat() {

    this.appFunction
      .modalCtrlCreate({
        component: MatchFormatViewPage,
        componentProps: {
          match: this.match,
        },
        cssClass: 'custom-modal-auto-height',
        canDismiss: true
      })
      .then((modal) => {

        modal
          .present()
          .catch((err) => {
            console.log('match.component.ts viewMatchFormat modal present error', err);
          });

      })
      .catch((err) => {
        console.log('match.component.ts viewMatchFormat modal create error', err);
      });

  }

  private async editMatchFormat() {

    this.appFunction
      .modalCtrlCreate({
        component: MatchMemberDetailPage,
        presentingElement: await this.appFunction.routerOutlet(),
        componentProps: {
          match: this.match,
          editMode: AppConfig.EDIT_MODE.update,
          matchOptions: AppConfig.EVENT_MATCH_CONFIGURATION
        },
        cssClass: 'custom-modal', //for md
        canDismiss: true
      })
      .then((modal) => {

        modal
          .present()
          .catch((err) => {
            console.log('match.component.ts editMatchFormat modal present error', err);
          });

        modal
          .onDidDismiss()
          .then((result) => {

            //if match was updated...
            if (result.role === 'update') {

              //...signal back to parent
              this.callback();

            }

          });

      })
      .catch((err) => {
        console.log('match.component.ts editMatchFormat modal create error', err);
      });

  }

  private deleteMatchConfirmation() {

    //confirmation
    this.appFunction
      .actionCtrl
      .create({
        header: 'Please confirm',
        buttons: [
          {
            text: 'Delete Match',
            role: 'destructive',
            handler: () => {
              this.match.delete();
            }
          },
          {
            text: 'Cancel',
            role: 'cancel'
          }
        ]
      })
      .then((action) => {
        action.present();
      });

  }

  matchActionConfirmation() {

    //confirmation
    this.appFunction
      .actionCtrl
      .create({
        buttons: [
          {
            text: 'Update Match',
            //icon: 'options',
            handler: () => {
              this.editMatchFormat();
            }
          },
          {
            text: 'Delete Match',
            //icon: 'trash',
            role: 'destructive',
            handler: () => {
              this.deleteMatchConfirmation();
            }
          },
          {
            text: 'Cancel',
            role: 'cancel'
          }
        ]
      })
      .then((action) => {
        action.present();
      });

  }

  updateSlides() {

    if (this.matchSlides) {

      //settime is to over come a defect where the update method doesn't do anything
      setTimeout(() => {
        this.matchSlides.update();
      }, 1);

    }

  }

  createTeam(subMatch: AppSubMatch) {

    //clear teamCreatePrompted
    subMatch.teamCreatePrompted = false;

    //create team
    this.match.createTeam(this.event.players.active.all, this.event);

  }

  teamId(index, item) {
    return item.team.id;
  }

  get showInScorecard(): boolean {
    //if scorecard callback is defined then show the toolbar, this will happen when the parent component is a scorecard
    return this.openScorecardMethod === undefined;
  }

}
